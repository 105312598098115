import { useState, useEffect, Suspense, lazy } from "react"
import { ethers } from 'ethers';
import styled from "styled-components"
// import Categories from "./components/Categories";
import { useParams } from "react-router-dom";
import { BscscanProvider } from "@ethers-ancillary/bsc";
import {
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  useAccount, useContractReads,
  useContractRead,
  useBalance
} from 'wagmi'
import { bscTestnet } from 'wagmi/chains'
import { CURRENCY } from "Project/Landing/Mint/const";
import { FIROFOX_ERC721, FIRO_ERC20, CHAIN } from "const/contract"
import Summary from "./components/Summary";
import linkDarkImg from 'App/assets/images/pullpayment/link-dark.png'
import firoIconImg from 'App/assets/images/pullpayment/firo-icon.png'
import binanceIconImg from 'App/assets/images/pullpayment/binance-icon.png'

const Categories = lazy(() => import('Project/PullPayment/components/Categories.js'));

const PullPayment = ({ dataRelase }) => {
  const { id } = useParams();

  const addressArr = ['', '0xCcAdf482D27Ce845CDDEB0c73E0552778a20d0D4',
    '0xF28f72BA7E681D18947c4Dc7fB138aD9487D419b',
    '0xaE7E2f17Cd295D404106e37AeA5e6A61F70637FE',
    '0x729E57fF064E8E74F29922bf1A14cdB6FF60F51d']

  const [address, setAddress] = useState(addressArr[id]);
  const [isWithdrawing, setIsWithdrawing] = useState({ firo: false, mint: false })
  const [contract, setContract] = useState({ totalSupply: 0, devMinted: 0 })
  const [funds, setFunds] = useState({ bnb: { releasable: 0, released: 0, image: firoIconImg }, firo: { releasable: 0, released: 0, image: binanceIconImg } })

  const setupProviders = () => {
    // let mainnetUrls = ["https://bsc-dataseed.binance.org", "https://bsc-dataseed1.binance.org", "https://bsc-dataseed2.binance.org", "https://rpc.ankr.com/bsc"]
    let testnetUrls = ["https://rpc.ankr.com/bsc_testnet_chapel", "https://data-seed-prebsc-2-s3.binance.org:8545"]
    const urls = testnetUrls;
    const providers = urls.map(url => {
      return { "provider": new ethers.providers.JsonRpcProvider(url), "stallTimeout": 1500 }
    });
    return providers;
  }

  const { data: devMintData, isFetched: devMintFetched } = useContractRead({
    address: FIROFOX_ERC721.address,
    abi: FIROFOX_ERC721.abi,
    functionName: "devMintCount",
    chainId: 97
  })

  const { data: totalSupplyData, isFetched: totalSupplyFetched } = useContractRead({
    address: FIROFOX_ERC721.address,
    abi: FIROFOX_ERC721.abi,
    functionName: "totalSupply",
    chainId: 97
  })

  const { data: dataRelease, isError: isErrorRelease, isLoading: isLoadingRelease, isFetching: isFetchingRelease, refetch: refetchRelease, isRefetching: isRefetchingRelease, isFetched: isFetchedRelease, isSuccess: isSuccessRelease } = useContractReads({
    contracts: [
      {
        address: FIROFOX_ERC721.address,
        abi: FIROFOX_ERC721.abi,
        functionName: 'released',
        chainId: 97,
        args: [FIRO_ERC20.address, address]
      },
      {
        address: FIROFOX_ERC721.address,
        abi: FIROFOX_ERC721.abi,
        functionName: 'released',
        chainId: 97,
        args: [address]
      },
      {
        address: FIROFOX_ERC721.address,
        abi: FIROFOX_ERC721.abi,
        functionName: 'releasable',
        chainId: 97,
        args: [FIRO_ERC20.address, address]
      },
      {
        address: FIROFOX_ERC721.address,
        abi: FIROFOX_ERC721.abi,
        functionName: 'releasable',
        chainId: 97,
        args: [address]
      },
    ],
    watch: true,
  })

  useEffect(() => {
    if (totalSupplyFetched && devMintFetched)
      setContract({ totalSupply: ethers.utils.formatUnits(totalSupplyData, 0), devMinted: ethers.utils.formatUnits(devMintData, 0) })
  }, [totalSupplyFetched, devMintFetched])

  const handleFetch = async () => {
    const response = await refetchRelease?.();
    const [firoReleased, bnbReleased, firoReleasable, bnbReleasable] = response.data
    setFunds({ firo: { releasable: parseFloat(ethers.utils.formatUnits(firoReleasable, 8)), released: parseFloat(ethers.utils.formatUnits(firoReleased, 8)), image: firoIconImg }, bnb: { releasable: parseFloat(ethers.utils.formatUnits(bnbReleasable, 18)), released: parseFloat(ethers.utils.formatUnits(bnbReleased, 18)), image: binanceIconImg } })
  }

  useEffect(() => {
    handleFetch();
    // if (bnbReleasedFetched && firoReleasedFetched && firoReleasableFetched && bnbReleasableFetched) {
    //   setFunds({ firo: { releasable: parseFloat(ethers.utils.formatUnits(firoReleasableData, 8)), released: parseFloat(ethers.utils.formatUnits(firoReleasedData, 8)), image: firoIconImg }, bnb: { releasable: parseFloat(ethers.utils.formatUnits(bnbReleasableData, 18)), released: parseFloat(ethers.utils.formatUnits(bnbReleasedData, 18)), image: binanceIconImg } })
    // }
  }, [dataRelease])

  const { config: firoReleaseConfig } = usePrepareContractWrite({
    address: FIROFOX_ERC721.address,
    abi: FIROFOX_ERC721.abi,
    functionName: 'release',
    chainId: 97,
    args: [FIRO_ERC20.address, address]

  })

  const { config: bnbReleaseConfig } = usePrepareContractWrite({
    address: FIROFOX_ERC721.address,
    abi: FIROFOX_ERC721.abi,
    functionName: 'release',
    chainId: 97,
    args: [address]
  })

  const { data: firoReleaseData, writeAsync: firoReleaseWrite, isFetching: isFetchingFiroRelease, isLoading: isLoadingFiroRelease, isSuccess: isSuccessFiroRelease } = useContractWrite(firoReleaseConfig)
  const { data: bnbReleaseData, writeAsync: bnbReleaseWrite, isFetching: isFetchingBnbRelease, isLoading: isLoadingBnbRelease, isSuccess: isSuccessBnbRelease } = useContractWrite(bnbReleaseConfig)

  // useEffect(() => {
  //   console.log('firoReleaseData', firoReleaseData);
  //   console.log('bnbReleaseData', bnbReleaseData);
  // }, [firoReleaseData, bnbReleaseData, isSuccessFiroRelease, isSuccessBnbRelease])

  const handleWithdraw = async (currency) => {
    if (currency === CURRENCY.WFIRO) {
      try {
        setIsWithdrawing(prev => {
          return { ...prev, firo: true }
        });
        let tx = await firoReleaseWrite?.()
        let receipt = await tx.wait();
        console.log(receipt);
      } catch (error) {
        console.error(error);
      } finally {
        await refetchRelease?.();
        setIsWithdrawing(prev => {
          return { ...prev, firo: false }
        });
      }
      setIsWithdrawing(prev => {
        return { ...prev, firo: false }
      });

    } else if (currency === CURRENCY.BNB) {
      try {
        setIsWithdrawing(prev => {
          return { ...prev, bnb: true }
        });
        let tx = await bnbReleaseWrite?.()
        let receipt = await tx.wait();
        console.log(receipt);
      } catch (error) {
        console.error(error)
      } finally {
        await refetchRelease?.();
        setIsWithdrawing(prev => {
          return { ...prev, bnb: false }
        });
      }
    }
  }

  return <Wrapper>
    <Suspense fallback={<div></div>}>
      <Container>
        <Body>
          <Identity>
            <WalletName>Wallet {id}</WalletName>
            <WalletAddress><a href={`${CHAIN.blockExplorerUrl}/address/${address}`} target="_blockExplorerAddress">{address} <LinkImg width="17" height="17" src={linkDarkImg} alt="" /></a></WalletAddress>
          </Identity>
          <Unreleased>
            <Suspense fallback={<div>loading</div>}>
              <Summary type={CURRENCY.WFIRO} handleClick={() => handleWithdraw(CURRENCY.WFIRO)} image={funds.firo.image} released={funds.firo.released} releasable={funds.firo.releasable} isDisabled={isWithdrawing.firo || funds.firo.releasable == 0} />
            </Suspense>
            <Suspense fallback={<div>loading</div>}>
              <Summary type={CURRENCY.BNB} handleClick={() => handleWithdraw(CURRENCY.BNB)} image={funds.bnb.image} released={funds.bnb.released} releasable={funds.bnb.releasable} isDisabled={isWithdrawing.bnb || funds.bnb.releasable == 0} />
            </Suspense>
          </Unreleased>
          <Suspense fallback={<div>hi dfsg sf  sdf  sd sdf</div>}>
            <Transactions>
              <Headers>
                <Status>Status</Status>
                <Released>Released</Released>
                <Currency>Currency</Currency>
                <Transaction>Transaction</Transaction>
                <DateData>Date</DateData>
              </Headers>
              <Categories setFunds={setFunds} dataRelease={dataRelease} />
            </Transactions>
          </Suspense>
        </Body>
        {/* .slice(0, -2) */}
        <Contract>
          <Point>
            <Header>Supply Minted</Header>
            <Data>{contract.totalSupply} of 2500 ({(contract.totalSupply / 2500).toFixed(2)}%)</Data>
            <Extra><Bar isActive={true} /><Bar /><Bar /><Bar /> </Extra>
          </Point>
          <Point>
            <Header>Dev Minted</Header>
            <Data>{contract.devMinted} of 100 ({(contract.devMinted / 2500).toFixed(2)}%)</Data>
            <Extra><Bar isActive={false} /><Bar /><Bar /><Bar /> </Extra>
          </Point>
          {/* <Point>
          <Header>Contract Received</Header>
          <Data>0 FIRO (0 mints)</Data>
          <Data>0 BNB (0 mints)</Data>
        </Point> */}
        </Contract>
      </Container>
    </Suspense>
  </Wrapper>
}

const PaddingTyoe = styled.div`
  display: flex;
  flex-direction: column;
    gap: 18px;
  padding: 5px 5px;
`

const TxLink = styled.a`
  display: flex;
  align-items: center;
  column-gap: 8px;

  &:hover {
    filter: brightness(1.3);
    cursor: pointer;
  }
`

const LinkImg = styled.img``

const Bar = styled.div`
  width: 40px;
  margin-right: 4px;
  height: 4px;
  background: ${props => props.isActive ? '#C86D6D' : '#FFF'};
`

const Extra = styled.div`
  display: flex;
`

const Tag = styled.div`
width: fit-content;
padding: 8px 10px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.7rem;
  color: #e6bad9;
  background: #5f2850;
`

const CategoryHeader = styled.div`

`


const Entry = styled.div`
display: flex;
align-items: center;
padding: 22px 0;
`

const DateData = styled(Entry)`width: 22%;`

const Transaction = styled(Entry)`
  display: flex;
  column-gap: 4px;
  width: 22%;
`

const Currency = styled(Entry)`width: 16%;`
const Released = styled(Entry)`width: 19%;`
const Status = styled(Entry)`
  margin-left: 5px;
  width: 19%;
`

const Data = styled.div`
  font-size: 1rem;
`
const Header = styled.div`
font-weight: 600;
  font-size: 1.15rem;
`
const Point = styled.div`
  color: #dcbdd3;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`
const Contract = styled.div`
  display: flex;
  padding: 50px;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  margin-top: 60px;
  width: 20%;
`


const WalletAddress = styled.div`
  display: flex;
  align-items: center;
  column-gap: 3px;
  width: fit-content;
  color: #8b5a7b;
  cursor: pointer;

  &:hover {
    filter: brightness(1.3);
  }
`
const WalletName = styled.div`
  color: #bb8dac;
  font-size: 26px;
`
const Identity = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`

const Unreleased = styled.div`
  display: flex;
  border-radius:  14px;
  background: #511c42;
  gap: 45px;
  padding: 45px;
`

const Headers = styled.div`
  display: flex;
  &:first-child { border-bottom: 1px solid #532D4B; }
  &:last-child { border: 0; }
  border-bottom: 1px solid #531e44;

  font-size: 1.1rem !important;
  color: #966b88 !important;


`

const Transactions = styled.div`
  display: flex;
  border-radius: 14px;
  margin: 30px;
  flex-direction: column;

`

const Body = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  row-gap: 30px;
`


const Container = styled.div`
  display: flex;

  background: #4c173d;
  border-radius: 32px;
  max-width: 1000px;
  width: 100%;
  color: #fff;
  padding: 50px;
`
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 150px 0;
  width: 100%;
`

export default PullPayment;