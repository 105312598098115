
import { TYPE, QUALITY } from '../const'
import armor1 from 'App/assets/3d/ui/equipment/clothing/armor1.png'
import armor2 from 'App/assets/3d/ui/equipment/clothing/armor2.png'
import firoToken from 'App/assets/3d/ui/firo-token.png'

import sleeperClothing from 'App/assets/3d/ui/equipment/clothing/Sleeper.png'
import sleeperIconClothing from 'App/assets/3d/ui/equipment/clothing/Sleeper Icon.png'

import goldenChestClothing from 'App/assets/3d/ui/equipment/clothing/Golden Chest.png'
import goldenChestIconClothing from 'App/assets/3d/ui/equipment/clothing/Golden Chest Icon.png'

import seafarerChestClothing from 'App/assets/3d/ui/equipment/clothing/Seafarer.png'
import seafarerIconClothing from 'App/assets/3d/ui/equipment/clothing/Seafarer Icon.png'

import beigeTwoTipsEars from 'App/assets/3d/ui/equipment/ears/Beige Two Tips.png'
// import beigeTwoTipsEarsIcon from 'App/assets/3d/ui/equipment/ears/Beiege Two Tips Icon.png'

import tawnyFuzzyHead from 'App/assets/3d/ui/equipment/head/Tawny Fuzzy.png'
// import beigeTwoTipsEarsIcon from 'App/assets/3d/ui/equipment/ears/Beiege Two Tips Icon.png'

import wineBandTail from 'App/assets/3d/ui/equipment/tail/Wine Band.png'

import redRangerShoulder from 'App/assets/3d/ui/equipment/shoulder/Red Ranger.png'
import redRangerIconShoulder from 'App/assets/3d/ui/equipment/shoulder/Red Ranger Icon.png'

import angerEyes from 'App/assets/3d/ui/equipment/eyes/Anger.png'
import angerIconEyes from 'App/assets/3d/ui/equipment/eyes/Anger Icon.png'
import jewelEyes from 'App/assets/3d/ui/equipment/eyes/Jewel.png'
import jewelIconEyes from 'App/assets/3d/ui/equipment/eyes/Jewel Icon.png'

import cloudEyebrows from 'App/assets/3d/ui/equipment/eyebrows/Cloud.png'
import catSmileMouth from 'App/assets/3d/ui/equipment/mouth/Cat Smile.png'
import erghMouth from 'App/assets/3d/ui/equipment/mouth/Ergh.png'

import farmerHatHat from 'App/assets/3d/ui/equipment/hat/Farmer Hat.png'
import farmerHatIconHat from 'App/assets/3d/ui/equipment/hat/Farmer Hat Icon.png'

import bunnyHelmetHat from 'App/assets/3d/ui/equipment/hat/Bunny Helmet.png'
import bunnyHelmetIconHat from 'App/assets/3d/ui/equipment/hat/Bunny Helmet Icon.png'

import wandererHatHat from 'App/assets/3d/ui/equipment/hat/Wanderer Hat.png'
import wandererHatIconHat from 'App/assets/3d/ui/equipment/hat/Wanderer Hat Icon.png'


import militaryHeadbandHat from 'App/assets/3d/ui/equipment/hat/Military Headband.png'
import militaryHeadbandIconHat from 'App/assets/3d/ui/equipment/hat/Military Headband Icon.png'

import crimsonFirearmsWeapon from 'App/assets/3d/ui/equipment/weapon/Crimson Firearms.png'
import crimsonFirearmsIconWeapon from 'App/assets/3d/ui/equipment/weapon/Crimson Firearms Icon.png'

const equipment = {
  [TYPE.Background]: [],
  [TYPE.Pattern]: [],
  [TYPE.Weapon]: [{
    id: 'Crimson Firearms',
    name: 'Crimson Firearms',
    description: '',
    properties: {
      rarity: QUALITY.legendary,
      type: TYPE.Weapon,
      showTooltip: true,
    },
    img: {
      avatar: crimsonFirearmsWeapon,
      icon: crimsonFirearmsIconWeapon
    }
  }],
  [TYPE.Tail]: [{
    id: 'Wine Band',
    name: 'Wine Band',
    description: '',
    properties: {
      rarity: QUALITY.rare,
      type: TYPE.Tail,
      showTooltip: true,
    },
    img: {
      avatar: wineBandTail,
      icon: undefined
    }
  }],
  [TYPE.Clothing]:
    [
      {
        id: 'Sleeper',
        name: 'Sleeper',
        description: 'Battle dented but highly protective',
        properties: {
          rarity: QUALITY.uncommon,
          type: TYPE.Clothing,
          showTooltip: true,

        },
        img: {
          avatar: sleeperClothing,
          icon: sleeperIconClothing
        }
      },
      {
        id: 'Golden Chest',
        name: 'Golden Chest',
        description: 'Battle dented but highly protective',
        properties: {
          rarity: QUALITY.legendary,
          type: TYPE.Clothing,
          showTooltip: true,

        },
        img: {
          avatar: goldenChestClothing,
          icon: goldenChestIconClothing
        }
      },
      {
        id: 'Seafarer',
        name: 'Seafarer',
        description: 'Battle dented but highly protective',
        properties: {
          rarity: QUALITY.rare,
          type: TYPE.Clothing,
          showTooltip: true,

        },
        img: {
          avatar: seafarerChestClothing,
          icon: seafarerIconClothing
        }
      }
    ],
  [TYPE.Shoulder]: [{
    id: 'Red Ranger',
    name: 'Red Ranger',
    description: '',
    properties: {
      rarity: QUALITY.epic,
      type: TYPE.Shoulder,
      showTooltip: true,
    },
    img: {
      avatar: redRangerShoulder,
      icon: redRangerIconShoulder
    }
  }],
  [TYPE.Ears]: [{
    id: 'Beige Two Tips',
    name: 'Beige Two Tips',
    description: 'Battle dented but highly protective',
    properties: {
      rarity: QUALITY.rare,
      type: TYPE.Ears,
      showTooltip: true,
    },
    img: {
      avatar: beigeTwoTipsEars,
      icon: undefined
    }
  }],
  [TYPE.Head]: [{
    id: 'Tawny Fuzzy',
    name: 'Tawny Fuzzy',
    description: 'Battle dented but highly protective',
    properties: {
      rarity: QUALITY.rare,
      type: TYPE.Head,
      showTooltip: true,
    },
    img: {
      avatar: tawnyFuzzyHead,
      icon: undefined
    }
  }],
  [TYPE.Eyes]: [{
    id: 'Anger',
    name: 'Anger',
    description: '',
    properties: {
      rarity: QUALITY.rare,
      type: TYPE.Eyes,
      showTooltip: true,
    },
    img: {
      avatar: angerEyes,
      icon: angerIconEyes
    }
  }, {
    id: 'Jewel',
    name: 'Jewel',
    description: '',
    properties: {
      rarity: QUALITY.rare,
      type: TYPE.Eyes,
      showTooltip: true,
    },
    img: {
      avatar: jewelEyes,
      icon: jewelIconEyes
    }
  }],
  [TYPE.Eyebrows]: [{
    id: 'Cloud',
    name: 'Cloud',
    description: '',
    properties: {
      rarity: QUALITY.rare,
      type: TYPE.Eyebrows,
      showTooltip: true,
    },
    img: {
      avatar: cloudEyebrows,
      icon: undefined
    }
  }],
  [TYPE.Mouth]: [{
    id: 'Cat Smile',
    name: 'Cat Smile',
    description: '',
    properties: {
      rarity: QUALITY.rare,
      type: TYPE.Mouth,
      showTooltip: true,
    },
    img: {
      avatar: catSmileMouth,
      icon: undefined
    }
  }, {
    id: 'Ergh',
    name: 'Ergh',
    description: '',
    properties: {
      rarity: QUALITY.rare,
      type: TYPE.Mouth,
      showTooltip: true,
    },
    img: {
      avatar: erghMouth,
      icon: undefined
    }
  }],
  [TYPE.Facial]: [],
  [TYPE.Hat]: [{
    id: 'Farmer Hat',
    name: 'Farmer Hat',
    description: '',
    properties: {
      rarity: QUALITY.rare,
      type: TYPE.Hat,
      showTooltip: true,
    },
    img: {
      avatar: farmerHatHat,
      icon: farmerHatIconHat
    }
  }, {
    id: 'Military Headband',
    name: 'Military Headband',
    description: '',
    properties: {
      rarity: QUALITY.rare,
      type: TYPE.Hat,
      showTooltip: true,
    },
    img: {
      avatar: militaryHeadbandHat,
      icon: militaryHeadbandIconHat
    }
  }, {
    id: 'Wanderer Hat',
    name: 'Wanderer Hat',
    description: '',
    properties: {
      rarity: QUALITY.uncommon,
      type: TYPE.Hat,
      showTooltip: true,
    },
    img: {
      avatar: wandererHatHat,
      icon: wandererHatIconHat
    }
  }, {
    id: 'Bunny Helmet',
    name: 'Bunny Helmet',
    description: '',
    properties: {
      rarity: QUALITY.uncommon,
      type: TYPE.Hat,
      showTooltip: true,
    },
    img: {
      avatar: bunnyHelmetHat,
      icon: bunnyHelmetIconHat
    }
  }],
}

const quest = [{
  id: 'Firo Token',
  name: 'Firo Token',
  description: 'Physical Firo Token',
  properties: {
    rarity: QUALITY.rare,
    type: TYPE.Quest,
    showTooltip: true,
  },
  img: {
    avatar: undefined,
    icon: firoToken
  }
}]

const empty = {
  id: '',
  name: '',
  description: '',
  properties: {
    rarity: QUALITY.rare,
    type: TYPE.Empty,
    showTooltip: false,
  },
  img: {
    avatar: undefined,
    icon: undefined
  }
}

const items = {
  quest,
  equipment,
  empty
}

export default items