import { useEffect, useRef, useCallback, useState } from 'react';
import axios from 'axios';
import { useInView } from 'react-intersection-observer';
import useSWRInfinite from 'swr/infinite';
import styled from "styled-components";
import Spinner from './Spinner';
import Tag from "./Tag";
import Card from "./Card";
import { SERVER_URL, SERVER_COLLECTION, SERVER_METADATA } from 'const/general';
import { isMobile } from 'react-device-detect';
import Preview from './Preview';
import { filterJotai, queryJotai, reducerJotai, sortMethodJotai } from 'data/atoms';

const emptyState = { background: [], pattern: [], weapon: [], tail: [], clothing: [], shoulder: [], ears: [], head: [], eyebrows: [], eyes: [], mouth: [], facial: [], hat: [] }

const Gallery = ({ state, filterWait, dispatch, query, sortMethod }) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewFox, setPreviewFox] = useState({});

  const fetcher = async url =>
    await axios(url).then(res => {
      dispatch({ type: 'updateState', updatedState: res.data.equipment })
      return res.data.results;
    });

  const { ref: bottomRef, inView: bottomIsVisible } = useInView({
    rootMargin: '-200px 0px 100px 0px',
  });

  const getKey = (pageIndex, previousPageData) => {
    return `${SERVER_COLLECTION}?page=${pageIndex + 1}&count=${48}&sortMethod=${sortMethod}&selected=${query !== undefined ? query : JSON.stringify(emptyState)}`; // SWR key
  };

  const swrOptions = { revalidateFirstPage: false, initialSize: 0 };

  const { data, error, isValidating, mutate, size, setSize } = useSWRInfinite(getKey, fetcher, swrOptions);
  const firofoxes = data ? [].concat(...data) : [];
  const isLoadingInitialData = !data && !error;
  const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === 'undefined');
  const isEmpty = data?.[0]?.length === 0;
  const isReachingEnd = isEmpty || (data && data[data.length - 1]?.length < 48);
  const isRefreshing = isValidating && data && data.length === size;

  useEffect(() => {
    if (!isLoadingInitialData && !isLoadingMore && !isRefreshing) setSize(size + 1);
  }, [bottomIsVisible]);

  const handlePreview = (fox) => {
    setPreviewFox(fox)
    setPreviewOpen(true);
  };

  return (<>
    {previewOpen && (
      <Preview
        previewOpen={setPreviewOpen}
        state={state}
        setPreviewOpen={setPreviewOpen}
        handleClose={() => setPreviewOpen(false)}
        previewFox={previewFox}
      />
    )}
    <Wrapper>
      <Container>
        <Tags>
          {state.stack.map(entry => <Tag key={entry.trait} handleClick={() => dispatch({ type: 'remove', category: entry.category, trait: entry.trait })}>{!isMobile && `${entry.category.toUpperCase()}:`} {entry.trait}</Tag>)}
        </Tags>
        <Cards filterWait={filterWait}>
          {/* {firofoxes.map(fox => <Card key={fox.id} id={fox.id} image={fox.image} rarityScore={fox.rarityScore} />)} */}
          {firofoxes.map(fox => <Card key={fox.id} fox={fox} handlePreview={handlePreview} id={fox.id} image={`${SERVER_COLLECTION}/${fox.id}/image`} rarityScore={fox.rarityScore} />)}
        </Cards>
        <LoadTrigger aria-hidden={true} ref={bottomRef} isVisible={!isReachingEnd && !isLoadingInitialData}>
          <SpinnerWrapperLoader>
            <Spinner width={120} height={120} />
          </SpinnerWrapperLoader>
        </LoadTrigger>
      </Container>
    </Wrapper>
  </>)
};

const Backdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.6);
`
const SpinnerWrapperLoader = styled.div`
  position: relative;
`;

const LoadTrigger = styled.div`
  ${props => (props.isVisible ? 'display: flex' : 'display: none')};
  height: 80px;
  margin-top: 80px;
  margin-bottom: 80px;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const Container = styled.div`
  width: 100%;
  display: flex;
  row-gap: 18px;
  flex-direction: column;
`

const Cards = styled.div`
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  justify-content: flex-start;
  align-items: flex-start;
  grid-gap: 1rem 0.5rem;
  column-gap: 1.3vw;
  row-gap: 1vw;

  ${props => props.filterWait ? 'filter: brightness(0.4);' : 'filter: brightness(1);'}

  grid-template-columns: repeat(5,minmax(0,1fr)); // px em rem % cm fr auto
  /* grid-template-rows: auto; */

  @media (max-width: 1280px) {
    column-gap: 1.25vw;
    row-gap: 1.25vw;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr)); // px em rem % cm fr auto
  }

  @media (max-width: 1024px) {
    column-gap: 2vw;
    row-gap: 2vw;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr)); // px em rem % cm fr auto
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr)); // px em rem % cm fr auto
  }

  @media (max-width: 480px) {
    column-gap: 12px;
    row-gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)); // px em rem % cm fr auto
  }
`

const Tags = styled.div`
  display: flex;
  gap: 8px;
  color: #fff;
  min-height: 55px;
  flex-wrap: wrap;
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  padding-top: 12vh;
  padding-bottom: 15vh;

  @media (max-width: 1024px) {
    padding-top: 80px;
  }
`

export default Gallery;