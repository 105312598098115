import styled from 'styled-components';

import header from 'App/assets/images/landing/world/header.png';

const Header = ({ text }) => {
  return (
    <Wrapper>
      <Shadow />
      <HeaderImg src={header} alt="" />
      <Text>{text}</Text>
    </Wrapper>
  );
};

const Shadow = styled.div`
  position: absolute;
  top: 92%;
  left: 50.5%;
  width: 87.25%;
  transform: translateX(-50%) translateZ(-5px) perspective(10px) rotateX(-1deg);
  height: 150px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, transparent 50%);
`;

const Text = styled.div`
  position: absolute;
  top: -17px;
  right: -20px;
  transform: rotate(5deg);
  font-family: var(--title-font);
  font-size: clamp(1.75rem, 4vw, 2.75rem);
  padding: 15px 20px;
  text-shadow: 5px 5px 0 #000, -5px -5px 0 #000, 5px -5px 0 #000, -5px 5px 0 #000, 5px 0 0 #000, 0 5px 0 #000, -5px 0 0 #000, 0 -5px 0 #000;

  /* font-family: 'Anime Ace'; */
  text-transform: uppercase;
`;

const HeaderImg = styled.img`
  position: relative;
  width: 100%;
  height: auto;
  transform: perspective(12px) rotateX(1deg);
`;

const Wrapper = styled.div``;

export default Header;
