import styled, { keyframes } from "styled-components"
import { useAtom } from "jotai"
import { useEffect, useState } from "react"
import { notificationAtom } from "Project/Game/data/atoms"


const Notification = () => {
  const [notification, setNotification] = useAtom(notificationAtom)
  const [isDisplayed, setIsDisplayed] = useState(false)

  useEffect(() => {
    setIsDisplayed(true)
    setTimeout(() => {
      setIsDisplayed(false)
    }, 3000)
  }, [notification])

  return <Wrapper>
    {isDisplayed &&
      <Container>
        <Header>Mission Successful</Header>
        <BreakLine />
        <Reward>You receive 10 Firo!</Reward>
      </Container>}
  </Wrapper>
}

const Reward = styled.div``

const BreakLine = styled.div`
  height: 3px;
  width: 100%;
  background: #333333;
`

const Header = styled.div`
  font-size: 2rem;
`

const animContainer = keyframes`
  0% { opacity: 0; transform: translateY(-10px) scale(1); }
  10% { opacity: 1; transform: translateY(-30px) scale(1); }
  90% { opacity: 1; transform: translateY(-30px) scale(1); }
  100% { opacity: 0; transform: translateY(-40px) scale(1); }
`

const Container = styled.div`
  color: #fff;
  width: 500px;
  row-gap: 20px;
  display: flex;
  flex-direction: column;
  font-family: var(--primary-text-font);
  padding: 50px;
  height: 100px;
  background: rgba(0, 0, 0, 0.7);
  animation: ${animContainer} 3s ease-in-out forwards;
`

const Wrapper = styled.div`
`

export default Notification