import { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components"
import PlayerSmall from "../MiniHUD/PlayerSmall";
import MenuButton from "./MenuButton";
import Panes from "./Panes";
import QuestsSmall from "../MiniHUD/QuestsSmall";
import Dialogue from "./Dialogue";
import { useAtom } from "jotai";
import { exampleAtom, zoomedAtom, showMenuHud, itemReducerAtom, cameraZoomAtom, camAtom, interfaceAtom, questsAtom } from "../../data/atoms";
import database from "../../data/items";
import { questData } from "../../data/gameData";
import { TYPE, ZOOM } from "../../const";
import comTowerExample from 'App/assets/images/temp/com-tower.png'
import dialogueFoxImg from 'App/assets/3d/ui/dialogue-fox1.png';
import DialogueOverlay from "./DialogueOverlay";
import NewModule from "../NewModule";
import Energy from "Project/Game/UI/MainHUD/Energy";
import Notification from "./Notification";
import { Howl } from 'howler';

var finishQuest = new Howl({
  src: ['/sound/drive_discovered_eqed.wav'],
  autoplay: false,
  loop: false,
  volume: 0.8,
});

const MainHUD = () => {
  const [revealNotification, setRevealNotification] = useState(false);
  const [quests, setQuests] = useAtom(questsAtom);
  const [interfaceState, setInterfaceState] = useAtom(interfaceAtom);
  const [items, dispatchitem] = useAtom(itemReducerAtom)
  const [cameraAtom, setCameraAtom] = useAtom(camAtom)
  const [example, setExample] = useAtom(exampleAtom)
  const [showMainHud, setShowMainHud] = useAtom(showMenuHud);
  const [activeQuest, setActiveQuest] = useState(null);

  var mouseOverSound = new Howl({
    src: ['/sound/mainmenu_mouseover.wav'],
    autoplay: false,
    loop: false,
    volume: 0.7,
  });

  // useEffect(() => {
  //   let farmerHat = database.equipment[TYPE.Hat].find(item => item.name === 'Farmer Hat')
  //   let sleeper = database.equipment[TYPE.Clothing].find(item => item.name === 'Sleeper');
  //   let seafarer = database.equipment[TYPE.Clothing].find(item => item.name === 'Seafarer');
  //   let anger = database.equipment[TYPE.Eyes].find(item => item.name === 'Anger');
  //   let bunnyHelmet = database.equipment[TYPE.Hat].find(item => item.name === 'Bunny Helmet');
  //   let wandererHat = database.equipment[TYPE.Hat].find(item => item.name === 'Wanderer Hat');
  //   let empty = database.empty;

  //   dispatchitem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Clothing].find(item => item.name === 'Golden Chest'), equippable: [seafarer, sleeper], type: TYPE.Clothing }, })
  //   dispatchitem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Tail].find(item => item.name === 'Wine Band'), equippable: [], type: TYPE.Tail }, })
  //   dispatchitem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Head].find(item => item.name === 'Tawny Fuzzy'), equippable: [], type: TYPE.Head }, })
  //   dispatchitem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Ears].find(item => item.name === 'Beige Two Tips'), equippable: [], type: TYPE.Ears }, })
  //   dispatchitem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Eyebrows].find(item => item.name === 'Cloud'), equippable: [], type: TYPE.Eyebrows, }, })
  //   dispatchitem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Shoulder].find(item => item.name === 'Red Ranger'), equippable: [], type: TYPE.Shoulder }, })
  //   dispatchitem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Mouth].find(item => item.name === 'Cat Smile'), equippable: [], type: TYPE.Mouth }, })
  //   dispatchitem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Eyes].find(item => item.name === 'Jewel'), equippable: [anger], type: TYPE.Eyes }, })
  //   dispatchitem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Weapon].find(item => item.name === 'Crimson Firearms'), equippable: [], type: TYPE.Weapon }, })
  //   dispatchitem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Hat].find(item => item.name === 'Military Headband'), equippable: [bunnyHelmet, wandererHat, farmerHat], type: TYPE.Hat } })
  // }, [])



  useEffect(() => {
    let timerthing;
    // activemarker is in our quests array
    if (quests.length === 4 && revealNotification === false) {
      timerthing = setTimeout(() => {
        setRevealNotification(true);
        finishQuest.play()
      }, 1500)
    }

    return () => {
      clearTimeout(timerthing);
    }
  }, [quests, cameraAtom])


  let overlayVisible = interfaceState.player.main || interfaceState.quests.main || interfaceState.quests.selected > 0

  const handleOverlay = () => {
    setInterfaceState(prev => {
      return {
        ...prev,
        player: {
          ...prev.player,
          main: false,
          mini: true
        },
        quests: {
          ...prev.quests,
          main: false,
          mini: true,
          selected: -1
        }
      }
    })
  }

  const handleMouseOver = () => {
    mouseOverSound.play();
  }

  return <>
    {/* <DialogueOverlay /> */}
    {/* <Dialogue isVisible={example} /> */}
    {revealNotification &&
      <CompletionWindow>
        <HeaderTxt>Completed Thieves in the Night</HeaderTxt>
        {/* <BreakLine /> */}
        <ReceivedTxt>Rewards </ReceivedTxt>
        <Reward>Schematics</Reward>
      </CompletionWindow>
    }
    <NewModule />
    <Overlay isVisible={overlayVisible} onClick={handleOverlay} />
    <Wrapper isVisible={true}>
      <QuestsMiniWrapper isVisible={interfaceState.quests.mini}>
        <QuestsSmall />
      </QuestsMiniWrapper>
      <WrapPlayerMini isVisible={interfaceState.player.mini}>
        <PlayerSmall handleMouseOver={handleMouseOver} handleClick={() => setInterfaceState(prev => { return { ...prev, player: { ...prev.player, main: true, mini: false }, quests: { ...prev.quests, main: false, selected: -1 } } })} />
        <WrapPlayerMiniEnergy>
          <Energy />
        </WrapPlayerMiniEnergy>
      </WrapPlayerMini>
      <WrapMainHud isVisible={true}>
        <Panes />
      </WrapMainHud>
      {/* <WrapInventory isVisible={interfaceState.player.mini}>
        <Inventory />
      </WrapInventory> */}
      {/* <WrapEnergy isVisible={true}>
        <Energy />
      </WrapEnergy> */}
      {/* <WrapNotification>
        <Notification />
      </WrapNotification> */}
      {/* <WrapMenuBtn isVisible={true} >
        <MenuButton handleClick={() => setInterfaceState(prev => { return { ...prev, player: { ...prev.player, main: true, mini: false }, quests: { ...prev.quests, main: false, selected: -1 } } })} />
      </WrapMenuBtn> */}
    </Wrapper>
  </>
}


const WrapMenuBtn = styled.div`
${props => props.isVisible ? `display: static` : `display: none`};
  position: fixed;
  bottom: 0;
  right: 0;  
  user-select: none;
  margin: 5vh 10vw;
`

const WrapPlayerMiniEnergy = styled.div`
  position: absolute;
  left: 20%;
  bottom: 20px;
  transform: translateX(-50%);
`

const ReceivedTxt = styled.div`
  opacity: 0.3;
`

const Reward = styled.div`
  color: lightgrey;
  padding: 15px;
  border: 3px solid rgba(255, 255, 255, 0.2);
  width: fit-content;
`

const BreakLine = styled.div`
  height: 3px;
  background: rgba(255, 255, 255, 0.2);
  width: 1005;
`

const HeaderTxt = styled.div`
  font-size: 1.5rem;
  padding-bottom: 30px;
`

const animCompletion = keyframes`
  0% {
    opacity: 0;
    height: 22px;
    transform: translate(-50%, -50%) scale(0.95);
  }
  10% {
    opacity: 1;
    height: 22px;
    transform: translate(-50%, -50%) scale(1);
  }

  40% {
    opacity: 1;
    height: 22px;
    transform: translate(-50%, -50%) scale(1);
  }

  60% {
    opacity: 1;
    height: 160px;
    transform: translate(-50%, -50%) scale(1);
  }

  90% {
    opacity: 1;
    height: 160px;
    transform: translate(-50%, -50%) scale(1);
  }

  99% {
    opacity: 0;
    height: 160px;
    transform: translate(-50%, -50%) scale(0.95);
  }

  100% {
    opacity: 0;
    height: 0px;
    transform: translate(-50%, -50%) scale(0.95);
  }

`

const CompletionWindow = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  user-select: none;
  row-gap: 20px;
  top: 50%;
  overflow: hidden;
  left: 50%;
  padding: 50px;
  font-family: var(--primary-text-font);
  width: 600px;
  height: 160px;
  opacity: 0;
  color: #fff;
  background: rgba(0, 0, 0, 0.8);
  transform: translate(-50%, -50%);
  z-index: 5;
  animation: ${animCompletion} 5s  cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`

const WrapNotification = styled.div`
  /* ${props => props.isVisible ? `display: static` : `display: none`}; */
  z-index: 124;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const WrapEnergy = styled.div`
  ${props => props.isVisible ? `display: static` : `display: none`};
  z-index: 4;
  position: fixed;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
`

const WrapInventory = styled.div`
${props => props.isVisible ? `display: static` : `display: none`};
  z-index: 4;
  position: fixed;
  bottom: 100px;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 1650px) {
  left: 800px;
  transform: translateX(0);
  }
`

const WrapDialogue = styled.div`
  /* ${props => props.isVisible ? `display: static` : `display: none`}; */
  z-index: 2;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const FoxImg = styled.img`
  position: absolute;
  bottom: 3vh;
  right: 20vw;
  width: 30%;
  z-index: 30;
`

const ComTowerExample = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
`

const LeaveBtn = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  user-select: none;
  font-size: 1.5rem;
  font-family: var(--primary-text-font);
  top: 100px;
  left: 50%;
  padding: 22px 80px;
  z-index: 5;
  background: #bf4040;
  cursor: pointer;
  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    filter: brightness(0.9);
  }
`

const QuestsMiniWrapper = styled.div`
  position: fixed;
  top: 100px;
  ${props => props.isVisible ? 'display: flex' : `display: none`};
  right: 0;
  margin: 5vh 10vw;
`

const animOverlay = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Overlay = styled.div`
 ${props => props.isVisible ? `display: flex` : `display: none`};
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: 3;
  animation: ${animOverlay} 0.8s cubic-bezier(0.1, 1, 0.1, 1) forwards;
`

const WrapMainHud = styled.div`
  ${props => props.isVisible ? `display: static` : `display: none`};
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  /* margin: 100px 150px; */
`

const WrapPlayerMini = styled.div`
${props => props.isVisible ? `display: static` : `display: none`};
  position: fixed;
  bottom: 0;
  left: 0;  
  user-select: none;
  margin: 5vh 10vw;

  &:hover {
    scale: 1.1;
    cursor: pointer;
  }
`

const Wrapper = styled.div`
   ${props => props.isVisible ? `display: flex` : `display: none`};
  position: absolute;
  left: 0;
  top: 0;  
  z-index: 2;

  justify-content: center;
  z-index: 4;
  align-items: center;
`

export default MainHUD