import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState } from "react";
import dialogueFoxImg from 'App/assets/3d/ui/dialogue-fox1.png';
import Delayed from "../components/Delayed";
import { Howl } from "howler";
import useText from "./useText";

const Description = ({ data, handleNextModule }) => {
  // console.log('data is', data)
  const { dialogue } = data;
  const recursionTextEl = useRef('');
  const [currentPos, setCurrentPos] = useState(0);
  const [prevText, npc, progressText] = useText({ dialogue, ref: recursionTextEl })
  const scrollContainer = useRef(null);
  const hasData = data !== null;

  // console.log('npc is ', npc)
  // console.log('prevText is', prevText)

  var progressSound = new Howl({
    src: ['/sound/dialoguepanel_click.wav'],
    autoplay: false,
    loop: false,
    volume: 0.4,
  });

  useEffect(() => {
    progressSound.play();
  }, [])


  const handleButton = (i) => {
    if (prevText.length === dialogue.length - 1) {
      handleNextModule();
    } else {
      progressSound.play();
      let newPos = currentPos + 1;
      setCurrentPos(newPos)
      progressText(newPos);
    }
  }

  const addSpaces = (num) => {
    let spaces = '';
    for (let i = 0; i < num; i++) {
      spaces += '\xa0';
    }
    return spaces;
  }

  return (<>
    <Wrapper isVisible={hasData}>
      <Backdrop>
        <InsideBackdrop />
      </Backdrop>
      <Container>
        <NpcWrapper key={npc.id}>
          <NpcImg key={npc.id} src={npc.img} alt="" />
          <ExpressionImg src={npc.expression} alt="" />
        </NpcWrapper>
        <ScrollContainer ref={scrollContainer}>
          <WrapText>
            <ExtraHider>{dialogue[currentPos].text}
              <NameTxt color={dialogue[currentPos].npc.color.name}><Name>{dialogue[currentPos].npc.name}</Name><Divider color={dialogue[currentPos].npc.color.body}>&nbsp;&nbsp;—</Divider></NameTxt>
              <TextLive color={dialogue[currentPos].npc.color.body} ref={recursionTextEl}></TextLive>
            </ExtraHider>
          </WrapText>
          <PreviousText>
            <Gradient />
            {prevText.map((text, idx) => <WrapPrev key={idx}><NameTxt2 color={text.color.name}>{text.name}<Divider color={text.color.body}>&nbsp;&nbsp;—</Divider></NameTxt2><Text color={text.color.body}>{addSpaces(text.spaces)}{text.text}</Text></WrapPrev>)}
          </PreviousText>
        </ScrollContainer>
        <InteractionWindow>
          <ContinueBtn onClick={() => handleButton()}>{prevText.length === dialogue.length - 1 ? 'Finish' : 'Continue'}</ContinueBtn>
        </InteractionWindow>
      </Container>
    </Wrapper>
  </>
  )
}

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;


`

const NameTxt2 = styled.div`
  color: #fff;
  display: flex;
  position: absolute;
  font-weight: bold;
  top: 40px;
  left: -30px;
  ${props => props.color && `color: ${props.color};`}
`

const Text = styled.div`
  ${props => props.color && `color: ${props.color};`}

`

const WrapPrev = styled.div`
  position: relative;
  padding: 40px 0;
  width: 100%;
  display: flex; 
`

const Name = styled.div`
  font-weight: bold;

`

const Divider = styled.div`
  ${props => props.color && `color: ${props.color};`}
`

const NameTxt = styled.div`
text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  color: #fff;
  display: flex;
  position: absolute;
  ${props => props.color && `color: ${props.color};`}
  top: 0;
  left: -30px;
`

const ExpressionImg = styled.img`
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`

const animFox = keyframes`
  0% { transform: translate(-20px, 0) scale(0.99); opacity: 0; filter: brightness(0); }
  100% { transform: translate(0, 0) scale(1); opacity: 1; filter: brightness(1); }
`

const NpcWrapper = styled.div`
  position: relative;
  opacity: 0;
  filter: brightness(0);
  animation: ${animFox} 1s 0.5s cubic-bezier(0.175, 0.92, 0.3, 1) forwards;
  position: absolute;
  bottom: 0vh;
  right: 800px;
  max-width: 600px;
  min-width: 400px;
  width: 25vw;

  @media (max-width: 1280px) {
    right: 700px;
  }

  @media (max-width: 1024px) {
    right: 550px;
  }
`

const animInNpc = keyframes`
  0% { transform: translate(0, 0); filter: brightness(0); }
  100% { transform: translate(0, 0); filter: brightness(1); }
`

const NpcImg = styled.img`
  width: 100%;

  /* transform: translate(50%, -50%); */
`

const ExtraHider = styled.div`
  position: relative;
  color: transparent;
`

const InsideBackdrop = styled.div`
  background: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 1000px;
  backdrop-filter: blur(40px);
`

const Backdrop = styled.div`
  position: fixed;
  top: 50%;
  /* right: 60px; */
  transform: translateY(-50%);
  width: 900px;
  height: 100%;

`

const animIn = keyframes`
  0% {  opacity: 0;  }
  100% { opacity: 1; }
`

const PositionText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 0;
  bottom: 14vh;
  line-height: 3.5rem;
  left: -280px;
`

const UpperGradientOverlay = styled.div`
  position: absolute;
  user-select: none;
  top: 0;
  pointer-events:none;
  right: 0;
  width: 100%;
  margin: 0 40px;
  height: 300px;
  background: linear-gradient(#000 0%, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0) 80%);
`

const WrapText = styled.div`
`

const TextLive = styled.div`
text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  position: absolute;
  top: 0;
  left: 0;
  ${props => props.color && `color: ${props.color};`}

  display: block;
`



const ScrollContainer = styled.div`
  line-height: 2.3rem;
  font-size: 1.75rem;
  margin-top: 40px;
  padding: 0 60px;
  overflow-y: auto;
  flex-direction: column-reverse;
  height: 100%;
  display: flex;
  /* -webkit-mask-image: linear-gradient(to top, black 70%, transparent 90%);
  mask-image: linear-gradient(to top, black 70%, transparent 90%); */
`

const PreviousText = styled.div`
  color: #555;
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  justify-content: flex-end;

`

const ContinueBtn = styled.button`
  background: #B93030;
  height: 90px;
  width: 270px;
  display: flex;
  color: pink;
  font-size: 1.7rem;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(1.1);
  }

  &:active {
    transform: translateY(1px);
    filter: brightness(0.95);
  }
`

const animBtn = keyframes`
  0% { transform: translateY(0); opacity: 0; }
  100% { transform: translateY(-7px); opacity: 1; }
`

const InteractionWindow = styled.div`
  display: flex;
  margin-top: 80px;
  justify-content: center;
  width: 100%;
  opacity:0;
  max-height: 400px;
  height: calc(10vw + 10vh);
  animation: ${animBtn} 0.3s 0.6s cubic-bezier(0.175, 0.92, 0.1, 1) forwards;
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`

const Wrapper = styled.div`
  user-select: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  font-family: 'Segoe UI';
  row-gap: 20px;
  /* background: rgba(0, 0, 0, 0.6); 
  backdrop-filter: blur(300px); */
  width: 50vw;
  color: #fff;
  z-index: 4;
  max-width: 800px;
  padding: 0 5vw;
  height: 80vh;
  margin: 5vw;
  top: 0;
  opacity: 0;
  right: 0;
  /* transform: translateX(-70%); */
  animation: ${animIn} 0.5s 0.1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;

  /* @media (min-width: 1921px) {
    margin-right: 300px;
  } */
`

export default Description