import React, { useState, useEffect, useRef } from "react";
import { useAtom } from "jotai";
import { npcAtom } from "Project/Game/data/atoms";
import { motion, AnimatePresence } from "framer-motion";
import Svg from "components/SVG";
import styled, { keyframes } from "styled-components";
import useText from "./useText";
import { Howl } from "howler";

var progressSound = new Howl({
  src: ['/sound/drive_mouseover.wav'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

const Window = ({ dialogue }) => {
  return <WrapperText key={dialogue}>
    {dialogue}
  </WrapperText>
}

const animInText = keyframes`
  0$ { opacity: 0; }
  100% { opacity: 1; }
`

const WrapperText = styled.div`
  opacity: 0;
  animation: ${animInText} 0.05s 0.15s cubic-bezier(0.1, 0.9, 0.5, 1) forwards;
`

const Dialogue = ({ data, handleNextModule, isFirst }) => {
  const { dialogue } = data;
  const textRef = useRef();
  const [prevText, progressText] = useText({ dialogue, ref: textRef })

  const [npc, setNpc] = useAtom(npcAtom)
  const [page, setPage] = useState(0);
  const timeoutId = useRef();
  const [revealButton, setRevealButton] = useState(false);

  useEffect(() => {
    let timer = setTimeout(() => {
      progressSound.play();
    }, 350);

    // if (page === npc.text.length - 1) setRevealButton(true)
    // else timeoutId.current = setTimeout(() => setRevealButton(true), 5000)
    // return () => clearTimeout(timeoutId.current)
    return () => timer;
  }, [])

  // const handleButton = () => {
  //   if (page === dialogue.length - 1) {
  //     handleNextModule();
  //     setPage(0)
  //   } else {
  //     setRevealButton(false);
  //     setPage(page + 1);
  //   }
  // }

  const handleButton = (i) => {
    if (prevText.length === dialogue.length - 1) {
      handleNextModule();
    } else {
      progressSound.play();
      progressText();
    }
  }

  return <Wrapper>
    <NpcImg hasDelay={isFirst} src={data.npc.img} alt="" />
    {/* <PositionText>
      <NpcName hasDelay={isFirst}> {data.npc.name}</NpcName>
      <NpcDescription hasDelay={isFirst}>{data.npc.description}</NpcDescription>
    </PositionText> */}
    <ChatAnimator>
      <AnimatePresence>
        <WrapChat
          // initial={{ opacity: 0 }}
          // animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.05 }}
          key={dialogue[page]}>
          {/* <ChatBoxExtraSmallSvg isVisible={dialogue[page]?.length <= 25} />
          <ChatBoxSvgSmallSvg isVisible={dialogue[page]?.length <= 45 && npc.text[page]?.length > 25} />
          <ChatBoxSvg isVisible={dialogue[page]?.length > 45} /> */}
          <ChatBoxSvg isVisible={dialogue[page]?.length > 5} />
          {/* <Name>{name}</Name> */}
          {/* <Text><Typer speed={20} dialogue={text[page]}></Typer></Text> */}
          {/* <Text><Window dialogue={dialogue[page]} /></Text> */}
          <Text><TextStuff ref={textRef} />{prevText}</Text>
        </WrapChat>
      </AnimatePresence>
    </ChatAnimator>
    <Overlay onClick={handleButton} />
    {revealButton && <Button onClick={handleButton}>{page === npc.text?.length - 1 ? 'Finish' : 'Continue'}</Button>}
  </Wrapper>
}

const TextStuff = styled.div``


const animInChat = keyframes`
  from { transform: scale(0.74) translate(20px, 40px) rotateZ(0deg); opacity: 0; }
  to { transform: scale(1) translateY(0, 0) rotateZ(4.55deg); opacity: 1; }
`

const Overlay = styled.div`
  position: fixed;
  cursor: pointer;
  width: 100vw;
  height: 100vh;
  z-index: 5;
`

const WrapChat = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  bottom: 30%;
  left: -200px;
  transform-origin: 30% 10%;
  opacity: 1;
  width: 951px;
  height: 342px;
  z-index: 3; 
  /* animation: ${animInChat} 0.05s  steps(3, end) forwards; */
`

const animFadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const ChatAnimator = styled.div`
 opacity: 0;
  animation: ${animFadeIn} 0.1s 0.4s cubic-bezier(0.5, 0.6, 0.4, 0.8) forwards;
`

const animDiagFore = keyframes`
  0% { d: path("m261 92 651-43 14 276-637-32-21-59-24 30-63-41-146 51 156-143 43 33Z") }
  100% {d: path("m262 92 651-43 14 276-637-32-21-58-24 30-62-41-146 50 154-141 42 31Z") }
`

const animDiagBack = keyframes`
  0% { d: path("m927 32 16 317-665-43-22-38-19 23-69-42L1 295l188-179 35 26 13-25 19-42Z") }
  100% {d: path("m928 32 16 317-665-43-22-38-19 23-69-42L1 295l188-178 35 26 13-25 19-42Z") }
`

const ChatBoxExtraSmallSvg = ({ isVisible }) => <ChatBoxExtraSmall isVisible={isVisible} viewBox="0 0 726 246">
  {/* <path id="Dialogue-Backdrop" d="M718 25v238l-483-24-26-35-23 11-53-37L1 198 164 79l22 10 29-43Z" />
  <path id="Dialogue" d="m223 60 484-18v202l-464-21-35-40-25 10-40-39-95 22 113-77 27 19Z" /> */}
  <path id="Dialogue-Backdrop" d="M718 25v221l-483-23-26-33-23 10-53-34L1 184 164 74l22 9 29-40Z" />
  <path id="Dialogue" fill="#fff" d="m223 58 484-16v185l-465-19-34-37-25 9-40-36-95 21 113-71 27 17Z" />
</ChatBoxExtraSmall>

const ChatBoxSvgSmallSvg = ({ isVisible }) => <ChatBoxSmall isVisible={isVisible} viewBox="0 0 952 292">
  <path id="Dialogue-Backdrop" d="m928 32 15 260-663-32-22-33-10 19-57-40L0 250 191 98l39 23 28-54Z" />
  <path id="Dialogue" d="m263 83 651-35 14 225-636-26-29-47-21 17-53-32-152 46 157-116 42 27Z" />
</ChatBoxSmall>

const animOut = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`

const ChatBoxExtraSmall = styled(Svg)`
  position: relative;
  top: 7px;
  left: 40px;
  width: 718px;
  height: 221px;
  ${props => props.isVisible ? '' : 'display: none'};
  & #Dialogue {
    fill: #fff;
  }
`


const ChatBoxSmall = styled(Svg)`
  ${props => props.isVisible ? '' : 'display: none'};
  & #Dialogue {
    fill: #fff;
  }
`

const ChatBoxSvg = ({ isVisible }) => (
  <ChatBox isVisible={isVisible} viewBox="0 0 954 349">
    <path id="Dialogue-Backdrop" d="m927 32 16 317-665-43-22-38-19 23-69-42L1 295l188-179 35 26 13-25 19-42Z" />
    <path id="Dialogue" d="m261 92 651-43 14 276-637-32-21-59-24 30-63-41-146 51 156-143 43 33Z" />
    {/* <path id="Name" d="m262 30 317-16-58 88-254 20-36-20-98 20Z" /> */}
  </ChatBox>
)

const ChatBox = styled(Svg)`
  ${props => props.isVisible ? '' : 'display: none'};
  & #Dialogue-Backdrop {
    animation: ${animDiagBack}  steps(2, end) infinite;
    fill: #000;
    opacity: 0.8;
  }

  & #Dialogue {
    /* animation: ${animDiagFore} 1s steps(2, end) infinite; */
    fill: #fff;
  }

  & #Name {
    fill: #000;
    opacity: 0.9;
  }
`;

const animInButton = keyframes`
  0% { opacity: 0; transform: translateY(68%) scale(0.98); }
  100% { opacity: 1; transform: translateY(60%) scale(1); }    
`

const Button = styled.button`
  position: absolute;
  user-select: none;
  display: flex;
  color: #fff;
  justify-content: center;
  font-family: var(--primary-text-font);
  align-items: center;
  font-size: 2rem;
  top: 60%;
  padding: 35px 70px;
  box-shadow: 15px 15px 0px rgba(0, 0, 0, 0.9);
  left: 70%;
  opacity: 0;
  z-index: 5;
  background: #C64444;
  animation: ${animInButton} 0.4s 1.25s cubic-bezier(0.1, 0.9, 0.2, 1) forwards;

  &:hover {
    background: #DD5757;; 
  }

  &:active {
    transform: translateY(1px);
    filter: brightness(0.9);
    background: #C64444;
  }
`

const Text = styled.div`
  position: absolute;
  user-select: none;
  display: flex;
  align-items: center;
  font-family: var(--primary-text-font);
  font-size: 1.8rem;
  line-height: 2.4rem;
  width: 590px;
  height: 180px;
  left: 300px;
  top: 100px;
  color: #000;
`


const animIn = keyframes`
  0% {  display: none;  }
  100% { display: static; }
`

const PositionText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 0;
  bottom: 4vh;
  line-height: 3.5rem;
  left: -280px;
`

const animName = keyframes`
  0% { transform: translate(-11px, 0); opacity: 0;}
  100% { transform: translate(0, 0); opacity: 1;}
`



const NpcDescription = styled.div`
  opacity: 0;
  font-size: 2.5rem;
  font-family: var(--title-font);
  animation: ${props => props.hasDelay && animName} 1s 0.6s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`

const NpcName = styled.div`
  font-size: 5.5rem;
  font-family: var(--title-font);
  opacity: 0;
  animation: ${props => props.hasDelay && animName} 1s 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  /* transform: translate(50%, -50%); */
`

const animFox = keyframes`
  0% { transform: translate(0, 0) scale(0.99); opacity: 0.9; filter: brightness(0%); }
  100% { transform: translate(0, 0) scale(1); opacity: 1; filter: brightness(100%); }
`

const NpcImg = styled.img`
  position: absolute;
  bottom: 5vh;
  left: -580px;
  animation: ${props => props.hasDelay && animFox} 1s cubic-bezier(0.375, 0.92, 0.3, 1) forwards;
  /* transform: translate(50%, -50%); */
`


const Wrapper = styled.div`
  display: flex;
  font-family: var(--primary-text-font);
  align-items: center;
  row-gap: 20px;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  user-select: none;
  color: #fff;
  z-index: 12;
  max-width: 800px;
  padding: 0 3vw;
  height: 100%;
  top: 0;
  right: 0;
  animation: ${animIn} cubic-bezier(0.075, 0.82, 0.165, 1) forwards;

  /* @media (min-width: 1921px) {
    margin-right: 300px;
  } */
`



export default Dialogue;