import { Canvas } from "@react-three/fiber";
import { Text3D, Center, Effects, Hud, PerspectiveCamera } from "@react-three/drei";
import { GlitchPass } from 'three-stdlib'
import PostProcess from "./PostProcess";

const StartScreen = ({ setStartedGame }) => {
  const canvasOptions = {
    flat: false,
    linear: false,
    shadows: {
      enabled: true,
      // type: THREE.PCFSoftShadowMap
    },
    camera: {
      position: [-5, 0, 18],
      fov: 65,
      near: 1,
      far: 100
    }
  }

  const fontOptions = {
    curveSegments: 32,
    bevelEnabled: true,
    bevelSize: 0.0,
    bevelThickness: 0.5,
    height: 0,
    lineHeight: 0.5,
    letterSpacing: 0.05,
    size: 2,
    font: "/fonts/AldotheApacheRegular.json"
  }

  const handleStartGame = () => {
    setStartedGame(true);
  }

  return <Hud renderPriority={2}>
    <PerspectiveCamera makeDefault {...canvasOptions.camera} position={[5, 0, 25]} />
    {/* <Center>
      <Text3D
        position={[-1, 3, 0]}
        rotation={[0.2, 0.895, -0.15]}
        {...fontOptions}>New Game
        <meshPhysicalMaterial color='#333' />
      </Text3D>
      <Text3D
        onClick={handleStartGame}
        position={[-1, 0, 0]}
        rotation={[0.10, 0.83, -0.075]}
        {...fontOptions}>Continue
        <meshPhysicalMaterial />
      </Text3D>
      <Text3D
        position={[0, -4, 0]}
        rotation={[-0.05, 0.895, 0.05]}
        {...fontOptions}>Options
        <meshPhysicalMaterial color='#333' />
      </Text3D>
    </Center> */}
    <ambientLight intensity={0.04} />
    <pointLight
      position={[15, -2, 25]}
      intensity={0.8}
      castShadow
      shadow-mapSize-height={512}
      shadow-mapSize-width={512}
      shadow-radius={10}
      shadow-bias={-0.0001}
    />
  </Hud>
}

export default StartScreen;