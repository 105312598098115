import styled from 'styled-components';
import Svg from 'components/SVG';

export const BoltBackgrounSvg = () => (
  <Bolt viewBox="0 0 100 300">
    <defs>
      <linearGradient x1="0%" y1="80%" x2="0%" y2="100%" id="bgGradient">
        <stop offset="0%" stopColor="rgb(199 185 138)" />
        <stop offset="100%" stopColor="rgb(199 185 138)" />
      </linearGradient>
      <linearGradient x1="0%" y1="0%" x2="0%" y2="100%" id="bgGradient2">
        <stop offset="0%" stopColor="#671c32" />
        <stop offset="20%" stopColor="#ab123e" />
        <stop offset="30%" stopColor="#671c32" />
        <stop offset="50%" stopColor="#ab123e" />
        <stop offset="53%" stopColor="#671c32" />
        <stop offset="70%" stopColor="#ab123e" />
        <stop offset="90%" stopColor="#671c32" />
      </linearGradient>
    </defs>
    {/* <path
      fill="url(#bgGradient)"
      d="M71.72-16.39C52.33,2.89,32.94,22.17,13.56,41.44C24.78,48.63,36,55.81,47.22,63
	c11.63-5.41,23.26-10.81,34.89-16.22c-7.85,14.52-15.7,29.04-23.56,43.56C45,87.89,31.44,85.44,17.89,83
	c-1.67,18.74-3.33,37.48-5,56.22c24.59-6.33,49.19-12.67,73.78-19c-3.85,13.78-7.7,27.56-11.56,41.33
	c-9.44-3.15-18.89-6.3-28.33-9.44c-11.54,14.24-23.07,28.48-34.61,42.72c17.83,5.09,35.67,10.19,53.5,15.28
	c-8.92,6.1-17.83,12.2-26.75,18.31c3.47,20.97,6.94,41.94,10.42,62.92c1.42-0.06,2.83-0.11,4.25-0.17c-2.5-19.19-5-38.39-7.5-57.58
	c10.64-7.92,21.28-15.83,31.92-23.75c-14.33-7.28-28.67-14.56-43-21.83c4.42-9.17,8.83-18.33,13.25-27.5
	c8.89,4.33,17.78,8.67,26.67,13c5.32-19.5,10.65-39,15.97-58.5c-19.85,1.48-39.7,2.96-59.56,4.44c-1.7-9.41-3.41-18.81-5.11-28.22
	c11.52,2.04,23.04,4.07,34.56,6.11c10.3-20.63,20.59-41.26,30.89-61.89c-14.37,6-28.74,12-43.11,18C41.7,48.93,34.85,44.41,28,39.89
	C43.19,21.06,58.37,2.24,73.56-16.58C72.94-16.52,72.33-16.45,71.72-16.39z"
    />
    <path
      fill="url(#bgGradient2)"
      d="M70.14-16.32C51.02,2.34,31.9,21,12.78,39.67c11.44,7.15,22.89,14.3,34.33,21.44
	c10.85-4.89,21.7-9.78,32.56-14.67c-7.15,14.07-14.3,28.15-21.44,42.22c-13.96-2.59-27.93-5.19-41.89-7.78
	c-2.26,18.81-4.52,37.63-6.78,56.44c24.52-6.07,49.04-12.15,73.56-18.22c-4.15,13.16-8.3,26.31-12.44,39.47
	c-8.22-2.68-16.44-5.35-24.67-8.03c-11.43,14.17-22.85,28.34-34.28,42.5c19.04,4.95,38.07,9.9,57.11,14.86
	c-11.25,6.75-22.5,13.5-33.75,20.25c4.28,21.11,8.56,42.22,12.83,63.33c1.42-0.11,2.83-0.22,4.25-0.33
	c-2.53-19.44-5.06-38.89-7.58-58.33c11.58-8.53,23.17-17.06,34.75-25.58c-15.07-7.05-30.15-14.09-45.22-21.14
	c4.56-9.26,9.11-18.52,13.67-27.78c8.77,4.11,17.54,8.22,26.31,12.33c5.08-19.15,10.17-38.3,15.25-57.44
	c-18.81,1.48-37.63,2.96-56.44,4.44c-1.56-9.41-3.11-18.81-4.67-28.22c10.78,1.76,21.56,3.52,32.34,5.28
	c10.11-20.35,20.22-40.71,30.33-61.06c-14.37,6.07-28.74,12.15-43.11,18.22c-6.85-4.52-13.7-9.04-20.56-13.56
	c4.92-5.9,9.84-11.81,14.77-17.71c10.3-12.35,20.6-24.71,30.9-37.06c-0.49,0.05-1.01,0.09-1.56,0.11
	C70.92-16.32,70.52-16.31,70.14-16.32z"
    /> */}
    <path
      fill="url(#bgGradient)"
      d="M71.72-16.39C52.33,2.89,32.94,22.17,13.56,41.44C24.78,48.63,36,55.81,47.22,63
	c11.63-5.41,23.26-10.81,34.89-16.22c-7.85,14.52-15.7,29.04-23.56,43.56C45,87.89,31.44,85.44,17.89,83
	c-1.67,18.74-3.33,37.48-5,56.22c24.59-6.33,49.19-12.67,73.78-19c-3.85,13.78-7.7,27.56-11.56,41.33
	c-9.44-3.15-18.89-6.3-28.33-9.44c-11.54,14.24-23.07,28.48-34.61,42.72c17.83,5.09,35.67,10.19,53.5,15.28
	c-8.92,6.1-17.83,12.2-26.75,18.31c3.47,20.97,6.94,41.94,10.42,62.92c1.42-0.06,2.83-0.11,4.25-0.17c-2.5-19.19-5-38.39-7.5-57.58
	c10.64-7.92,21.28-15.83,31.92-23.75c-14.33-7.28-28.67-14.56-43-21.83c4.42-9.17,8.83-18.33,13.25-27.5
	c8.89,4.33,17.78,8.67,26.67,13c5.32-19.5,10.65-39,15.97-58.5c-19.85,1.48-39.7,2.96-59.56,4.44c-1.7-9.41-3.41-18.81-5.11-28.22
	c11.52,2.04,23.04,4.07,34.56,6.11c10.3-20.63,20.59-41.26,30.89-61.89c-14.37,6-28.74,12-43.11,18C41.7,48.93,34.85,44.41,28,39.89
	C43.19,21.06,58.37,2.24,73.56-16.58C72.94-16.52,72.33-16.45,71.72-16.39z"
    />
    <path
      fill="url(#bgGradient2)"
      d="M70.14-16.32C51.02,2.34,31.9,21,12.78,39.67c11.44,7.15,22.89,14.3,34.33,21.44
	c10.85-4.89,21.7-9.78,32.56-14.67c-7.15,14.07-14.3,28.15-21.44,42.22c-13.96-2.59-27.93-5.19-41.89-7.78
	c-2.26,18.81-4.52,37.63-6.78,56.44c24.52-6.07,49.04-12.15,73.56-18.22c-4.15,13.16-8.3,26.31-12.44,37.47
	c-8.22-2.68-16.44-5.35-24.67-8.03c-11.43,14.17-22.85,28.34-34.28,42.5c19.04,4.95,38.07,9.9,57.11,14.86
	c-11.25,6.75-22.5,13.5-33.75,20.25c4.28,21.11,8.56,42.22,12.83,63.33c1.42-0.11,2.83-0.22,4.25-0.33
	c-2.53-19.44-5.06-38.89-7.58-58.33c11.58-8.53,23.17-17.06,34.75-25.58c-15.07-7.05-30.15-14.09-45.22-21.14
	c4.56-9.26,9.11-18.52,13.67-27.78c8.77,4.11,17.54,8.22,26.31,12.33c5.08-19.15,10.17-38.3,15.25-57.44
	c-18.81,1.48-37.63,2.96-56.44,4.44c-1.56-9.41-3.11-18.81-4.67-28.22c10.78,1.76,21.56,3.52,32.34,5.28
	c10.11-20.35,20.22-40.71,30.33-61.06c-14.37,6.07-28.74,12.15-43.11,18.22c-6.85-4.52-13.7-9.04-20.56-13.56
	c4.92-5.9,9.84-11.81,14.77-17.71c10.3-12.35,20.6-24.71,30.9-37.06c-0.49,0.05-1.01,0.09-1.56,0.11
	C70.92-16.32,70.52-16.31,70.14-16.32z"
    />
  </Bolt>
);

export const MobileBoltBackgroundSvg = () => (
  <MobileBolt viewBox="0 0 100 300">
    <defs>
      <linearGradient x1="0%" y1="80%" x2="0%" y2="100%" id="bgGradient3">
        <stop offset="0%" stopColor="rgb(199 185 138)" />
        <stop offset="100%" stopColor="rgb(199 185 138)" />
      </linearGradient>
      <linearGradient x1="0%" y1="0%" x2="0%" y2="100%" id="bgGradient4">
        <stop offset="0%" stopColor="#671c32" />
        <stop offset="20%" stopColor="#ab123e" />
        <stop offset="30%" stopColor="#671c32" />
        <stop offset="50%" stopColor="#ab123e" />
        <stop offset="53%" stopColor="#671c32" />
        <stop offset="70%" stopColor="#ab123e" />
        <stop offset="90%" stopColor="#671c32" />
      </linearGradient>
    </defs>
    <path
      fill="url(#bgGradient3)"
      d="M-7.78,267.78c10.64-7.92,75.14-50.03,85.78-57.94c-14.33-7.28-28.67-14.56-43-21.83
	c4.42-9.17,8.83-18.33,13.25-27.5c8.89,4.33,17.78,8.67,26.67,13c5.32-19.5,10.65-39,15.97-58.5c-19.85,1.48-39.7,2.96-59.56,4.44
	c-1.7-9.41-3.41-18.81-5.11-28.22c11.52,2.04,23.04,4.07,34.56,6.11c10.3-20.63,20.59-41.26,30.89-61.89c-14.37,6-28.74,12-43.11,18
	C41.7,48.93,34.85,44.41,28,39.89C43.19,21.06,58.37,2.24,73.56-16.58c-0.61,0.06-1.22,0.13-1.83,0.19
	C52.33,2.89,32.94,22.17,13.56,41.44C24.78,48.63,36,55.81,47.22,63c11.63-5.41,23.26-10.81,34.89-16.22
	c-7.85,14.52-15.7,29.04-23.56,43.56C45,87.89,31.44,85.44,17.89,83c-1.67,18.74-3.33,37.48-5,56.22
	c24.59-6.33,49.19-12.67,73.78-19c-3.85,13.78-7.7,27.56-11.56,41.33c-9.44-3.15-18.89-6.3-28.33-9.44
	c-11.54,14.24-23.07,28.48-34.61,42.72c17.83,5.09,35.67,10.19,53.5,15.28C56.75,216.21,4.92,248.9-4,255"
    />
    <path
      fill="url(#bgGradient4)"
      d="M-7,264.08c11.58-8.53,74.75-48.31,86.33-56.83c-15.07-7.05-30.15-14.09-45.22-21.14
	c4.56-9.26,9.11-18.52,13.67-27.78c8.77,4.11,17.54,8.22,26.31,12.33c5.08-19.15,10.17-38.3,15.25-57.44
	c-18.81,1.48-37.63,2.96-56.44,4.44c-1.56-9.41-3.11-18.81-4.67-28.22c10.78,1.76,21.56,3.52,32.34,5.28
	c10.11-20.35,20.22-40.71,30.33-61.06c-14.37,6.07-28.74,12.15-43.11,18.22c-6.85-4.52-13.7-9.04-20.56-13.56
	c4.92-5.9,9.84-11.81,14.77-17.71c10.3-12.35,20.6-24.71,30.9-37.06c-0.49,0.05-1.01,0.09-1.56,0.11c-0.41,0.02-0.81,0.02-1.19,0.01
	C51.02,2.34,31.9,21,12.78,39.67c11.44,7.15,22.89,14.3,34.33,21.44c10.85-4.89,21.7-9.78,32.56-14.67
	c-7.15,14.07-14.3,28.15-21.44,42.22c-13.96-2.59-27.93-5.19-41.89-7.78c-2.26,18.81-4.52,37.63-6.78,56.44
	c24.52-6.07,49.04-12.15,73.56-18.22c-4.15,13.16-8.3,26.31-12.44,39.47c-8.22-2.68-16.44-5.35-24.67-8.03
	c-11.43,14.17-22.85,28.34-34.28,42.5c19.04,4.95,38.07,9.9,57.11,14.86c-11.25,6.75-61,34.67-72.25,41.42"
    />
  </MobileBolt>
);

const Bolt = styled(Svg)`
  position: absolute;
  top: -210px;
  /* left: 12%; */
  /* left: 50%;
  transform: translateX(-50%); */
  width: 1800px;
  z-index: 1;

  @media (max-width: 1200px) {
    display: none;
  }
`;

const MobileBolt = styled(Svg)`
  position: absolute;
  z-index: 1;
  top: 0;
  display: flex;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 130vw;

  @media (min-width: 1201px) {
    display: none;
  }
`;
