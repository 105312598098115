import styled, { keyframes } from "styled-components"
// import goldenClothing from 'App/assets/3d/ui/equipment/clothing/Golden Chest.png'
// import beigeTwoTipsEars from 'App/assets/3d/ui/equipment/ears/Beige Two Tips.png'
// import cloudEyebrows from 'App/assets/3d/ui/equipment/eyebrows/Cloud.png'
import { Suspense, useState, useRef, useEffect, useLayoutEffect } from "react";
import { useAtom } from "jotai";
import { itemReducerAtom } from "Project/Game/data/atoms";
import { TYPE } from "Project/Game/const";

const Avatar = () => {
  const [slots, dispatchItem] = useAtom(itemReducerAtom);
  const [currentItem, setCurrentItem] = useState(undefined);

  return <Wrapper>
    <div>
      {/* <Background /> */}
      {/* <Pattern /> */}
      <Weapon src={slots[TYPE.Weapon]?.equipped?.img.avatar} />
      <Tail src={slots[TYPE.Tail]?.equipped?.img.avatar} />
      <Clothing src={slots[TYPE.Clothing]?.equipped?.img.avatar} />
      <Shoulder src={slots[TYPE.Shoulder]?.equipped?.img.avatar} />
      <Ears src={slots[TYPE.Ears]?.equipped?.img.avatar} />
      <Head src={slots[TYPE.Head]?.equipped?.img.avatar} />
      <Eyes src={slots[TYPE.Eyes]?.equipped?.img.avatar} />
      <Eyebrows src={slots[TYPE.Eyebrows]?.equipped?.img.avatar} />
      <Mouth src={slots[TYPE.Mouth]?.equipped?.img.avatar} />
      <Facial src={slots[TYPE.Facial]?.equipped?.img.avatar} />
      <Hat key={slots[TYPE.Hat]?.equipped?.img.avatar} src={slots[TYPE.Hat]?.equipped?.img.avatar} />
    </div>
  </Wrapper>
}

const animFadeIn = keyframes`
  from {
    filter: brightness(1.5) blur(5px);
  }
  to {
    filter: brightness(1) blur(0px);
  }
`

const WrapItem = styled.div`
  animation: ${animFadeIn} 0.5s ease-in-out forwards;
`

const Layer = styled.img`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
`

const Weapon = styled(Layer)`
`

const Tail = styled(Layer)`
`

const Clothing = styled(Layer)`
`

const Shoulder = styled(Layer)`
`

const Ears = styled(Layer)`
`

const Head = styled(Layer)`
`

const Eyes = styled(Layer)`
`

const Eyebrows = styled(Layer)`
`

const Mouth = styled(Layer)`
`

const Facial = styled(Layer)`
`

const Hat = styled(Layer)`
  animation: ${props => props.firstLoad == false && animFadeIn} 0.5s ease-in-out forwards;
`

const Wrapper = styled.div`
  height: 100%;
  filter: drop-shadow(0 -5px 0.75rem rgba(0,0,0,0.3));
  user-select: none;
`

export default Avatar;