import { CURRENCY } from "Project/Landing/Mint/const";
import styled from "styled-components";

const Summary = ({ handleClick, image, releasable, released, isDisabled, type }) => {
  return <Wrapper>
    <Container>
      <Logo width="67" height="67" src={image} alt="" />
      <Amounts>
        <Releasable>{releasable.toFixed(2)} {type === CURRENCY.WFIRO ? 'FIRO' : 'BNB'}</Releasable>
        <Total>{(releasable + released).toFixed(2)} TOTAL</Total>
      </Amounts>
    </Container>
    <ReleaseBtn onClick={handleClick} isDisabled={isDisabled}>RELEASE {(releasable > 0 && releasable).toFixed(2)}</ReleaseBtn>
  </Wrapper>
};


const Logo = styled.img``
const Releasable = styled.div`
  color: #e6bad9;
  font-size: 2.25rem;
`
const Total = styled.div`
  color: #a46392;
  font-size: 1.2rem;
`
const Amounts = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`

const ReleaseBtn = styled.div`
  background: #783260;
  border: 2px solid transparent;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #bd77a8;
  width: 100%;
  height:55px;
  font-size: 1.1rem;
  font-weight: 500;
  &:hover {
    filter: ${props => props.isDisabled ? '' : 'brightness(1.2)'};
  }

  &:active {
   transform: translateY(1px);
   filter: brightness(0.99);
  }

  /* ${props => props.isDisabled && 'border: 2px solid #714064; background: transparent;'} */

  filter: ${props => props.isDisabled ? 'grayscale(20%)' : 'brightness(1.1)'};
  cursor: ${props => props.isDisabled ? 'default' : 'pointer'};
  pointer-events: ${props => props.isDisabled ? 'none' : 'auto'};
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
    gap: 18px;
  padding: 5px 5px;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  background: #612751;
  width: 100%;
  border-radius: 8px;
  padding: 35px;
  gap: 18px;
`
const Unreleased = styled.div`
  display: flex;
  border-radius:  14px;
  background: #511c42;
  gap: 45px;
  padding: 45px;
`

export default Summary;