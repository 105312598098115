import styled from 'styled-components';

const SocialButton = ({ link, text, icon }) => {
  return (
    <Wrapper>
      {/* <Container target="_new" href={link}> */}
      <Container href={link}>
        <MobileText>{text}</MobileText>
        {icon}
      </Container>
    </Wrapper>
  );
};

const MobileText = styled.div`
  @media (min-width: 1025px) {
    display: none;
  }
`;

const Container = styled.a`
  display: flex;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  align-items: center;
  border: 3px solid transparent;
  padding: 0 0.5vw;
  cursor: pointer;
  outline: none;
  filter: brightness(0.8);
  /* transition: all 0.08s; */

  &:hover {
    filter: brightness(2);
  }

  @media (min-width: 1025px) {
    &:active {
      border-radius: 12px;
      transform: translateY(0.75px);
    }
  }

  @media (max-width: 768px) {
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;
  height: 60px;

  @media (max-width: 1024px) {
    width: 100%;
    height: clamp(60px, 8vh, 90px);

    cursor: pointer;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
`;

export default SocialButton;
