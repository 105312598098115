import Svg from "components/SVG";
import styled, { keyframes } from "styled-components";

const MenuButton = ({ handleClick }) => {
  return <Wrapper>
    <ButtonSvg onClick={handleClick} viewBox="0 0 129.402 105.611">
      <path id="Path_211" data-name="Path 211" d="M11.432,11.585,133.624,3.537l3.752,101.277L17.654,103.3Z" transform="matrix(0.999, 0.035, -0.035, 0.999, -7.767, -3.934)" fill="#060606" />
      <path id="Path_212" data-name="Path 212" d="M-6.183,18.008l105.842-6.94,2.932,83.965L-.447,94.408Z" transform="matrix(0.999, 0.035, -0.035, 0.999, 18.766, -1.769)" fill="#fff" />
      <path id="Path_213" data-name="Path 213" d="M-9.638,16.558,79.43,10.3,81.176,79.6l-86.8-.7Z" transform="matrix(0.999, 0.035, -0.035, 0.999, 30.749, 6.91)" fill="#d15151" />
      <line id="Line_7" data-name="Line 7" y1="3.246" x2="57.794" transform="translate(35.699 37.206) rotate(3)" fill="none" stroke="#000" stroke-width="8" />
      <line id="Line_8" data-name="Line 8" y1="3.246" x2="57.794" transform="translate(35.699 52.197) rotate(3)" fill="none" stroke="#000" stroke-width="8" />
      <line id="Line_9" data-name="Line 9" y1="3.246" x2="57.794" transform="translate(35.7 67.223) rotate(3)" fill="none" stroke="#000" stroke-width="8" />
      <line id="Line_4" data-name="Line 4" y1="3.246" x2="57.794" transform="translate(35.355 35.319) rotate(3)" fill="none" stroke="#fff" stroke-width="8" />
      <line id="Line_5" data-name="Line 5" y1="2.478" x2="56.771" transform="translate(35.591 50.824) rotate(3)" fill="none" stroke="#fff" stroke-width="8" />
      <line id="Line_6" data-name="Line 6" y1="3.011" x2="56.771" transform="translate(35.755 65.54) rotate(3)" fill="none" stroke="#fff" stroke-width="8" />
    </ButtonSvg>
  </Wrapper>
}

const animIn = keyframes`
  0% { transform: translateY(10px) scale(0.98); opacity: 0; }
  100% { transform: translateY(0) scale(1); opacity: 1; }
`

const ButtonSvg = styled(Svg)`
  width: 129px;
  height: 105px;
  cursor:pointer;

  &:hover {
    filter: brightness(1.1);
    transform: scale(1.1);
  }

  &:active {
    filter: brightness(0.9);
    transform: translateY(2px) scale(1.1);
  }
`

const Wrapper = styled.div`
  opacity: 0;
  animation: ${animIn} 0.7s 1.1s cubic-bezier(0, 0.8, 0.8, 0.9) forwards;
`

export default MenuButton;
