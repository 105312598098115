import styled from "styled-components"

const kFormatter = (num) => Math.abs(num) > 999 ? Math.sign(num) * ((Math.abs(num) / 1000).toFixed(1)) + 'k' : Math.sign(num) * Math.abs(num)

const Hud = ({ log, gl }) => {
  return <Wrapper>
    <Title>Firo Fox Debug</Title>
    <Divider>
      <Left>
        <FpsText>FPS</FpsText>
        <DrawCallText>Draws </DrawCallText>
        <TriangleText>Triangles</TriangleText>
      </Left>
      <Right>
        <Fps>{log?.fps.toFixed(0)}</Fps>
        <DrawCall>{gl?.info.render.calls.toFixed(0)}</DrawCall>
        <Triangle>{kFormatter(gl?.info.render.triangles.toFixed(0))}</Triangle>
      </Right>
    </Divider>
  </Wrapper>
}

const Left = styled.div``
const Right = styled.div``
const Divider = styled.div`
  display: flex;
  column-gap: 10px;
`

const Title = styled.div` 
color: #555;
`

const Text = styled.div`
  color: #888;
`
const FpsText = styled(Text)``
const DrawCallText = styled(Text)``
const TriangleText = styled(Text)``

const Data = styled.div`
  font-weight: 700;
  color: #aaa;
`
const Fps = styled(Data)``
const DrawCall = styled(Data)``
const Triangle = styled(Data)``

const Wrapper = styled.div`
color: #fff;
display: flex;
flex-direction: column;
row-gap: 5px;
line-height: 1rem;
font-size: 0.8rem;
`

export default Hud