import { useLayoutEffect } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';
import { scroller } from 'react-scroll';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import Hero from 'Project/Landing/Hero';
import FAQ from 'Project/Landing/FAQ';
import Features from 'Project/Landing/Features';
import Mint from 'Project/Landing/Mint';
import Compendium from 'Project/Landing/World';

import AnimateRouteTransition from 'components/AnimateRouteTransition';

// function getBrowserLocales(options = {}) {
//   const defaultOptions = {
//     languageCodeOnly: false,
//   };
//   const opt = {
//     ...defaultOptions,
//     ...options,
//   };
//   const browserLocales = navigator.languages === undefined ? [navigator.language] : navigator.languages;
//   if (!browserLocales) {
//     return undefined;
//   }
//   return browserLocales.map(locale => {
//     const trimmedLocale = locale.trim();
//     return opt.languageCodeOnly ? trimmedLocale.split(/-|_/)[0] : trimmedLocale;
//   });
// }

const Landing = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  // }, []);

  // separate from navigation due to code-splitting
  useLayoutEffect(() => {
    const options = {
      duration: 300,
      delay: 55,
      smooth: true,
      offset: 0,
    };

    const worldOptions = { ...options, offset: 280 };
    const mintOptions = { ...options, offset: -175 };
    const featuresOptions = { ...options, offset: -190 };

    const navigationType = window.performance.getEntriesByType('navigation')[0].type;

    const isPageReload = navigationType === 'reload';
    const isNavigation = navigationType === 'navigate';
    const isBackForwarad = navigationType === 'back_forward';
    const isPrerender = navigationType === 'prerender';

    // console.log('1', isPageReload);
    // console.log('2', isNavigation);
    // console.log('3', isBackForwarad);
    // console.log('4', isPrerender);

    if (location.hash === '' && location.pathname !== '/mint') scroller.scrollTo('hero', options);
    if (location.hash === '#world') scroller.scrollTo('world', worldOptions);
    if (location.hash === '#mint') scroller.scrollTo('mint', mintOptions);
    if (location.hash === '#features') scroller.scrollTo('features', featuresOptions);
    if (location.hash === '#faq') scroller.scrollTo('faq', options);
  }, [location]);

  return (
    <ParallaxProvider>
      <AnimateRouteTransition>
        <Gradient>
          <Hero />
          <Compendium />
          <Features />
        </Gradient>
        <Mint />
        <FAQ />
      </AnimateRouteTransition>
    </ParallaxProvider>
  );
};

const Gradient = styled.div`
  display: flex;
  flex-direction: column;
  background: #4b153c;

  @media (max-width: 800px) {
    background: linear-gradient(#4b153c 40%, #140d26 40%, #140d26 100%);
  }
`;

export default Landing;
