import { Canvas } from "@react-three/fiber";
import React, { useMemo, useEffect, Suspense, useRef } from 'react'
import Scene from "./Scene"
import { Vector3 } from "three";
import { KeyboardControls, Bvh, SoftShadows, AdaptiveDpr, AdaptiveEvents, OrbitControls, PositionalAudio } from "@react-three/drei";
import Controls from "Project/Game/Scenes/Overworld/Controls";
import { canvasOptions, fogOptions, controlMappingOptions } from "Project/Game/options";
import { PerfHeadless } from "r3f-perf";
import Loader from "./Loader";
import Models from "./Models";
import Camera from "./Controls/Camera";
import RayCast from "./Controls/RayCast";
// import infirmrySound from 'App/assets/sounds/infirmry.mp3'

const Overworld = () => {
  const map = useMemo(() => controlMappingOptions, [])
  const userFocalTarget = useRef(new Vector3(-300, 220, -400));
  const savedCameraPos = useRef({ position: { x: 0, y: 0, z: 0 }, target: { x: 0, y: 0, z: -650 } });

  // frameloop="demand" 

  let enabled = true
  return <Canvas {...canvasOptions} camera={{ ...canvasOptions.camera }}>
    <KeyboardControls map={map}>
      {/* <Controls userFocalTarget={userFocalTarget} /> */}
    </KeyboardControls>
    <Camera />
    <Suspense fallback={<Loader />}>
      <color args={['#4b153c']} attach="background" />
      <Bvh>
        <Models />
        <SoftShadows frustum={1.5} size={0.005} near={1.5} samples={20} rings={2} />
        <Scene />
        <AdaptiveDpr pixelated={true} />
        <AdaptiveEvents />
        <PerfHeadless />
      </Bvh>
      {/* <Preload all /> */}
      <fog attach="fog" args={['#140b26', 1000, 23800]} />
    </Suspense>
  </Canvas>
}

export default Overworld