import styled from "styled-components"
import { useState, useEffect } from "react"
import { CLASS } from "Project/Game/const";
import { useAtom } from "jotai";
import { playerClassAtom } from "Project/Game/data/atoms";
import { Howl } from "howler";

var promptSound = new Howl({
  src: ['/sound/drive_completewav.wav'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

var acceptSound = new Howl({
  src: ['/sound/skills_improveskill.wav'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

var hoverSound = new Howl({
  src: ['/sound/generic_click01.wav'],
  autoplay: false,
  loop: false,
  volume: 0.3,
});


const PickClass = ({ handleNextModule }) => {
  const [playerClass, setPlayerClass] = useAtom(playerClassAtom);
  const [selectedClass, setSelectedClass] = useState(null);
  const choices = [{ type: CLASS.StreetSamurai, name: "Street Samurai", description: "A strong fighter" }, { type: CLASS.Netrunner, name: "Netrunner", description: "A powerful spellcaster" }, { type: CLASS.Netjacker, name: "Netjacker", description: "A sneaky thief" }, { type: CLASS.Fixer, name: "Fixer", description: "A sneaky thief" }, { type: CLASS.Ironhide, name: "Ironhide", description: "A sneaky thief" }]

  useEffect(() => {
    promptSound.play();
  }, [])


  const handleButton = (type) => {
    // setSelectedClass(type);
    handleNextModule();
    setPlayerClass(type);
    acceptSound.play();
  }

  const handleMouseOver = () => {
    hoverSound.play();
  }

  return <>
    <Backdrop />
    <Wrapper>
      <Options>
        {choices.map((option) => <Option key={option.type} selected={selectedClass === option.type} onClick={() => handleButton(option.type)}>
          {/* <ComicBubble viewBox="0 0 616 260.9">
          <path d="M430 50 23.5 28 0 188.2l404.2-21.1 32.7 19.7 36.5-5.7 122.4 59.3L468.6 86.8 450 99Z" />
          <path fill="#fff" d="M420.3 64.6 34.4 44 14.1 173.6l394.7-19 28.6 18 34.4-5.4 97.8 51.4-101.7-109.1-30.3 14.6Z" data-name="Dialogue" />
        </ComicBubble> */}
          <Text onMouseOver={handleMouseOver}>{option.name}</Text>
        </Option>)}
      </Options>
    </Wrapper>
  </>
}

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  backdrop-filter: blur(3px);
  height: 100%;
  background: rgba(0,0,0, 0.4);
`

const Text = styled.div`
width: 100%;
height: 100%;
display: flex;
justify-content: center;
align-items: center;
  color: #000; 
  font-size: 1.4rem;
  left: 0;
`

const ComicBubble = styled.svg`
  position: absolute;
  top: -40px;
  left: 0;
  width: 500px;
  height: 200px;
`

const Option = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 150px;
  background: red;

  &:hover {
    scale: 1.05;
    cursor: pointer;
  }

  &:active {
    filter: brightness(0.9);
    transform: translateY(2px);
  }
`

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 800px;

`

const Wrapper = styled.div`
  display: flex;
  font-family: var(--primary-text-font);
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  color: #fff;
  z-index: 12;
  padding: 0 3vw;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`

export default PickClass

