import { createGlobalStyle } from 'styled-components';

import 'App/fontStyles.css';

let colorPalette = {
  border: '#debd6b',
  day: {
    clouds: {
      highlight: '#ffffff',
      shadow: '#f2f4f3',
    },
    cloudStreak: '#b3d7e2',
    skyStreak: '#acd5e3',
    skyGradient: {
      top: '#62b4d1',
      bottom: '#e2ebed',
    },
    text: {
      header: '#d1fdff',
      paragraph: '#ffffff',
    },
    world: {
      header: '#000',
      paragraph: '#000',
    },
    features: {
      background: '#fefefe',
    },
    faq: {
      border: 'rgba(255, 255, 255, 0.4)',
      header: '#fff',
      headerHighlight: '#d1fdff',
      paragraph: '',
    },
    grass: '#617a9c',
    road: '#eff6ff',
  },
  night: {
    clouds: {
      highlight: '#641d44',
      shadow: '#3c1532',
    },
    skyStreak: '#b51e46',
    cloudStreak: '#771a42',
    skyGradient: {
      top: '#461438',
      bottom: '#b51e46',
    },
    text: {
      header: '#ffa4ac',
      paragraph: '#ffffff',
    },
    world: {
      header: '#ff8d9b',
      paragraph: '#ffffff',
    },
    features: {
      background: '#fefefe',
    },
    faq: {
      border: '#a12d4c',
      header: '#fff',
      headerHighlight: '#fe647f',
      paragraph: '',
    },
    grass: '#502644',
    road: '#451f3a',
  },
};

const globalStyles = createGlobalStyle`
  html {
    /* background: #4b153c; */
    background: #0b090a;
  }

  body {
    font-family: 'Segoe UI', Helvetica, sans-serif;
  }

  #firofox-root {
    display: flex;
    flex-direction: column;
    align-items: center;  
    overflow-x: hidden;

  }

  button {
    all: unset;
    cursor: pointer;
  }

  input {
    all: unset;
  }

  a {
    all: unset;
  }

  :root {
    --shadow: rgba(0, 0, 0, 1);

    --collection-color-1: grey;

    --collection-deselected: #af9eab;
    --collection-selected: #fff;
    --collection-depressed: #af9eab;
    --collection-hover: #efdbea;

    /* --mint-success-foreground: #fff;
    --mint-success-midground: #7F7FFF;
    --mint-success-background: #00007F;   */

    --mint-success-foreground: #e9a4ff;
    --mint-success-midground: #9b3ea7;
    --mint-success-background: #6d007f;  
    
    --mint-success-foreground:#ffffff;
    --mint-success-midground:#ffffffa6;
    --mint-success-background:#ffffffba;  
    --mint-success-time: 3.5s;
    

    --world-shadow: #101823;

    --sky-cloud-shadow: #3c1532;
    --sky-cloud-highlight: #641d44;
    --sky-cloud-streak: #771a42;
    --sky-streak: #b51e46;

    --nav-background: #171717a8;
    --nav-button-hover: #fff;
    --content-max-width: 1800px;
    --title-font: 'Aldo the Apache';
    --secondary-text-font: 'Gosmick Sans';
    --primary-text-font: 'Anime Ace';
    --font-light: #fff;
    --nav-color: #fff;
    --nav-font-family: 'Aldo the Apache';
    --nav-font-size: 1.35rem;
    --nav-font-weight: normal;
    --nav-letter-spacing: 0.5px;
    --border: #ffe4a4;
    --gold2: #ffeaa6;
    --compendium-border-hover: #fff;

    --fan-1: #292629;
    --fan-2: #000000;

    --mint-pillar-primary-color-light: #7d3865;
    --mint-pillar-primary-color-shadow: #4e244c;

    --mint-pillar-secondary-color-light: #653c58;
    --mint-pillar-secondary-color-shadow: #301934;

    --mint-pillar-tertiary-color-shadow: #40233d;

    --mint-pillar-ribbon-red: #a8123d;
    --mint-pillar-ribbon-yellow: #ddc677;
    --mint-pillar-ribbon-primary-shadow: #4e2d42;
    --mint-pillar-ribbon-secondary-shadow: #32222b;
    --mint-pillar-ribbon-shadow-yellow: #81724e;

    /* --mint-ground-surface: #342337; */
    /* --mint-ground-cliff: #382A3C; */
    --mint-ground-surface: #3c293d;
    --mint-ground-cliff: #382138;
    

    --mint-glow-primary: #94fafe;
    --mint-glow-secondary: #82a7f7;
  }

  /* :root {  --landing-clouds-highlight: ${colorPalette.night.clouds.highlight}; --landing-clouds-shadow: ${colorPalette.night.clouds.shadow}; --landing-skyGradient-top: ${colorPalette.night.skyGradient.top}; --landing-skyGradient-bottom: ${colorPalette.night.skyGradient.bottom}; --landing-skyStreak: ${colorPalette.night.skyStreak}; --landing-cloudStreak: ${colorPalette.night.cloudStreak};  --landing-text-header: ${colorPalette.night.text.header}; --landing-text-paragraph: ${colorPalette.night.text.paragraph}; --landing-world-header: ${colorPalette.night.world.header}; --landing-world-paragraph: ${colorPalette.night.world.paragraph}; --landing-faq-border: ${colorPalette.night.faq.border}; --landing-faq-header: ${colorPalette.night.faq.header}; --landing-faq-headerHighlight: ${colorPalette.night.faq.headerHighlight}; --landing-faq-paragraph: ${colorPalette.night.faq.paragraph}; --landing-grass: ${colorPalette.night.grass};--landing-road: ${colorPalette.night.road}; --landing-features-background: ${colorPalette.night.features.background} */
`;

export default globalStyles;
