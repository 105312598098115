import { useEffect, useState } from "react";
import styled from "styled-components";

const Trait = ({ trait, handleTrait, count, isSelected }) => {
  // const [isChecked, setIsChecked] = useState(false);
  const handleClick = () => {
    handleTrait({ type: isSelected ? 'remove' : 'add', trait });
  }

  return <Label onClick={e => e.stopPropagation()}>
    <Checkbox onChange={handleClick} type="checkbox" checked={isSelected} />
    <Name isHighlighted={isSelected}>{trait} ({count})</Name>
  </Label>
}

const Checkbox = styled.input.attrs(props => ({ background: props.checked ? '#fff !important' : '#0b090a' }))`
  display: inline-block;
  width: 16px;
  height: 16px;
  cursor: pointer;
  background: ${props => props.background};
  border: 3px solid var(--collection-color-1);
  border-radius: 3px;

  @media (max-width: 1200px) {
    background: transparent;
  }
`;

const Name = styled.div.attrs(props => ({
  color: props.isHighlighted ? '#fff !important' : 'var(--collection-color-1)',
}))`
  color: ${props => props.color};
  overflow: hidden;
  font-size: 1rem;
  /* max-width: 18ch; */
  /* width: 100%; */
  /* height: 100%; */
  text-overflow: nowrap;
  white-space: nowrap;
  position: relative;
`;


const Label = styled.label`
  display: flex;
  padding: 5px 0;
  column-gap: 7px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 1024px) {
    padding: 11px 0;
  }

  &:hover {
    div {
      color: #ccc;
    }

    input {
      border: 3px solid #ccc;
    }
  }

`

export default Trait;