import { EffectComposer, SSAO, DepthOfField, GodRays, HueSaturation, SMAA, BrightnessContrast, DotScreen, Scanline, Bloom, Noise, Vignette, ChromaticAberration, TiltShift, Glitch } from "@react-three/postprocessing"
import { useDepthBuffer, useFBO, Effects, Environment } from "@react-three/drei"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass.js";
import { SSAOPass } from "three/examples/jsm/postprocessing/SSAOPass.js";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader.js";
import { BokehPass } from 'three-stdlib'
import { useRef } from "react";
import { extend, useThree } from "@react-three/fiber";
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass.js";
import { Vector3, Vector2, MeshBasicMaterial } from "three";
import { DepthOfFieldEffect, BlendFunction, TiltShiftEffect, BlurPass, Resizer, KernelSize, GlitchMode } from 'postprocessing';
import { useAtom } from "jotai";
import { camAtom } from "Project/Game/data/atoms";

extend({ SSAOPass, UnrealBloomPass, BokehPass, DepthOfFieldEffect, TiltShiftEffect })

const PostProcess = ({ debugOptions }) => {
  const [cameraState, setCameraState] = useAtom(camAtom)
  // let dofTarget = new Vector3(cameraState.target.x, cameraState.target.y, cameraState.target.z);
  const { viewport, scene, camera, gl } = useThree();
  const sunRef = useRef();
  const bokehPassParams = {
    focus: 2.5,
    aspect: 1,
    aperture: 1.5,
    maxblur: 0.5,
    width: window.innerWidth,
    height: window.innerHeight
  }

  return <>
    <EffectComposer enabled={debugOptions.postProcessing} depthBuffer={true} disableNormalPass={true}>
      {/* <SMAA /> */}
      {/* <DepthOfField
        target={dofTarget}
        focusDistance={4}
        focalLength={0.06}
        bokehScale={8}
      /> */}
      {/* <SSAO samples={20} bias={0.2} radius={0.02} intensity={20} luminanceInfluence={0.5} color="black" /> */}
      {/* <SSAO
        blendFunction={BlendFunction.MULTIPLY} // blend mode
        samples={30} // amount of samples per pixel (shouldn't be a multiple of the ring count)
        rings={4} // amount of rings in the occlusion sampling pattern
        distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
        distanceFalloff={0.0} // distance falloff. min: 0, max: 1
        rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
        rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
        luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
        radius={1} // occlusion sampling radius
        scale={0.5} // scale of the ambient occlusion
        bias={0.5} // occlusion bias
        intensity={20.0} // overall intensity
        color="black" // occlusion color
      /> */}
      {/* <TiltShift feather={0.05} bias={0} focusArea={0.35} offset={0} rotation={0} kernelSize={4} /> */}
      {/* <Bloom
        radius={15}
        intensity={2.0} // The bloom intensity.
        blurPass={undefined} // A blur pass.
        width={Resizer.AUTO_SIZE} // render width
        height={Resizer.AUTO_SIZE} // render height
        kernelSize={KernelSize.LARGE} // blur kernel size
        luminanceThreshold={0.65} // luminance threshold. Raise this value to mask out darker elements in the scene.
        luminanceSmoothing={0.025} // smoothness of the luminance threshold. Range is [0, 1]
      /> */}
      <ChromaticAberration
        blendFunction={BlendFunction.NORMAL}
        offset={[0.0005, 0.0005]}
      />
      <Noise
        premultiply // enables or disables noise premultiplication
        blendFunction={BlendFunction.ADD} // blend mode
        opacity={0.185} />
      <Vignette
        blendFunction={BlendFunction.NORMAL}
        eskil={false}
        offset={0.1}
        darkness={0.8} />
      {/* <HueSaturation
        blendFunction={BlendFunction.NORMAL} // blend mode
        hue={0} // hue in radians
        saturation={0} // saturation in radians
      /> */}
      {/* <BrightnessContrast
        brightness={0} // brightness. min: -1, max: 1
        contrast={0.01} // contrast: min -1, max: 1
      /> */}
      {/* <Glitch
        delay={[0, 3]} // min and max glitch delay
        duration={[0.1, 0.6]} // min and max glitch duration
        strength={[0.1, 0.2]} // min and max glitch strength
        mode={GlitchMode.CONSTANT_MILD} // glitch mode
        active={false} // turn on/off the effect (switches between "mode" prop and GlitchMode.DISABLED)
        ratio={0.19} // Threshold for strong glitches, 0 - no weak glitches, 1 - no strong glitches.
      /> */}
    </EffectComposer>
    {/* <Effects multisamping={0} depthBuffer={true}>
      <unrealBloomPass args={[new Vector2(window.innerWidth, window.innerHeight), 0.001, 0.001, 0.01]} />
      <sSAOPass args={[scene, camera, window.innerWidth, window.innerHeight]} minDistance={0.0004} maxDistance={0.0005} kernalRadius="6" />
      <shaderPass args={[FXAAShader]} />
      <tiltShiftEffect />
      <customOutlinePass args={[new Vector2(window.innerWidth, window.innerHeight), scene, camera]} />
      <bokehPass args={[scene, camera, bokehPassParams]} />
      <depthOfFieldEffect args={[camera, depthPassOptions]} />
    </Effects> */}
  </>
}

export default PostProcess