import styled, { keyframes } from "styled-components";

const Tag = ({ children, handleClick }) => {
  return <Wrapper onClick={handleClick}>{children} <Close>x</Close></Wrapper>
}

const animAppear = keyframes`
  0% { transform: translateY(1px) scale(0.96); opacity: 0.6;};
  100% { transform: translateY(0) scale(01); opacity: 1;};

`

const Close = styled.div`
  font-weight: 500;
  opacity: 0.5;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 11px;
  padding: 13px 14px;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 4px;
  height: fit-content;
  width: fit-content;
  background: #c33b47;
  cursor: pointer;
  opacity: 0;
  animation: ${animAppear} 0.06s ease-in forwards;

  &:hover{
    filter: brightness(1.2);
  }
`

export default Tag;