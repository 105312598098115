import { useContext } from 'react';

import styled from 'styled-components';
import firoLogoWhite from 'App/assets/images/footer/firo-logo-white.png';
import { FiroLogoSvg } from 'components/SVG/logos';

import { LanguageContext } from 'containers/Language';

import Explainer from './Explainer';

const Firo = () => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <Wrapper>
      <Container>
        <FiroLogoSvg />
        <Header>
          <Headline>{dictionary.footer.title}</Headline>
          <Paragraph>{dictionary.footer.description}</Paragraph>
        </Header>
      </Container>
      <Content>
        {dictionary.footer.explainer.map(explainer => (
          <Explainer tag={explainer.tag} title={explainer.title} description={explainer.description} link={explainer.link} />
        ))}
      </Content>
    </Wrapper>
  );
};

const Content = styled.ul`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  flex-wrap: wrap;
`;

const Paragraph = styled.p`
  max-width: 350px;
  width: 100%;
  line-height: 1.6rem;
`;

const Headline = styled.h3`
  opacity: 0.7;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.3rem;
  line-height: 1.5rem;
  font-size: 1.3rem;
`;

const FiroLogoWhite = styled.img`
  width: 100px;
  opacity: 0.4;
  height: auto;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 25px;
  column-gap: 2rem;
  /* border: 2px solid black; */
`;

const Wrapper = styled.div`
  display: flex;
  padding-top: 25px;
  gap: 30px;
  flex-direction: column;
  justify-content: center;
  /* background: #bf3f52; */
  width: 100%;
`;

export default Firo;
