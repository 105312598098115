import { useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from 'containers/Language';
import Header from 'components/Header';

import world1 from 'App/assets/images/landing/features/world1.png';

const Features = () => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <Wrapper name="features">
      <Container>
        {/* <NewHeaderWrapper>
          <NewHeader>Features</NewHeader>
        </NewHeaderWrapper> */}
        <WrapHeader>
          <Header text={dictionary.features.header} />
        </WrapHeader>
        <WrapExplanationBoxes>
          <WrapWrap>
            <NewExplainerWrap>
              <GoldBackground />
              <BlackBackground />
              <HeaderText>{dictionary.features.explainer[0].title}</HeaderText>
              <Paragraph>{dictionary.features.explainer[0].description}</Paragraph>
            </NewExplainerWrap>
            <VulparionImg src={world1} alt="" />
          </WrapWrap>
          <WrapWrap2>
            <NewExplainerWrap>
              <GoldBackground />
              <BlackBackground />
              <HeaderText>{dictionary.features.explainer[1].title}</HeaderText>
              <Paragraph>{dictionary.features.explainer[1].description}</Paragraph>
            </NewExplainerWrap>
          </WrapWrap2>
          <WrapWrap3>
            <NewExplainerWrap>
              <GoldBackground />
              <BlackBackground />
              <HeaderText>{dictionary.features.explainer[2].title}</HeaderText>
              <Paragraph>{dictionary.features.explainer[2].description}</Paragraph>
            </NewExplainerWrap>
          </WrapWrap3>
        </WrapExplanationBoxes>
      </Container>
    </Wrapper>
  );
};

const NewHeader = styled.div`
  font-size: 140px;
  font-family: var(--title-font);
`;

const NewHeaderWrapper = styled.div`
  position: absolute;
  top: -250px;
  right: 190px;
  transform: rotate(19deg) perspective(100px) rotate3d(15, -15, 5, 2deg);
`;

const WrapWrap = styled.div`
  position: relative;
  left: clamp(-600px, calc(-34vw + 300px), -300px);
  width: 580px;

  @media (max-width: 800px) {
    width: auto;
  }

  @media (max-width: 1200px) {
    left: auto;
  }
`;

const WrapWrap2 = styled.div`
  position: relative;
  top: 0;
  right: clamp(-250px, calc(-20vw - 150px), -100px);
  width: 580px;

  @media (max-width: 1200px) {
    right: auto;
  }

  @media (max-width: 800px) {
    width: auto;
  }
`;

const WrapWrap3 = styled.div`
  position: relative;
  left: calc(-18vw + 300px);
  width: 580px;

  @media (max-width: 800px) {
    width: auto;
  }

  @media (max-width: 1200px) {
    left: auto;
  }
`;

const VulparionImg = styled.img`
  z-index: 35;
  position: absolute;
  top: -128px;
  left: 57px;
  margin-left: 440px;

  @media (max-width: 800px) {
    position: relative;
    left: 47%;
    top: -20px;
    margin-left: auto;
    transform: translateX(-50%);
  }

  @media (max-width: 480px) {
    top: -31px;
    margin-bottom: -80px;
    width: auto;
    height: auto;
  }
`;

const WrapExplanationBoxes = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  row-gap: 130px;
`;

const HeaderText = styled.div`
  position: absolute;
  top: -15px;
  left: 65px;
  z-index: 25;
  text-shadow: -2px 2px 0 #000, -2px -2px 0 #000, 2px 2px 0 #000, 2px -2px 0 #000, 1px 5px 0 #000, 5px 5px 0 #000;
  font-family: var(--title-font);
  font-size: 3.55rem;
  color: var(--gold2);

  transform: translateZ(9px) rotate3d(15, 2, 3, -2deg);

  @media (max-width: 800px) {
    width: 200px;
    top: -19px;
    left: 50px;
    font-size: 2.5rem;
    line-height: 2.2rem;
  }
`;

const Paragraph = styled.p`
  font-size: clamp(1.15rem, calc(0.75vw + 0.75vh), 1.25rem);
  line-height: clamp(1.5rem, calc(1.75vw + 1.5vh), 2.15rem);
  padding: 50px 80px 50px 65px;
  z-index: 15;
  color: #fff;
  font-family: Arsenal;
  font-family: 'Anime Ace';
  transform: translateZ(7px);

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 60px 30px 60px 50px;
  }
`;

const GoldBackground = styled.div`
  position: absolute;
  top: 14px;
  left: 0px;
  background: linear-gradient(to right, #f2efb7 0%, #b58c39 50%, #fae68f 99%);
  background-repeat: repeat;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  color: #fff;
  transform: rotate3d(-4, -0.15, 13, 3.5deg) skew(0deg, 0deg);
  z-index: 5;
  width: 106%;
  height: 102%;
  position: absolute;

  @media (max-width: 800px) {
    position: absolute;
    flex-direction: row;
    width: 103%;
    top: 4px;
    left: 0;
    opacity: 1;
  }
`;

const BlackBackground = styled.div`
  position: absolute;
  top: 4px;
  left: 8px;
  background: #000;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
  color: #fff;
  border-top: 4px solid var(--border);
  border-right: 4px solid var(--border);
  border-left: 4px solid var(--border);
  border-bottom: 4px solid var(--border);
  transform: rotate3d(5, 0, 19, -3.75deg) skew(0deg, 0deg) translateZ(3px);
  z-index: 5;
  background-repeat: repeat;
  width: clamp(240px, 30vw, 620px);
  width: 100%;
  height: 100%;

  @media (max-width: 1200px) {
    position: absolute;
    order: 1;
  }
`;

const NewExplainerWrap = styled.div`
  position: relative;
  display: flex;
  perspective: 100;

  @media (max-width: 800px) {
    width: 86vw;
    max-width: 450px;
    top: auto;
    left: auto;
  }

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const WrapHeader = styled.div`
  position: absolute;
  top: -300px;
  left: 20%;
  width: 275px;
  z-index: 15;
  transform: rotate(5deg);
`;

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  max-width: 1200px;
  padding: 20px;
  width: 100%;
  z-index: 5;
  height: 100%;
`;

const Wrapper = styled.section`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90%;
  background: #150c27;
  padding-left: 5%;
  padding-right: 5%;
  padding-bottom: 1200px;
  padding-top: 350px;

  @media (max-width: 800px) {
    padding-bottom: 100px;
    padding-top: 100px;
  }
`;

export default Features;
