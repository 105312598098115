import { useState, useRef, useEffect, useContext } from 'react';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import { scroller, Link } from 'react-scroll';
import Header from 'components/Header';
import useThrottle from 'hooks/useThrottle';

import Selection from './Selection';
import bgTestNight from 'App/assets/images/landing/world/bgtestnight.png';

import vulpsPortrait from 'App/assets/images/landing/world/selectables/vulps.png';
import nomadsPortrait from 'App/assets/images/landing/world/selectables/nomads.png';
import elitesPortrait from 'App/assets/images/landing/world/selectables/elites.png';
import machariaPortrait from 'App/assets/images/landing/world/selectables/macharia.png';
import cryoPortrait from 'App/assets/images/landing/world/selectables/cryo.png';
import militiaPortrait from 'App/assets/images/landing/world/selectables/militia.png';
import weaponaryPortrait from 'App/assets/images/landing/world/selectables/weaponary.png';
import drPortrait from 'App/assets/images/landing/world/selectables/dr.png';
import pagodaPortrait from 'App/assets/images/landing/world/selectables/pagoda.png';

import selectables from './data';
import { LanguageContext } from 'containers/Language';
import { ArrowLeftSvg, ArrowRightSvg } from 'components/SVG/compendium';

const Compendium = () => {
  const { dictionary } = useContext(LanguageContext);
  const carouselRef = useRef();
  const [carousel, setCarousel] = useState({ choice: 0, totalWidth: 0, unitWidth: 0 });

  const throttledFn = () => {
    setCarousel(prev => {
      return { ...prev, totalWidth: carouselRef.current.clientWidth, unitWidth: carouselRef.current.children[0].clientWidth };
    });
  };

  useEffect(() => {
    setCarousel(prev => {
      return { ...prev, totalWidth: carouselRef.current.clientWidth, unitWidth: carouselRef.current.children[0].clientWidth };
    });
  }, []);

  const handleResize = useThrottle(throttledFn, 250, { leading: false, trailing: true });

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  const handleSelect = choice => {
    const options = {
      duration: 300,
      delay: 0,
      smooth: true,
      offset: 0,
    };

    const worldOptions = { ...options };
    isMobile && scroller.scrollTo('compendium-content', worldOptions);

    setCarousel(prev => {
      return { ...prev, choice };
    });
  };

  const handleArrow = choice => {
    setCarousel(prev => {
      return { ...prev, choice };
    });
  };

  return (
    <Wrapper name="world">
      <Container>
        {/* <NewHeaderWrapper>
          <NewHeader>World</NewHeader>
        </NewHeaderWrapper> */}
        <HeaderWrapper>
          <Header text={dictionary.world.header} />
        </HeaderWrapper>
        <Contents>
          <SelectionCarousel
            width={carousel.totalWidth}
            positionalWidth={carousel.choice === 0 ? 0 : carousel.unitWidth * carousel.choice}
            ref={carouselRef}
          >
            {dictionary.world.selectables.map((entry, idx) => (
              <Selection
                key={entry.name}
                subheader={dictionary.world.subheader}
                name={entry.name}
                foreground={selectables[idx].foreground}
                description={entry.description}
              />
            ))}
          </SelectionCarousel>
          <ArrowLeft
            isVisible={carousel.choice > 0}
            onClick={() => handleArrow(carousel.choice > 0 ? carousel.choice - 1 : carousel.choice)}
          >
            <ArrowLeftSvg />
          </ArrowLeft>
          <ArrowRight
            isVisible={carousel.choice < 8}
            onClick={() => handleArrow(carousel.choice < 8 ? carousel.choice + 1 : carousel.choice)}
          >
            <ArrowRightSvg />
          </ArrowRight>
        </Contents>
        <Legend>
          <Vulps isSelected={carousel.choice === 0} onClick={() => handleSelect(0)}>
            <SelectGraphicWrapper>
              <VulpsBg />
              <SelectImg src={vulpsPortrait} alt="" />
            </SelectGraphicWrapper>
            <VulpsName>{dictionary.world.selectables[0].nameShort}</VulpsName>
          </Vulps>
          <Nomads isSelected={carousel.choice === 1} onClick={() => handleSelect(1)}>
            <SelectGraphicWrapper>
              <NomadsBg />
              <SelectImg src={nomadsPortrait} alt="" />
            </SelectGraphicWrapper>
            <NomadsName>{dictionary.world.selectables[1].nameShort}</NomadsName>
          </Nomads>
          <Militas isSelected={carousel.choice === 2} onClick={() => handleSelect(2)}>
            <SelectGraphicWrapper>
              <MilitasBg />
              <SelectImg src={militiaPortrait} alt="" />
            </SelectGraphicWrapper>
            <MilitasName>{dictionary.world.selectables[2].nameShort}</MilitasName>
          </Militas>
          <Elites isSelected={carousel.choice === 3} onClick={() => handleSelect(3)}>
            <SelectGraphicWrapper>
              <ElitesBg />
              <SelectImg src={elitesPortrait} alt="" />
            </SelectGraphicWrapper>
            <ElitesName>{dictionary.world.selectables[3].nameShort}</ElitesName>
          </Elites>
          <Dr isSelected={carousel.choice === 4} onClick={() => handleSelect(4)}>
            <SelectGraphicWrapper>
              <DrBg />
              <SelectImg src={drPortrait} alt="" />
            </SelectGraphicWrapper>
            <DrName>{dictionary.world.selectables[4].nameShort}</DrName>
          </Dr>
          <Cryo isSelected={carousel.choice === 5} onClick={() => handleSelect(5)}>
            <SelectGraphicWrapper>
              <CryoBg />
              <SelectImg src={cryoPortrait} alt="" />
            </SelectGraphicWrapper>
            <CryoName>{dictionary.world.selectables[5].nameShort}</CryoName>
          </Cryo>
          <Weaponary isSelected={carousel.choice === 6} onClick={() => handleSelect(6)}>
            <SelectGraphicWrapper>
              <WeaponaryBg />
              <SelectImg src={weaponaryPortrait} alt="" />
            </SelectGraphicWrapper>
            <WeaponaryName>{dictionary.world.selectables[6].nameShort}</WeaponaryName>
          </Weaponary>
          <Macharia isSelected={carousel.choice === 7} onClick={() => handleSelect(7)}>
            <SelectGraphicWrapper>
              <MachariaBg />
              <SelectImg src={machariaPortrait} alt="" />
            </SelectGraphicWrapper>
            <MachariaName>{dictionary.world.selectables[7].nameShort}</MachariaName>
          </Macharia>
          <Pagoda isSelected={carousel.choice === 8} onClick={() => handleSelect(8)}>
            <SelectGraphicWrapper>
              <PagodaBg />
              <SelectImg src={pagodaPortrait} alt="" />
            </SelectGraphicWrapper>
            <PagodaName>{dictionary.world.selectables[8].nameShort}</PagodaName>
          </Pagoda>
        </Legend>
      </Container>
    </Wrapper>
  );
};

const NewHeader = styled.div`
  font-size: 140px;
  font-family: var(--title-font);
  /* text-shadow: -2px 2px 0 #e3cd80, -2px -2px 0 #e3cd80, 2px -2px 0 #e3cd80, 6px 4px 0 var(--border);
  color: #000; */
  /* text-shadow: -4px 4px 0 #000, -4px -4px 0 #000, 4px -4px 0 #000, 4px 4px 0 #000; */
  /* color: var(--border); */
  color: #fff;
`;

const NewHeaderWrapper = styled.div`
  position: absolute;
  top: -130px;
  left: 300px;
  transform: rotate(9deg) perspective(100px) rotate3d(15, -15, 5, -2deg);
`;

const CompendiumImg = styled.img``;

const HeaderWrapper = styled.div`
  position: absolute;
  top: 200px;
  width: 300px;
  transform: rotate3d(4, 0, 8, -7deg);
  right: 14%;

  @media (max-width: 800px) {
    top: 100px;
    width: 50%;
  }
`;

const SelectionCarousel = styled.div`
  display: flex;
  transform: ${props => `translate3d(-${props.positionalWidth}px, 0, 0);`};
  transition: ${isMobile ? '0.5s' : '0.25s'} ease-in-out;
  /* column-gap: 600px; */

  @media (max-width: 800px) {
    position: relative;
    column-gap: 0;
  }
`;

const WrapTest = styled.div`
  ${props => (props.isVisible ? 'display: flex;' : 'display: none;')}
`;

const EmptyState = styled.img`
  ${props => (props.isVisible ? 'display: flex;' : 'display: none;')}
  position: relative;
  left: 50%;
  width: 80%;
  transform: translateX(-50%);

  @media (max-width: 800px) {
    width: 120%;
  }
`;

const ArrowWrapper = styled.div`
  position: absolute;
  width: clamp(30px, 8vw, 80px);
  top: 50%;
  padding: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  ${props => !props.isVisible && 'display: none;'}

  &:hover {
    transform: translateY(-50%) scale(1.2);
    filter: brightness(1.05);
  }

  &:active {
    transform: translateY(calc(-50% + 1px)) scale(1.15);
    filter: brightness(0.85);
  }

  @media (max-width: 800px) {
    bottom: calc(50% + 50px);
  }
`;

const ArrowLeft = styled(ArrowWrapper)`
  left: -6.5%;
`;

const ArrowRight = styled(ArrowWrapper)`
  right: -6.5%;
`;

const Selectable = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  perspective: 100;
  /* min-width: clamp(220px, 30vw, 240px); */
  max-width: 190px;
  width: 100%;
  cursor: pointer;

  ${props => props.isSelected && 'z-index: 10;'}

  div {
    div:first-child {
      ${props => props.isSelected && 'border: 2px solid #fff;'}
    }
  }

  div:last-child {
    ${props => props.isSelected && 'transform: scale(1.3);border: 2px solid #fff;'}
  }

  @media (min-width: 801px) {
    img {
      transform: ${props => (props.isSelected ? 'scale(1.3) translateY(-20px);' : 'scale(1.3) translateY(-10px);')};
    }
  }

  &:hover {
    @media (min-width: 801px) {
      img {
        transform: scale(1.3) translateY(-20px);
      }
    }

    /* filter: brightness(1.3); */
    transform: scale(1.2);
    z-index: 15;

    div:last-child {
      @media (min-width: 801px) {
        /* padding: 12px 13px; */
      }
      transform: scale(1.3);
    }
  }

  &:active {
    filter: brightness(0.95);

    div {
      div:first-child {
      }
    }

    div:last-child {
      ${props => !props.isSelected && 'transform: scale(1.3);'}
    }
    transform: scale(1.2) translateY(1.5px);
  }

  @media (max-width: 800px) {
    width: 28vw;
  }
`;

const Vulps = styled(Selectable)`
  top: -5px;
  transform: ${props => (props.isSelected ? 'scale(1.2)' : 'scale(1) rotate(5deg)')};
`;

const Nomads = styled(Selectable)`
  top: -1px;
  right: 7px;
  transform: ${props => (props.isSelected ? 'scale(1.2)' : 'scale(1) rotate(-4deg)')};
`;

const Militas = styled(Selectable)`
  top: 5px;
  right: 4px;
  transform: ${props => (props.isSelected ? 'scale(1.2)' : 'scale(1) rotate(5deg)')};
`;

const Elites = styled(Selectable)`
  top: 5px;
  right: 4px;
  transform: ${props => (props.isSelected ? 'scale(1.2)' : 'scale(1) rotate(2deg)')};
`;

const Cryo = styled(Selectable)`
  top: 1px;
  right: 8px;
  transform: ${props => (props.isSelected ? 'scale(1.2)' : 'scale(1) rotate(3deg)')};
`;

const Weaponary = styled(Selectable)`
  top: 6px;
  right: 5px;
  transform: ${props => (props.isSelected ? 'scale(1.2)' : 'scale(1) rotate(6deg)')};
`;

const Macharia = styled(Selectable)`
  top: 2px;
  right: 14px;
  transform: ${props => (props.isSelected ? 'scale(1.2)' : 'scale(1) rotate(-5deg)')};
`;

const Pagoda = styled(Selectable)`
  top: -3px;
  left: 4px;
  transform: ${props => (props.isSelected ? 'scale(1.2)' : 'scale(1) rotate(4deg)')};
`;

const Dr = styled(Selectable)`
  top: 1px;
  right: 2px;
  transform: ${props => (props.isSelected ? 'scale(1.2)' : 'scale(1) rotate(-2deg)')};
`;

const SelectBg = styled.div`
  border: 2px solid var(--border);
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 60%;
  height: 90%;
  box-shadow: 6px 4px 0 var(--world-shadow), 30px -15px 30px rgba(0, 0, 0, 0.1), -30px -15px 30px rgba(0, 0, 0, 0.1);
  background: conic-gradient(
    var(--fan-2) 0deg 8deg,
    var(--fan-1) 8deg 16deg,
    var(--fan-2) 16deg 24deg,
    var(--fan-1) 24deg 32deg,
    var(--fan-2) 32deg 40deg,
    var(--fan-1) 40deg 48deg,
    var(--fan-2) 280deg 288deg,
    var(--fan-1) 288deg 296deg,
    var(--fan-2) 296deg 304deg,
    var(--fan-1) 304deg 312deg,
    var(--fan-2) 312deg 320deg,
    var(--fan-1) 320deg 328deg,
    var(--fan-2) 328deg 336deg,
    var(--fan-1) 336deg 344deg,
    var(--fan-2) 344deg 352deg,
    var(--fan-1) 352deg 360deg
  );
  background-position-x: -20px;
  background-position-y: 5px;
  background-size: 160% 160%;

  @media (max-width: 800px) {
    width: 55%;
    height: 80%;
  }
`;

const VulpsBg = styled(SelectBg)`
  transform: translateX(-50%) rotate3d(35, 66, 25, -5deg);
`;

const NomadsBg = styled(SelectBg)`
  transform: translateX(-50%) rotate3d(25, 46, 0, -1deg);
`;

const MilitasBg = styled(SelectBg)`
  transform: translateX(-50%) rotate3d(85, 46, 250, -6deg);
`;

const ElitesBg = styled(SelectBg)`
  transform: translateX(-50%) rotate3d(35, 66, 0, -5deg);
`;

const CryoBg = styled(SelectBg)`
  transform: translateX(-50%) rotate3d(25, 66, 0, -4deg);
`;

const WeaponaryBg = styled(SelectBg)`
  transform: translateX(-50%) rotate3d(35, 66, 0, -6deg);
`;

const MachariaBg = styled(SelectBg)`
  transform: translateX(-50%) rotate3d(25, 66, 15, 4deg);
`;

const PagodaBg = styled(SelectBg)`
  transform: translateX(-50%) rotate3d(35, 66, 0, -7deg);
`;

const DrBg = styled(SelectBg)`
  transform: translateX(-50%) rotate3d(35, 66, 0, 5deg);
`;

const Name = styled.div`
  position: absolute;
  bottom: clamp(-30px, -3vw, -10px);
  background: #000;
  border: 2px solid var(--border);
  font-family: var(--title-font);
  font-size: 2.25rem;
  padding: 14px 18px;
  width: fit-content;
  z-index: 10;
  box-shadow: 7px 5px 0 var(--world-shadow);

  @media (max-width: 800px) {
    font-size: 1rem;
    padding: 8px;
  }
`;

const VulpsName = styled(Name)`
  transform: translateZ(4px) rotate3d(35, 66, 0, 5deg);
`;

const NomadsName = styled(Name)`
  transform: translateZ(4px) rotate3d(-25, 26, 0, -1deg);
`;

const MilitasName = styled(Name)`
  transform: translateZ(4px) rotate3d(25, 26, 0, -2deg);
`;

const ElitesName = styled(Name)`
  transform: translateZ(4px) rotate3d(35, 66, 0, 5deg);
`;

const CryoName = styled(Name)`
  transform: translateZ(4px) rotate3d(5, 2, 0, -6deg);
`;

const WeaponaryName = styled(Name)`
  transform: translateZ(4px) rotate3d(-25, 26, 0, -1deg);
`;

const MachariaName = styled(Name)`
  transform: translateZ(4px) rotate3d(25, 26, 0, -2deg);
`;

const PagodaName = styled(Name)`
  transform: translateZ(4px) rotate3d(-25, 26, 0, -1deg);
`;

const DrName = styled(Name)`
  transform: translateZ(4px) rotate3d(15, 66, 0, 2deg);
`;

const SubTextDesktop = styled.div`
  position: relative;
  font-family: var(--title-font);
  font-size: clamp(1.5rem, 3vw, 2.75rem);
  transform: translateZ(2px);

  @media (max-width: 800px) {
    display: none;
  }
`;

const SubTextMobile = styled.div`
  position: relative;
  font-family: var(--title-font);
  font-size: clamp(1.75rem, 3vw, 2.75rem);
  transform: translateZ(2px);

  @media (min-width: 801px) {
    display: none;
  }
`;

const SubBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  border: 3px solid var(--border);
  transform: rotate3d(25, 15, -35, 3deg);
  box-shadow: 4px 4px 0px var(--border);
`;

const SelectGraphicWrapper = styled.div`
  position: relative;
  perspective: 100;
  width: 80%;
  height: clamp(120px, 30vw, 150px);

  @media (max-width: 800px) {
  }
`;

const SelectImg = styled.img`
  position: absolute;
  left: 0;
  /* bottom: -6%; */
  width: 100%;
  transform: translateZ(10px);

  @media (max-width: 800px) {
    bottom: 8%;
  }
`;

const Legend = styled.div`
  position: relative;
  display: flex;
  top: 100px;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 1100px;
  row-gap: 2vw;
  width: 100%;

  @media (max-width: 800px) {
    row-gap: 2vw;
    top: 0;
    max-width: auto;
    justify-content: center;
  }
`;

const ForegroundImg = styled.img`
  position: relative;
  width: 100%;
`;

const BackgroundImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Graphics = styled.div`
  position: absolute;
  right: -5vw;
  bottom: -12vw;
  width: clamp(800px, 70vw, 1200px);

  @media (max-width: 800px) {
    width: 550px;
    top: -270px;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const Text = styled.div`
  background: #000;
  font-family: var(--primary-text-font);
  font-size: clamp(0.8rem, 2vw, 1.25rem);
  padding: 20px;
  background: #000;
  border: 3px solid var(--border);
  box-shadow: 6px 8px 0px var(--border);
  line-height: clamp(1.4rem, 2vw, 2.1rem);
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  width: clamp(200px, 40vw, 500px);

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const Subheader = styled.div`
  position: relative;
  top: 0px;
  left: -10px;
  padding: 22px 28px 8px 28px;
  width: fit-content;
  perspective: 100;

  @media (max-width: 800px) {
    top: 5px;
  }
`;

const Title = styled.div`
  background: #000;
  border: 3px solid var(--border);
  font-family: var(--title-font);
  font-size: clamp(2rem, 3vw, 4.75rem);
  padding: 25px 40px;
  width: fit-content;
  transform: rotate3d(-45, 125, 22, 3deg) rotate(-3deg);

  @media (max-width: 800px) {
    display: none;
    /* position: absolute;
    left: 0;
    top: -100px; */
  }
`;

const Explainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  perspective: 100;
  gap: 30px;
`;

const Contents = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const Container = styled.div`
  position: relative;
  max-width: 1450px;
  width: 90%;
  margin: 0 5%;
  display: flex;
  z-index: 5;
  align-items: center;
  padding-top: 380px;
  flex-direction: column;
  row-gap: calc(1vw + 2vh);

  @media (max-width: 800px) {
    padding-top: 0;
  }
`;

const Wrapper = styled.div`
  background: url(${bgTestNight}), linear-gradient(transparent 100%, #140d26 0%);
  background-size: 3400px;
  background-repeat: no-repeat;
  background-position-y: 0;
  background-position-x: center;
  position: relative;

  display: flex;
  justify-content: center;
  padding-top: 0;
  padding-bottom: 350px;
  width: 100%;
  overflow-x: hidden;

  /* @media (max-width: 1200px) and (orientation: portrait) {
    padding-top: 450px;
  } */

  /* @media (max-width: 1200px) and (orientation: landscape) {
    padding-top: 500px;
  } */

  @media (max-width: 800px) {
    background-size: 2000px;
    padding-top: 50px;
    background-position-x: 80%;
    background-position-y: 100px;
    background-repeat: no-repeat;
  }
`;

export default Compendium;
