import { useState, lazy } from 'react';
import styled, { keyframes } from 'styled-components';
import { CURRENCY } from './const';
import Modal from './Modal';
import {
  ChamberFullSvg,
  ButtonsSvg,
  GroundSvg,
  Pillar1BottomSvg,
  Pillar1TopSvg,
  Pillar2Svg,
  Pillar3BottomSvg,
  Pillar3TopSvg,
  Pillar4BottomSvg,
  Pillar4TopSvg,
  Pillar5BottomSvg,
  Pillar5TopSvg,
  RibbonBackgroundPillar2to3Svg,
  RibbonForegroundPillar2to3Svg,
  RibbonForegroundPillars3to4Svg,
  ParticlesSvg,
} from 'components/SVG/mint';

import { Provider, atom, useAtom } from 'jotai'
const STEP = {
  'Reset': 0,
  'Connect': 1,
  'Amount': 2,
  'Currency': 3,
  'Finalize': 4,
}

const mintState = atom({ step: STEP.Reset, mintAmount: 1, amountAuthorized: false, currency: CURRENCY.BNB, receipt: null, mintedNfts: [], selectedWallet: null })

// const Modal = lazy(() => import('./Modal'));


const WALLET = {
  'MetaMask': false,
  'BinanceWallet': false,
}

const Mint = () => {
  const [openModal, setOpenModal] = useState(false);
  const [mint, setMint] = useAtom(mintState);

  const handleButton = () => {
    if (mint.step === STEP.Reset) {
      setMint(prev => {
        return { ...prev, step: 1 };
      });
      setOpenModal(true);
    }

  };

  const handleReset = () => {
    setMint({ step: STEP.Reset, mintAmount: 1, currency: CURRENCY.WFIRO, txReceipt: null });
  };

  return (
    <Provider>
      <Wrapper>
        <Container>
          <PillarWrapper>
            <SecondaryLeftPillarWrapper>
              <Pillar1TopSvg />
              <Pillar1BottomSvg />
              <Pillar1Glow />
            </SecondaryLeftPillarWrapper>
            <PrimaryPillarWrapper>
              <RibbonBackgroundPillar2to3Svg />
              <Pillar2Svg />
              <Pillar2Glow />
              <BlueGlow2 />
              <Pillar3TopSvg />
              <Pillar3BottomSvg />
              <RibbonForegroundPillar2to3Svg />
              <RibbonForegroundPillars3to4Svg />
              <Pillar4TopSvg />
              <Pillar4BottomSvg />
            </PrimaryPillarWrapper>
            <SecondaryRightPillarWrapper>
              <Pillar5BottomSvg />
              <Pillar5TopSvg />
              <Pillar3Glow />
              <Pillar4Glow />
            </SecondaryRightPillarWrapper>
            <ParticlesWrapper>
              <ParticlesSvg />
            </ParticlesWrapper>
            <ParticlesWrapper2>
              <ParticlesSvg />
            </ParticlesWrapper2>
          </PillarWrapper>
          <BlueGlow1 />
        </Container>
        <Foreground isVisible={mint.step != 6} >
          <ChamberWrapper name="mint">
            <BackgroundGlow isActive={mint.step > STEP.Finalize} />
            <ChamberFullSvg isActive={mint.step > STEP.Reset} step={mint.step} />
            <ButtonsWrapper disabled={mint.step > STEP.Reset} onClick={handleButton}>
              <ButtonsSvg isActive={mint.step > STEP.Reset} />
              <MintText isActive={mint.step > STEP.Reset}>Mint</MintText>
            </ButtonsWrapper>
          </ChamberWrapper>
          <GroundWrapper>
            <GroundSvg />
            <Ground1Glow />
            <Ground2Glow />
          </GroundWrapper>
        </Foreground>
        {mint.step > 0 && <Modal mint={mint} setMint={setMint} handleReset={handleReset} />}
      </Wrapper>
    </Provider>
  );
};

const ResetBtn = styled.div`
  position: absolute;
  bottom: 500px;
  left: 0;
  width: 35px;
  height: 35px;
  background: green;
  cursor: pointer;
  z-index: 10000;
`;

const GroundWrapper = styled.div`
  position: relative;
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;

  @media (max-width: 1650px) {
    width: 150%;
  }

  @media (max-width: 1280px) {
    width: 200%;
  }

  @media (max-width: 768px) {
    width: 400%;
    left: -165%;
  }
`;

const Foreground = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  transition: 2.3s 0.105s all cubic-bezier(0.5, 0.6, 0.5, 1);
  transform: ${props => (props.isActive ? 'translate(-220px, 9px) scale(1.2)' : 'translate(0, 0) scale(1)')};
  ${props => props.isVisible ? 'display: flex;' : 'display: none;'}


  @media (max-width: 1025px) {
    transition: 1.45s 0.105s all ease-in-out;

    transform: ${props => (props.isActive ? 'translate(-160px, 9px) scale(1)' : 'translate(0, 0) scale(1)')};
  }
`;

const ParticlesWrapper2 = styled.div`
  position: absolute;
  bottom: 120px;
  right: 10%;
`;
const ParticlesWrapper = styled.div`
  position: absolute;
  bottom: 160px;
  left: 0;
`;

const animGlowChamberInitial = keyframes`
  0%{ opacity: 0.0;}
  100%{ opacity: 0.2;}
`;

const animGlowChamber = keyframes`
  0%{ opacity: 0.3;}
  2%{ opacity: 0.5;}
  3%{ opacity: 0.3;}

  22%{ opacity: 0.3;}
  24%{ opacity: 0.5;}
  25%{ opacity: 0.3;}

  64%{ opacity: 0.3;}
  68%{ opacity: 0.4;}
  69%{ opacity: 0.3;}
  100%{ opacity: 0.3;}
`;

const animGlowChamberInitial2 = keyframes`
  0%{ opacity: 0.0;}
  100%{ opacity: 0.4;}
`;

const animGlowChamber2 = keyframes`
  0% { opacity: 0.4;}
  50% { opacity: 0.8;}
  100% { opacity: 0.4;}

`;

const BackgroundGlow = styled.div`
  position: absolute;
  z-index: 0;
  top: calc(50%);
  left: calc(50%);
  transform: translate(-50%, -50%);
  width: 250px;
  z-index: 0;
  opacity: 0;
  height: 420px;
  border-radius: 40%;
  box-shadow: inset 0 0 90px #f0f, inset 20px 0 80px #f0f, inset -20px 0 80px #0ff, inset 20px 0 300px #f0f, inset -20px 0 300px #0ff,
    0 0 90px #f0f, -10px 0 80px #f0f, 10px 0 80px #0ff;
  transition: 1s all;
  animation: ${props => props.isActive && animGlowChamberInitial2} 1.5s ease-in-out forwards,
    ${props => props.isActive && animGlowChamber2} 2s 1.5s ease-in-out infinite;
`;

const MintText = styled.div`
  ${props => (props.isActive ? 'transform: translateY(5px); cursor: default;' : ' cursor: pointer;')}
  cursor: pointer;
  position: absolute;
  font-family: var(--title-font);
  font-size: 2.5rem;
  top: 50%;

  left: 50%;
  transform: translate(-50%, -50%);
  ${props => (props.isActive ? 'transform: translate(-50%, -42%); cursor: default; color: #d1d1d1;' : ' cursor: pointer;')}
`;

const ButtonsWrapper = styled.div`
  position: absolute;
  left: 51%;
  user-select: none;
  transform: translateX(-50%);
  bottom: 80px;
  z-index: 150;
  width: 260px;

  pointer-events: ${props => props.disabled ? 'none' : 'auto'};

  @media (min-width: 481px) {
    &:hover {
      filter: brightness(1.35);
    }

    &:active {
      filter: brightness(0.9);
    }
  }

  @media (max-width: 480px) {
    bottom: 55px;
    width: 160px;
  }
`;

const HeaderWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`;

// const GroundWrapper = styled.div`
//   position: relative;
//   z-index: 20;
//   position: absolute;
//   bottom: 100px;
//   left: 50%;
//   transform: translateX(-50%);
// `;

const animChamberInterior = keyframes`
  0% { background-position: 50% 45%; }
  20% { background-position: 50% 55%; }
  80% { background-position: 50% 45%; }
  100% { background-position: 50% 45%; }
`;

const ChamberInterior = styled.div`
  position: absolute;
  bottom: 100px;
  transform: perspective(100px) rotateX(1.25deg) scale(1);
  width: 295px;
  height: 475px;
  background: linear-gradient(#00bdf2 30%, #c0198c 70%);
  background-size: 100% 220%;
  background-position: 50% 25%;
  transition: 1s background-position ease-in;
  animation: ${animChamberInterior} 6s ease-in-out infinite;

  @media (max-width: 1024px) {
    width: 202px;
    bottom: 80px;
    height: 320px;
  }
`;

const ChamberBody = styled.img`
  position: relative;
  width: 530px;
  transform: perspective(100px) translateZ(4px) scale(1);
  @media (max-width: 1024px) {
    width: 365px;
    height: auto;
  }
  /* bottom: 100px; */
`;

const ChamberWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 50;
  left: 50%;
  /* bottom: 100px; */
  bottom: 25%;
  transform: translateX(-50%);
  /* bottom: 100px; */
`;

const SecondaryPillarWrapper = styled.div`
  position: absolute;
  width: 100%;
  @media (max-width: 1650px) {
    display: none;
  }
`;

const SecondaryLeftPillarWrapper = styled(SecondaryPillarWrapper)`
  left: 0;
`;
const SecondaryRightPillarWrapper = styled(SecondaryPillarWrapper)`
  right: 0;
`;

const PrimaryPillarWrapper = styled.div`
  position: relative;
  width: 1850px;
`;

const BlueGlow = styled.div`
  position: absolute;
  opacity: 0.15;

  background: radial-gradient(#94fafe 0%, transparent 70%);
`;

const BlueGlow1 = styled(BlueGlow)`
  width: 800px;
  height: 800px;
  left: 0;
  bottom: -400px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const BlueGlow2 = styled(BlueGlow)`
  width: 800px;
  height: 800px;
  right: 0;
  bottom: -400px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const animGlow = keyframes`
  0% {opacity: 0.15; }
  50% {opacity: 0.25; }
  100% {opacity: 0.15; }
`;

const GreenGlow = styled.div`
  opacity: 0.3;
  position: absolute;
  animation: ${animGlow} 3s ease-in-out infinite;
  background: radial-gradient(#00aa4c 0%, transparent 70%);

  @media (max-width: 768px) {
    display: none;
  }
`;

const Ground1Glow = styled(GreenGlow)`
  bottom: 1vw;
  left: 15vw;
  transform: translateX(-50%);
  width: 300px;
  height: 250px;
  opacity: 0.15;
  animation: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const Ground2Glow = styled(GreenGlow)`
  bottom: 3vw;
  left: 32vw;
  transform: translateX(-50%);
  width: 250px;
  height: 200px;
  opacity: 0.15;
  animation: 0;
`;

const Pillar1Glow = styled(GreenGlow)`
  position: absolute;
  bottom: 340px;
  left: -80px;
  width: 450px;
  height: 400px;
`;

const Pillar2Glow = styled(GreenGlow)`
  bottom: 480px;
  left: 290px;
  width: 550px;
  height: 400px;

  @media (max-width: 1650px) {
    display: none;
  }
`;

const Pillar3Glow = styled(GreenGlow)`
  bottom: 520px;
  left: 54%;
  transform: translateX(-50%);
  width: 550px;
  height: 500px;
`;

const Pillar4Glow = styled(GreenGlow)`
  bottom: 375px;
  right: -370px;
  transform: translateX(-50%);
  width: 450px;
  height: 400px;

  @media (max-width: 1650px) {
    display: none;
  }
`;

const PillarWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  transition: 2.335s 0.08s all ease-in-out;
  transform: ${props => (props.isActive ? 'translate(-150px, 30px) scale(1.1)' : 'translate(0, 0) scale(1)')};

  @media (max-width: 1025px) {
    transition: 1.5s 0.08s all ease-in-out;

    transform: ${props => (props.isActive ? 'translate(-100px, 30px) scale(1)' : 'translate(0, 0) scale(1)')};
  }
`;

const Container = styled.div`
  position: relative;
  /* padding-top: 400px; */
  z-index: 2;

  @media (max-width: 1200px) {
    padding-top: 140px;
  }

  @media (max-width: 768px) {
    padding-top: 600px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background: linear-gradient(179deg, #150c27 5%, #4b153cad 30%, #a71c44 100%);
`;

export default Mint;
