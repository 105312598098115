import styled from "styled-components";
import { useAtom } from "jotai";
import { useEffect, useRef } from "react";
import { playerEnergyAtom } from "Project/Game/data/atoms";

const Energy = () => {
  const [energy, setEnergy] = useAtom(playerEnergyAtom);

  const populateEnergy = () => {
    const eneg = [];

    for (let i = 0; i < energy.total; i++) {
      if (i < energy.available) {
        eneg.push(true);
      } else
        eneg.push(false);
    }

    return eneg;
  }

  const energyArr = populateEnergy();

  return <Wrapper>
    <Container>
      <EnergyBox active={energyArr[0]} key={1}>
        <Text>{energy.available}</Text>
        <EnergySvg isVisible={energyArr[0]} viewBox="0 0 26 26">
          <path d="M9.8 0 0 13h9.8L3.2 26 26 9.7H13L22.8 0Z" />
        </EnergySvg>
      </EnergyBox>
    </Container>
  </Wrapper>
}

const Text = styled.div`
  color: #fff;
  font-family: var(--primary-text-font);
`

const EnergySvg = styled.svg`
  ${props => props.isVisible ? 'opacity: 1;' : 'opacity: 0;'}

  /* fill: #ffe5b5; */
  fill: pink;
  width: 16px;
  filter: drop-shadow(0 0 0.75rem rgba(255, 120, 154, 0.8));
`

const EnergyBox = styled.div`
  position: relative;
  column-gap: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  padding: 10px;
  height: 25px;
  background: #fff;
  ${props => props.active ? 'background: #d45f55;' : 'background: #bc574d;'}
  transition: 1s cubic-bezier(0.075, 0.82, 0.45, 1);
`

const Container = styled.div`
  box-shadow: 0 0 2rem rgba(0, 0, 0, 0.2);
  /* div:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
  }

  div:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  } */
  display: flex;
  /* justify-content: space-between; */
`

const Wrapper = styled.div``

export default Energy;