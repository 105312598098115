import styled from "styled-components";
import List from "./List";
import QuestPreview from "./QuestPreview";

const QuestLog = ({ isActive, selectedQuestId, handleSelect }) => {
  console.log('selected quest id', selectedQuestId);
  return <Wrapper>
    <Container>
      <Background />
      <WrapList>
        <List selectedQuestId={selectedQuestId} />
      </WrapList>
      <WrapPreview>
        <QuestPreview selectedQuestId={selectedQuestId} />
      </WrapPreview>
    </Container>
  </Wrapper>
}

const Background = styled.div`
  background: #F0DFB6;
  top: 0px;
  left: -30px;
  width: 102%;
  position: absolute;
  height: 102%;
  transform: perspective(100px) rotate3d(-2, 1, -8, -0.65deg) scale(1);
`

const WrapPreview = styled.div`
  position: relative;
  top: 70px;
`

const WrapList = styled.div`
  position: relative;
  left: -50px;
  top: 50px;
`

const Container = styled.div`
  position: relative;
  height: 630px;
  display: flex;
  padding:5px;
  column-gap: 15px;
  justify-content: center;
  width: 100%;
`

const Wrapper = styled.div`
  width: 1100px;
  position: relative;
  left: -20px;
  z-index: 15;
  /* flex-wrap: wrap; */
`


export default QuestLog;