
import { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import { SERVER_URL, SERVER_COLLECTION } from "const/general";
import backgrounds from 'Project/Collection/backgrounds.json'
import foxSkullsPattern from 'App/assets/images/collection/preview/fox-skulls.png';
import bunniesPattern from 'App/assets/images/collection/preview/bunnies.png';
import WebFont from "webfontloader";

import { TopRibbonSvg, BottomRibbonSvg } from "components/SVG/ribbon";

const Preview = ({ previewFox, handleClose }) => {
  const [fontLoaded, setFontLoaded] = useState(false);
  useEffect(() => {
    WebFont.load({
      custom: {
        families: ['Anime Ace'],
        urls: ['App/fontStyles.css']
      },
      active: function () { if (fontLoaded === false) setFontLoaded(true) },
      classes: false,
      timeout: 8000
    });
  }, []);

  return <>
    <Backdrop onClick={handleClose} />
    {fontLoaded &&
      <Wrapper onClick={handleClose}>
        <TopRibbonSvg />
        <BottomRibbonSvg />
        <Container>
          <Background isBunniesPattern={previewFox.attributes[1].value === 'Bunnies'} hasBackground={previewFox.attributes[1].trait_type === 'Pattern'}>
            <List onClick={e => e.stopPropagation()}>
              {/* {previewFox.attributes.map(attribute => <Item>{attribute.trait_type.toUpperCase()} - {attribute.value} <DarkenText>({findRarity(attribute.trait_type, attribute.value)}%)</DarkenText></Item>)} */}
              {previewFox.attributes.map(attribute => <Label key={attribute.trait_type}>
                {/* <Value>{attribute.value.toUpperCase()} </Value> */}
                <Value minWidth={attribute.trait_type === 'Background' || attribute.trait_type === 'Clothing'} >{attribute.trait_type === 'Background' && <ColorBox backgroundColor={backgrounds[attribute.value]} />} {attribute.value.toUpperCase()} </Value>
                <Type>{attribute.trait_type.toUpperCase()}</Type>
              </Label>
              )}
            </List>
          </Background>
          <Portrait width="650" height="650" src={`${SERVER_COLLECTION}/${previewFox.id}/image/portrait`} alt="" />
          <Title>Firo Fox #{previewFox.id}</Title>
          {/* AVERAGE RARITY <DarkenTextFinal>{previewFox.rarityScore.toFixed(2)}%</DarkenTextFinal> */}
        </Container>
      </Wrapper>
    }
  </>
}

const ColorBox = styled.div`
 width: 19px;
 height: 19px;
 background: ${props => props.backgroundColor};
`

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 10;
  height: 100vh;
  background: rgba(20,25,36,0.7);
`

const Value = styled.div`
  display: flex;
  column-gap: 8px;
  font-size: 1.2rem;
  text-align: center;
  justify-content: center;
  align-items: center;
  ${props => props.minWidth && 'min-width: 6ch;'}

  @media (max-width: 1250px) {
    font-size: 1.15rem;
  }
`
const Type = styled.div`
  position: absolute;
  top: -11px;
  color: #aaa;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  z-index: 20;
  font-weight: 500;


  &:before {
    content: '';
    position: absolute;
    top: 0;
    z-index: -1;
    left: -10%;
    width: 120%;
    height: 100%;
    background-color: #141924;
  }
`

const Label = styled.li`
  position: relative;
  display: flex;
  padding: 12px 13px;
  justify-content: center;
  row-gap: 4px;
  font-family: var(--primary-text-font);
  width: fit-content;
  flex-direction: column;
  color: #fff;
  /* background: rgba(255, 255, 255, 0.5); */
  border: 3px solid #2f333e;

  @media (max-width: 1250px) {
    padding: 14px 12px;
    scroll-snap-align: center;
  }
`

const List = styled.ul`
  position: absolute;
  flex-wrap: wrap;
  right: 20px;
  line-height: 1.4rem;
  top: 110px;
  color: black;
  align-items: center;
  justify-content: center;
  width: 540px;
  padding: 15px;
  overflow-x: hidden;
  display: flex;
  gap: 15px;

  @media (max-width: 1250px) {
    top: auto;
    right: 12px;
    left: 0;
    right: auto;
    width: 86%;
    margin: 0 10px;
    flex-wrap: nowrap;
    justify-content: flex-start;
    bottom: 0px;

    -webkit-overflow-scrolling: touch;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    overflow-x: scroll;
    scrollbar-width: none;

    -webkit-scrollbar {
      display: none;
    }
    ::-webkit-scrollbar {
      display: none;
    }
  }
`

const Portrait = styled.img`
  position: absolute;
  bottom: 13px;
  left: -4px;
  width: 650px;
  z-index: 10;
  height: 650px;

  @media (max-width: 1250px) {
    bottom: auto;
    width: 450px;
    height: 450px;
    top: -60px;
    left: 50%;
    transform: translateZ(5px) translateX(-50%);
  }

  @media (max-width: 768px) {
    bottom: auto;
    width: 390px;
    height: 390px;
    top: -60px;
    left: 50%;
    transform: translateZ(5px) translateX(-53%);
  }
`

const Title = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  top: 35px;
  width: 500px;
  right: 40px;
  font-family: var(--primary-text-font);
  color: #fff;
  rotate: -0.5deg;
  font-size: 3rem;

  @media (max-width: 1250px) {
    top: auto;
    bottom: 130px;
    font-size: 2rem;
    width: max-content;
    left: 50%;
    transform: translateZ(4px) translateX(-50%);
  }
`

const Container = styled.div`
  position: relative;
  padding: 25px;
  width: 1100px;
  height: 530px;

  @media (max-width: 1250px) {
    width: 500px;
  }

  @media (max-width: 768px) {
    height: 460px;
    width: 80vw;
  }
`

const Background = styled.div`
  top: -16px;
  left: 0;
  position: absolute;
  padding: 25px;
  width: 1100px;
  height: 530px;
  background: #141924; 
  ${props => props.hasBackground && `background-image: url(${props.isBunniesPattern ? bunniesPattern : foxSkullsPattern});`}
  background-repeat: repeat;
  border: 12px solid #f9eabc;
  transform: perspective(100px) rotate3d(-25, 85, 244, -1deg) skew(-1deg, 0.5deg) ;
  box-shadow: 10px 20px 0 #141924;

  @media (max-width: 1250px) {
    width: 500px;
  }

  @media (max-width: 768px) {
    height: 460px;
    width: 75%;
  }
`
const animLoad = keyframes`
  0% { transform: translate(-54%, -48.5%) translateZ(1px); opacity: 0.4; }
  100% { transform: translate(-54%, -50%) translateZ(1px); opacity: 1; }
`
const Wrapper = styled.div`
  position: fixed;
  left: 55%;
  top: 50%;
  transform: translate(-55%, -50%);
  animation: ${animLoad} 0.45s cubic-bezier(0.1, 1, 0.6, 1) forwards;
  z-index: 20;
`

export default Preview;