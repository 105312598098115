import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

const Card = ({ id, image, handlePreview, fox }) => {
  return (
    <Wrapper onClick={() => handlePreview(fox)}>
      <Container>
        <Picture>
          <Image width="250" height="250" src={image} alt="" />
        </Picture>
      </Container>
      <Caption>
        <Title>FIRO FOX</Title>
        <Number>NO. {id}</Number>
      </Caption>
    </Wrapper>
  );
};

const Caption = styled.div`
  display: flex;
  flex-direction: column;
  color: #fff;
  align-items: center;
  row-gap: 5px;
`;

const Number = styled.div`
  font-size: 0.8rem;
  letter-spacing: 1px;
`;

const Title = styled.div`
  font-size: 0.8rem;
  color: #887685;
`;

const Picture = styled.picture`

`;

const Image = styled.img`
  object-fit: cover;
  border-radius: 12px;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  aspect-ratio: 3/3.5;
  border-radius: 12px;
  box-shadow: 7px 9px 0px rgba(0,0,0,0.4);
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  cursor: pointer;
  transition: 0.15s cubic-bezier(0.4, 1, 0.2, 1);


  @media (min-width: 1251px) {
    &:active {
      scale: 1.05;
      filter: brightness(0.9);
    }

    &:hover {
      scale: 1.05;
    }
  }
`;

export default Card;
