import Svg from "components/SVG"
import styled, { keyframes } from "styled-components"
import miniProfileImg from 'App/assets/3d/ui/miniplayer-profile.png'
import Avatar from "../../MainHUD/Avatar"
import MenuButton from "../../MainHUD/MenuButton"
import ProgressBar from "../../components/ProgressBar"
import { useAtom } from "jotai"
import { playerClassAtom } from "Project/Game/data/atoms"
import meleeBlackOutline from 'App/assets/3d/ui/icons/class/melee-black-outline.png';


const PlayerMini = ({ handleClick, handleMouseOver }) => {
  const [playerClass] = useAtom(playerClassAtom)

  const getClassType = (playerClass) => {
    switch (playerClass) {
      case 0:
        return 'Street Samurai'
      case 1:
        return 'Netrunner'
      case 2:
        return 'Netjacker'
      case 3:
        return 'Fixer'
      case 4:
        return 'Ironhide'
    }
  }

  const classType = getClassType(playerClass);
  return <Wrapper onClick={handleClick}>
    <BackgroundTest />
    <WrapAvatar>
      <Avatar />
    </WrapAvatar>
    {/* <PFP src={miniProfileImg} alt="" /> */}
    {/* <ClassImg src={meleeBlackOutline} alt="" /> */}

    <Name>Ylvis</Name>
    {/* {playerClass && */}
    <WrapClass>
      {/* <ClassBg /> */}
      <ClassTxt>Level 3 Fixer{classType}</ClassTxt>
    </WrapClass>
    {/* } */}
    {/* <WrapTimer>
      <ProgressBar colorScheme="light" />
    </WrapTimer> */}
    {/* <BackgroundSvg />
    <TimerBackgroundSvg /> */}
    {/* <WrapMenuButton>
      <MenuButton handleClick={() => setShowMainHud(!showMainHud)} />
    </WrapMenuButton> */}

  </Wrapper>
}

const ClassImg = styled.img`
  position: absolute;
  top: -60px;
  right: -50px;
  user-select: none;
  filter: invert(1);
  opacity: 0.09;
  width: 220px;
`

const ClassTxt = styled.div`
  position: relative;
  padding: 12px 18px;
  font-family: var(--primary-text-font);
  font-size: 0.9rem;
  color: #fe7e72;
`

const ClassBg = styled.div`
  background: #d04a4a;
  position: absolute;
  width: 100%;
  height: 100%;
  transform: perspective(100px) rotate3d(-3, 4, -2, -1deg) skew(-2deg, -1deg) scale(1);
`

const WrapClass = styled.div`
  position: absolute;
  bottom: 8px;
  left: 350px;
  width: 290px;
  transform: translateX(-50%);
`

const WrapTimer = styled.div`
  position: absolute;
  left: 0px;
  bottom: 40px;

  &:hover {
    scale: 1.05;
    filter: brightness(1.1);
  }
`

const WrapMenuButton = styled.div`
  position: absolute;
  top: -5px;
  scale: 0.8;
  right: -80px;
`

const animTimerBar = keyframes`
  0% { transform: translateY(10px) scale(0.99); opacity: 0; }
  100% { transform: translateY(0) scale(1); opacity: 1; }
`

const WrapAvatar = styled.div`
  position: absolute;
  top: -125px;
  width: 220px;
  height: 220px;
`

const PFP = styled.img`
  position: absolute;
  top: -125px;
  width: 220px;
  height: 220px;
`

const Name = styled.div`
  position: absolute;
  top: 30px;
  left: 220px;
  font-size: 3rem;
  font-family: var(--primary-text-font);
  color: #fff;
`

const TimerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const Bar = styled.div`
  position: absolute;
  /* background: #545454; */
  background: #7b1d14;
  width: 248px;
  height: 24px;
  transform: perspective(100px) rotate3d(-6, 4, -2, -3deg) skew(-1deg, -1deg) scale(1);
`
const Progress = styled.div`
  position: absolute;
  background: #ffffff;
  width: 90px;
  top: -2px;
  height: 27px;
  transform: perspective(100px) rotate3d(-6, 4, -2, -3deg) skew(-1deg, -1deg) scale(1);

`

const Time = styled.div`
  margin-left: 40px;
  width: 130px;
  z-index: 2;
  font-family: var(--primary-text-font);

`
const TimerBarBackground = styled.div`
  position: absolute;
  left: -5px;
  /* background: rgb(0,0,0); */
  background: #d55454;
  width: 434px;
  height: 49px;
  transform: perspective(100px) rotate3d(-3, 4, -2, -1deg) skew(-2deg, -1deg) scale(1);
`

const TimerBar = styled.div`
  display: flex;
  align-items: center;
  color: #fff;
  opacity: 0;
  animation: ${animTimerBar} 1.6s 0.1s cubic-bezier(0, 0.6, 0, 1) forwards;
`

const BackgroundTest = styled.div`
  width: 100%;
  height: 100%;
  /* background: rgba(0,0,0,0.6); */
  background: #BF4040;
  transform: perspective(100px) rotate3d(-3, 4, -2, -2deg) skew(-2deg, -1deg) scale(1);
`

const Wrapper = styled.div`
  position: relative;
  margin: 30px 40px;
  width: 404.05px;
  height: 114.69px;
  opacity: 0;
  animation: ${animTimerBar} 1.4s 0.05s  cubic-bezier(0, 0.7, 0.1, 1) forwards;
`

export default PlayerMini
