import { useEffect, useState, useReducer, useCallback, useLayoutEffect } from 'react';
import styled from 'styled-components';
import Gallery from './Gallery';
import Filters from './Filters';
import { SORT } from 'const/sort';
import state from 'Project/Collection/traits.json'
import useDebounce from 'hooks/useDebounce';
import { Provider, atom, useAtom } from 'jotai'
import { useReducerAtom } from 'jotai/utils'
import { filterJotai, queryJotai, reducerJotai, sortMethodJotai } from 'data/atoms';

const initialSelected = { background: [], pattern: [], weapon: [], tail: [], clothing: [], shoulder: [], ears: [], head: [], eyebrows: [], eyes: [], mouth: [], facial: [], hat: [] }

const initialReducerState = { selected: { background: [], pattern: [], weapon: [], tail: [], clothing: [], shoulder: [], ears: [], head: [], eyebrows: [], eyes: [], mouth: [], facial: [], hat: [] }, state, stack: [], sortMethod: SORT.lastMint }

const reducer = (state, action) => {
  let updatedStack;
  switch (action.type) {
    case 'add':
      let prevArray = state.selected[action.category];
      updatedStack = state.stack;
      updatedStack.push({ category: action.category, trait: action.trait });
      prevArray.push(action.trait)
      let newFilter = { ...state.selected, [action.category]: prevArray }
      let { stack, ...newObj } = { ...state, selected: newFilter, stack: updatedStack }
      return {
        ...newObj, stack
      }
    case 'remove':
      updatedStack = state.stack.filter(entry => {
        if (entry.category === action.category && entry.trait === action.trait)
          return false;
        else
          return true;
      });
      let { stack: stackRemove, ...newObjRemove } = {
        ...state, selected: {
          ...state.selected,
          [action.category]: state.selected[action.category].filter(filter => filter !== action.trait)
        }, stack: updatedStack
      }
      return { ...newObjRemove, stack: stackRemove }
    case 'sort':
      return { ...state, sortMethod: action.method }
    case 'updateState':
      return { ...state, state: action.updatedState }
    default:
      break;
  }
}

// const filterJotai = atom(false)
// const queryJotai = atom(JSON.stringify(state.selected))
// const reducerJotai = atom(initialReducerState)
// const sortMethodJotai = atom(1)


const Collection = () => {


  const [state, dispatch] = useReducerAtom(reducerJotai, reducer)
  const [sortMethod, setSortMethod] = useAtom(sortMethodJotai);
  const [query, setQuery] = useAtom(queryJotai);
  const [filterWait, setFilterWait] = useAtom(filterJotai);
  const [initialLoad, setInitialLoad] = useState(true);

  const sorState = useCallback(() => {
    let stringified = JSON.stringify(state.selected);
    // console.log(stringified);
    setFilterWait(false);
    setQuery(JSON.stringify(state.selected));
    setSortMethod(state.sortMethod)
  }, [state.selected, state.sortMethod]);

  const debouncedUpdateQuery = useDebounce(() => sorState(), 900);

  useEffect(() => {
    if (initialLoad) {
      setInitialLoad(false);
    } else {
      debouncedUpdateQuery();
      setFilterWait(true);
    }
    // if (isFirstLoad === false) setFilterWait(true);
    // if (isFirstLoad === true) setIsFirstLoad(false);

    return () => {
      debouncedUpdateQuery.cancel();
    };
  }, [state.selected, state.sortMethod, debouncedUpdateQuery]);

  useEffect(() => {
    window.scrollTo({
      top: 100,
      left: 100,
      behavior: 'auto',
    });
  }, []);

  return (
    <Wrapper>
      <Container>
        <Provider>
          <Filters setFilterWait={setFilterWait} state={state} dispatch={dispatch} />
          <Gallery filterWait={filterWait} sortMethod={sortMethod} state={state} dispatch={dispatch} query={query} />
        </Provider>
      </Container>
    </Wrapper>
  );
};

const NextWrapper = styled.div`
  width: 100%;
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  /* max-width: 1600px; */
  max-width: 1600px;
  width: 100%;

  @media (max-width: 1920px) {
    /* width: 1840px; */
    padding-left: 70px;
    padding-right: 70px;
  }

  @media (max-width: 1024px) {
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 0;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: #0b090a;
  height: 100%;
  width: 100%;
`;

export default Collection;
