import { useContext, useState } from 'react';
import styled from 'styled-components';

import { languageOptions } from 'languages';
import { LanguageContext } from 'containers/Language';
import { GlobeSvg, SelectArrowsSVG } from 'components/SVG/ui';

export default function LanguageSelector() {
  const [isOpen, setIsOpen] = useState(false);
  const { userLanguage, userLanguageChange } = useContext(LanguageContext);

  // set selected language by calling context method
  // const handleLanguageChange = e => userLanguageChange(e.target.value);

  const handleLanguageChange = id => {
    userLanguageChange(id);
    setIsOpen(false);
  };

  return (
    <>
      <Overlay isVisible={isOpen} onClick={() => setIsOpen(false)} />
      <Wrapper>
        <Options isVisible={isOpen}>
          {Object.entries(languageOptions).map(([id, name]) => (
            <Choice key={id} isSelected={userLanguage === id} value={id} onClick={() => handleLanguageChange(id)}>
              <State>{userLanguage === id && `✓`}</State>
              <Text>{name}</Text>
            </Choice>
          ))}
        </Options>
        <Button onClick={() => setIsOpen(!isOpen)}>
          <GlobeSvg />
          {userLanguage === 'en' && 'English'}
          {userLanguage === 'ip' && 'Ipsum'}
          <SelectArrowsSVG />
        </Button>
      </Wrapper>
    </>
  );
}

const State = styled.div`
  width: 16px;
`;

const Text = styled.div``;

const Overlay = styled.div`
  ${props =>
    props.isVisible ? 'visibility: visible; background: rgba(0, 0, 0, 0.6);' : 'visibility: hidden; background: rgba(0, 0, 0, 0.0);'}
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 100%;
  height: 100%;

  transition: 0.15s background ease-out;
`;

const Choice = styled.div`
  display: flex;
  border-bottom: 1px solid #000;
  background: #454243;
  padding: 20px 24px 20px 15px;
  width: 160px;
  font-size: 0.9rem;
  cursor: pointer;
  ${props => props.isSelected && ' background: grey;'}

  &:hover {
    background: #fff;
    color: #000;
  }
`;

const Options = styled.div`
  display: flex;
  color: #fff;
  ${props => (props.isVisible ? 'display: flex;' : 'display: none;')}
  flex-direction: column;
  z-index: 1005;
  position: absolute;
  top: -7.55rem;
  width: 150px;
  left: 15%;
  transform: translateX(-50%);
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 12px 8px;
`;

const Wrapper = styled.div`
  position: relative;
`;
