import { useState } from "react"
import styled, { keyframes } from "styled-components"
import { useAtom } from "jotai"
import { itemReducerAtom } from "Project/Game/data/atoms"
import { TYPE } from "Project/Game/const"
import { Howl } from "howler"
var mouseOver = new Howl({
  src: ['/sound/mainmenu_mouseover.wav'],
  autoplay: false,
  loop: false,
  volume: 0.8,
});

var clickSlot = new Howl({
  src: ['/sound/generic_click01.wav'],
  autoplay: false,
  loop: false,
  volume: 0.8,
});

var clickEquipment = new Howl({
  src: ['/sound/generic_click02.wav'],
  autoplay: false,
  loop: false,
  volume: 0.8,
});


const Slot = ({ item, equippable, side, isActive, setIsActive }) => {
  let isThisItemActive = isActive === item?.properties?.type;
  const equippableLength = equippable?.length > 0 && equippable.length
  const [items, dispatchItem] = useAtom(itemReducerAtom)
  const backgroundColor = item?.properties?.rarity ? item.properties.rarity : '#ca4e4e';

  const handleItem = (item) => {
    clickEquipment.play();
    console.log('dispatched item')
    dispatchItem({ type: 'EQUIP', payload: { item } });
    setIsActive(-1);
  }

  const emptyFn = () => {

  }

  const handleSlotClick = () => {
    clickSlot.play();
    if (isThisItemActive) {
      setIsActive(-1);
    } else if (equippableLength > 1) {
      setIsActive(item?.properties?.type);
    }
  }
  const handleMouseOver = () => {
    mouseOver.play();
  }


  return <Wrapper onClick={handleSlotClick} backgroundColor={backgroundColor} isActive={isThisItemActive}>
    {/* <Overlay /> */}
    {equippableLength > 1 && <Counter onMouseOver={e => e.stopPropagation()} side={side}>{equippableLength}</Counter>}
    {item?.img?.icon && <Img onMouseEnter={isThisItemActive === false ? handleMouseOver : emptyFn} onMouseOver={e => e.stopPropagation()} src={item.img.icon} alt="" />}
    <Drawer onMouseEnter={e => e.stopPropagation()} onClick={e => e.stopPropagation()} isVisible={isThisItemActive && equippable?.length > 1} side={side}>
      {equippable?.map((equippableItem, i) => <DrawerItem onMouseOver={e => e.stopPropagation()} isSelected={equippableItem.id === item.id} backgroundColor={equippableItem.properties.rarity} onClick={() => handleItem(equippableItem)}><Img onMouseEnter={equippableItem.id !== item.id ? handleMouseOver : emptyFn} src={equippableItem.img.icon} alt="" /></DrawerItem>)}
    </Drawer>
  </Wrapper>
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vw;
  background: rgba(0, 0, 0, 0.4);
`

const DrawerItem = styled.div`
  img {
    cursor: pointer;
    ${props => props.backgroundColor && `background: ${props.backgroundColor};`}
    ${props => props.isSelected && 'filter: contrast(0.4); cursor: default;'}
    &:hover {
      filter: brightness(1.25);
      ${props => props.isSelected && 'filter: contrast(0.4); cursor: default;'}
    }
  }
`

const animeDrawerIntro = keyframes`
  from {
    opacity: 0;
    scale: 0.95;
  scale: 0.7;

    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    scale: 1;
  scale: 0.7;

    transform: translateY(0);
  }
`

const Drawer = styled.div`
  ${props => props.isVisible ? 'display: flex' : 'display: none'};
  position: absolute;
  cursor: default;
  column-gap: 8px;
  width: fit-content;
  top: 30px;
  background: #ca4b4b;
  padding: 15px;
  opacity: 0;
  animation: ${animeDrawerIntro} 0.3s cubic-bezier(0.1,0.8, 0, 1) forwards;
`

const Counter = styled.div`
  position: absolute;
  top: -13px;
  font-family: var(--primary-text-font);
  ${props => props.side == 'right' ? 'right: -13px' : 'left: -13px'};
  font-size: 1rem;
  font-weight: 600;
  width: 35px;
  height: 35px;
  background: white;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Img = styled.img`
  width: 80px;
  height: 80px;
  transform: ${props => props.isDragging && 'scale(0.8)'};
  /* background: ${props => props.isDragging && props.backgroundColor}; */
`

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  &:hover {
    filter: brightness(1.05);
    ${props => props.isActive && 'filter: brightness(1.2); pointer: default;'}
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;  
  z-index: 500;
  ${props => props.backgroundColor && `background: ${props.backgroundColor};`}
  ${props => props.isActive && 'filter: brightness(1.2); pointer: default;'}

  /* border: 3px solid #DECFAD; */
  border: 4px solid transparent;
`

export default Slot