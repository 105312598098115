import styled from "styled-components";
import React, { useEffect, useState, useCallback } from 'react'
import { useThree } from "@react-three/fiber";
import gsap from "gsap";
import MapMarker from "../../UI/MainHUD/MapMarker";
import { ZOOM } from "Project/Game/const";
import { Cloud } from "@react-three/drei";
import { PositionalAudio } from "@react-three/drei";
import Environment from "./Environment";
import PostProcess from "./PostProcessing";
import { debugOptionsAtom, exampleAtom, zoomedAtom, npcAtom, questsAtom, camInTransitionAtom, cameraZoomAtom, areasAtom, selectedQuestAtom, camAtom } from "Project/Game/data/atoms";
import { useAtom } from "jotai";
import { buildingMarkers, questData as questDatabase, areaMarkers } from "Project/Game/data/gameData";
import AreaMarker from "Project/Game/UI/MainHUD/AreaMarker";

const Scene = () => {
  const { camera } = useThree()
  const [camInTransition, setCamInTransition] = useAtom(camInTransitionAtom)
  const [cameraState, setCameraState] = useAtom(camAtom)
  const [areas, setAreas] = useAtom(areasAtom);
  const [quests, setQuests] = useAtom(questsAtom);
  const [questMarkers, setQuestMarkers] = useState([])
  const [breadcrumbMarkers, setBreadcrumbMarkers] = useState([])
  const [debugOptions, setDebugOptions] = useAtom(debugOptionsAtom)

  useEffect(() => {
    const setMapMarkers = (quests) => {

      const getQuestBuildingIds = (quests) => {
        let activeMarkerIds = [];

        quests.forEach(quest => {
          let isNotFinished = (quest.endTime === null || quest.endTime > Date.now()) && quest.rewardsClaimed === false;

          if (isNotFinished) {
            let activeQuest = questDatabase.find(dataEntry => dataEntry.id === quest.id);
            let questArea = areaMarkers.find(areaData => areaData.type === activeQuest.area);

            let isQuestAndCameraSectionMatch = cameraState.section === questArea.section;

            if (isQuestAndCameraSectionMatch)
              activeMarkerIds.push({ id: activeQuest.buildingId, state: quest.endTime > Date.now() ? 'Timer' : 'Open' })
          }
        })

        return activeMarkerIds;
      }

      const getbuildingMarkerData = (ids) => {
        return ids.map(id => {
          let data = buildingMarkers.find(marker => marker.id === id.id)
          return { id: id.id, data, state: id.state }
        })
      }

      let buildingIds = getQuestBuildingIds(quests);
      let markers = getbuildingMarkerData(buildingIds);
      setQuestMarkers(markers)
      console.log('Markers are', markers);
      console.log('Quests are now', quests)

    }

    setMapMarkers(quests);
  }, [quests, cameraState])

  // useEffect(() => {
  //   console.log(questMarkers)
  // }, [questMarkers]);

  const handleOpenMarker = (marker, zoom) => {
    setCameraState(prev => {
      return {
        ...prev,
        activeMarker: marker,
        position: marker.data.position,
        target: marker.data.target,
        zoom
      }
    })
  }

  return <group>
    <PostProcess debugOptions={debugOptions} />
    <Environment />
    {/* {quests.length === 2 || quests.length === 3 && <pointLight position={[2852, 155, 734]} distance={400} intensity={0.5} color="red" />} */}
    {/* <Cloud
      disabled={true}
      position={[2852, 155, 734]}
      opacity={quests.length === 3 || quests.length === 4 ? 1 : 0}
      scale={[25, 25, 25]}
      speed={0.1} // Rotation speed
      width={5} // Width of the full cloud
      depth={1.5} // Z-dir depth
      segments={30} // Number of particles
    />
    <Cloud
      disabled={true}
      position={[2700, 180, -600]}
      opacity={quests.length === 3 || quests.length === 4 ? 1 : 0}
      scale={[45, 35, 35]}
      speed={0.1} // Rotation speed
      width={5} // Width of the full cloud
      depth={1.5} // Z-dir depth
      segments={30} // Number of particles
    /> */}
    {cameraState.zoom === ZOOM.Area && camInTransition === false && questMarkers.length > 0 && questMarkers.map(marker => cameraState.activeMarker.type === marker.data.areaType && <MapMarker key={marker.data.id} state={marker.state} description={marker.data.description} name={marker.data.name} audioId={marker.data.audioId} handleOpen={() => handleOpenMarker(marker.data, ZOOM.Building)} position={marker.data.data.markerPos} boxTransforms={marker.data.data.boxTransforms} />)}

    {/* {cameraState.zoom === ZOOM.Area && camInTransition === false && (quests.length === 2 || quests.length === 4) && questMarkers.map(marker => cameraState.activeMarker.type === marker.data.areaType && <MapMarker key={buildingMarkers[1].id} state={marker.state} isDisabled={true} description={buildingMarkers[1].description} name={buildingMarkers[1].name} audioId={buildingMarkers[1].audioId} position={buildingMarkers[1].data.markerPos} boxTransforms={buildingMarkers[1].data.boxTransforms} />)}

    {cameraState.zoom === ZOOM.Area && camInTransition === false && (quests.length > 2) && questMarkers.map(marker => cameraState.activeMarker.type === marker.data.areaType && <MapMarker key={buildingMarkers[2].id} state={marker.state} isDisabled={true} description={buildingMarkers[2].description} name={buildingMarkers[2].name} audioId={buildingMarkers[2].audioId} position={buildingMarkers[2].data.markerPos} boxTransforms={buildingMarkers[2].data.boxTransforms} />)} */}

    {cameraState.zoom === ZOOM.Overworld && camInTransition === false && areas.length > 0 && areas.map(area => <AreaMarker key={area.id} description={area.description} name={area.name} handleOpen={() => handleOpenMarker(area, ZOOM.Area)} position={area.data.markerPos} audioId={area.data.audioId} boxTransforms={area.data.boxTransforms} />)}
  </group>
}

const Stick = styled.div`
  position: absolute;
  top: 70px;
  
  background: rgba(255, 255, 255, 1);
  width: 1px;
  height: 100%;
`

const SvgHome = styled.svg`
  fill: #fff;
  width: 30px;
`

const Descriptor = styled.div`
  font-weight: 200;
  padding-top: 5px;
  padding-left: 10px;
  opacity: 0.7;
  font-size: 0.85rem;
`
const Header = styled.h3`
  font-weight: 700;
  font-size: 1.55rem;
  padding-top: 10px;
  padding-left: 10px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgba(255, 255, 255, 1);
`

const IconWrapper = styled.div`
  outline: 1px solid #fff;
  padding: 10px;
  width: 50px;
  height: 50px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;

  &:hover {
    div:first-child {
      background: indigo; 
    }
  }
`

const Icon = styled.div`
  display: flex;
  pointer-events: default;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 1);
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  outline: 3px solid #fff;
  padding: 10px;
  font-size: 3rem;


`
const Info = styled.span`
  font-family: var(--primary-text-font);
  position: absolute;
  left: 70px;
  top: -8px;
  pointer-events: none;
  width: 200px;
  justify-content: center;
  visibility: hidden;
  color: #fff;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
`

const AnnotationWrapper = styled.div`
  position: absolute;
  justify-content: center;
  display: flex;
  height: 50px;
  column-gap: 30px;
  /* transform: perspective(150px) rotateX(-2deg) rotateY(6deg) rotateZ(-1deg) scale(1); */

  div:nth-child(2):hover {
    span {
      visibility: visible;
    }
  }
`

const PopupWrapper = styled.div`
`

const Wrapper = styled.div`
  margin-top: 300px;
`

export default Scene;
