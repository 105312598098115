import { useContext } from 'react';
import styled from 'styled-components';
import { LanguageContext } from 'containers/Language';
import Question from 'Project/Landing/FAQ//Question';

const FAQ = () => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <Wrapper name="faq">
      <Container>
        {/* <Head>FAQ</Head> */}
        <Category>
          {/* <SubHeader>FAQ</SubHeader> */}
          <InformationWrapper role="list">
            {dictionary.faq.map((entry, idx) => (
              <Question key={idx} entry={entry} />
            ))}
          </InformationWrapper>
        </Category>
      </Container>
    </Wrapper>
  );
};

const Category = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: clamp(5px, 2vw, 30px);
`;

const SubHeader = styled.div`
  font-size: 2.25rem;
  font-weight: 500;
  letter-spacing: -1px;
  opacity: 0.6;
`;

const Head = styled.div`
  /* font-family: var(--title-font); */
  font-size: 8rem;
  /* letter-spacing: -2px; */
  position: relative;
  left: -9px;
  opacity: 0.3;
`;

const InformationWrapper = styled.ol`
  position: relative;
  list-style-type: none;
  /* margin-left: 12%;
  margin-right: 12%; */
  display: flex;
  row-gap: 15px;
  flex-direction: column;
  width: 100%;
  /* padding: 0 5%; */
  z-index: 5;

  transition: opacity 1s;
`;

const Headline = styled.h2`
  transition: all 0.5s linear;
  top: -0px;
  position: relative;
  color: #dd4776;
  font-family: var(--title-font);
  align-self: flex-start;
  left: -15vw;
  font-size: 45rem;
  line-height: clamp(3rem, 5.5vw, 5.25rem);
  font-weight: 3100;
  letter-spacing: -8px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1024px;
  row-gap: clamp(20px, 2vw, 50px);
  width: 90%;
`;

const Wrapper = styled.div`
  position: relative;
  z-index: 10;
  background: #382138;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: clamp(100px, 8vw, 150px);
  padding-top: clamp(100px, 8vw, 150px);

  overflow-y: hidden;
  width: 100%;
  min-height: 50vh;
`;

export default FAQ;
