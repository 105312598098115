import styled from "styled-components";
import { useState } from "react";
import MainHUD from "Project/Game/UI/MainHUD";
import StartScreen from "Project/Game/UI/StartScreen";
import { GAMESTATE } from "../const";
import { useAtom } from "jotai";
import { gameStateAtom } from "../data/atoms";

const UI = () => {
  const [gameState, setGameState] = useAtom(gameStateAtom);
  return <Wrapper>
    {gameState === GAMESTATE.StartScreen && <StartScreen />}
    {gameState === GAMESTATE.MainGame && <MainHUD />}
  </Wrapper>
}

const Wrapper = styled.div`
`;

export default UI;