import { useRef, useEffect, useCallback } from 'react';
import debounce from 'lodash.debounce';

function useDebounce(cb, delay) {
  const options = { leading: false, maxWait: 3000, trailing: true }; // add custom lodash options
  const cbRef = useRef(cb);

  // use mutable ref to make useCallback/throttle not depend on `cb` dep
  useEffect(() => {
    cbRef.current = cb;
  });

  return useCallback(
    debounce((...args) => cbRef.current(...args), delay, options),
    [delay]
  );
}

export default useDebounce;
