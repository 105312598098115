import { BigNumber } from 'ethers';
// import erc721 from 'data/erc721.json';
// import erc20 from 'data/erc20.json';
import erc721test from 'data/test/erc721.json';
import erc20test from 'data/test/erc20.json';

// let isProduction = process.env.REACT_APP_PRODUCTION;
let isProduction = false;

export const FIRO_ERC20_COST = {
  value: 12,
  decimals: 8,
  bigNumberValue: BigNumber.from('1200000000')
}

export const BNB_COST = {
  value: 0.08,
  decimals: 16,
  bigNumberValue: BigNumber.from('80000000000000000'),
}

// ethers.utils.parseEther("0.08")

export const FIROFOX_ERC721 = {
  address: isProduction ? '0xe8fAc87e61Bd2C6f9344b5975aaa08A730aE1938' : '0xe8fAc87e61Bd2C6f9344b5975aaa08A730aE1938',
  abi: isProduction ? erc721test : erc721test,
  cost: {
    bnb: {
      value: 0.08,
      decimals: 16,
      bigNumberValue: BigNumber.from('80000000000000000'),
    },
    firo: {
      value: 12,
      decimals: 8,
      bigNumberValue: BigNumber.from('1200000000')
    }
  }
}

export const FIRO_ERC20 = {
  address: isProduction ? '0x09aB7d51f4F0e58B7136249BF6549867389ceE78' : '0x09aB7d51f4F0e58B7136249BF6549867389ceE78',
  abi: isProduction ? erc20test : erc20test
}

export const CHAIN = {
  id: isProduction ? { hex: '0x38', decimal: 56 } : { hex: '0x61', decimal: 97 },
  rpcUrls: isProduction ? ['https://bsc-dataseed.binance.org'] : ['https://data-seed-prebsc-1-s1.binance.org:8545'],
  name: isProduction ? 'Binance Smart Chain Mainnet' : 'Binance Smart Chain Testnet',
  nativeCurrency: isProduction ? {
    name: 'Binance Chain Native Token',
    symbol: 'BNB',
    decimals: 18
  } : {
    name: 'Binance Chain Native Token',
    symbol: 'tBNB',
    decimals: 18
  },
  network: isProduction ? 'bsc-mainnet' : 'bsc-testnet',
  blockExplorerUrl: isProduction ? ['https://bscscan.com'] : ['https://testnet.bscscan.com'],
  openseaUrl: isProduction ? `https://opensea.io/assets/bsc/${FIROFOX_ERC721.address}` : `https://testnets.opensea.io/assets/bsc/${FIROFOX_ERC721.address}`
}

export const ETHERS_POLLING_SPEED = 4000;
export const REQUEST_TIMEOUT = 140000; // 140 seconds
export const GAS_LIMIT = 800000;