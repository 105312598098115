import styled from "styled-components"
import { useReducer, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Droppable from "./components/Droppable";
import Draggable from "./components/Draggable";
import { useAtom } from "jotai";
import { inventoryReducerAtom } from "Project/Game/data/atoms";

const Inventory = ({ slots, setSlots, mouseDown, setMouseDown }) => {
  const [items, dispatchItem] = useAtom(inventoryReducerAtom);
  const test = Object.keys(items).map((key) => items[key]);

  return <Wrapper>
    {test.map((item, index) => <WrapSlot>
      <Droppable id={index} item={item} key={index}>
        {item ?
          <Draggable mouseDown={mouseDown} setMouseDown={setMouseDown} slotId={index} item={item} />
          :
          null}
      </Droppable>
    </WrapSlot>)}
  </Wrapper>
}

const WrapSlot = styled.div`
  position: relative;
  display: flex;
  height: 75px;
  width: 80px;
`

const Wrapper = styled.div`
  display: flex;
  gap: 15px;
  right: 0;
  top: 80px;
  flex-wrap: wrap;
  /* width: 100%; */
  padding: 20px;
`

export default Inventory