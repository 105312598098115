import { useEffect, useRef } from "react";
import { useFrame, useThree } from "@react-three/fiber";
import { useKeyboardControls, OrbitControls } from "@react-three/drei";
import { debugOptionsAtom } from "Project/Game/data/atoms";
import { useAtom } from "jotai";

const Controls = ({ userFocalTarget, orbitalControlRef }) => {
  const [debugOptions, setDebugOptions] = useAtom(debugOptionsAtom);
  const [sub, get] = useKeyboardControls()
  const startTime = useRef(0);
  const { camera } = useThree()

  // console.log('camera', camera)

  useEffect(() => {
    return sub(
      (state) => state.forward || state.back,
      (pressed) => {
        startTime.current = new Date().getTime();
      }
    )
  }, [])

  useFrame((_, delta) => {
    // Fetch fresh data from store
    const pressedForward = get().forward
    const pressedBackward = get().back
    const pressedR = get().r

    // if (pressedForward && isZoomed === false) {
    if (pressedForward && camera.position.z < -300) {
      const duration = new Date().getTime() - startTime.current;

      // increase momentum if key pressed longer
      let momentum = Math.sqrt(duration + 400) * 0.075 + 0.05;

      // adjust for actual time passed
      momentum = (momentum * delta) / 0.008;

      // // increase momentum if camera higher
      // momentum = momentum + camera.position.z * 0.02;

      camera.position.x = camera.position.x - momentum;
      camera.updateProjectionMatrix();
      // console.log('momentum', momentum)
      // camera.translateZ(-momentum);
      // camera.translateX(momentum);
    }

    if (pressedR) {
      console.log('pressed r')
      camera.updateProjectionMatrix();
    }

    if (pressedBackward && camera.position.z > -700) {
      // if (pressedBackward && isZoomed === false) {
      const duration = new Date().getTime() - startTime.current;

      // increase momentum if key pressed longer
      let momentum = Math.sqrt(duration + 400) * 0.075 + 0.05;

      // adjust for actual time passed
      momentum = (momentum * delta) / 0.008;

      // // increase momentum if camera higher
      // momentum = momentum + camera.position.z * 0.02;

      camera.position.x = camera.position.x + momentum;
      camera.updateProjectionMatrix();

      // camera.translateZ(-momentum);
      // camera.translateX(momentum);
    }
  })

  return null;
}

export default Controls;