import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import WebFont from 'webfontloader';
import newPagoda from 'App/assets/images/landing/hero/pagoda-floating.png';
import nightHero from 'App/assets/images/landing/hero/hero-defend.png';
import nightHeroine from 'App/assets/images/landing/hero/heroine-defend.png';
import drXFront from 'App/assets/images/landing/hero/dr-x-front.png';
import maleMobile from 'App/assets/images/landing/hero/male-mobile.png';
import femaleMobile from 'App/assets/images/landing/hero/female-mobile.png';

import { CloudCenterSvg, CloudTopRightSvg, CloudSmallSvg, StreakLeftSvg, StreakRightSvg, CloudsLeftSvg } from 'components/SVG/world';
import { BoltBackgrounSvg, MobileBoltBackgroundSvg } from 'components/SVG/landing';

const Hero = () => {
  const [isFontLoaded, setIsFontLoaded] = useState(false);

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ['Aldo the Apache', 'Anime Ace'],
        urls: ['App/fontStyles.css']
      },
      active: function () { setIsFontLoaded(true) },
      classes: false,
      timeout: 8000
    });
  }, []);

  return (
    <Wrapper name="hero">
      <StreakLeftSvg />
      <CloudsLeftSvg />
      <StreakRightSvg />
      <CloudSmallSvg />
      <CloudTopRightSvg />
      <CloudCenterSvg />
      <BoltBackgrounSvg />
      <MobileBoltBackgroundSvg />
      <Pagoda src={newPagoda} alt="" />
      <Container>
        <Enemy>
          {/* <EnemyCyan src={drXFrontCyan} alt="" /> */}
          <EnemyImg src={drXFront} alt="" />
        </Enemy>
        <Content>
          <FemaleMobileImg src={femaleMobile} alt="" />
          <MaleMobileImg src={maleMobile} alt="" />
          <Female>
            <FemaleImg src={nightHeroine} alt="" />
          </Female>
          <Male>
            <MaleImg src={nightHero} alt="" />
          </Male>
        </Content>
        <TitleTextWrapper isFontLoaded={isFontLoaded}>
          <TitleText>Protectors</TitleText>
          <TitleText2>
            <SmallTxtWrapper>
              <OfTextBlack>
                <span>o</span>
                <span>f</span>
              </OfTextBlack>
              <OfText>
                <span>o</span>
                <span>f</span>
              </OfText>
            </SmallTxtWrapper>
            <PrivacyTemp>Privacy</PrivacyTemp>
            {/* <PoweredBy>Powered by Firo</PoweredBy> */}
          </TitleText2>
          {/* <DescriptionBox>
            <TextBox>
              <TextTop>Etch your name&nbsp;&nbsp;</TextTop>
              <TextBottom>in hero history</TextBottom>
            </TextBox>
          </DescriptionBox> */}
        </TitleTextWrapper>
      </Container>
    </Wrapper>
  );
};

const EnemyCyan = styled.img`
  position: absolute;
  top: 0;
  clip-path: polygon(0% 0%, 100% 0%, 100% 30%, 0% 30%, 0% 35%, 100% 35%, 100% 50%, 0% 50%, 0% 55%, 100% 55%, 100% 100%, 0% 100%);
  left: 0;

  width: clamp(453px, calc(22vw + 19vh), 656px);
  height: auto;
`;

const PoweredBy = styled.div`
  position: absolute;
  bottom: -30px;
  right: 75px;
  font-size: 2rem;
  /* text-shadow: 1px 1px 0px #000, -1px -1px 0px #000; */
  text-shadow: 1px 1px 0px transparent;

  color: #ee5c5c;
  background: -webkit-linear-gradient(#ee5c5c 40%, #ce3636 60%);
  /* background: -webkit-linear-gradient(#ee5c5c, #ae2020);
  background: -webkit-linear-gradient(#c3b98d, #847847); */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  @media (max-width: 480px) {
    font-size: 1.5rem;
    right: 25px;
  }
`;

const SmallTxtWrapper = styled.div`
  position: relative;
`;

const PrivacyTemp = styled.div`
  transform: skew(-12deg);
`;

const animEnemy = keyframes`
  0% { transform: translate(-200px, 0); }
  100% { transform: translate(0, 0); }
`;

const animGlitchRight = keyframes`
  0%,
  10% {
    transform: translateX(5px);
  }
  11% {
    transform: none;
  }
`;

const EnemyImg = styled.img`
  position: relative;
  width: clamp(453px, calc(22vw + 19vh), 656px);
  height: auto;
  /* clip-path: polygon(0% 0%, 100% 0%, 100% 30%, 0% 30%, 0% 35%, 100% 35%, 100% 50%, 0% 50%, 0% 55%, 100% 55%, 100% 100%, 0% 100%); */
  /* animation: ${animGlitchRight} 1s infinite; */
  @media (max-width: 480px) {
    display: none;
  }
`;

const Enemy = styled.div`
  position: absolute;
  right: calc(12% - 150px);
  top: 150px;
  z-index: 10;
  /* bottom: clamp(1px, calc(20vw + 30vh), 40%); */
  animation: ${animEnemy} 2.5s cubic-bezier(0.1, 0.6, 0.01, 0.8) forwards;

  @media (max-width: 800px) {
    display: none;
  }

  @media (max-width: 480px) {
    bottom: -5%;
    left: 30%;
  }
`;

const FemaleImg = styled.img`
  width: clamp(180px, calc(10vw + 13vh), 327px);
  @media (max-width: 800px) {
    display: none;
  }
`;

const animFemaleMobile = keyframes`
  0% { transform: translateX(-70px); opacity: 0;}
  100% { transform: translateX(0); opacity: 1;}
`;

const FemaleMobileImg = styled.img`
  width: clamp(180px, calc(45vw + 8vh), 250px);
  position: absolute;
  bottom: 0;
  opacity: 0;
  filter: brightness(95%) contrast(95%);
  left: calc(20% - 170px);
  animation: ${animFemaleMobile} 2s 0.5s cubic-bezier(0, 1, 0.38, 0.99) forwards;

  @media (min-width: 801px) {
    display: none;
  }
`;

const MaleImg = styled.img`
  width: clamp(383px, calc(16vw + 18vh), 556px);
  transform: rotate(-3deg);
  @media (max-width: 800px) {
    display: none;
  }
`;

const animMaleMobile = keyframes`
  0% { transform: translateX(70px); opacity: 0;}
  100% { transform: translateX(0); opacity: 1;}
`;

const MaleMobileImg = styled.img`
  width: clamp(383px, calc(50vw + 25vh), 380px);
  position: absolute;
  bottom: 5vh;
  right: calc(18vw - 180px);
  opacity: 0;
  animation: ${animMaleMobile} 2s cubic-bezier(0, 1, 0.1, 0.99) forwards;
  @media (min-width: 769px) {
    display: none;
  }
`;

const animMaleChargeIn = keyframes`
  0% { transform: translate(-150px, 0); opacity: 0; }
  100% { transform: translate(0, 0); opacity: 1; }
`;

const Male = styled.div`
  position: absolute;
  /* left: clamp(0px, calc(-15% + 150px), 300px); */
  left: 0;
  /* bottom: calc(5.5vw + 3vh); */
  bottom: 0;
  opacity: 0;
  animation: ${animMaleChargeIn} 3.5s 0.7s cubic-bezier(0, 1, 0.08, 0.99) forwards;
  z-index: 10;

  @media (max-width: 480px) {
    left: 7%;
    opacity: 1;
    bottom: 20%;
  }
`;

const animFemaleChargeIn = keyframes`
  0% { transform: translate(-100px, 0); opacity: 0; }
  100% { transform: translate(0, 0); opacity: 1; }
`;

const Female = styled.div`
  position: absolute;
  left: calc(-10% + 00px);
  /* bottom: calc(5.5vw + 20vh); */
  bottom: 200px;
  opacity: 0;
  animation: ${animFemaleChargeIn} 3.5s 1.3s cubic-bezier(0, 1, 0.38, 0.99) forwards;
  filter: brightness(90%);

  @media (max-width: 480px) {
    opacity: 1;
    left: -12%;
    bottom: 30%;
  }
`;

const OfText = styled.span`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  padding: 5px calc(0.5vw + 0.5vh) 0.5vh calc(0.5vw + 0.5vh);
  font-size: clamp(0.75rem, calc(1.5vw + 2vh), 3rem);
  text-shadow: none;
  /* text-shadow: 5px 5px 0px #fff; */
  /* color: var(--landing-skyGradient-bottom); */
  transform: skew(-12deg);
  background-color: white;
  color: black;
  mix-blend-mode: screen;
`;

const OfTextBlack = styled.span`
  position: absolute;
  top: 9px;
  left: 9px;
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  padding: 5px calc(0.5vw + 0.5vh) 0.5vh calc(0.5vw + 0.5vh);
  font-size: clamp(0.75rem, calc(1.5vw + 2vh), 3rem);
  text-shadow: none;
  /* text-shadow: 5px 5px 0px #fff; */
  /* color: var(--landing-skyGradient-bottom); */
  transform: skew(-12deg);
  background-color: var(--shadow);
  color: var(--shadow);
`;

const TitleTextWrapper = styled.div`
  ${props => props.isFontLoaded ? 'visibility: visible;' : 'visibility: hidden;'}
  position: absolute;
  font-size: clamp(4.5rem, calc(6vw + 5vh), 9.5rem);
  /* top: clamp(calc(12vw + 11vh), calc(15vw + 0vh), 350px); */
  top: 22vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-family: var(--title-font);
  color: var(--firofox-red);
  text-shadow: 10px 10px 0px var(--shadow);
  z-index: 10;
`;

const TitleText2 = styled.div`
  display: flex;
  column-gap: 10px;
  top: -0.5vw;
  position: relative;
  left: clamp(10px, 3vw, 100px);
  align-items: center;
`;

const TitleText = styled.div`
  transform: skew(-12deg);
`;

const animFloatPagoda = keyframes`
  0% { transform: translate(0, 0) scale(0.75); }
  50% { transform: translate(-2.5px, -10px) scale(0.75); }
  100% { transform: translate(0, 0) scale(0.75); }
`;

const Pagoda = styled.img`
  position: absolute;
  width: clamp(500px, 60vw, 800px);
  top: clamp(0px, calc(18vw + 15vh), 320px);
  left: 30%;
  filter: brightness(40%) saturate(120%) contrast(85%) hue-rotate(50deg);
  animation: ${animFloatPagoda} 7s ease-in-out infinite forwards;

  @media (max-width: 800px) {
    display: none;
  }
`;

const WrapBackground = styled.div`
  overflow: hidden;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  justify-content: center;
  width: 100%;
`;

const Content = styled.div`
  position: relative;
  z-index: 10;
  width: 1400px;
  /* width: 100%; */
  transform: translateX(5%);
`;

const animBgTopToBottom = keyframes`
  0% { background-position: 50% 40%; }
  100% { background-position: 0% 100%;  }
`;

const Container = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  max-width: 1900px;
  width: 100%;
  height: 1000px;
  /* max-height: 1000px; */

  background-size: 150% 300%;
  margin-left: 5%;
  margin-right: 5%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;

  @media (max-width: 1200px) {
    height: 600px;
  }

  @media (max-width: 800px) {
    height: 90vh;
    max-height: 900px;
  }
`;

const Wrapper = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  transition: all 4s ease-out;
  /* background: linear-gradient(#62b4d1 0%, #a7dde3 25%, #bee3c2 30%, #e6e070 33%, #ffbf6d 38%, #feb45d 40%, #f37d73 53%, #d96c83, 56%, #864088 60%, #461438 70%, #b51e46 95%); */

  /* linear-gradient(
    176deg,
    var(--landing-skyGradient-top) 0%,
    var(--landing-skyGradient-top) 5%,
    var(--landing-skyGradient-bottom) 50%,
    var(--landing-skyGradient-bottom) 100%,
    var(--landing-road) 100%
  ); */
`;

export default Hero;
