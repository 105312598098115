import { Environment as DreiEnvironment, Sky, Stars } from "@react-three/drei";
import { useRef, useEffect } from "react";
import { useThree } from "@react-three/fiber";
import shanghaiBundExr from 'App/assets/3d/texture/moonless_golf_1k.hdr'
import { sRGBEncoding } from "three";

const Environment = () => {
  const { gl, scene } = useThree();
  const sunRef = useRef();
  const hemiLightRef = useRef();
  const sunTarget = useRef();
  const newSkyMaterialRef = useRef();
  const testRef = useRef();
  // useHelper(testRef, DirectionalLightHelper, 80)

  // useEffect(() => {
  //   // console.log('sunRef', sunRef.current.position);
  //   if (sunRef.current.position)
  //     sunRef.current.position.x = sun.x;
  //   sunRef.current.position.y = sun.y;
  //   sunRef.current.position.z = sun.z;
  // }, [sun])

  useEffect(() => {
    sunRef.current.position.set(2, 0.2, 0.9)
  }, [])

  return <group>
    {/* <Sky azimuth={24.2} turbidity={2} rayleigh={0.1} inclination={55.29} distance={15500} /> */}
    {/* <Sky ref={skyRef} azimuth={effectController.azimuth} turbidity={effectController.turbidity} rayleigh={effectController.rayleigh} inclination={effectController.inclination} mieCoefficient={effectController.mieCoefficient} mieDirectionalG={effectController.mieDirectionalG} /> */}
    <ambientLight intensity={0.11} />
    {/* <DreiEnvironment
      blur={0.75} background={true}
      // files={shanghaiBundExr}
      files={shanghaiBundExr}
      // encoding={sRGBEncoding}
      // preset={'city'}
      near={1} far={1500} resolution={256}
    /> */}
    {/* <Stars radius={16000} depth={150} count={5000} factor={14} saturation={0} speed={1} /> */}
    <rectAreaLight // key
      width={4233}
      height={2603}
      color={'aqua'}
      intensity={0.5}
      rotation={[Math.PI / 2, 0, Math.PI / 1]}
      position={[-3325, 0, 0]}
    />
    {/* <rectAreaLight // key
      width={4133}
      height={2033}
      color={'aqua'}
      intensity={3}
      rotation={[Math.PI / 2, Math.PI / 4, Math.PI / 2]}
      position={[4000, 0, 1000]}
      penumbra={5}
    /> */}
    {/* <rectAreaLight // fill
      width={51}
      height={51}
      intensity={1}
      // color={'#bdefff'}
      color={'red'}
      position={[0, 25, 0]}
      penumbra={2}
    /> */}
    {/* <spotLight intensity={0.1} position={[3000, 0, 1000]} /> */}
    {/* <pointLight intensity={1} decay={0.2} power={3} position={[4000, 45, 1000]} color='white' /> */}
    {/* <hemisphereLight args={['#5a2443', '#5a2443', 0.6]} /> */}
    <hemisphereLight args={['purple', '#5a2443', 0.4]} />
    <directionalLight
      intensity={0.03}
      ref={testRef}
      castShadow={true}
      radius={1}
      position={[-1, 0.8, -1]}
      // target={sunRef.current}
      shadow-mapSize={[4096, 4096]}
      shadow-camera-near={-15000}
      shadow-camera-far={15000}
      shadow-bias={-0.0001}
      shadow-camera-top={2500}
      shadow-camera-right={2500}
      shadow-camera-bottom={-2500}
      shadow-camera-left={-2500}
    />
    {/* <directionalLight
      position={[325, 20, 100]}
      intensity={0.3}
      color={'purple'}
      shadow-mapSize={[2048, 2048]}
      shadow-camera-near={-1500}
      shadow-camera-far={1500}
      shadow-camera-top={1500}
      shadow-camera-right={1000}
      shadow-camera-bottom={-1500}
      shadow-camera-left={-1000}
    /> */}
    <object3D ref={sunRef} position={[15, 5, -30]} />
    <object3D ref={sunTarget} position={[0, 25, 100]} />
    {/* <directionalLight position={direction} intensity={0.4} shadow-mapSize={1024} castShadow />
    <directionalLight position={[-5, 5, 5]} intensity={0.01} shadow-mapSize={128} castShadow />
    <directionalLight position={[-5, 5, -5]} intensity={0.01} shadow-mapSize={128} castShadow />
    <directionalLight position={[0, 5, 0]} intensity={0.10} shadow-mapSize={128} castShadow />
    <AccumulativeShadows frames={100} alphaTest={0.85} opacity={0.75} scale={30} position={[0, -1.5, 0]}>
      <RandomizedLight amount={8} radius={2.5} ambient={0.5} intensity={1} position={direction} bias={0.001} />
    </AccumulativeShadows>
    <EnvironmentImpl preset="city" /> */}
  </group>;
}

export default Environment