import { OrbitControls, CycleRaycast } from "@react-three/drei"
import { useThree } from "@react-three/fiber"
import { debugOptionsAtom } from "Project/Game/data/atoms"
import { useAtom } from "jotai"
import { useEffect, useRef, useCallback } from "react"
import { ZOOM } from "Project/Game/const"
import { camAtom, selectedQuestAtom, camInTransitionAtom, loadedAudioAtom, interfaceAtom } from "Project/Game/data/atoms"
import { gsap } from "gsap"
import { audioData } from "Project/Game/data/gameData"
import { overworldMarkers, areaMarkers } from "Project/Game/data/gameData"
import infirmrySoundLoop from 'App/assets/sound/infirmry.mp3'
import { Howl } from 'howler';


var ambiantSound = new Howl({
  src: ['/sound/main.ogg'],
  autoplay: false,
  loop: true,
  volume: 1,
  sprite: {
    infirmary: [1000, 6850],
    slums: [7850, 3680],
    undergroundBase: [12000, 17970],
    communicationTower: [34000, 9420]
  },
  onfade: (id) => {
    // if (ambiantSound.volume({ id }) === 0)
    //   ambiantSound.stop({ id });
  }
});


var cameraZoomIn = new Howl({
  src: ['/sound/camera_zoomin.wav'],
  autoplay: false,
  loop: false,
  volume: 0.35,
});

var cameraZoomOut = new Howl({
  src: ['/sound/camera_zoomout.wav'],
  autoplay: false,
  loop: false,
  volume: 0.35,
});




const Camera = () => {
  const { camera } = useThree()
  const [interfaceState, setInterfaceState] = useAtom(interfaceAtom)
  const [loadedAudio, setLoadedAudio] = useAtom(loadedAudioAtom)
  const [camInTransition, setCamInTransition] = useAtom(camInTransitionAtom)
  const [cameraState, setCameraState] = useAtom(camAtom)
  const orbitalControlRef = useRef();
  const soundRef = useRef();
  const firstRun = useRef(true);
  const previousSound = useRef(undefined);
  const zoomLevel = useRef(0);

  const updateZoom = () => {
    if (zoomLevel.current === ZOOM.Area) {
      const setZoomToSection = () => {
        let section = overworldMarkers.find(section => section.type === cameraState.activeMarker.section);
        setCameraState(prev => {
          return {
            ...prev,
            activeMarker: section,
            position: section.data.position,
            target: section.data.target,
            zoom: ZOOM.Overworld
          }
        })
      }
      setZoomToSection();
    }
  }

  const handleRightClick = useCallback((event) => {
    event.preventDefault();
    // if (cameraState.zoom === ZOOM.Building) {
    //   let area = areaMarkers.find(area => area.type === cameraState.activeMarker.areaType);
    //   setCameraState(prev => {
    //     return {
    //       ...prev,
    //       position: area.data.position,
    //       target: area.data.target,
    //       zoom: ZOOM.Area,
    //       activeMarker: area
    //     }
    //   })

    //   setInterfaceState(prev => {
    //     return {
    //       ...prev,
    //       player: {
    //         ...prev.player,
    //         main: false,
    //         mini: true
    //       },
    //       quests: {
    //         ...prev.quests,
    //         main: false,
    //         mini: true,
    //         selected: -1
    //       }
    //     }
    //   })
    //   cameraZoomIn.play();
    // }

    updateZoom();
  }, [cameraState])

  useEffect(() => {
    zoomLevel.current = cameraState.zoom;
    console.log('cameraState', cameraState)
    if (firstRun.current === false) {
      handleCamTransitionState(cameraState);
    }
    else {
      firstRun.current = false;
    }

    const disabledRightClick = window.addEventListener('contextmenu', (event) => {
      handleRightClick(event)
    });
    return () => { window.removeEventListener('contextmenu', disabledRightClick) }
  }, [cameraState])

  const handleCamTransitionState = (marker) => {
    cameraZoomIn.play();

    const { position, target } = marker;

    if (marker.activeMarker.zoom === ZOOM.Building) {
      const disableInterface = () => {
        setInterfaceState(prev => {
          return {
            ...prev,
            player: {
              ...prev.player,
              main: false,
              mini: false
            },
            quests: {
              ...prev.quests,
              main: false,
              mini: false,
              selected: -1
            }
          }
        })
      }

      disableInterface();
    }


    if (previousSound.current !== undefined) {
      // console.log('fading out old sound', previousSound.current)
      ambiantSound.fade(1, 0, 1000, previousSound.current);
    }

    if (marker.activeMarker.audioId !== undefined) {
      const fadeOldPlayNewSound = () => {
        let current = ambiantSound.play(marker.activeMarker.audioId);
        previousSound.current = current;
        ambiantSound.fade(0.0, 1.0, 1000, current);
      }
      fadeOldPlayNewSound();
    } else {
      previousSound.current = undefined;
    }

    const onStart = () => { };
    const onComplete = () => { };
    const onUpdate = () => { };

    var tl = gsap.timeline();
    tl.to(camera.position, { x: position.x, y: position.y, z: position.z, duration: 1.2, ease: 'power2.easeIn' })
    tl.to(orbitalControlRef.current.target, { x: target.x, y: target.y, z: target.z, duration: 1.2, ease: 'power2.easeIn' }, '-=1.2')
    tl.play();
  }

  // const handleRightClick = useCallback(event => {
  //   console.log('rightclick')
  //   event.preventDefault()

  //   console.log('selected before update is', selected)
  //   if (selected)
  //     handleCamTransitionState(selected.data)
  //   // if (selected.zoom === ZOOM.Building) {
  //   //   let area = areaMarkers.find(area => area.type === selected.area);
  //   //   setCameraState(prev => {
  //   //     return {
  //   //       ...prev,
  //   //       position: area.data.position,
  //   //       target: area.data.target,
  //   //       zoom: ZOOM.Area
  //   //     }
  //   //   })
  //   // }

  //   // if (selected.zoom === ZOOM.Area) {
  //   //   console.log('selected is', selected);
  //   //   let section = overworldMarkers.find(section => section.type === selected.section);
  //   //   console.log('section iffffffffffffffffffffffff ffs', section)
  //   //   // let area = areaMarkers.find(area => area.type === selected.area);
  //   //   setCameraState(prev => {
  //   //     return {
  //   //       ...prev,
  //   //       position: section.data.position,
  //   //       target: section.data.target,
  //   //       zoom: ZOOM.Overworld
  //   //     }
  //   //   })
  //   // }

  //   // if (cameraState.previousCameraState.length > 0) {
  //   //   console.log('greater than 0')

  //   //   setCameraState(prev => {
  //   //     let newState = { ...prev }
  //   //     let newPosition = newState.previousCameraState.pop();
  //   //     return { ...newState, position: newPosition.position, target: newPosition.target }
  //   //   })
  //   // }

  //   // else if (cameraState.zoomLevel === ZOOM.Area) {
  //   //   setCameraState(prev => { return { ...prev, zoomLevel: ZOOM.Overworld } })
  //   // }
  // }, [])

  let enabled = false

  return <>

    <OrbitControls
      ref={orbitalControlRef}
      enabled={true}
      enableDamping={true}
      makeDefault
      dampingFactor={0.1}
      enableRotate={enabled}
      minDistance={0}
      maxDistance={16000}
      enablePan={enabled}
      enableZoom={enabled}
      maxPolarAngle={Math.PI / 1.8}
      minPolarAngle={Math.PI / 5.2}
      events={true}
    />
  </>
}

export default Camera