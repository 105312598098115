import { useState, useRef, useLayoutEffect } from 'react';
import styled from 'styled-components';

import { PlusSvg, MinusSvg } from 'components/SVG/ui';

const Question = ({ entry }) => {
  const [isOpen, setIsOpen] = useState(false);
  const descriptionRef = useRef();

  useLayoutEffect(() => {
    if (isOpen === true) descriptionRef.current.style.height = `0px`;
    setIsOpen(false);
  }, [entry]);

  const handleClick = e => {
    if (isOpen === false) descriptionRef.current.style.height = `${descriptionRef.current.scrollHeight + 25}px`;
    if (isOpen === true) descriptionRef.current.style.height = `0px`;
    setIsOpen(!isOpen);
  };

  // 13 or 32

  return (
    <Wrapper
      onKeyDown={e => {
        if (e.keyCode === 13 || e.keyCode === 32) {
          e.preventDefault();
          handleClick();
        }
      }}
      tabIndex="0"
    >
      <ListItemTitle isOpen={isOpen} onClick={() => handleClick()}>
        <TitleWrapper>{entry.title}</TitleWrapper>
      </ListItemTitle>
      <ListItemAccordianContent aria-hidden={isOpen} ref={descriptionRef} onClick={e => e.stopPropagation()}>
        {entry.description}
      </ListItemAccordianContent>
      <Status>{isOpen ? <MinusSvg /> : <PlusSvg />}</Status>
    </Wrapper>
  );
};

const Status = styled.div`
  position: absolute;
  right: 20px;
  top: 35px;
  transform: translateY(-50%);
`;

const TitleWrapper = styled.div`
  width: 85%;
  font-weight: 500;
  line-height: 1.75rem;

  @media (max-width: 480px) {
    font-weight: 400;
    width: 80%;
  }
`;

const ListItemTitle = styled.h3`
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 1.45;
  width: 100%;
  padding: 20px  0;

  ${props => props.isOpen && 'color: #fe647f;'}

  &:hover {
    color: #fe647f;
  }

  @media (max-width: 1024px) {
    font-size: 1.3rem;

    &:after {
      width: 10px;
      height: 10px;
    }
  }
`;

const ListItemAccordianContent = styled.div`
  position: relative;
  cursor: default;
  height: 0;
  display: flex;
  overflow: hidden;
  transition: height 0.2s cubic-bezier(0.5, 1, 0.89, 1);
  padding: 0 15% 0 0;
  flex-direction: column;
  font-size: 1.3rem;
  padding-right: 15%;
  line-height: 1.45;
  cursor: text;

  p {
    &:first-child {
      padding-top: 0;
    }
    padding-top: 1rem;
  }

  @media (max-width: 480px) {
    padding-right: 10%;
    line-height: 1.5;
    font-size: 1.25rem;
  }
`;

const Wrapper = styled.li`
  position: relative;
  outline: none;
  display: flex;
  padding-left: 28px;
  /* background: #2a1b2c; */
  background: linear-gradient(#2a1b2c 68px, hsl(293deg 24% 12%) 168px);
  box-shadow: 6px 6px 1px rgba(0,0,0, 0.25);
  flex-direction: column;

  @media (max-width: 480px) {
  }
`;

export default Question;
