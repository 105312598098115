import styled, { keyframes } from "styled-components";
import { Suspense, lazy, useState } from "react";
import { useAtom } from "jotai";
import { gameStateAtom } from "Project/Game/data/atoms";
import { GAMESTATE } from "Project/Game/const";
// import Avatar from "../../MainHUD/Avatar";

const Avatar = lazy(() => import("../../MainHUD/Avatar"));

const MenuChoices = () => {
  const [gameState, setGameState] = useAtom(gameStateAtom);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (choice) => {
    if (choice === 0 && isClicked === false) {
      setIsClicked(true);
      setTimeout(() => {
        setGameState(GAMESTATE.MainGame);
      }, 2000);
    }
  }

  return (
    <Wrapper isClicked={isClicked}>
      <WrapSelection onClick={() => handleClick(0)}>
        <WrapAvatar >
          <Suspense fallback={<div></div>}>
            <Avatar />
          </Suspense>
        </WrapAvatar>
        <WrapText>
          <Name>Ylvis</Name>
          <Class>Level 3 Nethacker</Class>
        </WrapText>
      </WrapSelection>
      <WrapSelection2 />
      <WrapSelection3 />
      {/* <Options>
        <Avatar />
        <Choice onClick={() => handleClick(0)}>New Game</Choice>
        <Choice onClick={() => handleClick(1)}>Continue</Choice>
      </Options> */}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  filter: brightness(1);
  opacity: 1;
  row-gap: 20px;
  cursor: pointer;
  ${props => props.isClicked && `filter: brightness(0); opacity: 0; cursor: default !important;`}
  transition: all 1.5s cubic-bezier(0.175, 0.92, 0.1, 1);
`

const WrapText = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  padding: 2rem;
`

const Class = styled.div`
  font-family: var(--primary-text-font);
  font-size: 1.5rem;
  padding: 0rem;
`

const Name = styled.div`
  font-family: var(--primary-text-font);
  font-size: 4rem;
`

const animText = keyframes`
  0% {
    transform: translate(0px, 0);
  }
  100% {
    transform: translate(30px, 0);
  }
`

const animAvatar = keyframes`
  0% {
    filter: brightness(1);
    transform: translate(0px, 50%) scale(1);
  }
  100% {
    filter: brightness(0.9);
    transform: translate(0, 50%) scale(1);
  }
`


const animIn = keyframes`
  0% {
    transform: translate(0px, 20px) scale(0.99);
    opacity: 0.4;
  }
  100% {
    transform: translate(0px, 0) scale(1);
    opacity:1;
  }
`

const WrapSelection3 = styled.div`
  display: flex;
  width: 800px;
  height: 90px;
  column-gap: 20px;
  position: relative;
  align-items: center;
  border: 5px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  opacity: 0;
  animation: ${animIn} 0.25s 0.15s cubic-bezier(0.175, 0.92, 0.1, 1) forwards;

  &:hover {
    border: 5px solid rgba(255, 255, 255, 0.4);
  }

  &:active {
    border: 5px solid rgba(255, 255, 255, 0.18);
  }
`

const WrapSelection2 = styled.div`
  display: flex;
  width: 800px;
  height: 90px;
  column-gap: 20px;
  position: relative;
  align-items: center;
  border: 5px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  opacity: 0;
  animation: ${animIn} 0.3s 0.1s cubic-bezier(0.175, 0.92, 0.1, 1) forwards;

  &:hover {
    border: 5px solid rgba(255, 255, 255, 0.4);
  }

  &:active {
    border: 5px solid rgba(255, 255, 255, 0.18);
  }
`



const WrapSelection = styled.div`
  display: flex;
  width: 800px;
  height: 200px;
  column-gap: 20px;
  position: relative;
  align-items: center;
  border: 5px solid rgba(255, 255, 255, 0.2);
  overflow: hidden;
  opacity: 0;
  animation: ${animIn} 0.3s cubic-bezier(0.175, 0.92, 0.1, 1) forwards;

  &:hover {
    border: 5px solid rgba(255, 255, 255, 0.4);
    scale: 1.05;


  }

  &:active {
    border: 5px solid rgba(255, 255, 255, 0.18);
  }
`

const WrapAvatar = styled.div`
  position: relative;
  top: 20px;
  left: 40px;
  width: 360px;
  height: 100%;
`

const Choice = styled.li`
  font-size: 2rem;
  cursor: pointer;
`

const Options = styled.ul`
  font-family: var(--primary-text-font);
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`

const Container = styled.div``
const Wrapped = styled.div``

export default MenuChoices;