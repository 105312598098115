import { useContext } from 'react';

import styled from 'styled-components';
import Firo from 'Project/Footer/Firo';

import LanguageSelector from 'components/LanguageSelector';
import { LanguageContext } from 'containers/Language';

import faqFoxes from 'App/assets/images/landing/world/faq-foxes.png';

const Footer = () => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <Wrapper>
      <Container>
        <Foxes src={faqFoxes} alt="" />
        <Firo />
        <LinkWrapper>
          <LinkList>
            <Link>
              <LanguageSelector />
            </Link>
            <Link>Privacy Policy</Link>
            <Link>firo.org</Link>
          </LinkList>
        </LinkWrapper>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  position: relative;
  max-width: 1250px;
`;

const Foxes = styled.img`
  position: absolute;
  top: -80px;
  right: 100px;
  z-index: 20;
`;

const WrapSelector = styled.div`
  display: flex;
  gap: 2px;
`;

const Link = styled.li`
  display: flex;
  cursor: pointer;
  align-items: center;
  &:hover {
    color: #fe647f;
  }

  @media (min-width: 1024px) {
    padding: 5px;
  }
`;

const LinkList = styled.ul`
  display: flex;
  column-gap: 25px;
  margin: 0 10%;
  @media (max-width: 480px) {
    justify-content: center;
  }
`;

const LinkWrapper = styled.div`
  display: flex;
  padding: 145px 0 45px 0;
  justify-content: flex-end;
  align-items: center;
  height: 0px;
  max-width: var(--table-break);
  color: #fff;

  @media (max-width: 480px) {
    justify-content: center;
  }
`;

const Wrapper = styled.footer`
  position: relative;
  display: flex;
  row-gap: 30px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  /* background-color: #c94747; */
  /* background-color: #ae3c4d; */
  /* background-color: #c62840; */
  background: #100b11;
`;

export default Footer;
