import styled from 'styled-components';
import Svg from 'components/SVG';

export const MinusSvg = () => (
  <Minus viewBox="0 0 24 24">
    <path transform="rotate(-90 3 14)" d="M3 14H7V32H3z"></path>
  </Minus>
);

export const PlusSvg = () => (
  <Plus viewBox="0 0 24 24">
    <path fillRule="evenodd" clipRule="evenodd" d="M14 3h-4v7H3v4h7v7h4v-7h7v-4h-7V3z"></path>
  </Plus>
);

export const GlobeSvg = () => (
  <Globe viewBox="0 0 64 64">
    <path d="M32,7.448C18.461,7.448,7.449,18.462,7.449,32c0,13.538,11.013,24.552,24.551,24.552   c13.538,0,24.551-11.014,24.551-24.552C56.551,18.462,45.538,7.448,32,7.448z M39.68,45.697v-5.55h1.261v5.55H39.68z M53.235,32   c0,0.351-0.036,0.694-0.053,1.041l-0.41-0.41l-3.152,3.152l-3.082-3.082h-2.601l-0.963,0.961l-4.557-4.556v-2.153h-4.438   l-2.544-2.544l-3.129,3.13h3.349l1.306,1.306H36.6l5.904,5.904v1.87l-3.528,3.528v5.746l-4.292,4.292h-1.794l-2.765-2.764v-7.469   l-1.939-1.941h-3.104l-3.201-3.199v-3.491l4.509-3.782v-4.123l7.275-7.273l2.182,2.182h5.044l3.525-3.525   C49.751,18.657,53.235,24.914,53.235,32z M18.312,15.778c0.73-0.616,1.506-1.176,2.316-1.69h9.044l-4.511,4.51h-2.989l-2.12,2.12   h-1.74V15.778z M15.335,18.865v4.212l-3.965,3.965C12.097,24.011,13.465,21.235,15.335,18.865z M10.865,33.997l9.417,7.119v3.492   l-2.803,2.859C13.811,44.021,11.36,39.299,10.865,33.997z"></path>
  </Globe>
);

export const SelectArrowsSVG = () => (
  <SelectArrows viewBox="0 0 1000 1000">
    <path d="M500,10L227.8,391.1h544.4L500,10z M500,990l272.2-381.1H227.8L500,990L500,990z" />
  </SelectArrows>
);

export const ChevronSvg = () => <Chevron viewBox="0 0 32 32">
  <g>
    <line x1="16" x2="7" y1="20.5" y2="11.5" />
    <line x1="25" x2="16" y1="11.5" y2="20.5" />
  </g>
</Chevron>

const Chevron = styled(Svg)`
  height: 22px;
  width: 22px;
  stroke-width: 2px;
  stroke: grey;
`

const SelectArrows = styled(Svg)`
  fill: #fff;
  width: 14px;
  stroke: #fff;
  height: auto;
`;

const Globe = styled(Svg)`
  fill: #b3b3b3;
  width: 18px;
`;

const Minus = styled(Svg)`
  fill: #fff;
  width: 20px;
`;

const Plus = styled(Svg)`
  fill: #fff;
  width: 20px;
`;
