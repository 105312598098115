
import React from 'react'
import styled from 'styled-components';
import { Html, useProgress, } from "@react-three/drei";

const Loader = () => {
  const { active, progress, errors, item, loaded, total } = useProgress()
  // return <Html center><TextStyle>{progress.toFixed(0)}%</TextStyle></Html>
  return <Html center><Overlay><WrapText><TextStyle>Loading</TextStyle></WrapText></Overlay></Html>
}

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`

const WrapText = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const TextStyle = styled.div`
  font-size: 2.5rem;
  position: absolute;
  font-weight:600;
  color: #fff;
`

export default Loader