import * as THREE from 'three'

export const canvasOptions = {
  flat: false,
  linear: false,
  shadows: {
    enabled: false,
    // type: THREE.PCFSoftShadowMap
  },
  // 3926, 2237, 3758
  camera: {
    // position: [6776, 2786, 5759],
    position: [3152, 52, 934],
    fov: 45,
    near: 1,
    far: 40000
  }
}

export const perfOptions = {
  deepAnalyze: false,
  overClock: false,
  chart: {
    hz: 60,
    length: 120
  },
  antialias: false,
  minimal: false,
  showGraph: false,
  style: { marginLeft: 30, marginTop: 30, opacity: 1 },
  logsPerSecond: 2,
  position: "top-left",
}

export const fogOptions = {
  attach: "fog",
  args: ['#111111', 500, 1500]
}

export const controlMappingOptions = [
  { name: 'forward', keys: ['ArrowUp', 'w', 'W'] },
  { name: 'forward', keys: ['ArrowUp', 'w', 'W'] },
  { name: 'back', keys: ['ArrowDown', 's', 'S'] },
  { name: 'left', keys: ['ArrowLeft', 'a', 'A'] },
  { name: 'right', keys: ['ArrowRight', 'd', 'D'] },
  { name: 'space', keys: ['Space'] },
  { name: 'r', keys: ['R', 'r'] },
  { name: 'c', keys: ['C', 'c'] },
  { name: 'esc', keys: ['Esc'] },
]