import styled from 'styled-components';
import Svg from 'components/SVG';

export const CloudTopRightSvg = () => (
  <CloudTopRight viewBox="0 0 90 60">
    <path
      d="M77.85,15.95c-4.09,5.49-8.77,11.77-16,16.78c-1.95,1.35-4.65,3-4.83,3.11c-1.11,0.68-3.52,2.12-7,3.94
	C37,46.61,24.07,51.9,24.07,51.9c-6.22,2.53-19.78,7.85-20.06,7.22c-0.13-0.29,2.54-1.91,14.39-7.61c5.04-2.46,8.61-4.12,10.17-4.67
	c0.18-0.06,0.91-0.31,0.94-0.72c0.04-0.48-0.94-0.7-1.72-1.56c-0.65-0.71-0.99-1.68-1-2.25c-0.05-3.49,12.25-8.06,14.83-8.75
	c0.21-0.05,0.99-0.26,1-0.56c0.01-0.35-1.06-0.57-1.83-0.89c-1.7-0.7-3.5-2.5-3.56-3.78c-0.15-3.45,12.18-8.69,18.72-9.44
	c0.31-0.04,2.31-0.26,2.39-0.89c0.08-0.66-2.02-0.99-3.78-2.67c-1.12-1.07-2.37-2.94-2.36-4.44C52.26,5,71.82,1.68,73.18,1.45
	c7.86-1.28,9.3-0.03,9.78,0.72c1.13,1.77-0.25,4.86-1.61,7.89C80.04,12.99,78.57,14.99,77.85,15.95z"
    />
  </CloudTopRight>
);

export const CloudDarkRightSvg = () => (
  <CloudDarkRight viewBox="0 0 70 60">
    <path
      d="M62.76,1.16C57.5-0.89,48.82,5.46,49.32,7.52c0.25,1.07,2.87,0.68,3.32,2.06c0.66,2.02-4.49,4.23-8.16,10.04
	c-1.36,2.16-2.1,4.15-2.51,5.47c-0.43,0.39-1.15,1.03-2.06,1.79c-6.75,5.67-10.87,7.3-16.41,11.48c-1.84,1.39-4.57,3.61-7.62,6.81
	c3.33-0.72,5.92-1.04,6.19-0.36c0.85,2.16-22.11,12.86-21.79,13.54c0.19,0.4,8.68-2.96,17.04-6.81c4.54-2.09,12.57-5.97,12.73-5.65
	c0.03,0.06-0.28,0.24-0.72,0.81c-0.33,0.43-1.09,1.55-0.9,1.88C29,49.6,39,45.03,40.44,44.37c5.67-2.6,8.05-4.06,11.48-4.39
	c2.89-0.28,4.33-0.43,5.29-0.27c0.9,0.15,2.17,0.47,3.78,0.08c0,0,0.83-0.19,1.6-0.61C72.75,33.53,71.52,4.56,62.76,1.16z"
    />
  </CloudDarkRight>
);

export const CloudCenterSvg = () => (
  <CloudCenter viewBox="0 0 90 30">
    <ShadowPath
      d="M32.32,21.39c-0.34,0.15-0.77,0.49-0.71,0.71c0.08,0.27,0.91,0.36,1.54,0.08c0.32-0.14,0.77-0.46,0.71-0.68
	C33.79,21.23,32.95,21.11,32.32,21.39z"
    />
    <ShadowPath
      d="M1.74,29.21c0,0.02,0,0.03,0.01,0.05c0.1,0.35,1.29,0.63,7.65-0.52c3.53-0.6,7.18-1.33,10.93-2.23
	c0.98-0.23,1.96-0.48,2.92-0.72c-0.08,0.1-0.64,0.8-0.39,1.31c0.15,0.3,0.51,0.4,0.67,0.44c1.4,0.38,5.77-1.69,6.47-2.03
	c-0.25,0.56-0.49,1.26-0.24,1.47c0.07,0.06,0.17,0.06,0.21,0.06c6.15-0.07,10.5-1.1,10.5-1.1c3.54-0.84,3.12-0.7,4.32-1.02
	c1.04-0.28,3.36-0.93,6.44-2.06c3.99-1.46,5.69-2.42,6.8-3.13c1.45-0.93,2.99-2.12,3.22-3.86c0.03-0.22,0.03-0.4,0.03-0.53
	c0.67-0.1,1.64-0.32,2.72-0.83c0.6-0.29,0.9-0.43,0.89-0.56c-0.06-0.5-4.21-0.48-7-0.33c-2.01,0.1-5.5,0.39-11.33,1.67
	c0,0-11.01,2.42-11.67,4.86c-0.16,0.59,0.06,0.97,0.06,0.97c0.27,0.48,0.92,0.59,1.19,0.64c1.82,0.31,6.43-2.18,6.92-2.44
	c-0.57,0.04-1.14,0.08-1.72,0.11c-0.26,0.02-0.52,0.03-0.78,0.05c1.44-0.61,2.65-1.08,3.55-1.41c0.54-0.2,4.88-1.78,8.17-1.39
	c0.27,0.03,0.7,0.1,0.81,0.36c0.28,0.7-1.92,2.19-2.47,2.57c-2.32,1.57-4.83,2.51-7.75,3.17c-2.71,0.61-5.02,0.83-6.92,0.92
	c-3.31,0.16-12.17,0.28-12.42-1.29c-0.17-1.09,3.9-2.51,10.81-4.94c7.19-2.53,13.04-3.95,16.39-4.69c1.72-0.38,3.72-0.82,6.44-1.28
	c6.41-1.07,18.94-2.59,19.58-0.31c0.04,0.16,0.07,0.35-0.17,0.78c-2.62,4.72-15.11,9.03-15.11,9.03c-1.23,0.42-2.2,0.73-2.19,0.75
	c0.01,0.04,4.42-1.19,9.22-3c3.68-1.38,6.63-2.75,8.67-3.75c-0.14,0.33-0.47,1.23-0.14,1.53c0.34,0.3,1.18-0.17,1.81-0.53
	c5.79-3.29,6.82-4.7,7.39-5.81c0.4-0.78,0.41-1.08,0.39-1.28c-0.21-1.94-4.25-2.99-5.78-3.33C69.97,3.1,50.38,7.29,50.38,7.29
	c-23.43,5-28.62,7-32.33,9.89c-2.67,2.07-4.41,4.95-8.83,6.44c-2.66,0.9-6.14,1.25-7.19,3.69C1.71,28.05,1.7,28.75,1.74,29.21z"
    />
    <HighlightPath
      d="M5.61,23.39c-0.48,0.17-1.86,0.74-2.87,2.22c-1.05,1.55-1.03,3.12-1.01,3.62c0.93-0.85,2.29-1.91,4.12-2.8
	c1.51-0.74,2.9-1.15,4-1.39c0.23-0.28,0.64-0.77,1.2-1.32c1.44-1.44,3.29-2.75,5.79-3.84C32.88,12.93,52.09,9.06,52.09,9.06
	c5.44-1.05,8.35-1.56,11.36-1.9c5.84-0.65,10.79-0.71,14.38-0.62c2.32-0.05,4.12,0.33,5.29,0.67c2.77,0.8,3.06,1.66,3.12,1.87
	c0.19,0.7-0.18,1.4-0.45,1.81c0.56-0.6,1.5-1.75,1.91-3.26c0.26-0.94,0.16-1.49,0.08-1.79c0,0-0.17-0.59-0.61-1.11
	C85.4,2.67,74.06,2.78,74.06,2.78c-5.25,0.05-6.01,0.08-6.69,0.11C64,3.05,61.57,3.17,58.44,3.69c-3.13,0.53-8.16,1.68-8.19,1.54
	c0,0,0.03-0.06,0.09-0.17c0.03-0.06,0.04-0.09,0.07-0.15c0.05-0.09,0.25-0.57,0.21-1.03c0,0-0.04-0.43-0.38-0.86
	c-2.55-3.3-20.33,2.15-20.33,2.15c-0.08,0.02-16.46,5.04-21.74,11.02c-0.64,0.73-1.03,1.37-1.2,1.67C5.81,19.9,5.6,21.97,5.61,23.39
	z"
    />
    <HighlightPath
      d="M35.17,17.17c-0.11,0.1-1.1,1.01-0.96,2.37c0.09,0.93,0.65,1.52,0.82,1.69c-0.02-0.17-0.02-0.41,0.06-0.69
	c0,0,0.02-0.08,0.06-0.16c0.27-0.66,1.42-2.17,8.23-4c2.17-0.58,2.65-0.66,4.38-1.12c2.33-0.61,2.5-0.69,3.87-0.92
	c2.11-0.36,4.3-0.72,7.25-0.71c2.4,0.01,4.4,0.27,5.84,0.52c0.12-0.09,0.27-0.23,0.33-0.44c0.22-0.79-1.01-1.72-1.24-1.89
	c-0.99-0.74-2.1-0.89-2.82-0.91c-4.88,0.67-8.98,1.51-12.18,2.26c-1.3,0.31-4.08,0.99-7.6,2.04C38.71,15.95,36.65,16.64,35.17,17.17
	z"
    />
  </CloudCenter>
);

export const CloudsLeftSvg = () => (
  <CloudsLeft viewBox="0 0 90 30">
    <path
      d="M38.91,15.32c0.38-0.27,1.02-0.64,1.85-0.82c3.14-0.68,5.62,2.11,9.27,4.03c1.68,0.88,3.98,1.37,8.59,2.35
	c11.12,2.37,27.24,2.92,29.71,2.99c-7.94,0.62-14.66,1.04-19.76,1.32c-6.49,0.36-15.16,0.74-25.34,0.96
	c-9.38,0.2-14.46,0.14-16.47,0.11c-6.12-0.1-10.58-0.32-12.51-0.43c-3.6-0.2-6.71-0.43-9.2-0.64c-1.36-2.1-4.22-7.21-2.6-12.26
	C5.1,4.64,17.84,2.68,25.69,4.23c1.99,0.39,9.53,1.89,12.3,7.81C38.58,13.3,38.81,14.48,38.91,15.32z"
    />
  </CloudsLeft>
);

export const CloudSmallSvg = () => (
  <CloudSmall viewBox="0 0 100 30">
    <path
      d="M1.51,29.56c-0.01-0.08,23.48-2.31,25.11-9.63c0.11-0.51,0.15-1.2,0.68-1.96c1.99-2.86,7.72-2.35,12.64-1.32
	c0.12-0.59,3.08-13.82,10.09-14.83c1.63-0.24,3.4,0.2,5.16,0.64c4.13,1.02,7.21,2.84,9.13,4.2c0.41-0.59,1.04-1.39,1.96-2.19
	c3.19-2.77,8.57-4.41,10.5-2.37c0.36,0.38,0.99,1.3,0.68,3.47c6.79-1.73,13.57-3.47,20.36-5.2c-3.4,2.15-8.08,4.94-13.83,7.85
	c-11.73,5.94-21.22,8.98-28.03,11.14c-13.65,4.32-23.94,6.11-35.01,8.03C4.69,30.2,1.51,29.59,1.51,29.56z"
    />
  </CloudSmall>
);

export const CloudBottomLeftSvg = () => (
  <CloudBottomLeft viewBox="0 0 100 45">
    <path
      d="M5.05,2.68c2.72-1.29,3.94,1.8,17.76,5.86c4.55,1.34,7.92,2.04,11,2.51c4.03,0.61,7.44,0.79,9.91,0.84
	c-0.28,0.04-2.14,0.3-2.25,1.03c-0.1,0.67,1.37,1.4,1.93,1.67c13.18,6.5,48.62,0.34,48.71,0.32l0,0c6.14-1.05,6.44-1.16,6.43-1.16
	c0-0.01-6.33,1.33-15.32,2.81c-4.98,0.82-9.86,2.13-14.81,3.09c-2.96,0.57-6.98,1.32-7.29,3.16c-0.22,1.31,1.46,1.99,1.25,3
	c-0.66,3.19-18.77,1.2-19.37,4.16c-0.19,0.92,1.5,1.43,1.35,2.32c-0.45,2.71-16.1-2.08-29.21,4.83c-6.22,3.28-9.38,7.87-11.78,6.56
	c-1.95-1.06-1.57-5.01-1.29-9.01C3.8,10.89,1.13,4.54,5.05,2.68z"
    />
  </CloudBottomLeft>
);

export const StreakLeftSvg = () => (
  <StreakLeft viewBox="0 0 100 20">
    <path
      d="M2.28,0.32C8.16,3,16.51,6.34,26.87,9.02c12.43,3.22,22.07,4.05,33.81,4.94c9.58,0.72,22.16,1.25,37.06,0.71
	c-6.78,0.99-12.67,1.7-17.41,2.21c-12.31,1.32-22.74,1.94-30.66,2.24c-7.74,0.3-11.64,0.42-16.92,0.26
	c-8.79-0.27-19.13-1.32-30.66-3.93"
    />
  </StreakLeft>
);

export const StreakRightSvg = () => (
  <StreakRight viewBox="0 0 100 80">
    <path
      d="M52.81,46.6c-5.45,3.84-12.26,8.2-13.68,9.11c-15.29,9.8-28.47,17.05-37.88,21.94
	c11.16-4.57,20.66-8.66,28.11-11.94c16.76-7.38,25-11.48,28.5-13.24c6.33-3.19,9.49-4.79,13.05-6.88
	c6.77-3.98,16.22-10.34,26.48-20.31c0.15-6.96,0.29-13.92,0.44-20.88C79.92,25.8,63.85,38.83,52.81,46.6z"
    />
  </StreakRight>
);

export const SunSvg = () => (
  <Sun viewBox="0 0 512 512">
    <g>
      <g>
        <path d="m256,105.5c-83.9,0-152.2,68.3-152.2,152.2 0,83.9 68.3,152.2 152.2,152.2 83.9,0 152.2-68.3 152.2-152.2 0-84-68.3-152.2-152.2-152.2zm0,263.5c-61.4,0-111.4-50-111.4-111.4 0-61.4 50-111.4 111.4-111.4 61.4,0 111.4,50 111.4,111.4 0,61.4-50,111.4-111.4,111.4z" />
        <path d="m256,74.8c11.3,0 20.4-9.1 20.4-20.4v-23c0-11.3-9.1-20.4-20.4-20.4-11.3,0-20.4,9.1-20.4,20.4v23c2.84217e-14,11.3 9.1,20.4 20.4,20.4z" />
        <path d="m256,437.2c-11.3,0-20.4,9.1-20.4,20.4v22.9c0,11.3 9.1,20.4 20.4,20.4 11.3,0 20.4-9.1 20.4-20.4v-22.9c0-11.2-9.1-20.4-20.4-20.4z" />
        <path d="m480.6,235.6h-23c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h23c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4z" />
        <path d="m54.4,235.6h-23c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h22.9c11.3,0 20.4-9.1 20.4-20.4 0.1-11.3-9.1-20.4-20.3-20.4z" />
        <path d="M400.4,82.8L384.1,99c-8,8-8,20.9,0,28.9s20.9,8,28.9,0l16.2-16.2c8-8,8-20.9,0-28.9S408.3,74.8,400.4,82.8z" />
        <path d="m99,384.1l-16.2,16.2c-8,8-8,20.9 0,28.9 8,8 20.9,8 28.9,0l16.2-16.2c8-8 8-20.9 0-28.9s-20.9-7.9-28.9,0z" />
        <path d="m413,384.1c-8-8-20.9-8-28.9,0-8,8-8,20.9 0,28.9l16.2,16.2c8,8 20.9,8 28.9,0 8-8 8-20.9 0-28.9l-16.2-16.2z" />
        <path d="m99,127.9c8,8 20.9,8 28.9,0 8-8 8-20.9 0-28.9l-16.2-16.2c-8-8-20.9-8-28.9,0-8,8-8,20.9 0,28.9l16.2,16.2z" />
      </g>
    </g>
  </Sun>
);

const HighlightPath = styled.path`
  fill: var(--sky-cloud-highlight);
`;

const ShadowPath = styled.path`
  fill: var(--sky-cloud-shadow);
`;

const Sun = styled(Svg)`
  width: 30px;
  fill: #fff;
`;

const StreakRight = styled(Svg)`
  position: absolute;
  width: clamp(450px, 60vw, 1200px);
  fill: var(--sky-cloud-streak);
  /* top: clamp(-600px, calc(-25vw - 5vh), -400px); */
  top: clamp(-300px, -25vw, -100px);
  right: clamp(-350px, calc(-50vw - 5vh), -150px);
`;

const StreakLeft = styled(Svg)`
  position: absolute;
  width: clamp(450px, 50vw, 1200px);
  fill: var(--sky-cloud-streak);
  left: clamp(-400px, -62vw, -200px);
  top: clamp(0px, calc(37vw + 12vh), 550px);
`;

const CloudSmall = styled(Svg)`
  position: absolute;
  fill: var(--sky-cloud-shadow);
  width: clamp(300px, 55vw, 500px);
  left: calc(32.5% - 100px);
  top: clamp(300px, calc(22vw + 10vh), 500px);

  @media (max-width: 480px) {
    display: none;
  }
`;

const CloudsLeft = styled(Svg)`
  width: clamp(500px, 45vw, 800px);
  position: absolute;
  /* left: -300px; */
  left: clamp(-500px, -12vw, -100px);
  fill: var(--sky-cloud-shadow);
  top: clamp(0px, 40vw + 10vh, 750px);

  @media (max-width: 480px) {
    /* display: none; */
    top: 50vh;
    width: 300px;
    left: -15vw;
  }
`;

const CloudCenter = styled(Svg)`
  width: clamp(1000px, calc(70vw + 30vh), 2000px);
  height: auto;
  position: absolute;
  top: -2vw;
  /* left: 12.5%; */
  left: clamp(0, 12vw, 1000px);
  transform: translateX(-20%);

  @media (max-width: 480px) {
    width: 1200px;
    left: -200px;
    top: -150px;
  }
`;

const CloudTopRight = styled(Svg)`
  width: clamp(800px, 50vw, 1100px);
  fill: var(--sky-cloud-shadow);
  position: absolute;
  top: clamp(0px, 5vw, 100px);
  right: clamp(-550px, -40vw, -450px);

  @media (max-width: 480px) {
    top: 40vh;
    width: 700px;
  }
`;

const CloudDarkRight = styled(Svg)`
  fill: var(--sky-streak);
  width: clamp(500px, 65vw, 1000px);
  position: absolute;
  bottom: 40vh;
  z-index: 0;
  right: -200px;
`;

const CloudBottomLeft = styled(Svg)`
  position: absolute;
  fill: var(--sky-streak);
  width: clamp(500px, 75vw, 1500px);
  left: -100px;
  bottom: 30vh;
  z-index: 0;
`;
