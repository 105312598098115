import React, { useRef, useState, useLayoutEffect, useEffect, Fragment, useMemo } from 'react'
import { House2Model } from "../../Models/New/Newest/House2";
import { House2aModel } from "../../Models/New/Newest/House2a";
import { House2bModel } from "../../Models/New/Newest/House2b";
import { House2cModel } from "../../Models/New/Newest/House2c";
import * as THREE from "three";
import { BakeShadows, CurveModifier } from "@react-three/drei";
import { useThree, useFrame } from "@react-three/fiber";
import { useAtom } from 'jotai';
import Cloud from "./Cloud";
import cloudData from "./Cloud/data";
import { ZOOM } from 'Project/Game/const';

const Models = () => {
  const { camera, scene, gl } = useThree()
  const curveRef = useRef();
  const curveRef2 = useRef();
  const curveRef3 = useRef();

  // useFrame(() => {
  //   console.log(camera)
  // })

  let handlePos = [
    new THREE.Vector3(-2505, 950, 222),
    new THREE.Vector3(-2600, 950, 622),
    new THREE.Vector3(-2400, 950, 422),
  ]

  let handlePos2 = [
    new THREE.Vector3(70, 155, -510),
    new THREE.Vector3(150, 155, -750),
    new THREE.Vector3(250, 155, -930),
  ]

  let handlePos3 = [
    new THREE.Vector3(-1505, 950, 22),
    new THREE.Vector3(-1600, 950, 22),
    new THREE.Vector3(-1400, 950, 22),
  ]
  const curve = React.useMemo(() => new THREE.CatmullRomCurve3([...handlePos], true, 'centripetal', 0.0001), [handlePos])
  const curve2 = React.useMemo(() => new THREE.CatmullRomCurve3([...handlePos2], true, 'centripetal', 0.0001), [handlePos])
  const curve3 = React.useMemo(() => new THREE.CatmullRomCurve3([...handlePos3], true, 'centripetal', 0.0001), [handlePos])


  useFrame(() => {
    if (curveRef.current) {
      curveRef.current.moveAlongCurve(0.0003);
      curveRef2.current.moveAlongCurve(0.0003);
      curveRef3.current.moveAlongCurve(0.0003);
    }
  })

  // useEffect(() => {
  //   if (curveRef.current && meshRef.current) {
  //     let spotlight = new THREE.SpotLight(0xffffff, 15, 100, Math.PI / 2, 0.5, 1);
  //     // console.log('spotlight', spotlight)
  //     spotlight.position.set(0, 25, 100)
  //     spotlight.attach = meshRef.current;
  //     console.log('curveref', curveRef.current)
  //     meshRef.current.attach(spotlight);
  //     scene.add(spotlight);
  //     console.log('mr', meshRef.current);
  //   }
  // }, [])

  return <>
    {/* <CurveModifier ref={curveRef} curve={curve}>
      <group >
        <mesh >
          <boxGeometry args={[20, 20]} />
          <meshPhysicalMaterial emissive='red' emissiveIntensity={5} />
        </mesh>
      </group>
    </CurveModifier>
    <CurveModifier ref={curveRef2} curve={curve2}>
      <group>
        <mesh >
          <boxGeometry args={[20, 20]} />
          <meshPhysicalMaterial emissive='red' emissiveIntensity={1} />
        </mesh>
      </group>
    </CurveModifier>
    <CurveModifier ref={curveRef3} curve={curve3}>
      <group>
        <mesh >
          <boxGeometry args={[20, 20]} />
          <meshPhysicalMaterial emissive='red' emissiveIntensity={2} />
        </mesh>
      </group>
    </CurveModifier> */}
    <BakeShadows />
    <House2Model />
    {/* <House2aModel /> */}
    {/* <House2bModel /> */}
    {/* <House2cModel /> */}
    {/* <Cloud {...cloudData[1]} /> */}
    {/* <Cloud {...cloudData[3]} /> */}
  </>
}

export default Models;