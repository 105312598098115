import foxVulps from 'App/assets/images/landing/world/foxVulps-standardized.png';
import foxMilitas from 'App/assets/images/landing/world/foxMilitias-standardized.png';
import foxNomads from 'App/assets/images/landing/world/foxNomads-standardized.png';
import foxElites from 'App/assets/images/landing/world/foxElites-standardized.png';
import chamber from 'App/assets/images/landing/world/chamber-standardized.png';
import crimsonWeaponry from 'App/assets/images/landing/world/crimsonWeaponry-standardized.png';
import macharia from 'App/assets/images/landing/world/macharia-standardized.png';
import pagoda from 'App/assets/images/landing/world/pagoda-standardized.png';
import foxHacker from 'App/assets/images/landing/world/foxHacker-standardized.png';

const selectables = [
  {
    foreground: foxVulps,
  },
  {
    foreground: foxNomads,
  },
  {
    foreground: foxMilitas,
  },
  {
    foreground: foxElites,
  },
  {
    foreground: foxHacker,
  },
  {
    foreground: chamber,
  },
  {
    foreground: crimsonWeaponry,
  },
  {
    foreground: macharia,
  },
  {
    foreground: pagoda,
  },
];

export default selectables;
