import styled from "styled-components"
import { useState } from "react"
import { PerfHeadless, usePerf } from "r3f-perf"
import { Html } from "@react-three/drei"
import Hud from "./Hud"
import { useThree } from "@react-three/fiber"
import { canvasOptions } from "../options"
import { useAtom } from "jotai"
import { debugOptionsAtom, zoomedAtom } from "../data/atoms"


const DebugHeadless = ({ setPostEnabled, setIsMouseCameraEnabled, postEnabled }) => {
  const [gl, log] = usePerf((s) => [s.gl, s.log])
  const [debugOptions, setDebugOptions] = useAtom(debugOptionsAtom)
  const [isZoomed, setIsZoomed] = useAtom(zoomedAtom)
  const handleButton = (type, state) => setDebugOptions(prev => { return { ...prev, [type]: state } })

  // const handleCameraReset = () => {
  //   camera.position = ({ x: canvasOptions.camera.position.x, y: canvasOptions.camera.position.y, z: canvasOptions.camera.position.z })
  // }

  return <Wrapper>
    <Hud log={log} gl={gl} />
    <Buttons>
      {/* Camera Control */}
      {/* <Button onClick={() => handleButton('postProcessing', !debugOptions.postProcessing)}>{debugOptions.postProcessing ? 'Disable' : 'Enable'} PostProcessing</Button> */}
      {/* <Button disabled={isZoomed.inProgress || isZoomed.complete == true} onClick={() => handleButton('mouseCamera', !debugOptions.mouseCamera)}>{debugOptions.mouseCamera ? 'Lock' : 'Unlock'}</Button> */}
      {/* <Button disabled={isZoomed.inProgress || isZoomed.complete == true} onClick={() => handleButton('resetCamera', !debugOptions.resetCamera)}>Reset</Button> */}
      {/* <Button disabled={isZoomed == true} onClick={() => handleButton('mouseCamera', !debugOptions.mouseCamera)}>Reset Camera</Button> */}
      {/* <input type='number' onChange={handleFov} value={debugOptions.camera.fov} placeholder="40" min={10} max={100} /> */}
    </Buttons>
  </Wrapper>
}

const Buttons = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  opacity: 0.6;
  row-gap: 5px;
`

const Button = styled.div`
  border: 2px solid #fff;
  width: fit-content;
  display: flex;
  justify-content: center;
  padding: 8px;
  width: 100%;
  max-width: 75px;
  border-radius: 4px;
  cursor: pointer;
  opacity: 0.7;
  user-select: none;

  &:hover {
    opacity: 0.9;
  }

  &:active {
    opacity: 1;
  }

  opacity:${props => props.disabled && '0.2'};
  pointer-events: ${props => props.disabled ? 'none' : 'auto'};
`

const FpsText = styled.div``
const DrawCallText = styled.div``
const TriangleText = styled.div``

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding: 22px;
  border-radius: 8px;
  color: #fff;
  margin: 50px;
  line-height: 1rem;
  font-size: 0.8rem;
  opacity: 0.5;
  position: absolute;
  top: 0;
  left: 0;  
  z-index: 9999;
  background: rgba(0,0,0,0.5);
`

export default DebugHeadless