import { Suspense, lazy } from 'react';
import styled, { keyframes } from 'styled-components';




const Dapp = lazy(() => import('./Dapp'));

const Modal = ({ mint, setMint, handleReset }) => {
  window.Buffer = window.Buffer || require("buffer").Buffer;



  return (
    <Suspense fallback={<LoadingTemp></LoadingTemp>}>
      <Dapp mint={mint} setMint={setMint} handleReset={handleReset} />
    </Suspense>
  );
};

const LoadingTemp = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 15;
`

export default Modal;
