
import React, { useRef, useEffect } from "react";
import { useGLTF } from "@react-three/drei";
// import house1Glb from 'App/assets/model/new/newest/house1.glb'
import house2Glb from 'App/assets/model/new/newest/house16.glb'
import * as THREE from 'three';
import { useTexture } from "@react-three/drei";
import { MeshStandardMaterial, Mesh, ShaderMaterial, UniformsLib, Color, MeshToonMaterial, DoubleSide, MeshPhysicalMaterial, FrontSide, BackSide } from 'three';
import threeToneGradientJpg from 'App/assets/3d/texture/gradient/threeTone.jpg'
import { useThree } from "@react-three/fiber";
import fiveToneGradientJpg from 'App/assets/3d/texture/gradient/fiveTone.jpg'

export function House2Model(props) {
  const { camera, gl, scene, size } = useThree();
  const { nodes, materials } = useGLTF(house2Glb);
  // const toonMaterial = new MeshToonMaterial({ color: '#ffffff', map: nodes.mesh_0.material.map });
  const toonMaterial = new MeshPhysicalMaterial({ map: nodes.mesh_0.material.map, clearcoat: 0, clearcoatRoughness: 1, metalness: 0.3, roughness: 1, reflectivity: 0.8, ior: 1, opacity: 1, transparent: false, side: FrontSide });
  // const toonMaterial2 = new MeshPhysicalMaterial({ map: nodesLights.mesh_0.material.map, emissive: true, emissiveIntensity: 1, color: 'yellow', side: FrontSide });


  const meshRef = useRef();
  const raycaster = new THREE.Raycaster();
  const pointer = new THREE.Vector2();

  useEffect(() => {
    function onPointerMove(event) {
      pointer.x = (event.clientX / gl.domElement.clientWidth) * 2 - 1;
      pointer.y = - (event.clientY / gl.domElement.clientHeight) * 2 + 1;
      raycaster.setFromCamera(pointer, camera);
      const intersects = raycaster.intersectObject(meshRef.current);
      if (intersects.length > 0) console.log(intersects[0].point)
    }

    // window.addEventListener('pointermove', onPointerMove);
    // return () => {
    //   window.removeEventListener('pointermove', onPointerMove);
    // }
  }, [])


  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_0.geometry}
        material={toonMaterial}
        position={[50392.99, -3510.06, -56080.49]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={[38613.9, 18726.89, 21472.25]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_1.geometry}
        material={toonMaterial}
        position={[35165.24, -3510.06, -93545.11]}
        rotation={[0, -Math.PI / 4, 0]}
        scale={[38613.9, 18726.89, 21472.25]}
      />
      <mesh
        ref={meshRef}
        castShadow
        receiveShadow
        geometry={nodes.mesh_2.geometry}
        material={toonMaterial}
      />
    </group>
  );
}

useGLTF.preload(house2Glb);





// import React, { useRef } from "react";
// import { useGLTF } from "@react-three/drei";
// // import house1Glb from 'App/assets/model/new/newest/house1.glb'
// import house2Glb from 'App/assets/model/new/newest/house9.glb'
// import { useTexture } from "@react-three/drei";
// import { MeshStandardMaterial, Mesh, ShaderMaterial, UniformsLib, Color, MeshToonMaterial, DoubleSide, MeshPhysicalMaterial, FrontSide, BackSide } from 'three';
// import threeToneGradientJpg from 'App/assets/3d/texture/gradient/threeTone.jpg'
// import fiveToneGradientJpg from 'App/assets/3d/texture/gradient/fiveTone.jpg'

// export function House2Model(props) {
//   const { nodes, materials } = useGLTF(house2Glb);
//   // const toonMaterial = new MeshToonMaterial({ color: '#ffffff', map: nodes.mesh_0.material.map });
//   const toonMaterial = new MeshPhysicalMaterial({ map: nodes.mesh_0.material.map, clearcoat: 0, clearcoatRoughness: 1, metalness: 0.3, roughness: 1, reflectivity: 0.8, ior: 1, opacity: 1, transparent: false, side: FrontSide });
//   // const toonMaterial2 = new MeshPhysicalMaterial({ map: nodesLights.mesh_0.material.map, emissive: true, emissiveIntensity: 1, color: 'yellow', side: FrontSide });

//   return (
//     <group scale={.8} {...props} dispose={null}>
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes.mesh_0.geometry}
//         material={toonMaterial}
//       />

//     </group>
//   );
// }

// useGLTF.preload(house2Glb);

