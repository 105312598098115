import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState } from "react";
import dialogueFoxImg from 'App/assets/3d/ui/dialogue-fox1.png';
import Delayed from "../../components/Delayed";
import { Howl } from "howler";
const dialogueTest = [
  'Aliquam vulputate elit id dignissim sollicitudin. Phasellus orci arcu, tempus a sagittis at, ornare sit amet metus. Aliquam non felis at diam blandit convallis. Praesent nibh magna, ultrices a ipsum vel, condimentum volutpat justo.  ',
  'Sed venenatis ultrices diam, et iaculis augue molestie nec. Vestibulum et dapibus nisl. Aenean hendrerit aliquet ipsum nec varius. Aliquam vehicula urna in nunc vulputate, eu placerat tellus commodo. Ut non rhoncus metus, id rhoncus dolor. Mauris eu molestie purus. Cras eget tincidunt arcu. ',
  'Aliquam vulputate elit id dignissim sollicitudin. Phasellus orci arcu, tempus a sagittis at, ornare sit amet metus. Aliquam non felis at diam blandit convallis. Praesent nibh magna, ultrices a ipsum vel, condimentum volutpat justo.  ',
  'Suspendisse potenti. Duis ipsum lorem, mollis a lacus nec, convallis ullamcorper ante. Donec porta hendrerit libero vel elementum. Donec at erat ut elit gravida porta nec in leo. Sed vel suscipit massa. Integer iaculis orci at orci pharetra vulputate.',
  'Aliquam vulputate elit id dignissim sollicitudin. Phasellus orci arcu, tempus a sagittis at, ornare sit amet metus. Aliquam non felis at diam blandit convallis. Praesent nibh magna, ultrices a ipsum vel, condimentum volutpat justo.  ',
  'Sed venenatis ultrices diam, et iaculis augue molestie nec. Vestibulum et dapibus nisl. Aenean hendrerit aliquet ipsum nec varius. Aliquam vehicula urna in nunc vulputate, eu placerat tellus commodo. Ut non rhoncus metus, id rhoncus dolor. Mauris eu molestie purus. Cras eget tincidunt arcu. ',
]

var progressSound = new Howl({
  src: ['/sound/dialoguepanel_click.wav'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

var animateIn = new Howl({
  src: ['/sound/dialoguepanel_panelanimatein.wav'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

var animateOut = new Howl({
  src: ['/sound/dialoguepanel_convo_end.wav'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

const DialogueOverlay = ({ data = null, handleQuestEnd }) => {
  const [position, setPosition] = useState(0)
  const [dialogue, setDialogue] = useState([]);

  const recursionTextEl = useRef('');
  const currentLength = useRef(0);
  const scrollContainer = useRef(null);

  const handleButton = (i) => {
    progressSound.play();
    if (position === data.modules[0].text.length - 1) {
      handleQuestEnd(data.id);
      animateOut.play();
    } else {
      let newDialogue = [...dialogue];
      newDialogue.push(data.modules[0].text[i]);
      setDialogue(newDialogue);
      setPosition(prev => prev + 1);
    }
  }

  let showText = function (message, index, interval) {
    if (index < message.length) {
      recursionTextEl.current.textContent = message.slice(0, index);

      setTimeout(function () {
        showText(message, index + 10, interval);
      }, interval);
    }
  }

  useEffect(() => {
    animateIn.play();
    // progressSound.play();
  }, [])


  useEffect(() => {
    if (data !== null && position < data.modules[0].text.length) {
      currentLength.current = data.modules[0].text[position].length / 32;
      showText(data.modules[0].text[position], 0, 10);
      recursionTextEl.current.style.height = `${currentLength.current * 28 + 80}px`;
    }
  }, [position])

  return (
    <Wrapper isVisible={data !== null}>
      <NpcImg src={data.modules[0].npc.img} alt="" />
      <PositionText>
        <NpcName>{data.modules[0].npc.name}</NpcName>
        <NpcDescription>{data.modules[0].npc.description}</NpcDescription>
      </PositionText>
      <Container>
        <ScrollContainer ref={scrollContainer}>
          {/* <UpperGradientOverlay /> */}
          <WrapText>
            <TextLive ref={recursionTextEl} />
          </WrapText>
          <PreviousText>
            {dialogue.map((text, idx) => <Text key={idx}>{text}</Text>)}
          </PreviousText>
        </ScrollContainer>
        <InteractionWindow>
          <ContinueBtn onClick={() => handleButton(position)}>Continue</ContinueBtn>
        </InteractionWindow>
      </Container>
    </Wrapper>
  )
}


const animIn = keyframes`
  0% {  display: none;  }
  100% { display: static; }
`

const PositionText = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 0;
  bottom: 14vh;
  line-height: 3.5rem;
  left: -280px;
`

const animName = keyframes`
  0% { transform: translate(-11px, 0); opacity: 0;}
  100% { transform: translate(0, 0); opacity: 1;}
`

const animFox = keyframes`
  0% { transform: translate(-25px, 0) scale(0.99); opacity: 0.5; filter: brightness(0%); }
  100% { transform: translate(0, 0) scale(1); opacity: 1; filter: brightness(100%); }
`

const NpcDescription = styled.div`
  opacity: 0;
  font-size: 2.5rem;
  font-family: var(--title-font);
  animation: ${animName} 2s 0.6s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`

const NpcName = styled.div`
  font-size: 5.5rem;
  font-family: var(--title-font);
  opacity: 0;
  animation: ${animName} 2s 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  /* transform: translate(50%, -50%); */
`

const NpcImg = styled.img`
  position: absolute;
  bottom: 15vh;
  left: -580px;
  animation: ${animFox} 5s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
  /* transform: translate(50%, -50%); */
`

const UpperGradientOverlay = styled.div`
  position: absolute;
  user-select: none;
  top: 0;
  pointer-events:none;
  right: 0;
  width: 100%;
  margin: 0 40px;
  height: 300px;
  background: linear-gradient(#000 0%, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0) 80%);
`

const WrapText = styled.div`
`

const TextLive = styled.div`
  padding: 40px 0;
  display: block;
`

const Text = styled.div`
  padding: 40px 0;
`

const ScrollContainer = styled.div`
  line-height: 2.6rem;
  font-size: 1.55rem;
  margin-top: 40px;
  padding: 0 40px;
  overflow-y: auto;
  flex-direction: column-reverse;
  height: 100%;
  display: flex;
  -webkit-mask-image: linear-gradient(to top, black 70%, transparent 90%);
  mask-image: linear-gradient(to top, black 70%, transparent 90%);
`

const PreviousText = styled.div`
  color: #555;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: flex-end;
`

const ContinueBtn = styled.button`
  background: red;
  height: 80px;
  width: 300px;
  display: flex;
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;

  &:hover {
    filter: brightness(1.2);
  }

  &:active {
    filter: brightness(0.7);
  }
`

const InteractionWindow = styled.div`
  display: flex;
  margin-top: 80px;
  justify-content: center;
  width: 100%;
  max-height: 400px;
  height: calc(10vw + 10vh);
`

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
`

const Wrapper = styled.div`
  display: flex;
  font-family: var(--primary-text-font);
  align-items: center;
  row-gap: 20px;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  background: rgba(0, 0, 0, 0.6); 
  backdrop-filter: blur(300px);
  width: 100%;
  color: #fff;
  z-index: 12;
  max-width: 800px;
  padding: 0 3vw;
  height: 100%;
  top: 0;
  right: 0;
  animation: ${animIn} 0.5s 2s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;

  /* @media (min-width: 1921px) {
    margin-right: 300px;
  } */
`

export default DialogueOverlay