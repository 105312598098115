import styled from 'styled-components';
import { Link } from 'react-router-dom';

const MainButton = ({ to, children }) => {
  return (
    <Wrapper>
      <Container to={to}>{children}</Container>
    </Wrapper>
  );
};

const Container = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  padding: 13px 15px;
  width: 100%;
  cursor: pointer;
  min-height: 25px;
  min-width: 20px;
  border: 3px solid transparent;
  font-weight: 500;
  justify-content: flex-start;
  column-gap: 8px;
  color: #fff;
  opacity: 0.8;
  outline: none;

  @media (min-width: 1025px) {
    &:hover {
      filter: brightness(2);
    }

    &:active {
      transform: translateY(0.75px);
    }
  }

  @media (min-width: 1025px) {
    &:active,
    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    padding: 15px 1vw;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;

  @media (max-width: 1024px) {
    width: 100%;
    height: clamp(60px, 8vh, 90px);
    cursor: pointer;

    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &:first-child {
      border-top: 0;
    }
  }
`;

export default MainButton;
