import { useState, useEffect } from "react";
import styled from "styled-components";
import { useAtom } from "jotai";
import { questsAtom } from "Project/Game/data/atoms";
import { questData } from "Project/Game/data/gameData";
import { Howl } from "howler";
import QuestPreview from "./QuestPreview";

const TAB = {
  Active: 0,
  Complete: 1,
}

var openMenu = new Howl({
  src: ['/sound/drive_menuopen.wav'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

const ActiveQuests = ({ isActive, selectedQuestId, handleSelect }) => {
  const [questState] = useAtom(questsAtom);
  const [quests, setQuests] = useState([]);

  useEffect(() => {
    let newQuests = [];
    questData.forEach(databaseQuest => {
      questState.forEach(stateQuest => {
        if (databaseQuest.id === stateQuest.id) {
          if (stateQuest.endTime === null) {
            newQuests.push(databaseQuest);
          }
        }
      })
    })
    setQuests(newQuests);
    console.log('Quest Log is now', newQuests)
  }, [questState])

  // useEffect(() => {
  //   console.log('quests', localIds)
  // }, [localIds])

  return <Quests isActive={isActive}>
    {quests.map(quest => {
      return <Quest onClick={() => handleSelect(quest.id)} isSelected={quest.id === selectedQuestId} key={quest.id}>{quest.title}</Quest>
    })}

    {/* <Quest>Test</Quest>
    <Quest isSelected>Test</Quest>
    <Quest>fda</Quest>
    <Quest>adsfadsf</Quest>
    <Quest>asddfs</Quest>
    <Quest>dda</Quest>
    <Quest>asdfasdfds</Quest>
    <Quest>asdfads</Quest>
    <Quest>asdf</Quest>
    <Quest>asdf</Quest>
    <Quest>adsfdasfsadfds</Quest> */}
  </Quests>;
}

const CompleteQuests = () => {
  return null;
}

const List = ({ selectedQuestId, setInterfaceState, isVisible }) => {
  const [activeTab, setActiveTab] = useState(TAB.Active);
  const handleClick = (selectedTab) => {
    setActiveTab(selectedTab);
  }

  useEffect(() => {
    if (isVisible === true)
      openMenu.play();
  }, [isVisible])

  const handleSelect = (questId) => {
    setInterfaceState(prev => {
      return { ...prev, quests: { ...prev.quests, main: true, selected: questId } }
    })
  }

  return <Wrapper isVisible={true}>
    <Tabs>
      <TabActive isSelected={activeTab === TAB.Active} onClick={() => handleClick(TAB.Active)}>
        <TabBg isSelected={activeTab === TAB.Active} />
        <TabTxt>Active</TabTxt>
      </TabActive>
      <TabComplete isSelected={activeTab === TAB.Complete} onClick={() => handleClick(TAB.Complete)}>
        <TabBg isSelected={activeTab === TAB.Complete} />
        <TabTxt>Complete</TabTxt>
      </TabComplete>
    </Tabs>
    <BodyWrapper>
      <Background />
      <Body>
        <ActiveQuests handleSelect={handleSelect} selectedQuestId={selectedQuestId} isActive={activeTab === TAB.Active} />
        <CompleteQuests handleSelect={handleSelect} isActive={activeTab === TAB.Complete} />
      </Body>
    </BodyWrapper>
  </Wrapper>;
}

const QuestSelected = styled.div`
  position: absolute;
  height: 100%;
  left: -100px;
  width: 120%;
  background: red;
`

const Tab = styled.div`
  user-select: none;
  position: relative;
  font-size: 1.35rem;
  ${props => props.isSelected ? 'z-index: 445; cursor: default;' : 'top: 10px; cursor: pointer;'}
  padding: 20px 30px;
`

const TabTxt = styled.div`
  position: relative;
  font-family: var(--primary-text-font);
  padding: 5px 15px;
`

const TabBg = styled.div`
  position: absolute;
  width: 100%;
  height: 120%;
  ${props => props.isSelected ? `background: #000; top: 0; left: 0; ` : `background: #313030; top: 0; left: 0;`}
`

const TabActive = styled(Tab)`
  div {
    transform: perspective(1000px) rotate3d(-2, 70, -9, -9deg);
  }
`
const TabComplete = styled(Tab)`
  div {
    transform: perspective(1000px) rotate3d(-2, -40, -9, -4deg);
  }
`

const Background = styled.div`
  background: #000;
  position: absolute;
  top: 0;
  z-index: -1;
  left: 5px;
  width: 100%;
  height: 100%;
  transform: perspective(1000px) rotate3d(-2, -40, -9, -4deg);
`

const BodyWrapper = styled.div`
  padding: 35px 35px 45px 35px;
  /* padding: 25px; */
  position: relative;
  z-index: 520;
`

const Body = styled.div`
  font-family: var(--primary-text-font);
  overflow: hidden scroll;
  /* background: #000; */
  height: 350px;

  /* scrollbar-width: none;
  -ms-overflow-style: none; */

  &::-webkit-scrollbar {
    width: 10px;
    background-color: var(--background-color);
  }

  &:hover {
    ::-webkit-scrollbar-track {
      /* border: 5px solid #0b0a19; */
      background-color: #222;
    }

    ::-webkit-scrollbar {
      width: 24px;
      background-color: #f5f5f5;
    }

    ::-webkit-scrollbar-thumb {
      border: 7px solid #222;
      width: 10px;
      background-color: #ccc;
    }
  }
`

const Quest = styled.div`
  position: relative;
  padding: 0 20px;
  cursor: pointer;
  display: flex;
  &:hover {
    ${props => props.isSelected ? 'background: #e35353;' : 'background: #111'};
  }
  ${props => props.isSelected && `background: #D64C4C;`}
  align-items: center;
  height: 60px;
  font-size: 1.25rem;
  /* &:before {
    content: '';
    position: absolute;
    top: 0;
    left: -30px;
    ${props => props.isSelected && `background: #D64C4C;`}
    width: 115%;
    height: 70px;
  }

  &:after {
    content: 'Test';
    display: flex;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  } */
`
const Quests = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.isActive ? `display: flex;` : 'display: none;'}
`

const Tabs = styled.div`
display: flex;
position: relative;
left: 15px;
`

const Wrapper = styled.div`
color: #fff;
${props => props.isVisible ? '' : 'display: none;'}
font-family: var(--primary-text-font);
/* position: absolute;
top: 0; */
height: 350px;
width: 500px;
`

export default List