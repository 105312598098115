import { useEffect, useRef, useState } from "react"
import { Html } from "@react-three/drei"
import { useAtom } from "jotai"
import styled, { keyframes } from "styled-components"
import { MarkerBackgroundSvg, markers } from "./MarkerSvg"
import { npcAtom, zoomedAtom } from "../../../data/atoms"
import { ZOOM } from "Project/Game/const"
import dialogueFoxImg from 'App/assets/3d/ui/dialogue-fox1.png';
import dialogueFox2Img from 'App/assets/3d/ui/dialogue-fox2.png';
import { Howl } from "howler"
import { userQuests } from "Project/Game/data/gameData"
import { questsAtom } from "../../../data/atoms"
import { findQuest } from "../../NewModule/helpers"

var mouseOverSound = new Howl({
  src: ['/sound/inv_item_mouseover.wav'],
  autoplay: false,
  loop: false,
  volume: 0.4,
});

const MapButton = ({ state = 'Open', name, description, handleOpen, audioId, isDisabled = false, position, boxTransforms }) => {
  const [hovered, setHovered] = useState(false)
  const [npc, setNpc] = useAtom(npcAtom)
  const [show, setShow] = useState(false);
  const timer = useRef();
  const [quests, setQuests] = useAtom(questsAtom)

  if (state == 'Timer')
    isDisabled = true;

  // useEffect(() => {
  //   // let quest = findQuest(id);
  //   // console.log(`quest for marker ${id} is`, quest)
  //   console.log(`State for ${name} is ${state}`)
  // }, [])


  useEffect(() => {
    if (isDisabled === false) {
      if (hovered) {
        mouseOverSound.play();
        timer.current = setTimeout(() => {
          setShow(true)
        }, 200)
      } else {
        clearTimeout(timer.current)
        setShow(false)
      }
    }
    return () => clearTimeout(timer.current)
  }, [hovered])


  const handleClick = () => {
    // if (name == 'Old Hospital') {
    //   setNpc(prev => {
    //     return { ...prev, name: 'Doctor', text: ['Come back when you need medical assistance.'], img: dialogueFoxImg }
    //   });
    // }

    // if (name == 'Ruined Academy') {
    //   setNpc(prev => {
    //     return { ...prev, name: 'Teacher', text: ['This is an example of simple dialogue.', `Each text bubble is its own entry, flowing sequentially.`, 'And this is the second last line!', ['Final line!']], img: dialogueFox2Img }
    //   });
    // }
    if (isDisabled === false)
      handleOpen();
  }

  return <Html style={{ display: 'flex', justifyContent: 'center', position: 'relative', bottom: 0 }} scale={8} sprite position={position}>
    <Wrapper onMouseOver={() => setHovered(true)} onMouseOut={() => setHovered(false)} onClick={handleClick}>
      {/* <Stick /> */}
      <WrapContent >
        <WrapIcon hovered={hovered && !isDisabled} >
          <MarkerBackgroundSvg isDisabled={isDisabled} hovered={hovered} />
          <IconWrapper isDisabled={isDisabled}>
            <Icon hovered={hovered}>
              {markers[audioId]}
            </Icon>
          </IconWrapper>
        </WrapIcon>
        <WrapText isVisible={hovered && show}>
          <Info>
            <AnimateBackground boxTransforms={boxTransforms}>
              <BackgroundBlack />
              <Header>{name}</Header>
              <Description>{description}</Description>
            </AnimateBackground>
            {/* <Descriptor>Buy food here</Descriptor> */}
          </Info>
        </WrapText>
      </WrapContent>
      {state === 'Timer' &&
        <WrapCombat>
          <CombatSvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.413 49.413"><path fill="#fff" d="M24.706 0a24.707 24.707 0 1 0 24.707 24.706A24.7 24.7 0 0 0 24.706 0Zm5.689 34.878-8.787-6.386a1.2 1.2 0 0 1-.488-.966V10.759a1.2 1.2 0 0 1 1.2-1.2h4.777a1.2 1.2 0 0 1 1.2 1.2v13.718l6.326 4.6a1.2 1.2 0 0 1 .259 1.674l-2.809 3.865a1.2 1.2 0 0 1-1.678.262Z" data-name="Icon awesome-clock" /></CombatSvg>
        </WrapCombat>}
    </Wrapper>
  </Html >
}

const CombatSvg = styled.svg`
  width: 35px;
`

const WrapCombat = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #000;
  border: 6px solid #000;
  border-radius: 50%;
  top: -25px;
  right: -15px;
`

const animDialogue = keyframes`
  0% { transform: rotate3d(15, 15, -5, 1deg); }
  50% {transform: rotate3d(15, 15, -3, -2deg);}
  80% {transform: rotate3d(2, 3, -4, 1deg);}
`

const WrapIcon = styled.div`
  ${props => props.hovered && 'scale: 1.2'};
`

const animText = keyframes`
  0% { opacity: 0; transform: translateX(-5px); }
  100% {opacity: 1; transform: translateX(0);}
`

const WrapText = styled.div`
  ${props => props.isVisible ? 'display: flex' : 'display: none'};
  opacity: 0;
  justify-content: center;
  animation: ${animText} 0.05s cubic-bezier(0.17, 0.57, .13, .97) forwards;
  transform: translateY(10px);  
`

const animInBackground = keyframes`
  0% { opacity: 0; }
  100% {opacity: 1; }
`

const AnimateBackground = styled.div`
  transform: ${props => `perspective(${props.boxTransforms[0]}px) rotateX(${props.boxTransforms[1]}deg) rotateY(${props.boxTransforms[2]}deg) rotateZ(${props.boxTransforms[3]}deg)`};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
    padding-top: 16px;
  padding-left: 25px;
  padding-right: 10px;
  padding-bottom: 12px;
  animation: ${animInBackground} 0.1s cubic-bezier(0.17, 0.97, .13, .97) forwards;
`

const BackgroundBlack = styled.div`
  width: 100%;  position: absolute;
  top: -6px;
  left: 0px;
  height: 100%;
  padding: 7px 21px 7px 15px;
  background: rgba(0,0,0,1);
  /* border: 6px solid #fff; */
`

const WrapContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

const Stick = styled.div`
  position: absolute;
  top: 60px;
  background: rgba(255, 255, 255, 1);
  width: 2px;
  height: 100%;
`

const SvgHome = styled.svg`
  fill: #000;
  width: 35px;
`

const Descriptor = styled.div`
  font-weight: 200;
  padding-top: 5px;
  padding-left: 10px;
  opacity: 0.7;
  font-size: 0.85rem;
`

const animHeader = keyframes`
  0% { opacity: 0.4; transform: translateX(-1px); }
  100% {opacity: 1; transform: translateX(0);}
`

const Header = styled.h3`
  font-weight: 700;
  position: relative;
  white-space: nowrap;
  align-self: flex-start;
  line-height: 1.5rem;
  font-size: 1.45rem;

  user-select: none;  
  opacity: 0.4;
  transform: translateX(-1px);
  animation: ${animHeader} 0.3s 0.015s cubic-bezier(0.17, 0.57, .13, .97) forwards;
`

const Description = styled.h3`
  font-weight: 700;
  position: relative;
  line-height: 1.15rem;
  max-width: 340px;
  width: max-content;
  align-self: flex-start;
  font-size: 0.85rem;
  color: #aaa;
  user-select: none;
  opacity: 0;
  transform: translateX(-1px);
  animation: ${animHeader} 0.5s 0.02s cubic-bezier(0.17, 0.57, .13, .97) forwards;

`

const Icon = styled.div`
  color: #000;
  display: flex;
  /* ${props => props.hovered && 'scale: 1.1'}; */
  pointer-events: default;
  align-items: center;
  justify-content: center;
`

const IconWrapper = styled.div`
  width: 60px;
  height: 60px;
  padding: 5px 10px 15px 10px;
  position: relative;
  scale: 1.1;
  ${props => props.isDisabled ? 'cursor: default;' : 'cursor: pointer;'}
  
  display: flex;
  align-items: center;
  justify-content: center;
`

const Info = styled.span`
  font-family: var(--primary-text-font);
  position: absolute;
  padding: 5px;
  top: -70px;
  left: 50px;

  pointer-events: none;
  justify-content: center;
  align-items: center;
  /* visibility: hidden; */
  color: #fff;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
`

const animIn = keyframes`
  0% { opacity: 0; transform: translateY(5px) scale(0.95); }
  100% {opacity: 1; transform: translateY(0) scale(1);}
`

const Wrapper = styled.div`
  position: absolute;
  justify-content: center;
  bottom: 0;
  display: flex;
  /* height: 150px; */
  column-gap: 30px;
  opacity: 0;
  animation: ${animIn} 0.3s 0.2s cubic-bezier(0.27, 0.57, .03, .97) forwards;
  /* ${props => props.isVisible ? `display: flex` : `display: none`}; */
  /* transform: perspective(150px) rotateX(-2deg) rotateY(6deg) rotateZ(-1deg) scale(1); */

  /* div:nth-child(1):hover {
    span {
      visibility: visible;
    }
  } */
`

export default MapButton;

