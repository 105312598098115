import styled from 'styled-components';
import Svg from 'components/SVG';

export const ArrowLeftSvg = () => (
  <ArrowLeft viewBox="0 0 35 50">
    <ArrowShadow
      d="M20.79,5.82c-5.06,6.86-9.21,13.99-14.27,20.86l14.27,20.14
	l8.87,0l-14-20.68c4.8-7.05,9.2-13.27,14-20.32C27.72,5.84,22.69,5.79,20.79,5.82z"
    />
    <ArrowBody
      d="M16.64,3.6c-5.06,6.86-9.21,13.99-14.27,20.86
	L16.64,44.6l8.87,0l-14-20.68c4.8-7.05,9.2-13.27,14-20.32C23.58,3.63,18.55,3.57,16.64,3.6z"
    />
  </ArrowLeft>
);

export const ArrowRightSvg = () => (
  <ArrowRight viewBox="0 0 35 50">
    <ArrowShadow
      d="M15.39,46.81c5.06-6.86,9.21-13.99,14.27-20.86L15.39,5.81
	l-8.87,0l14,20.68c-4.8,7.05-9.2,13.27-14,20.32C8.45,46.78,13.48,46.84,15.39,46.81z"
    />
    <ArrowBody
      d="M11.24,44.59c5.06-6.86,9.21-13.99,14.27-20.86
	L11.25,3.59l-8.87,0l14,20.68c-4.8,7.05-9.2,13.27-14,20.32C4.31,44.57,9.34,44.62,11.24,44.59z"
    />
  </ArrowRight>
);

const Arrow = styled(Svg)`
  width: 100%;
`;

const ArrowBody = styled.path`
  fill: var(--border);
  stroke-miterlimit: 10px;
  stroke-width: 2px;
  stroke: #fff;
`;

const ArrowShadow = styled.path`
  stroke-width: 2px;
  stroke-miterlimit: 10px;
  stroke: #000;

  @media (max-width: 768px) {
    stroke-width: 4px;
  }
`;

const ArrowLeft = styled(Arrow)``;

const ArrowRight = styled(Arrow)``;
