export const TYPE = {
  'Background': 0,
  'Pattern': 1,
  'Weapon': 2,
  'Tail': 3,
  'Clothing': 4,
  'Shoulder': 5,
  'Ears': 6,
  'Head': 7,
  'Eyes': 8,
  'Eyebrows': 9,
  'Mouth': 10,
  'Facial': 11,
  'Hat': 12,
  'Quest': 13,
  'Empty': 14,
  'All': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
  'Equipment': [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  'Currency': 15
}


export const GAMESTATE = {
  StartScreen: 0,
  MainGame: 1,
}


export const INTERFACE = {
  'Menu': 0,
  'Character': 1,
  'Inventory': 2,
  'Quests': 3,
  'Settings': 4,
  'Debug': 5,
  'Empty': 9,
}

export const SPEAKER = {
  "Player": 1,
  "NPC": 2
}

export const QUALITY = {
  common: '#fff',
  uncommon: '#68d969',
  rare: '#5196d9',
  epic: '#a335ee',
  legendary: '#f0983f',
  artifact: '#e6cc80',
  heirloom: '#e6cc80'
}

export const EXPRESSION = {
  "Wink": 0,
  "SideLook": 1,
  "Sharp": 2,
  "Slanted": 3,
  "Jewel": 4,
  "Closed": 5,
  "Despise": 6,
  "Anger": 7,
}

export const QUEST_TYPE = { "Explore": 1, "Combat": 2 }

export const ZOOM = { "Overworld": 0, "Area": 1, "Building": 2 }
export const MAP_AREA = { "Slums": 0, "Outskirts": 1, "Innercity": 2 }
export const MAP_SECTION = { "OuterRim": 0, "Outercity": 1, "Innercity": 2 }
export const CURRENCY = { "CBDC": 0, "Firo": 1 }
export const CLASS = { "StreetSamurai": 0, "Netrunner": 1, "Netjacker": 2, "Fixer": 3, "Ironhide": 4 }

export const MODULE_TYPE = {
  "None": 0,
  "Description": 1,
  "Dialogue": 2,
  "Response": 3,
  "Combat": 4,
  "PickClass": 5,
  "Shop": 6,
}

// '#d9c7a4' : '#222';