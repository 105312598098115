import styled from 'styled-components';

import { motion } from 'framer-motion';

const AnimateRouteTransition = ({ children }) => {
  return (
    <Wrapper
      initial={{ opacity: 0, y: -50, scale: 0.985 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      // exit={{ opacity: 0, y: -160, scale: 0.95 }}
      transition={{ duration: 0.45 }}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(motion.main)`
  display: flex;
  color: #fff;
  flex-direction: column;
  /* row-gap: 5vw; */
  overflow: hidden;
  width: 100%;
`;

export default AnimateRouteTransition;
