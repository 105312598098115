import { MAP_AREA, ZOOM, TYPE, ITEM, QUEST_TYPE, MAP_SECTION, MODULE_TYPE, EXPRESSION, SPEAKER } from 'Project/Game/const';
import dialogueFoxImg from 'App/assets/3d/ui/dialogue-fox1.png';
import dialogueFox2Img from 'App/assets/3d/ui/dialogue-fox2.png';
import infirmryAudioLoop from 'App/assets/sound/infirmry.mp3'
import comTowerImg from 'App/assets/images/temp/comm_tower_bg.png'
import undergroundBgImg from 'App/assets/images/temp/underground_bg.png'
import undergroundFgImg from 'App/assets/images/temp/underground_fg.png'
import warehouseBgImg from 'App/assets/images/temp/warehouse_bg.png'

import boomerBodyTempImg from 'App/assets/images/temp/npc/boomer-body.png'
import yukiBodyTempImg from 'App/assets/images/temp/npc/yuki-body.png'
import thiefBodyTempImg from 'App/assets/images/temp/npc/thief.png'

import unknownImg from 'App/assets/images/temp/npc/unknown.png'



import boomerEyesDespiseImg from 'App/assets/images/temp/npc/boomer-eyes-despise.png'
import boomerEyesSharpImg from 'App/assets/images/temp/npc/boomer-eyes-sharp.png'
import boomerEyesSidelookImg from 'App/assets/images/temp/npc/boomer-eyes-sidelook.png'

import yukiEyesDespiseImg from 'App/assets/images/temp/npc/yuki-eyes-despise.png'
import yukiEyesMadImg from 'App/assets/images/temp/npc/yuki-eyes-mad.png'
import yukiEyesWinkImg from 'App/assets/images/temp/npc/yuki-eyes-wink.png'


export const audioData = [{
  "id": 0,
  "name": "Infirmry",
  "src": infirmryAudioLoop,
}]

// "Wink": 0,
// "Side Look": 1,
// "Sharp": 2,
// "Slanted": 2,
// "Jewel": 2,
// "Closed": 2,
// "Despise": 2,
// "Anger": 2,

const npcs = [{
  "id": 1,
  "spaces": 10,
  "name": "You",
  "description": "You",
  "color": {
    "name": "#9CAABC",
    "body": "#9CAABC",
  },
  "image": {
    "body": null,
    "expressions": {
      [EXPRESSION.Despise]: null,
      [EXPRESSION.Sharp]: null,
      [EXPRESSION.SideLook]: null,
    }
  }
}, {
  "id": 2,
  "spaces": 20,
  "name": "Unknown",
  "description": "Unknown",
  "color": {
    "name": "#fff",
    "body": "#fff",
  },
  "image": {
    "body": unknownImg,
    "expressions": {
      [EXPRESSION.Despise]: null,
      [EXPRESSION.Sharp]: null,
      [EXPRESSION.SideLook]: null,
    }
  }
}, {
  "id": 3,
  "spaces": 13,
  "name": "Mara",
  "description": "Leader",
  "color": {
    "name": "#3EA263",
    "body": "#fff",
  },
  "img": boomerBodyTempImg,
  "image": {
    "body": boomerBodyTempImg,
    "expressions": {
      [EXPRESSION.Despise]: boomerEyesDespiseImg,
      [EXPRESSION.Sharp]: boomerEyesSharpImg,
      [EXPRESSION.SideLook]: boomerEyesSidelookImg,
    }
  }
}, {
  "id": 4,
  "spaces": 11,
  "name": "Riko",
  "description": "Weaponsmith",
  "color": {
    "name": "#00c3ff",
    "body": "#fff",
  },
  "image": {
    "body": yukiBodyTempImg,
    "expressions": {
      [EXPRESSION.Despise]: yukiEyesDespiseImg,
      [EXPRESSION.Mad]: yukiEyesMadImg,
      [EXPRESSION.Wink]: yukiEyesWinkImg,
    }
  }
}, {
  "id": 5,
  "spaces": 25,
  "name": "Thief Leader",
  "description": "Weaponsmith",
  "color": {
    "name": "#ffae00",
    "body": "#fff",
  },
  "image": {
    "body": thiefBodyTempImg,
    "expressions": {
      [EXPRESSION.Despise]: null,
      [EXPRESSION.Mad]: null,
      [EXPRESSION.Wink]: null,
    }
  }
}]

const questModules = [
  {
    "id": 0,
    "type": MODULE_TYPE.Description,
    "dialogue": [
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Sharp, "text": `Your vision is blurry, and your head is pounding. You find yourself lying on a makeshift bed in a small, dimly lit room. The walls are made of rusty metal sheets, and the room is filled with the sound of distant chatter and the smell of smoke. This doesn't look like the hovel you call home. Your memory is foggy, and you can't quite recall how you ended up here.` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Sharp, "text": `As you try to gather your thoughts, a figure approaches you. It's a woman with a stern expression and a commanding presence.` },
      { "speaker": SPEAKER.NPC, npc: npcs[1], "expression": EXPRESSION.Sharp, "text": `"You're finally awake. You've been out for a while. My name is Mara, and I'm with the Resistance."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"The Resistance? What... Where am I? What happened to me?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[2], "expression": EXPRESSION.Despise, "text": `"We found you unconscious in an alleyway. You were badly beaten, and it seemed like you were being followed. We brought you here, to our hideout, for your safety. But more importantly, we needed to talk to you."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"Talk to me? I don't understand... Why me?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[2], "expression": EXPRESSION.SideLook, "text": `"During our investigation, we discovered that you have a NeuLite implant. We've installed an Inhibitor inside you. It prevents the NeuLite from sending information back to Ging, and instead feeds them false data. You're now off their radar."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"You did what? I... I can't believe this is happening."` },
      { "speaker": SPEAKER.NPC, npc: npcs[2], "expression": EXPRESSION.SideLook, "text": `"I understand this is a lot to take in, but you're not the only one who's been suffering under Ging's control. We've been secretly recruiting people like you – those who have been manipulated and exploited by their technology. We believe you can help us fight back."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"I... I don't know. This is all so sudden. What do you want me to do?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[2], "expression": EXPRESSION.Sharp, "text": `"Our goal is to bring down Ging and put an end to their oppressive regime. We have a plan to infiltrate their heavily guarded data centers, but we need all the help we can get. We believe you have the skills and motivation to join our cause.\n\nAs Mara speaks, you can't help but think about your life under Ging's control. The NeuLite implant, the poverty, and the constant surveillance have taken their toll. Despite your confusion and fear, you feel a burning desire to fight back and reclaim your freedom."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"I... I want to help, but I need some time to think. Can you give me a moment?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[2], "expression": EXPRESSION.SideLook, "text": `"Of course. Take all the time you need. When you're ready, we'll be here to discuss our plans further.\n\nWith a whirlwind of emotions, you reflect on your situation and the possibility of joining the Resistance. The battle against Ging looms on the horizon, and the decision you make will change your life forever."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Sharp, "text": `With a whirlwind of emotions, you reflect on your situation and the possibility of joining the Resistance. The battle against Ging looms on the horizon, and the decision you make will change your life forever.` },
    ]
  },
  {
    "id": 1,
    "type": MODULE_TYPE.Description,
    "dialogue": [
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Sharp, "text": `Your vision is blurry, and your head is pounding. You find yourself lying on a makeshift bed in a small, dimly lit room. The walls are made of rusty metal sheets, and the room is filled with the sound of distant chatter and the smell of smoke. This doesn't look like the hovel you call home. Your memory is foggy, and you can't quite recall how you ended up here.` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Sharp, "text": `As you try to gather your thoughts, a figure approaches you. It's a woman with a stern expression and a commanding presence.` },
      { "speaker": SPEAKER.NPC, npc: npcs[1], "expression": EXPRESSION.Sharp, "text": `"You're finally awake. You've been out for a while. My name is Mara, and I'm with the Resistance."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"The Resistance? What... Where am I? What happened to me?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[2], "expression": EXPRESSION.Despise, "text": `"We found you unconscious in an alleyway. You were badly beaten, and it seemed like you were being followed. We brought you here, to our hideout, for your safety. But more importantly, we needed to talk to you."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"Talk to me? I don't understand... Why me?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[2], "expression": EXPRESSION.SideLook, "text": `"During our investigation, we discovered that you have a NeuLite implant. We've installed an Inhibitor inside you. It prevents the NeuLite from sending information back to Ging, and instead feeds them false data. You're now off their radar."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"You did what? I... I can't believe this is happening."` },
      { "speaker": SPEAKER.NPC, npc: npcs[2], "expression": EXPRESSION.SideLook, "text": `"I understand this is a lot to take in, but you're not the only one who's been suffering under Ging's control. We've been secretly recruiting people like you – those who have been manipulated and exploited by their technology. We believe you can help us fight back."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"I... I don't know. This is all so sudden. What do you want me to do?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[2], "expression": EXPRESSION.Sharp, "text": `"Our goal is to bring down Ging and put an end to their oppressive regime. We have a plan to infiltrate their heavily guarded data centers, but we need all the help we can get. We believe you have the skills and motivation to join our cause.\n\nAs Mara speaks, you can't help but think about your life under Ging's control. The NeuLite implant, the poverty, and the constant surveillance have taken their toll. Despite your confusion and fear, you feel a burning desire to fight back and reclaim your freedom."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"I... I want to help, but I need some time to think. Can you give me a moment?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[2], "expression": EXPRESSION.SideLook, "text": `"Of course. Take all the time you need. When you're ready, we'll be here to discuss our plans further.\n\nWith a whirlwind of emotions, you reflect on your situation and the possibility of joining the Resistance. The battle against Ging looms on the horizon, and the decision you make will change your life forever."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Sharp, "text": `With a whirlwind of emotions, you reflect on your situation and the possibility of joining the Resistance. The battle against Ging looms on the horizon, and the decision you make will change your life forever.` },
    ]
  },
  {
    "id": 2,
    "type": MODULE_TYPE.Description,
    "dialogue": [
      { "speaker": SPEAKER.NPC, npc: npcs[3], "expression": EXPRESSION.Mad, "text": `"Hey there, stranger. I've heard you're working with the Resistance now, huh? I guess we're on the same side. My name's Riko, and I used to be a technician in the city. But, you know, things got complicated when Ging started forcing those blasted NeuLites on everyone."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Mad, "text": `"What happened?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[3], "expression": EXPRESSION.Mad, "text": `"I managed to steal some schematics on the NeuLites. We thought they might hold the key to removing them safely. But, as luck would have it, my place got raided, and the schematics were taken."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Mad, "text": `"Who took them?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[3], "expression": EXPRESSION.Despise, "text": `"I'm not quite sure. They could've been random thieves, or maybe even hired by Ging. Either way, I need those schematics back. They're crucial to our fight against Ging."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Mad, "text": `"What can I do to help?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[3], "expression": EXPRESSION.Mad, "text": `"I need you to find those thieves and get the schematics back. I've heard rumors about a group of thieves hiding in the old warehouse district. That might be a good place to start your search. Be careful though, they won't give up those schematics easily."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"I'll do my best."` },
      { "speaker": SPEAKER.NPC, npc: npcs[3], "expression": EXPRESSION.Mad, "text": `"Thank you. If we can get those schematics back, we might just have a chance at breaking Ging's hold on us. Good luck, and stay safe out there."` },
    ]
  }, {
    "id": 3,
    "type": MODULE_TYPE.Description,
    "dialogue": [
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Mad, "text": `"Hold it right there! I'm looking for some stolen schematics. I've been told you might have them."` },
      { "speaker": SPEAKER.NPC, npc: npcs[4], "expression": EXPRESSION.Mad, "text": `"Hah! Look who we have here. Another hero trying to save the day. What makes you think we'd just hand over those schematics?"` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Mad, "text": `"They're not yours to keep, and they're important to a lot of people."` },
      { "speaker": SPEAKER.NPC, npc: npcs[4], "expression": EXPRESSION.Despise, "text": `"Important, huh? I guess that's why Ging's been sniffing around, looking for 'em. Maybe we should just sell them to the highest bidder."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Mad, "text": `"Ging can't get their hands on those schematics. You have no idea what you're dealing with."` },
      { "speaker": SPEAKER.NPC, npc: npcs[4], "expression": EXPRESSION.Mad, "text": `"Oh, I know exactly what we're dealing with. And I also know that we can make a fortune off this. So, how about you turn around and walk away before you get hurt?"` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "text": `"I'm not leaving without those schematics. I'm giving you one last chance to hand them over peacefully."` },
      {
        "speaker": SPEAKER.NPC, npc: npcs[4], "expression": EXPRESSION.Mad, "text": `The thief leader laughs at you. "You've got guts, I'll give you that. But guts won't save you. Boys, let's show our uninvited guest what we do to heroes around here.
      
      \n\nThe thieves draw their weapons."` },
    ]
  },
  {
    "id": 4,
    "type": MODULE_TYPE.Combat,
    "description": "Retrieve the schematics from the thieves.",
    "time": 120,
    "rewards": [],
    "text": [
      'As you cross the passage into the underground base, your eyes are slow to adjust. The entrance is dimly lit. The entrance is cavernous, echoing rattling and crashing you hear in the distant. It stops, and is followed by a grumbling voice that approaches you.',
    ]
  },
  {
    "id": 5,
    "type": MODULE_TYPE.Description,
    "dialogue": [
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Mad, "text": `"Riko, I've got the schematics! It wasn't easy, but I managed to get them back from those thieves."` },
      { "speaker": SPEAKER.NPC, npc: npcs[3], "expression": EXPRESSION.Wink, "text": `"You did it! I can't believe you actually got them back. You've really saved us. We might finally have a chance at removing these NeuLites safely."` },
      { "speaker": SPEAKER.Player, npc: npcs[0], "expression": EXPRESSION.Mad, "text": `"It was a tough fight, but it was worth it. What's the next step?"` },
      { "speaker": SPEAKER.NPC, npc: npcs[3], "expression": EXPRESSION.Mad, "text": `"We'll need to analyze these schematics and see if there's any information that can help us. It might take some time, but I'll get our best people on it. In the meantime, you should rest and recover."` },
    ]
  },
  {
    "id": 6,
    "type": MODULE_TYPE.Dialogue,
    "npc": npcs[0],
    "text": [
      'We were attacked by a group of scavengers. They\'re a new group, but they\'re well equipped. We\'re not sure where they came from.',
      'Help us take them out and we\'ll give you a reward. Follow the smoke trail to the city ruins. That\'s where they\'re holed up.',
    ]
  },
  {
    "id": 7,
    "type": MODULE_TYPE.Combat,
    "description": "Retaliate against the raiding scavengers",
    "time": 120,
    "rewards": [],
    "text": [
      'As you cross the passage into the underground base, your eyes are slow to adjust. The entrance is dimly lit. The entrance is cavernous, echoing rattling and crashing you hear in the distant. It stops, and is followed by a grumbling voice that approaches you.',
    ]
  }, {
    "id": 8,
    "type": MODULE_TYPE.Description,
    "text": [
      'As you approach the smokey trail, you see a group of scavengers. They\'re well equipped and well armed.',
      'They\'re not expecting you, but they\'re ready for a fight.',
    ]
  }, {
    "id": 9,
    "type": MODULE_TYPE.Description,
    "text": [
      'Yuki motions for you to follow him, leading you further into the ruins. He stops at a large, metal door, and pulls out a key.',
      'Inside, the walls are lined with shelves of weapons and armor. Yuki pulls out a large, metal chest and opens it. Inside, you see an assortment of specialized weapons.',
    ]
  }, {
    "id": 9,
    "type": MODULE_TYPE.Dialogue,
    "npc": npcs[1],
    "text": [
      'Pick a weapon that fancies you.',
      'But choose wisely, as this is will be your specialty.',
    ]
  }
]

export const userQuests = [{
  "id": 1,
  "audioId": undefined,
  "area": MAP_AREA.Slums,
  "questType": QUEST_TYPE.Explore,
  "objectives": [],
  "duration": 0,
  "prevQuestId": null,
  "nextQuestId": 2,
  "img": comTowerImg,
  "modules": [questModules[1]],
  // "modules": [questModules[6]],
  "repeatable": false,
  "breadcrumbQuestIds": [],
  "title": "Awakening",
  "description": "Waypoint 1",
  "objectives": [],
  "rewards": [],
  "buildingId": 6,
  "npcId": 1,
  "requirements": {
    "money": { TYPE: null, amount: 0 },
    "item": null,
    "level": 1,
  }
}]


// export const userQuests = [{
//   "id": 3,
//   "audioId": undefined,
//   "area": MAP_AREA.Slums,
//   "questType": QUEST_TYPE.Explore,
//   "duration": 20,
//   "prevQuestId": 2,
//   "nextQuestId": 4,
//   "img": comTowerImg,
//   "modules": [questModules[3], questModules[4]],
//   "repeatable": false,
//   "breadcrumbQuestIds": [],
//   "title": "Thieves in the Night",
//   "description": "The schematics were last seen in an old warehouse at the edge of the district.",
//   "objectives": [],
//   "rewards": [],
//   "buildingId": 8,
//   "npcId": 1,
//   "requirements": {
//     "money": { TYPE: null, amount: 0 },
//     "item": null,
//     "level": 1,
//   }
// }]

export const questData = [{
  "id": 1,
  "audioId": "undergroundBase",
  "visible": false,
  "area": MAP_AREA.Slums,
  "questType": QUEST_TYPE.Explore,
  "objectives": [],
  "duration": 0,
  "prevQuestId": null,
  "nextQuestId": 2,
  "img": undergroundBgImg,
  "foreground": undergroundFgImg,
  "modules": [questModules[1]],
  // "modules": [questModules[6]],
  "repeatable": false,
  "breadcrumbQuestIds": [],
  "title": "Awakening",
  "description": "Waypoint 1",
  "rewards": [],
  "buildingId": 6,
  "npcId": 1,
  "requirements": {
    "money": { TYPE: null, amount: 0 },
    "item": null,
    "level": 1,
  }
},
{
  "id": 2,
  "audioId": "academy",
  "visible": false,
  "area": MAP_AREA.Slums,
  "questType": QUEST_TYPE.Explore,
  "objectives": [
    'Find schematic thieves'
  ],
  "duration": 0,
  "prevQuestId": 1,
  "nextQuestId": 3,
  "img": comTowerImg,
  "modules": [questModules[2]],
  "repeatable": false,
  "breadcrumbQuestIds": [1],
  "description": "The Communication Tower is in an uproar after something of importance was stolen.",
  "rewards": [],
  "buildingId": 7,
  "npcId": 1,
  "requirements": {
    "money": { TYPE: null, amount: 0 },
    "item": null,
    "level": 1,
  }
},
{
  "id": 3,
  "audioId": "combat",
  "visible": true,
  "area": MAP_AREA.Slums,
  "questType": QUEST_TYPE.Combat,
  "objectives": [
    'Engage thieves'
  ],
  "duration": 10,
  "prevQuestId": 2,
  "nextQuestId": 4,
  "img": warehouseBgImg,
  "modules": [questModules[3], questModules[4]],
  "repeatable": false,
  "breadcrumbQuestIds": [],
  "title": "Thieves in the Night",
  "description": "The schematics were last seen in an old warehouse at the edge of the district.",
  "rewards": [],
  "buildingId": 8,
  "npcId": 1,
  "requirements": {
    "money": { TYPE: null, amount: 0 },
    "item": null,
    "level": 1,
  }
},
{
  "id": 4,
  "audioId": "combat",
  "visible": true,
  "area": MAP_AREA.Slums,
  "questType": QUEST_TYPE.Explore,
  "objectives": [
    'Engage thieves',
    'Return schematics to Reki'
  ],
  "duration": 0,
  "prevQuestId": 3,
  "nextQuestId": -1,
  "img": comTowerImg,
  "modules": [questModules[5]],
  "repeatable": false,
  "breadcrumbQuestIds": [],
  "title": "Thieves in the Night",
  "description": "Return the stolen schematics to Reki.",
  "rewards": [],
  "buildingId": 7,
  "npcId": 1,
  "requirements": {
    "money": { TYPE: null, amount: 0 },
    "item": null,
    "level": 1,
  }
}, {
  "id": 5,
  "audioId": undefined,
  "area": MAP_AREA.Slums,
  "questType": QUEST_TYPE.Combat,
  "duration": 0,
  "prevQuestId": 4,
  "nextQuestId": 6,
  "modules": [questModules[5]],
  "repeatable": false,
  "breadcrumbQuestIds": [],
  "title": "Stolen Schematics",
  "description": "Waypoint 1",
  "objectives": [],
  "rewards": [],
  "buildingId": 7,
  "npcId": 1,
  "requirements": {
    "money": { TYPE: null, amount: 0 },
    "item": null,
    "level": 1,
  }
}, {
  "id": 6, // bandits
  "audioId": undefined,
  "area": MAP_AREA.Slums,
  "questType": QUEST_TYPE.Combat,
  "duration": 0,
  "prevQuestId": 3,
  "nextQuestId": 5,
  "modules": [questModules[6]],
  "repeatable": false,
  "breadcrumbQuestIds": [],
  "title": "Smokey Trail",
  "description": "Waypoint 1",
  "objectives": [],
  "rewards": [],
  "buildingId": 8,
  "npcId": 1,
  "requirements": {
    "money": { TYPE: null, amount: 0 },
    "item": null,
    "level": 1,
  }
}]


export const dialogue = [{
  "dialogueId": 0,
  "questId": 0,
  "npcId": 0,
}]

export const overworldMarkers = [{
  "overworldId": 0,
  "audioId": undefined,
  "type": MAP_SECTION.OuterRim,
  "areas": [MAP_AREA.Slums],
  "zoom": ZOOM.Overworld,
  "data": {
    "markerPos": [2852, 95, 1134],
    "position": { x: 6776, y: 2786, z: 5759 },
    // position: { x: 50, y: 45, z: 60 },
    // "target": { x: 2852, y: 5, z: 434 },
    target: { x: -45, y: 45, z: 65 },
    "boxTransforms": [130, -1, 1, -1]
  }
}];

export const areaMarkers = [{
  "areaId": 1,
  "audioId": "slums",
  "section": MAP_SECTION.OuterRim,
  "type": MAP_AREA.Slums,
  "zoom": ZOOM.Area,
  "name": "Slums",
  "description": "The slums are a place of poverty and crime. The people here are desperate and will do anything to survive.",
  "requirements": {
    "level": 1,
  },
  data: {
    "markerPos": [3052, 95, 734],
    "position": { x: 3852, y: 896, z: 1734 },
    // position: { x: 50, y: 45, z: 60 },
    "target": { x: 2852, y: 5, z: 434 },
    // target: { x: -45, y: 45, z: 65 },
    "boxTransforms": [130, -1, 1, -1]
  }
},
  // {
  //   "areaId": 2,
  //   "audioId": undefined,
  //   "section": MAP_SECTION.OuterRim,
  //   "type": MAP_AREA.Outskirts,
  //   "zoom": ZOOM.Area,
  //   "name": "Ohter place",
  //   "description": "The slums are a place of poverty and crime. The people here are desperate and will do anything to survive.",
  //   "requirements": {
  //     "level": 1,
  //   },
  //   data: {
  //     "markerPos": [3352, 95, 2934],
  //     "position": { x: 4452, y: 996, z: 3434 },
  //     // position: { x: 50, y: 45, z: 60 },
  //     "target": { x: 2152, y: 15, z: 2134 },
  //     // target: { x: -45, y: 45, z: 65 },
  //     "boxTransforms": [130, -1, 1, -1]
  //   }
  // }
]

export const buildingMarkers = [
  {
    "id": 4,
    "audioId": "communicationTower",
    "areaType": MAP_AREA.Slums,
    "zoom": ZOOM.Building,
    "name": "Communication Tower",
    "description": "Uplink to the Resistance",
    "requirements": {
      "level": 1,
    },
    "data": {
      "markerPos": [3052, 95, 734],
      "position": { x: 3052, y: 52, z: 1285 },
      // position: { x: 50, y: 45, z: 60 },
      "target": { x: 3052, y: 15, z: 395 },
      // target: { x: -45, y: 45, z: 65 },
      "boxTransforms": [130, -1, 1, -1]
    }
  }, {
    "id": 5,
    "audioId": "communicationTower",
    "areaType": MAP_AREA.Slums,
    "zoom": ZOOM.Building,
    "name": "Communication Tower",
    "description": "Uplink to the Resistance",
    "requirements": {
      "level": 1,
    },
    "data": {
      "markerPos": [3052, 95, 734],
      "position": { x: 3052, y: 52, z: 1285 },
      // position: { x: 50, y: 45, z: 60 },
      "target": { x: 3052, y: 15, z: 395 },
      // target: { x: -45, y: 45, z: 65 },
      "boxTransforms": [130, -1, 1, -1]
    }
  },
  {
    "id": 6,
    "audioId": "undergroundBase",
    "areaType": MAP_AREA.Slums,
    "zoom": ZOOM.Building,
    "name": "Underground Base",
    "description": "Resistance Headquarters",
    "data": {
      "markerPos": [2852, 95, 734],
      "position": { x: 3152, y: 52, z: 934 },
      // position: { x: 50, y: 45, z: 60 },
      "target": { x: 1335, y: 15, z: 195 },
      // target: { x: -45, y: 45, z: 65 },
      "boxTransforms": [130, -1, 1, -1]
    }
  },
  {
    "id": 7,
    "audioId": "communicationTower",
    "areaType": MAP_AREA.Slums,
    "zoom": ZOOM.Building,
    "name": "Communication Tower",
    "description": "Resistance network hub",
    "data": {
      "markerPos": [3252, 95, 734],
      "position": { x: 3552, y: 52, z: 934 },
      // position: { x: 50, y: 45, z: 60 },
      "target": { x: 1335, y: 0, z: -700 },
      // target: { x: -45, y: 45, z: 65 },
      "boxTransforms": [130, -1, 1, -1]
    }
  },
  {
    "id": 8,
    "audioId": "combat",
    "areaType": MAP_AREA.Slums,
    "zoom": ZOOM.Building,
    "name": "Old Warehouse",
    "description": "Last bandit sighting",
    "data": {
      "markerPos": [2700, 80, -600],
      "position": { x: 2900, y: 10, z: -550 },
      // position: { x: 50, y: 45, z: 60 },
      "target": { x: 2700, y: 15, z: -600 },
      // target: { x: -45, y: 45, z: 65 },
      "boxTransforms": [130, -1, 1, -1]
    }
  }]

// export const mapMarkers = [...buildingMarkers, ...areaMarker, ...overworldMarkers]