import styled, { keyframes } from "styled-components"
import { useState } from "react";
import PaperDoll from "./PaperDoll";
import Inventory from "./Inventory";
import { equipmentList, questItems } from "./items";
import { INTERFACE, TYPE } from "Project/Game/const"
import { useAtom } from "jotai";
import { itemReducerAtom, inventoryReducerAtom, interfaceAtom } from "Project/Game/data/atoms";
import { DndContext, useSensor, useSensors, MouseSensor, TouchSensor } from '@dnd-kit/core';
import QuestLog from "./QuestLog";
import QuestPreview from "./QuestLog/QuestPreview";

const Panes = () => {
  const [equipment, dispatchEquipment] = useAtom(itemReducerAtom);
  const [interfaceState, setInterfaceState] = useAtom(interfaceAtom);
  const [mouseDown, setMouseDown] = useState(false);
  const [tab, setTab] = useState(0);

  const mouseSensor = useSensor(MouseSensor, {
    // Require the mouse to move by 10 pixels before activating
    activationConstraint: {
      distance: 15,
    },
  });

  const touchSensor = useSensor(TouchSensor, {
    // Press delay of 250ms, with tolerance of 5px of movement
    activationConstraint: {
      delay: 250,
      tolerance: 5,
    },
  });

  const sensors = useSensors(mouseSensor, touchSensor);

  const handleDragEnd = (event) => {
    const { active, over } = event;
    const isTargetOccupied = over.data.current.item !== undefined;

    if (isTargetOccupied) {
      dispatchEquipment({ type: 'SWAP', payload: { active, over } })
    } else {
      dispatchEquipment({ type: 'MOVE', payload: { active, over } })
    }
  }

  const handleTabClick = (choice) => {
    let isSelected = tab === choice
    if (isSelected === false)
      setTab(choice);
  }

  return <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
    <Wrapper>
      <Container isVisible={interfaceState.player.main}>
        {interfaceState.player.main &&
          <>
            <PDTab onClick={() => handleTabClick(0)} isSelected={tab === 0}>Character</PDTab>
            <QuestsTab onClick={() => handleTabClick(1)} isSelected={tab === 1}>Quests</QuestsTab>
            {tab === 0 && <PaperDoll isVisible={interfaceState.player.main} setMouseDown={setMouseDown} mouseDown={mouseDown} />}
          </>}
        <>
          {tab === 1 &&
            <>
              <QuestLog selectedQuestId={interfaceState.quests.selected} setInterfaceState={setInterfaceState} isVisible={true} />
              {/* <QuestPreview selectedQuestId={interfaceState.quests.selected} isVisible={true} /> */}
              {/* <QuestPreview selectedQuestId={interfaceState.quests.selected} isVisible={interfaceState.quests.selected > 0} /> */}
            </>
          }
        </>
      </Container>
    </Wrapper>
  </DndContext>;
}

const Container = styled.div`
  width: 1100px;
  height: 734px;
  ${props => props.isVisible ? 'display: block;' : 'display: none;'}
`

const Tab = styled.div`
  top: -70px;
  position: absolute;
  padding: 25px 35px;
  background: #F0DFB6;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  color: #000;
  font-family: var(--primary-text-font);
  justify-content: center;
  user-select: none;
  ${props => props.isSelected ? 'cursor: default; z-index: 20;' : 'cursor: pointer; z-index: -1;'}

  &:hover {
    /* ${props => props.isSelected ? 'transform: translateY(0) scale(1);' : 'transform: translateY(-5px) scale(1.00);'} */
    ${props => props.isSelected ? 'filter: brightness(1);' : 'filter: brightness(1.1);'}

  }
`

const QuestsTab = styled(Tab)`

  left: 250px;
  background: #F0DFB6;
  color: #000;
`

const PDTab = styled(Tab)`
  left: 0;
  background: #BF4040;
  color: #fff;
`

const NavTxt = styled.div`
  position: relative;
`

const Highlight = styled.div`
  width: 200px;
  height: 70px;
  opacity: 0;
  ${props => props.isSelected && 'opacity: 1'};
  border: 4px solid #fff;
  position: absolute;
  transform: perspective(100px) rotate3d(-2, -30, -9, -9deg) skew(-10deg, -1deg);
`

const NavItem = styled.div`
  position: relative;
  cursor: pointer;
  user-select: none;
  font-size: 4rem;
  ${props => props.isSelected && 'color: #fff; text-shadow: -6px 2px 0  #000; font-size: 5rem;'}

  &:hover {
      transform: perspective(100px) rotate3d(-2, -30, -9, -5deg) scale(1.1);
  }

  &:active {
    transform: perspective(100px) rotate3d(-2, -30, -9, -5deg) scale(1.1) translateY(2px);
    filter: brightness(0.9);
  }
`

const Quest = styled(NavItem)`
    transform: perspective(100px) rotate3d(-2, -30, -9, -5deg) scale(1);
`

const Player = styled(NavItem)`
    transform: perspective(100px) rotate3d(-2, -30, -9, -5deg) scale(1);
`

const Navigation = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  column-gap: 40px;
  width: 700px;
  top: -70px;
  left: 100px;
  
  font-family: var(--title-font);
  color: #fff;
  z-index: 5;
`

const animIn = keyframes`
  0% { transform: translateY(10px) scale(0.99); opacity: 0; }
  100% { transform: translateY(0) scale(1); opacity: 1; }
`

const BgTest = styled.div`
  position: absolute;
  background: #F0DFB6;
  top: 0;
  left: 0;
  width: 1271px;
  /* height: 682px; */
  transform: perspective(100px) rotate3d(-2, -2, -9, -1deg) scale(1);
`

const BgTest2 = styled.div`
  position: absolute;
  background: #000;
  top: -9px;
  left: -8px;
  width: 1287px;
  height: 702px;
  transform: perspective(100px) rotate3d(-2, -2, -9, -1deg) scale(1);
`

const BgTest3 = styled.div`
  position: absolute;
  background: rgba(0,0,0, 0.6);
  top: -8px;
  left: -20px;
  width: 1280px;
  height: 680px;
  transform: perspective(100px) rotate3d(-0.5, 0, -9, -4deg) scale(1);
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  /* height: 682px; */
  animation: ${animIn} 0.5s cubic-bezier(0.1, 1, 0.1, 1) forwards;
`

export default Panes