import styled, { keyframes } from "styled-components";
import { useState, useEffect, useRef } from "react";
import { Howl } from "howler";
import { useAtom } from "jotai";
import { playerEnergyAtom, notificationAtom } from "Project/Game/data/atoms";

const Combat = ({ handleNextModule }) => {
  const [notification, setNotification] = useAtom(notificationAtom);
  const [playerEnergy, setPlayerEnergy] = useAtom(playerEnergyAtom);
  const containerRef = useRef();

  var engageSound = new Howl({
    src: ['/sound/skills_newskill.wav'],
    autoplay: false,
    loop: false,
    volume: 0.7,
  });

  var mouseOverSound = new Howl({
    src: ['/sound/mainmenu_mouseover.wav'],
    autoplay: false,
    loop: false,
    volume: 0.7,
  });


  var openMenu = new Howl({
    src: ['/sound/drive_menuopen.wav'],
    autoplay: false,
    loop: false,
    volume: 0.4,
  });

  var successSound = new Howl({
    src: ['/sound/drive_completewav.wav'],
    autoplay: false,
    loop: false,
    volume: 0.4,
  });

  useEffect(() => {
    openMenu.play();
  }, [])

  const [isEngaged, setIsEngaged] = useState(false);
  const [timer, setTimer] = useState(0);
  const handleCancel = () => {
    handleNextModule();
  }

  const handleConfirm = () => {
    setIsEngaged(true);
    containerRef.current.style.height = "0";
    engageSound.play();
    setTimer(120);
    setPlayerEnergy(prev => {
      return { ...prev, available: prev.available - 1 }
    })
    handleNextModule();
  }

  const handleCheat = () => {
    // handleNextModule();
    successSound.play();
  }

  const handleMouseOver = () => {
    mouseOverSound.play();
  }

  return <Wrapper>
    <Container isEngaged={isEngaged} ref={containerRef}>
      <Header>
        <Title>Smokey Trail</Title>
        <Description>Retaliate against raiders</Description>
        <Type>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25">
            <path fill="rgba(216,216,216,0.9)" d="M12.488 0A12.5 12.5 0 1 0 25 12.5 12.494 12.494 0 0 0 12.488 0Zm.012 22.5a10 10 0 1 1 10-10 10 10 0 0 1-10 10Zm.625-16.25H11.25v7.5l6.562 3.938.938-1.537-5.625-3.338Z" data-name="Icon material-access-time" />
          </svg>
          2 hours
        </Type>
        <Energy>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 26 26">
            <path fill="#d8d8d8" d="M9.8 0 0 13h9.8L3.2 26 26 9.7H13L22.8 0Z" data-name="Icon metro-power" />
          </svg>
          <Txt>1 energy</Txt>
        </Energy>
      </Header>
      <BreakLine />
      {isEngaged == false &&
        <Rewards>
          <RewardHeader>You Receive</RewardHeader>
          <Items>
            <Item>Stolen Schematics</Item>
          </Items>
        </Rewards>}
      {isEngaged &&
        <WrapProgress>
          <TimerBar>
            <TimerBarBackground />
            <Time>2 HOURS</Time>
            <TimerWrapper>
              <Bar>
                {/* <Progress /> */}
              </Bar>
            </TimerWrapper>
          </TimerBar>
        </WrapProgress>}
      <Buttons>
        {isEngaged == false ? <>
          {/* <CancelBtn onClick={handleCancel}>X</CancelBtn> */}
          <EmbarkBtn onMouseOver={handleMouseOver} onClick={handleConfirm}>Embark</EmbarkBtn>
        </> :
          null
          // <SkipBtn onMouseOver={handleMouseOver} onClick={handleCheat}>Cheat Progress</SkipBtn>
        }
      </Buttons>
    </Container>
  </Wrapper>
}


const animSkip = keyframes`
  0% { opacity: 0; transform: scale(0.9); }
  100% { opacity: 1; transform: scale(1); }
`

const SkipBtn = styled.button`
    position: relative;
    bottom: -140px;
    left: -120px;
    display: flex;
    justify-content: center;1
    align-items: center;
    padding: 40px 50px;
    background: #D64C4C;
    &:hover {filter: brightness(1.2); }
    &:active {filter: brightness(0.9); }
    opacity: 0;
    animation: ${animSkip} 0.5s 3.5s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`

const Txt = styled.div`
display: flex;
width: max-content;
`

const animTimerBar = keyframes`
    0% {transform: translateY(10px) scale(0.99); opacity: 0; }
    100% {transform: translateY(0) scale(1); opacity: 1; }
    `

const TimerWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    `

const Bar = styled.div`
    position: absolute;
    background: #545454;
    /* background: #545454; */
    /* background: #7b1d14; */
    width: 448px;
    height: 44px;
    transform: perspective(100px) rotate3d(-6, 4, -2, -3deg) skew(-1deg, -1deg) scale(1);
    `
const Progress = styled.div`
    position: absolute;
    background: #ffffff;
    width: 90px;
    top: -2px;
    height: 47px;
    transform: perspective(100px) rotate3d(-6, 4, -2, -3deg) skew(-1deg, -1deg) scale(1);
    `

const Time = styled.div`
    margin-left: 40px;
    width: 130px;
    z-index: 2;
    font-family: var(--primary-text-font);

    `
const TimerBarBackground = styled.div`
    position: absolute;
    background: #222222;
    left: -5px;
    /* background: rgb(0,0,0); */
    /* background: #d55454; */
    width: 100%;
    height: 79px;
    transform: perspective(100px) rotate3d(-3, 4, -2, -1deg) skew(-2deg, -1deg) scale(1);
    `

const animTimer = keyframes`
    0% {transform: translateY(0) scale(0.99); opacity: 0.4; }
    100% {transform: translateY(0) scale(1); opacity: 1; }
`

const TimerBar = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 700px;
    color: #fff;
    opacity: 0;
    height: 79px;
    animation: ${animTimer} 0.3s 0.1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`

const WrapProgress = styled.div`
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    `

const animFlash = keyframes`
    0% {opacity: 0.01; transform: scale(1); height: 303px; }
    100% {opacity: 0; transform: scale(2.5); height: 450px; }
    `

const WrapFlash = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    `

const FlashEffect = styled.div`
    display: flex;
    flex-direction: column;
    background: #fff;
    padding: 70px 70px;
    width: 855px;
    height: 303px;
    opacity: 0;
    animation: ${animFlash} 2s 0.1s  cubic-bezier(0.075, 0.82, 0.665, 1) forwards;
    `

const Item = styled.div`
    padding: 18px 25px;
    border: 3px solid #CB4646;
    `

const Items = styled.div`
    display: flex;
    font-size: 25px;
    gap: 20px;
    `

const EmbarkBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 50px;
    background: #289BFE;
    &:hover {filter: brightness(1.2); }
    &:active {filter: brightness(0.9); }
    `

const CancelBtn = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 50px;
    background: #D64C4C;
    &:hover {filter: brightness(1.2); }
    &:active {filter: brightness(0.9); }
    `

const Buttons = styled.div`
    position: absolute;
    column-gap: 20px;
    bottom: -50px;
    right: 100px;
    display: flex;
    font-size: 2.5rem;
    color: #fff;
    `

const RewardHeader = styled.div`
    font-size: 1.25rem;
    color: #787878;
    `

const Rewards = styled.div`
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    `

const BreakLine = styled.div`
    height: 3px;
    width: 100%;
    background: #333333;
    `

const Energy = styled.div`
display: flex;
gap: 10px;
align-items: center;
position: absolute;
top: 40px;
right: 0;
font-size: 1.5rem;
color: #B5B5B5;
`

const Type = styled.div`
    display: flex;
    gap: 10px;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.5rem;
    color: #B5B5B5;
    `

const Description = styled.div`
    font-size: 1.5rem;
    `

const Title = styled.div`
    font-size: 2.5rem;
    color: #B5B5B5;

    `

const Header = styled.div`
    position: relative;
    display: flex;
    row-gap: 15px;
    flex-direction: column;
    font-family: var(--primary-text-font);
    `

const animIn = keyframes`
    0% {opacity: 0; transform: translateY(20px) scale(0.98); }
    100% {opacity: 1; transform: translateY(0) scale(1); }
    `

const Container = styled.div`
    display: flex;
    min-height: 100px;
    flex-direction: column;
    background: #000000;
    row-gap: 25px;
    padding: 70px 70px;
    width: 855px;
    height: 303px;
    opacity: 0;
    animation: ${animIn} 1s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
    transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
    `

const Wrapper = styled.div`
    display: flex;
    font-family: var(--primary-text-font);
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    position: absolute;
    color: #fff;
    z-index: 12;
    padding: 0 3vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    `

export default Combat;