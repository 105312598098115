import styled, { keyframes } from "styled-components";
import { useEffect, useRef, useState, forwardRef } from "react";
import Delayed from "../components/Delayed";
import { SPEAKER } from "Project/Game/const";
import { Howl } from "howler";

const useText = ({ dialogue, ref }) => {
  const [dialoguePage, setdialoguePage] = useState(0)
  const [npc, setNpc] = useState({ id: -1, img: null })
  const [currentText, setCurrentText] = useState([]);
  const currentLength = useRef(0);

  useEffect(() => {
    if (dialoguePage === 0) {
      progressText(0);
    }
  }, [])

  const progressText = (position) => {
    let recentDialogue = [...currentText];

    if (position > 0) {
      recentDialogue.push({ name: dialogue[position - 1].npc.name, color: dialogue[position - 1].npc.color, spaces: dialogue[position - 1].npc.spaces, text: dialogue[position - 1].text });
    }

    const newExpression = dialogue[position].expression

    if (dialogue[position].speaker !== SPEAKER.Player && dialogue[position].npc.id !== npc.id) {
      setNpc({ id: 0, img: dialogue[position].npc.image.body, expression: dialogue[position].npc.image.expressions[newExpression] });
    }

    if (position > 0)
      setCurrentText(recentDialogue);

    let dialogueToUse = dialogue[position].text;
    let spacesToAdd = ``

    for (let index = 0; index < dialogue[position].npc.spaces; index++) {
      spacesToAdd = `\xa0` + spacesToAdd;
    }

    showText(spacesToAdd + dialogueToUse, 0, 15);
    let newDialoguePage = dialoguePage + 1;
    setdialoguePage(newDialoguePage);
  }

  let showText = function (message, index, interval) {
    if (index < message.length + 15) {
      ref.current.textContent = message.slice(0, index);
      setTimeout(function () {
        showText(message, index + 15, interval);
      }, interval);
    }
  }

  return [currentText, npc, (pos) => progressText(pos)]
}

export default useText