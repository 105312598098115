import styled from "styled-components";
import { useAtom } from "jotai";
import database from "Project/Game/data/items";
import { TYPE } from "Project/Game/const";
import { itemReducerAtom } from "Project/Game/data/atoms";

const ConnectWallet = ({ setIsConnected }) => {
  const [items, dispatchItem] = useAtom(itemReducerAtom)

  const handleClick = () => {
    const loadItems = () => {
      let farmerHat = database.equipment[TYPE.Hat].find(item => item.name === 'Farmer Hat')
      let sleeper = database.equipment[TYPE.Clothing].find(item => item.name === 'Sleeper');
      let seafarer = database.equipment[TYPE.Clothing].find(item => item.name === 'Seafarer');
      let anger = database.equipment[TYPE.Eyes].find(item => item.name === 'Anger');
      let bunnyHelmet = database.equipment[TYPE.Hat].find(item => item.name === 'Bunny Helmet');
      let wandererHat = database.equipment[TYPE.Hat].find(item => item.name === 'Wanderer Hat');

      dispatchItem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Clothing].find(item => item.name === 'Golden Chest'), equippable: [seafarer, sleeper], type: TYPE.Clothing }, })
      dispatchItem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Tail].find(item => item.name === 'Wine Band'), equippable: [], type: TYPE.Tail }, })
      dispatchItem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Head].find(item => item.name === 'Tawny Fuzzy'), equippable: [], type: TYPE.Head }, })
      dispatchItem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Ears].find(item => item.name === 'Beige Two Tips'), equippable: [], type: TYPE.Ears }, })
      dispatchItem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Eyebrows].find(item => item.name === 'Cloud'), equippable: [], type: TYPE.Eyebrows, }, })
      dispatchItem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Shoulder].find(item => item.name === 'Red Ranger'), equippable: [], type: TYPE.Shoulder }, })
      dispatchItem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Mouth].find(item => item.name === 'Cat Smile'), equippable: [], type: TYPE.Mouth }, })
      dispatchItem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Eyes].find(item => item.name === 'Jewel'), equippable: [anger], type: TYPE.Eyes }, })
      dispatchItem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Weapon].find(item => item.name === 'Crimson Firearms'), equippable: [], type: TYPE.Weapon }, })
      dispatchItem({ type: 'INITIALIZE', payload: { item: database.equipment[TYPE.Hat].find(item => item.name === 'Military Headband'), equippable: [bunnyHelmet, wandererHat, farmerHat], type: TYPE.Hat } })
    }

    loadItems();
    setIsConnected(true)
  }

  return <Wrapped>
    <Container onClick={handleClick}>
      <Background />
      {/* <ForegroundFrame /> */}

      <Text>
        Connect Wallet
      </Text>
    </Container>
  </Wrapped>
}

const ForegroundFrame = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate3d(-15, 24, 4, 3deg)  skew(-0.5deg, -1deg);
  width: 80%;
  height: 80%;
  border: 3px solid #fff;
`

const Text = styled.div`
  position: relative;
  font-size: 2rem;
  z-index: 5;
  font-family: var(--primary-text-font);
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #FF0000;
  transform: rotate3d(0, 0, 1, -1deg) skew(-0.5deg, 0deg);
`

const Container = styled.div`
  cursor: pointer;
  padding: 35px 65px;

  &:hover { filter: brightness(1.1) }
  &:active { filter: brightness(0.95) }
`
const Wrapped = styled.div``

export default ConnectWallet;