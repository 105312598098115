
import React, { useRef } from "react";
import { useGLTF } from "@react-three/drei";
// import house1Glb from 'App/assets/model/new/newest/house1.glb'
import house2Glb from 'App/assets/model/new/newest/com.glb'
import { useTexture } from "@react-three/drei";
import { MeshStandardMaterial, Mesh, ShaderMaterial, UniformsLib, Color, MeshToonMaterial, DoubleSide, MeshPhysicalMaterial, FrontSide, BackSide } from 'three';
import threeToneGradientJpg from 'App/assets/3d/texture/gradient/threeTone.jpg'
import fiveToneGradientJpg from 'App/assets/3d/texture/gradient/fiveTone.jpg'

export function House2cModel(props) {
  const { nodes, materials } = useGLTF(house2Glb);
  // const toonMaterial = new MeshToonMaterial({ color: '#ffffff', map: nodes.mesh_0.material.map });
  const toonMaterial = new MeshPhysicalMaterial({ map: nodes.mesh_0.material.map, clearcoat: 0, clearcoatRoughness: 1, metalness: 0.3, roughness: 1, reflectivity: 0.8, ior: 1, opacity: 1, transparent: false, side: FrontSide });
  // const toonMaterial2 = new MeshPhysicalMaterial({ map: nodesLights.mesh_0.material.map, emissive: true, emissiveIntensity: 1, color: 'yellow', side: FrontSide });
  return (
    <group {...props} dispose={null}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.mesh_0.geometry}
        material={toonMaterial}
      />
    </group>
  );
}

useGLTF.preload(house2Glb);





// import React, { useRef } from "react";
// import { useGLTF } from "@react-three/drei";
// // import house1Glb from 'App/assets/model/new/newest/house1.glb'
// import house2Glb from 'App/assets/model/new/newest/house9.glb'
// import { useTexture } from "@react-three/drei";
// import { MeshStandardMaterial, Mesh, ShaderMaterial, UniformsLib, Color, MeshToonMaterial, DoubleSide, MeshPhysicalMaterial, FrontSide, BackSide } from 'three';
// import threeToneGradientJpg from 'App/assets/3d/texture/gradient/threeTone.jpg'
// import fiveToneGradientJpg from 'App/assets/3d/texture/gradient/fiveTone.jpg'

// export function House2Model(props) {
//   const { nodes, materials } = useGLTF(house2Glb);
//   // const toonMaterial = new MeshToonMaterial({ color: '#ffffff', map: nodes.mesh_0.material.map });
//   const toonMaterial = new MeshPhysicalMaterial({ map: nodes.mesh_0.material.map, clearcoat: 0, clearcoatRoughness: 1, metalness: 0.3, roughness: 1, reflectivity: 0.8, ior: 1, opacity: 1, transparent: false, side: FrontSide });
//   // const toonMaterial2 = new MeshPhysicalMaterial({ map: nodesLights.mesh_0.material.map, emissive: true, emissiveIntensity: 1, color: 'yellow', side: FrontSide });

//   return (
//     <group scale={.8} {...props} dispose={null}>
//       <mesh
//         castShadow
//         receiveShadow
//         geometry={nodes.mesh_0.geometry}
//         material={toonMaterial}
//       />

//     </group>
//   );
// }

// useGLTF.preload(house2Glb);

