import styled, { keyframes, css } from "styled-components"
import { useState, useEffect } from "react"
import paperdollImg from 'App/assets/3d/ui/paperdoll-profile.png'
import Droppable from "../Inventory/components/Droppable"
import Draggable from "../Inventory/components/Draggable"
import { TYPE } from "Project/Game/const"
import Avatar from "Project/Game/UI/MainHUD/Avatar"
import { useAtom } from "jotai";
import { itemReducerAtom } from "Project/Game/data/atoms";
import Slot from "./Slot"
import { showMenuHud } from "Project/Game/data/atoms";
import { Howl } from "howler"


var openMenu = new Howl({
  src: ['/sound/drive_menuopen.wav'],
  autoplay: false,
  loop: false,
  volume: 0.8,
});

const localSlotData = {
  left: [
    {
      slotId: 12,
      name: 'Background',
      type: TYPE.Background,
      transformOuterSlot: 'perspective(100px) rotate3d(-4, -6, 9, 4deg) scale(1)',
      transformInnerSlot: 'perspective(100px) rotate3d(-4, -6, -13, -3deg) scale(1)',
      side: 'left'
    },
    {
      slotId: 13,
      name: 'Pattern',
      type: TYPE.Pattern,
      transformOuterSlot: 'perspective(100px) rotate3d(-12, 15, -19, -4deg) scale(1)',
      transformInnerSlot: 'perspective(100px) rotate3d(-4, -6, -19, -3deg) scale(1)',
      side: 'left'
    },
    {
      slotId: 14,
      name: 'Eyes',
      type: TYPE.Eyes,
      transformOuterSlot: 'perspective(100px) rotate3d(-12, -2, 9, -7deg) scale(1)',
      transformInnerSlot: 'perspective(100px) rotate3d(-4, -6, 2, -5deg) scale(1)',
      side: 'left'
    },
  ],
  right: [
    {
      slotId: 15,
      name: 'Hat',
      type: TYPE.Hat,
      transformOuterSlot: 'perspective(100px) rotate3d(-4, -22, 9, -5deg) scale(1)',
      transformInnerSlot: 'perspective(100px) rotate3d(-4, -6, 2, 2deg) scale(1)',
      side: 'Right'
    },
    {
      slotId: 16,
      name: 'Shoulder',
      type: TYPE.Shoulder,
      transformOuterSlot: 'perspective(100px) rotate3d(4, 6, 1, 12deg) scale(1)',
      transformInnerSlot: 'perspective(100px) rotate3d(-4, -6, 2, 8deg) scale(1)',
      side: 'Right'
    },
    {
      slotId: 17,
      name: 'Clothing',
      type: TYPE.Clothing,
      transformOuterSlot: 'perspective(100px) rotate3d(6, -2, 9, 4deg) scale(1)',
      transformInnerSlot: 'perspective(100px) rotate3d(-4, -6, 2, -3deg) scale(1)',
      side: 'Right'
    },
    {
      slotId: 18,
      name: 'Weapon',
      type: TYPE.Weapon,
      transformOuterSlot: 'perspective(100px) rotate3d(14, 15, 9, 5deg) scale(1)',
      transformInnerSlot: 'perspective(100px) rotate3d(-4, 1, 1, 6deg) scale(1)',
      side: 'Right'
    }
  ]
}

const EquipmentSlotComponent = ({ side, isActive, setIsActive, equippable, slotId, img, type, item, transformOuterSlot, transformInnerSlot, name, mouseDown, setMouseDown }) => {
  return <EquipmentSlot>
    <WrapBackground side={side}>
      <EquipmentSlotBg transform={transformOuterSlot} />
      <EquippableBg transform={transformInnerSlot}>
        <Slot isActive={isActive} setIsActive={setIsActive} equippable={equippable} slotId={slotId} item={item} side={side} />
        {/* <Droppable supports={type} img={img} key={slotId} id={slotId} item={item}>
          {item !== undefined ?
            <Draggable mouseDown={mouseDown} setMouseDown={setMouseDown} slotId={slotId} item={item} /> : null}
        </Droppable> */}
      </EquippableBg>
      <EquipmentName>{name}</EquipmentName>
    </WrapBackground>
  </EquipmentSlot>
}

const PaperDoll = ({ mouseDown, setMouseDown, handleClick, isVisible }) => {
  const [items, dispatchItem] = useAtom(itemReducerAtom);
  const [isActive, setIsActive] = useState(-1)
  const [showMainHud, setShowMainHud] = useAtom(showMenuHud)

  useEffect(() => {
    if (showMainHud === false) {
      setIsActive(-1)
    }
  }, [showMainHud])

  useEffect(() => {
    if (isVisible === true)
      openMenu.play();
  }, [isVisible])

  const handleMainClick = () => {
    setIsActive(-1)
    handleClick()
  }

  return <WrapCharacter>
    <Wrapper isVisible={isVisible} onClick={handleMainClick}>
      <Background />
      <WrapLeftSide onClick={e => e.stopPropagation()}>
        {localSlotData.left.map((slot, index) => {
          return <EquipmentSlotComponent side="left" isActive={isActive} setIsActive={setIsActive} equippable={items[slot.type].equippable} mouseDown={mouseDown} setMouseDown={setMouseDown} type={slot.type} key={slot.slotId} transformOuterSlot={slot.transformOuterSlot} transformInnerSlot={slot.transformInnerSlot} name={slot.name} slotId={slot.slotId} item={items[slot.type].equipped} />
        }
        )}
      </WrapLeftSide>
      <WrapRightSide onClick={e => e.stopPropagation()}>
        {localSlotData.right.map((slot, index) => {
          return <EquipmentSlotComponent side="right" isActive={isActive} setIsActive={setIsActive} equippable={items[slot.type].equippable} mouseDown={mouseDown} setMouseDown={setMouseDown} type={slot.type} key={slot.slotId} transformOuterSlot={slot.transformOuterSlot} transformInnerSlot={slot.transformInnerSlot} name={slot.name} slotId={slot.slotId} item={items[slot.type].equipped} />
        })}
      </WrapRightSide>
      <WrapAvatar>
        <Avatar />
      </WrapAvatar>
      <Name>
        <RelativeWrap>
          <NameBg />
          <NameTxt>Ylvis</NameTxt>
        </RelativeWrap>
      </Name>
    </Wrapper >
    <BlackBox />
  </WrapCharacter>
}

const WrapCharacter = styled.div`
  width: 1100px;
  display: flex;
  align-items: flex-end;
  /* flex-wrap: wrap; */
`

const BlackBox = styled.div`
  position: relative;
  width: 400px;/* background: #df766e; */
  left: -40px;
  height: 700px;
  background: #df766e;

  /* @media (max-width: 1280px) {
    width: 100%;
  } */
`

const WrapAvatar = styled.div`
  position: absolute;
  bottom: 160px;
  left: 50%;
  width: 450px;
  transform: translateX(-50%);
`

const PaperDollImg = styled.img`
  position: absolute;
  bottom: 120px;
  left: 50%;
  width: 450px;
  transform: translateX(-50%);
`


const WeaponSlot = styled.div`
  position: absolute;
  left: -30px;
  top: 39%;
  transform: translateY(-60%);
`


const NameTxt = styled.div`
  position: relative;
  color: #fff;
`

const RelativeWrap = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  font-family: var(--primary-text-font);
  width: 360px;
  height: 125px;
`

const NameBg = styled.div`
  position: absolute;
  top: 2px;
  left: 0;
  background: #ed4c4c;
  width: 100%;
  height: 100%;
  transform: perspective(100px) rotate3d(-2, 5, -8, -3deg) scale(1);
`

const Name = styled.div`
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);

`

const WrapRightSide = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  right: -90px;
  top:50px;
`

const WrapLeftSide = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  left: -80px;
  top:50px;
`

const EquipmentName = styled.div`
  position: absolute;
  color: #FFAFAB;
  font-size: 1.75rem;
  font-family: var(--title-font);
  top: 25px;
  /* right: 20px; */
`

const WrapBackground = styled.div`
  position: relative;
  width: 150px;
  height: 155px;

  div:nth-child(2) {
    ${props => props.side == 'left' ? 'right: 20px' : 'left: 15px'};
  }
  div:last-child {
    ${props => props.side == 'left' ? 'right: 1px' : 'left: 1px'};
  }
`

const Background = styled.div`


  background: #BF4040;
  top: -10px;
  width: 102%;
  position: relative;
  height: 102%;
  transform: perspective(100px) rotate3d(-2, 1, -8, -2deg) scale(1);
`


const EquippableWeaponBg = styled.div`
  position: absolute;
  top: 50px;
  /* left: 20px; */
  width: 100px;
  height: 100px;
  background: #fff;
  transform: ${props => props.transform};
`

const EquippableBg = styled.div`
  position: absolute;
  top: 60px;
  /* left: 20px; */
  /* width: 100px; */
  /* height: 70px; */
  width: 100px;
  height: 80px;
  /* transform: ${props => props.transform}; */
`


const WeaponSlotBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 140px;
  height: 155px;
  transform: ${props => props.transform};
`

const EquipmentSlotBg = styled.div`
  position: absolute;
  top: 5px;
  left: 0;
  width: 150px;
  height: 155px;

  background: #BF4040;
  transform: ${props => props.transform};
`

const EquipmentSlot = styled.div`
  position: relative;
  color: #fff;
`

const animIn = keyframes`
  0% { transform: translate(0, 20px) scale(0.95); }
  100% { transform: translate(0, 0) scale(1);  }
`

const Wrapper = styled.div`
  position: relative;
  left: -50px;
  z-index: 100;
  width: 660px;
  height: 734px;
  opacity: 1;
  /* animation: ${props => props.isVisible && animIn} 0.3s cubic-bezier(0.1, 0.694, 0.1, 0.96) forwards; */
`

export default PaperDoll