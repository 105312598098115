import styled from "styled-components"

const Response = ({ data }) => {
  const handleButton = () => {

  }

  return <Wrapper>
    <Options>
      {data.choices.map((option, index) => <Option onClick={() => handleButton(index)} key={index}>
        {/* <ComicBubble viewBox="0 0 616 260.9">
          <path d="M430 50 23.5 28 0 188.2l404.2-21.1 32.7 19.7 36.5-5.7 122.4 59.3L468.6 86.8 450 99Z" />
          <path fill="#fff" d="M420.3 64.6 34.4 44 14.1 173.6l394.7-19 28.6 18 34.4-5.4 97.8 51.4-101.7-109.1-30.3 14.6Z" data-name="Dialogue" />
        </ComicBubble> */}
        <Text>{option}</Text>
      </Option>)}
    </Options>
  </Wrapper>
}

const Text = styled.div`
  color: #000; 
  font-size: 1.4rem;
  left: 0;
`

const ComicBubble = styled.svg`
  position: absolute;
  top: -40px;
  left: 0;
  width: 500px;
  height: 200px;
`

const Option = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  height: 150px;
  background: red;

  &:hover {
    scale: 1.05;
    cursor: pointer;
  }

  &:active {
    filter: brightness(0.9);
    transform: translateY(2px);
  }
`

const Options = styled.div`
  display: flex;
  row-gap: 20px;

  flex-direction: column;
`

const Wrapper = styled.div`
  display: flex;
  font-family: var(--primary-text-font);
  align-items: center;
  flex-direction: column;
  justify-content: flex-end;
  position: absolute;
  color: #fff;
  z-index: 12;
  padding: 0 3vw;
  left: 50%;
  bottom:100px;
  transform: translateX(-50%);
`

export default Response

