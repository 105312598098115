import { useState, useEffect } from 'react'
import styled, { keyframes } from "styled-components";
import { useAtom } from 'jotai';
import { questData } from 'Project/Game/data/gameData';
import { questsAtom, camAtom, interfaceAtom } from 'Project/Game/data/atoms';
import { overworldMarkers, areaMarkers } from "Project/Game/data/gameData"
import { ZOOM, MAP_AREA, MODULE_TYPE } from 'Project/Game/const';
import { Howl } from 'howler';
import Response from './Response';
import Combat from './Combat';
import Description from './Description';
import Dialogue from './Dialogue';
import PickClass from './PickClass';
import { QUEST_TYPE } from 'Project/Game/const';
import { findQuest } from './helpers';
import dayjs from 'dayjs';

var chatOutSound = new Howl({
  src: ['/sound/dialoguepanel_convo_end.wav'],
  autoplay: false,
  loop: false,
  volume: 0.8,
});

var chatInSound = new Howl({
  src: ['/sound/dialoguepanel_panelanimatein.wav'],
  autoplay: false,
  loop: false,
  volume: 0.8,
});

const NewModule = () => {
  const [interfaceState, setInterfaceState] = useAtom(interfaceAtom);
  const [userQuests, setUserQuests] = useAtom(questsAtom);
  const [cameraState, setCameraState] = useAtom(camAtom);
  const [activeQuest, setActivateQuest] = useState(null);
  const [module, setModule] = useState({
    key: 0,
    type: MODULE_TYPE.None,
    position: 0,
    data: null
  })

  useEffect(() => {
    let timer;

    const activateModule = () => {
      chatInSound.play();

      const activeMarkerBuildingId = cameraState.activeMarker.id;

      let activeQuest;

      userQuests.forEach(quest => {
        let fullQuestData = questData.find(questData => quest.id === questData.id);
        let thisQuestType = fullQuestData.questType;
        console.log('quest', quest)

        let isQuestTimeActive = quest.endTime === null || quest.endTime < Date.now();
        let isQuestTimeTiming = quest.endTime !== null && quest.endTime > Date.now();
        console.log('isQuestTimeTiming', isQuestTimeTiming)

        if (thisQuestType === QUEST_TYPE.Combat) {
          console.log('This is a combat quest')
        }

        if (isQuestTimeActive)
          activeQuest = fullQuestData
      })

      // const activeQuest = questData.find(quest => quest.buildingId === activeMarkerBuildingId);

      setActivateQuest(activeQuest);
      setModule(prev => {
        return { ...prev, data: activeQuest.modules, type: activeQuest.modules[0].type }
      });
    }

    const activeMarkerArea = cameraState.activeMarker.areaType; // once in building, set to map area slums? unclear
    if (activeMarkerArea === MAP_AREA.Slums)
      activateModule();

    return () => {
      clearTimeout(timer);
    }
  }, [userQuests, cameraState])

  const handleNextModule = () => {
    let areModulesComplete = module.position >= activeQuest.modules.length - 1;

    const incrementModule = () => {
      setModule(prev => {
        return { ...prev, position: prev.position + 1, type: activeQuest.modules[prev.position + 1].type, key: activeQuest.modules[prev.position + 1].id }
      });
    }

    if (areModulesComplete)
      handleEndQuest(activeQuest.id, activeQuest.questType);
    else
      incrementModule();
  }

  const resetModuleState = () => {
    setModule({
      type: MODULE_TYPE.None,
      position: 0,
      data: null
    })
  }

  const handleQuestState = (id) => {
    setUserQuests(prev => {
      let currentQuestEndTime;
      const setCurrentQuestEndTime = (id) => {
        return prev.map(quest => {
          if (quest.id === id) {
            const questFromDatabase = findQuest(id);
            const duration = questFromDatabase.duration;

            currentQuestEndTime = Date.now() + duration * 1000
            // var baselineTime = new Date();

            // let nowBaseline = dayjs(baselineTime);
            // let now = dayjs(time);
            // console.log('now is', now);
            // console.log('now baseline is', nowBaseline);
            return { ...quest, endTime: currentQuestEndTime, rewardsClaimed: true }
          }
          return quest;
        })
      }

      let activeQuestData = questData.find(quest => quest.id === id);
      let nextQuestId = activeQuestData.nextQuestId
      let isNextQuestValid = nextQuestId !== -1
      let updatedQuestState = setCurrentQuestEndTime(id);

      if (isNextQuestValid && currentQuestEndTime <= Date.now()) {
        updatedQuestState = [...updatedQuestState, { id: nextQuestId, startTime: Date.now(), endTime: null, rewardsClaimed: false }]
      }

      return updatedQuestState;
    })
  }

  const handleQuestCombatState = (id) => {
    let currentQuestEndTime;
    setUserQuests(prev => {
      const setCurrentQuestEndTime = (id) => {
        return prev.map(quest => {
          if (quest.id === id) {
            const questFromDatabase = findQuest(id);
            const duration = questFromDatabase.duration;

            currentQuestEndTime = duration * 1000
            // var baselineTime = new Date();

            // let nowBaseline = dayjs(baselineTime);
            // let now = dayjs(time);
            // console.log('now is', now);
            // console.log('now baseline is', nowBaseline);
            return { ...quest, startTime: Date.now(), endTime: Date.now() + currentQuestEndTime, rewardsClaimed: false }
          }
          return quest;
        })
      }

      let updatedQuestState = setCurrentQuestEndTime(id);
      return updatedQuestState;
    })

    // console.log('current quest end time', currentQuestEndTime)

    const timer = setTimeout(() => {
      setUserQuests(prev => {
        const setCurrentQuestEndTime = (id) => {
          return prev.map(quest => {
            if (quest.id === id) {
              const questFromDatabase = findQuest(id);
              const duration = questFromDatabase.duration;

              currentQuestEndTime = duration * 1000
              // var baselineTime = new Date();

              // let nowBaseline = dayjs(baselineTime);
              // let now = dayjs(time);
              // console.log('now is', now);
              // console.log('now baseline is', nowBaseline);
              return { ...quest, startTime: Date.now(), endTime: Date.now() + currentQuestEndTime, rewardsClaimed: true }
            }
            return quest;
          })
        }

        let activeQuestData = questData.find(quest => quest.id === id);
        let nextQuestId = activeQuestData.nextQuestId
        let isNextQuestValid = nextQuestId !== -1
        let updatedQuestState = setCurrentQuestEndTime(id);

        if (isNextQuestValid)
          updatedQuestState = [...updatedQuestState, { id: nextQuestId, startTime: Date.now(), endTime: null, rewardsClaimed: false }]

        return updatedQuestState;
      })
    }, currentQuestEndTime);
  }


  const handleEndQuest = (id, questType) => {
    chatOutSound.play();
    resetModuleState();

    // add end date to current finish quest
    if (questType === QUEST_TYPE.Combat) {
      handleQuestCombatState(id);
    } else {
      handleQuestState(id)
    }

    // set camera to go out of building
    let area = areaMarkers.find(area => area.type === cameraState.activeMarker.areaType);
    setCameraState(prev => {
      return {
        ...prev,
        position: area.data.position,
        target: area.data.target,
        zoom: ZOOM.Area,
        activeMarker: area
      }
    })

    // reset interface state
    setInterfaceState(prev => {
      return {
        ...prev,
        player: {
          ...prev.player,
          main: false,
          mini: true
        },
        quests: {
          ...prev.quests,
          main: false,
          mini: true,
          selected: -1
        }
      }
    })

    setActivateQuest(null);
  }

  const handleCombatModule = () => {

  }


  return <>
    {
      module.data && activeQuest && <Wrapper isVisible={module.data[module.position]}>
        <SolidColor />
        <BuildingImg src={activeQuest.img} alt="" />
        <BuildingImgFg src={activeQuest.foreground} alt="" />
        {module.type === MODULE_TYPE.Description && <Description key={module.key} handleNextModule={handleNextModule} data={module.data[module.position]} />}
        {/* {module.type === MODULE_TYPE.Dialogue && <Dialogue key={module.key} handleNextModule={handleNextModule} isFirst={!module.position > 0} data={module.data[module.position]} />} */}
        {/* {module.type === MODULE_TYPE.Response && <Response key={module.key} handleNextModule={handleNextModule} data={module.data[module.position]} />} */}
        {module.type === MODULE_TYPE.Combat && <Combat key={module.key} handleNextModule={handleNextModule} data={module.data[module.position]} />}
        {/* {module.type === MODULE_TYPE.PickClass && <PickClass key={module.key} handleNextModule={handleNextModule} data={module.data[module.position]} />} */}
      </Wrapper>
    }
  </>
}

const animFadeBg = keyframes`
  0% { opacity: 0; backdrop-filter: blur(0); }
  100% { opacity: 1; backdrop-filter: blur(0); }
`

const SolidColor = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.6);
  z-index: 2;
  opacity: 0; backdrop-filter: blur(0);
  animation: ${animFadeBg} 0.8s 0.2s cubic-bezier(0.6, 0.9, 0.1, 1) forwards;
`

const animInBg = keyframes`
  0% { opacity: 0;  transform: translate(-10px, 0); }
  100% { opacity: 1;  transform: translate(0, 0); }
`

const animInFg = keyframes`
  0% { opacity: 0.7;  transform: translate(-80px, 0); }
  100% { opacity: 1;  transform: translate(0, 0); }
`

const BuildingImgFg = styled.img`
  position: absolute;
  bottom: -430px;
  width: 2400px;
  left: calc(15vw - 56rem);
  opacity: 0;
  min-width: 1200px;
  z-index: 2;
  animation: ${animInFg} 4s 0.2s cubic-bezier(0.1, 0.7, 0.1, 1) forwards;
`

const BuildingImg = styled.img`
  position: absolute;
  bottom: -430px;
  width: 2400px;
  left: calc(15vw - 56rem);
  opacity: 0;
  min-width: 1200px;
  z-index: 2;
  animation: ${animInBg} 4s 0.2s cubic-bezier(0.1, 0.7, 0.1, 1) forwards;
`

const Wrapper = styled.div`
  ${props => props.isVisible ? 'display: block' : 'display: none'};
`

export default NewModule;