import styled, { keyframes } from 'styled-components';
import Svg from 'components/SVG';
import { Background } from 'react-parallax';

const animEnlarge = keyframes`
  0% { transform: scale(1) skew(0deg, 0deg) translate(0, 0); }
  100% { transform: scale(1.4) skew(-3deg, 2deg) translate(-24px, -12px); }
`;

const animEnlargeFox = keyframes`
  0% { transform: scale(1)  translate(0, 0); }
  100% { transform: scale(1.4)  translate(-26px, -13px); }
`;

const EnlargePolygon = styled.polygon`
  animation: ${animEnlarge} var(--mint-success-time) cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`;

const EnlargeG = styled.g`
  animation: ${animEnlargeFox} var(--mint-success-time) cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`;

const animLeftShards = keyframes`
  0% { transform: translate(0, 0) scale(1); }
  100% { transform: translate(-21px, -2px) scale(1.05); }
`;

const animRightShards = keyframes`
  0% { transform: translate(0, 0) skew(0deg, 0deg) scale(1); }
  100% { transform: translate(25px, -3px) skew(3deg, -2deg) scale(1.05); }
`;

const animTopShards = keyframes`
  0% { transform: translate(0, 0) skew(0deg, 0deg) scale(1); }
  100% { transform: translate(-8px, -14px) skew(3deg, -2deg) scale(1.1); }
`;

const animBottomShards = keyframes`
  0% { transform: translate(0, -4px) scale(1); }
  100% { transform: translate(-5px, 9px) scale(1.05); }
`;

const LeftShardGroup = styled.g`
  animation: ${animLeftShards} var(--mint-success-time) cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`;

const BackgroundLeftShard = styled(LeftShardGroup)`
  animation-timing-function: cubic-bezier(0.26, 0.82, 0.165, 1);
`;

const MidgroundLeftShard = styled(LeftShardGroup)`
  animation-timing-function: cubic-bezier(0.15, 0.82, 0.165, 1);
`;

const ForegroundLeftShard = styled(LeftShardGroup)`
  animation-timing-function: cubic-bezier(0.02, 0.82, 0.165, 1);
`;

const RightShardGroup = styled.g`
  animation: ${animRightShards} var(--mint-success-time) cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`;

const BackgroundRightShard = styled(RightShardGroup)`
  animation-timing-function: cubic-bezier(0.26, 0.82, 0.165, 1);
`;

const MidgroundRightShard = styled(RightShardGroup)`
  animation-timing-function: cubic-bezier(0.15, 0.82, 0.165, 1);
`;

const ForegroundRightShard = styled(RightShardGroup)`
  animation-timing-function: cubic-bezier(0.02, 0.82, 0.165, 1);
`;

const TopShardGroup = styled.g`
  animation: ${animTopShards} var(--mint-success-time) cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`;

const BackgroundTopShard = styled(TopShardGroup)`
  animation-timing-function: cubic-bezier(0.26, 0.82, 0.165, 1);
`;

const MidgroundTopShard = styled(TopShardGroup)`
  animation-timing-function: cubic-bezier(0.15, 0.82, 0.165, 1);
`;

const ForegroundTopShard = styled(TopShardGroup)`
  animation-timing-function: cubic-bezier(0.02, 0.82, 0.165, 1);
`;

const BottomShardGroup = styled.g`
  animation: ${animBottomShards} var(--mint-success-time) cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`;

const BackgroundBottomShard = styled(BottomShardGroup)`
  animation-timing-function: cubic-bezier(0.26, 0.82, 0.165, 1);
`;

const MidgroundBottomShard = styled(BottomShardGroup)`
  animation-timing-function: cubic-bezier(0.15, 0.82, 0.165, 1);
`;

const ForegroundBottomShard = styled(BottomShardGroup)`
  animation-timing-function: cubic-bezier(0.02, 0.82, 0.165, 1);
`;

export const SuccessSVG = () => (
  <Success viewBox="0 0 200 120" overflow="visible">
    <g id="Layer_1">
      <g id="Background_00000034804221156088010600000002424784751071390619_">
        <EnlargePolygon
          id="Base_00000122715374964180487920000001687733409364686730_"
          fill="var(--mint-success-background)"
          points="74.11,33.89 75.11,34.78 
			69.89,38.11 62.56,37.11 65,39.44 64.78,41 59.33,44.33 55.67,43.44 53.11,44.56 55.56,50.22 45.11,57.56 36.44,57.56 
			38.33,62.22 44.11,62.89 51.67,71.11 49.78,74 56.56,73.67 61.78,76.56 58.56,80 62.67,80.11 66.89,79.89 63.11,85.67 
			67.33,82.78 74.56,82.89 77.22,87 85.11,90.89 84.67,88.89 86.56,89.67 91.22,87.67 93,88.56 94.56,84.89 111.22,84.22 
			113.89,82.67 123.11,86.78 123.78,82.22 132.89,74.67 147.11,72.89 152.33,69.33 151.89,64.11 154.22,61.56 166,60.89 
			157.11,59.44 145.11,40.44 145.22,43.33 136.89,43.67 135.78,42.56 136.22,40.56 134.78,38.78 130.67,39.56 132.78,36.89 
			134,31.67 129.78,26.89 127.33,34 123.78,35.22 115.67,33.89 114.56,30.22 112,32.56 110,33.22 108.89,31.44 111.56,21.44 
			102.56,29 95.44,24.11 98.22,32 94.44,32.78 89.11,23.44 87.22,23.11 87.11,25.89 85.67,24.89 85.44,29.78 81.78,31.44 		"
        />
        <g id="Shards_00000163794785409719787910000005753665934752069048_">
          <BackgroundTopShard id="Top_00000123407895822817888330000008367957386558755734_">
            <polygon fill="var(--mint-success-background)" points="84.58,32.08 71.92,27.42 72.75,31.67 85.58,33.75 				" />
            <path
              fill="var(--mint-success-background)"
              d="M65.92,32.75c0,0,3.58,2.33,3.83,2.33s15.5-3.75,15.5-3.75l-7.5-1.17L65.92,32.75z"
            />
            <polygon fill="var(--mint-success-background)" points="69.5,22 83.17,12.67 86.75,14.08 				" />
            <polygon fill="var(--mint-success-background)" points="130.92,26.25 144.67,28.83 142.75,26.17 				" />
            <polygon fill="var(--mint-success-background)" points="127,26.58 121.17,32.5 128.75,31.75 130,29.75 				" />
            <polygon fill="var(--mint-success-background)" points="113.08,21.42 116.25,12 119,12.5 117.75,17.33 				" />
            <polygon fill="var(--mint-success-background)" points="90.75,22.5 99,24.25 102.75,23.17 102.33,18.42 				" />
            <polygon fill="var(--mint-success-background)" points="83.17,20.92 83.17,25.92 86.17,22.08 85.33,19.25 				" />
            <polygon fill="var(--mint-success-background)" points="93.92,8.67 94.33,12.75 96.17,12.33 97,8.92 96.83,6.25 				" />
            <polygon fill="var(--mint-success-background)" points="68.67,20.08 71.17,18.58 74,33.08 				" />
            <polygon fill="var(--mint-success-background)" points="126.5,21.17 128.25,22.25 131.33,22.5 128,20.25 124.5,20.25 				" />
            <polygon fill="var(--mint-success-background)" points="61,10.22 62.67,11.67 63.33,10.56 				" />
            <polygon fill="var(--mint-success-background)" points="52.67,32.22 52.67,35 53.67,35.89 				" />
            <polygon fill="var(--mint-success-background)" points="146.33,13.33 148.78,11.33 147.56,14.22 				" />
            <path fill="var(--mint-success-background)" d="M113.67,24.58c0.33,0.25,3.17,3.75,3.17,3.75l3.33,0.5l-2.75-4.33L113.67,24.58z" />
          </BackgroundTopShard>
          <BackgroundBottomShard id="Bottom_00000103241772816097271080000009947943504741697960_">
            <path fill="var(--mint-success-background)" d="M91.75,93.58c1.33,0.17,7.75,4.33,7.75,4.33l5-5.5l-9.17-0.83L91.75,93.58z" />
            <polygon fill="var(--mint-success-background)" points="107.25,93.75 108.25,98.92 111.67,100.25 110,95.83 				" />
            <polygon fill="var(--mint-success-background)" points="116.42,93.5 119.83,90.42 122.58,90.92 119.83,94.08 				" />
            <polygon fill="var(--mint-success-background)" points="89.17,99.33 88.33,86.92 91.5,99.17 				" />
            <polygon fill="var(--mint-success-background)" points="75,90 78.67,95.58 78.33,82.33 				" />
            <polygon fill="var(--mint-success-background)" points="104.42,99.83 104.25,105.83 105.33,111.25 105.67,103.25 				" />
            <polygon fill="var(--mint-success-background)" points="114.5,103.08 116.33,105.83 116.58,101.5 				" />
            <polygon fill="var(--mint-success-background)" points="161.89,98.56 162.22,96.67 163.11,96.67 				" />
            <polygon fill="var(--mint-success-background)" points="138.56,90.78 136,93.33 139,91.89 				" />
          </BackgroundBottomShard>
          <BackgroundRightShard id="Right_00000125603195476416918130000003636336957815473304_">
            <polygon fill="var(--mint-success-background)" points="155.92,42.25 162,40.58 164.83,42.75 160.92,43.67 				" />
            <polygon fill="var(--mint-success-background)" points="190.67,65.5 196.08,57.83 198,61.75 				" />
            <polygon fill="var(--mint-success-background)" points="132.25,69.33 137.08,83 136.75,67.17 				" />
            <polygon fill="var(--mint-success-background)" points="133.42,40.5 136.67,36.83 141.75,36.83 137.58,39.33 				" />
            <path
              fill="var(--mint-success-background)"
              d="M144.42,52l7.75-5.92c0,0-6.17,12.17-6.25,12.42C145.83,58.75,144.42,52,144.42,52z"
            />
            <polygon fill="var(--mint-success-background)" points="162.44,52 163.44,52.89 165.22,51.22 				" />
            <polygon fill="var(--mint-success-background)" points="134,105.33 133.89,106.78 135.44,106.56 				" />
            <polygon fill="var(--mint-success-background)" points="194.22,74 194.33,75.78 195.67,75.89 				" />
            <polygon fill="var(--mint-success-background)" points="154.25,75.67 157.83,79.75 160.08,78.5 				" />
            <polygon fill="var(--mint-success-background)" points="178.58,58.75 175.08,69.42 181.83,62.5 184,54.5 				" />
          </BackgroundRightShard>
          <BackgroundLeftShard id="Left_00000020372521041659331950000015004299170232299140_">
            <polygon fill="var(--mint-success-background)" points="63.75,44 61.25,32 68.08,42.25 68.25,47.42 				" />
            <polygon fill="var(--mint-success-background)" points="26,36.25 32.75,44.17 36.67,46.25 36.33,43.25 				" />
            <polygon fill="var(--mint-success-background)" points="45,43.83 45.08,41.42 58.75,51.08 				" />
            <polygon fill="var(--mint-success-background)" points="51.58,39 57.25,41.33 53.67,37.5 				" />
            <polygon fill="var(--mint-success-background)" points="26.5,58.33 48,60.67 45,55.17 				" />
            <polygon fill="var(--mint-success-background)" points="38.75,32.58 40.75,35.5 41.33,33.5 				" />
            <polygon fill="var(--mint-success-background)" points="8,59 10.42,55.58 12,57.75 				" />
            <polygon fill="var(--mint-success-background)" points="19.5,62.92 16.83,60.25 18.5,57.83 22.75,61.5 				" />
            <polygon fill="var(--mint-success-background)" points="69.5,95.17 71.5,97.25 73.33,97.33 73.67,95 				" />
            <polygon fill="var(--mint-success-background)" points="43.22,23.44 43.89,24.89 45.44,24.22 				" />
            <polygon fill="var(--mint-success-background)" points="52.33,88.89 55.33,88.89 53.44,87.44 				" />
            <polygon fill="var(--mint-success-background)" points="23.78,71.33 25,70 27.33,70 				" />
            <polygon fill="var(--mint-success-background)" points="67.22,95.78 66.22,98.56 66.89,100.33 				" />
            <polygon fill="var(--mint-success-background)" points="5,57.67 4.11,59.33 4.44,60.44 6.56,60.44 				" />
            <polygon fill="var(--mint-success-background)" points="55.78,100.33 54.11,101.78 56,102 				" />
            <path fill="var(--mint-success-background)" d="M46.42,72.5c0-0.25-5.75,4.25-5.75,4.25l-0.33,4.08L46.42,72.5z" />
          </BackgroundLeftShard>
        </g>
      </g>
      <g>
        <EnlargePolygon
          id="Base_00000180325104676622344080000014470069036106934197_"
          fill="var(--mint-success-midground)"
          points="129,70.44 132.56,70 
			129.67,67.33 132.56,66.89 135.22,59.44 130.67,53.78 128.22,53.22 126.33,51.11 131.78,47.67 121.89,47.89 119.22,48.89 
			113.44,49.78 118.78,44 124.44,38.67 110.67,47.33 107.11,45.22 108,41.22 104.33,38.22 100.89,44.44 93.11,42.78 98.67,37.44 
			89.78,37.22 85.56,40.56 82.11,37.22 83.44,42.11 79.89,44.44 72.44,41.33 75.67,46.22 71.67,48.33 61.11,47.11 66.33,51.67 
			61.67,56.44 58.78,55.89 58.22,59.78 61,63.67 65.44,64.78 65.33,70.67 70.56,70.11 67,73.11 72.33,74.78 74.89,72.67 
			77.44,73.22 77.78,77.78 82.22,74.67 84.33,75.78 80,78.56 86.89,81 100.67,75.56 102.56,82.89 104.33,80.89 103.56,76.67 
			107,79.78 108.11,78 112.22,79.78 109.78,75.44 117.22,80 116.22,76.44 124,78.22 115.56,71.22 		"
        />
        <g id="Shards_00000140730427681192228660000002546866883208230282_">
          <MidgroundBottomShard id="Bottom_00000016036262725108824240000010335177746172947106_">
            <polygon fill="var(--mint-success-midground)" points="79.76,108.11 79.53,112.78 83.74,110.62 82.95,107.77 				" />
            <polygon fill="var(--mint-success-midground)" points="114.25,85.58 121.64,92.97 120.16,85.35 116.18,82.73 				" />
            <polygon fill="var(--mint-success-midground)" points="89.55,90.58 109.69,90.24 111.17,94 				" />
            <polygon fill="var(--mint-success-midground)" points="108.44,88.42 111.17,87.51 112.54,89.22 				" />
          </MidgroundBottomShard>
          <MidgroundRightShard id="Right_00000145018958529862717050000011523915276324941470_">
            <polygon fill="var(--mint-success-midground)" points="133.95,59.37 132.02,63.81 136.57,64.94 139.53,61.87 142.37,54.02 				" />
            <polygon fill="var(--mint-success-midground)" points="147.27,60.62 144.76,75.64 150.11,67.33 150,62.1 				" />
            <polygon fill="var(--mint-success-midground)" points="145.1,52.08 148.75,57.77 155.12,59.03 142.71,42.41 				" />
            <polygon fill="var(--mint-success-midground)" points="124.16,81.67 129.28,76.55 142.03,74.85 133.5,82.59 127.35,84.75 				" />
            <polygon fill="var(--mint-success-midground)" points="160.7,59.94 160.24,64.15 164.11,65.74 162.86,60.62 				" />
            <g>
              <polygon
                fill="var(--mint-success-midground)"
                points="125.3,64.83 132.81,75.76 133.95,75.64 131.45,60.73 129.17,58.8 124.39,61.87 					"
              />
            </g>
            <polygon fill="var(--mint-success-midground)" points="111.87,71.66 128.6,74.96 120.41,66.42 114.94,67.11 				" />
            <polygon fill="var(--mint-success-midground)" points="130.2,55.27 129.51,57.09 130.88,58.23 				" />
            <polygon fill="var(--mint-success-midground)" points="138.39,66.99 139.76,63.01 140.78,66.08 138.85,69.38 				" />
          </MidgroundRightShard>
          <MidgroundLeftShard id="Left_00000011744817622936079710000004225397527300664468_">
            <polygon fill="var(--mint-success-midground)" points="38.46,61.3 47.68,59.6 46.2,62.9 42.79,63.35 				" />
            <polygon fill="var(--mint-success-midground)" points="60.43,79.97 54.74,81.67 58.27,83.72 60.32,83.72 				" />
            <polygon fill="var(--mint-success-midground)" points="63.62,43.78 64.53,45.82 67.49,45.48 71.47,43.09 65.89,42.64 				" />
            <polygon fill="var(--mint-success-midground)" points="67.94,80.65 74.88,92.49 72.95,82.81 				" />
            <polygon fill="var(--mint-success-midground)" points="72.95,88.62 83.42,92.83 90.02,89.98 80.69,87.48 				" />
            <polygon fill="var(--mint-success-midground)" points="55.19,72.68 70.44,77.8 56.1,75.87 				" />
            <polygon fill="var(--mint-success-midground)" points="43.47,69.61 45.75,75.19 47.45,72.57 				" />
            <polygon fill="var(--mint-success-midground)" points="51.32,38.2 54.97,39.56 57.24,39 56.9,37.29 				" />
            <polygon fill="var(--mint-success-midground)" points="10.69,53.9 9.56,60.16 11.83,65.29 15.59,58.8 				" />
            <polygon fill="var(--mint-success-midground)" points="55.88,48.56 62.93,56.75 66.92,54.59 61.68,50.04 				" />
            <polygon fill="var(--mint-success-midground)" points="62.59,60.39 53.83,59.14 48.25,65.74 52.92,66.42 				" />
            <polygon fill="var(--mint-success-midground)" points="84.56,75.07 75.11,81.33 84.9,84.86 90.02,74.39 				" />
            <polygon fill="var(--mint-success-midground)" points="74.43,67.11 60.66,67.22 58.72,71.43 65.78,72.8 				" />
            <polygon fill="var(--mint-success-midground)" points="46.66,62.78 46.09,65.17 48.02,63.35 				" />
            <polygon fill="var(--mint-success-midground)" points="52.01,76.44 50.53,79.17 52.23,80.31 				" />
            <polygon fill="var(--mint-success-midground)" points="65.44,91.23 64.98,94.08 66.57,93.97 				" />
            <polygon fill="var(--mint-success-midground)" points="28.45,32.39 28.45,34.1 29.7,33.76 				" />
          </MidgroundLeftShard>
          <MidgroundTopShard id="Top_00000108268662930947713310000012128717460537046151_">
            <polygon
              fill="var(--mint-success-midground)"
              points="68.4,37.63 75.79,44.12 79.09,42.18 79.78,40.47 77.73,38.08 69.88,36.72 				"
            />
            <polygon fill="var(--mint-success-midground)" points="84.22,30.57 89.22,37.97 89.91,33.53 85.92,27.84 83.65,26.82 				" />
            <polygon fill="var(--mint-success-midground)" points="110.51,34.67 128.15,52.99 108.8,37.17 				" />
            <polygon fill="var(--mint-success-midground)" points="131.22,43.43 130.76,52.43 133.04,47.76 132.93,41.16 				" />
            <polygon fill="var(--mint-success-midground)" points="121.43,50.04 125.87,33.87 128.6,35.24 127.01,43.09 				" />
            <polygon fill="var(--mint-success-midground)" points="136.57,49.81 138.05,56.64 139.3,56.64 140.32,50.38 139.3,47.53 				" />
            <polygon fill="var(--mint-success-midground)" points="53.71,54.59 59.06,53.45 58.72,54.47 55.08,56.64 				" />
            <polygon fill="var(--mint-success-midground)" points="108.46,21.92 97.76,33.3 106.07,32.74 109.94,24.54 				" />
            <polygon fill="var(--mint-success-midground)" points="91.84,27.27 94,33.99 96.51,33.99 98.21,31.71 98.33,29.78 92.75,25.91 				" />
            <polygon fill="var(--mint-success-midground)" points="115.74,53.45 129.4,49.12 120.86,55.5 				" />
            <polygon fill="var(--mint-success-midground)" points="63.27,47.64 64.41,49.01 65.89,47.99 				" />
            <polygon fill="var(--mint-success-midground)" points="111.99,41.95 111.64,43.55 112.67,43.55 				" />
            <polygon fill="var(--mint-success-midground)" points="62.71,40.13 61.57,42.07 63.5,42.41 				" />
            <polygon fill="var(--mint-success-midground)" points="103.34,19.31 102.08,20.22 102.2,21.58 				" />
          </MidgroundTopShard>
        </g>
      </g>
      <g id="Foreground_00000163795092208453203540000013586441714889740165_">
        <EnlargePolygon
          id="Base"
          fill="var(--mint-success-foreground)"
          points="83.67,44.56 84.78,52.33 82.11,53.67 83.56,55.33 82.67,57.44 80.22,57.78 81.22,59.11 
			80.22,59.33 82.22,61.11 77.89,65.67 85.11,64.44 79.89,68.33 86.33,66.56 74.56,76.67 89.89,66 92.44,68.44 94.11,65.56 
			95,66.33 94.67,67.56 95.33,68.44 93.89,70.22 95,71.89 96.56,70.11 99.44,70.22 97.78,68.11 98.56,66.33 101.22,71 104.67,66.67 
			108.33,69.44 110.56,67.33 109.67,64.67 117.33,68.22 110.56,62.89 114.44,63 123.78,65 115.11,61.22 117.44,56.33 114.11,51.44 
			115,50.44 113.44,47.56 110,49 109.33,47.89 107.11,49.11 104.44,52.78 102,50.11 103.11,45.22 99.67,48.56 97.67,45.56 
			88.44,45.33 85.67,46.33 		"
        />
        <g id="Shards">
          <ForegroundBottomShard id="Bottom">
            <polygon fill="var(--mint-success-foreground)" points="79.89,70.78 92.56,76.22 88.16,80.4 85.67,78.89 				" />
            <polygon fill="var(--mint-success-foreground)" points="76.89,74.22 77.78,78.56 84.33,70.33 				" />
            <polygon fill="var(--mint-success-foreground)" points="101.56,76.22 108.33,69.44 113.89,72.89 114.44,75.89 				" />
          </ForegroundBottomShard>
          <ForegroundRightShard id="Right">
            <polygon fill="var(--mint-success-foreground)" points="99.78,47.11 111.56,34.67 105.78,49 				" />
            <polygon fill="var(--mint-success-foreground)" points="127.67,67.56 120.78,73.67 127.22,72 				" />
            <polygon fill="var(--mint-success-foreground)" points="126.56,48.44 124.89,56.56 125.67,58.67 127.11,52.22 				" />
            <polygon fill="var(--mint-success-foreground)" points="122.11,56.33 121.22,60.56 123.56,61.33 123.33,56.89 				" />
            <polygon fill="var(--mint-success-foreground)" points="107.33,62 123.78,65 109.11,58.11 				" />
            <polygon fill="var(--mint-success-foreground)" points="107,55 120.11,50.89 111.67,59.56 				" />
          </ForegroundRightShard>
          <ForegroundLeftShard id="Left">
            <polygon fill="var(--mint-success-foreground)" points="68.56,55.33 68.56,63.56 74.33,54 				" />
            <polygon fill="var(--mint-success-foreground)" points="71.11,63.56 80.22,64.44 80.22,67.11 				" />
            <polygon fill="var(--mint-success-foreground)" points="71.22,72.33 83.78,47.89 78.33,68.22 72.78,74.89 				" />
            <polygon fill="var(--mint-success-foreground)" points="71.78,49.78 75.67,53 81.78,52.44 80,50.11 				" />
            <polygon fill="var(--mint-success-foreground)" points="80.33,68.11 89.89,61.78 94.44,66.78 				" />
            <polygon fill="var(--mint-success-foreground)" points="61.33,57.67 62.78,60 63.89,58.56 62.78,57.11 				" />
          </ForegroundLeftShard>
        </g>
      </g>
      <EnlargeG id="Fox">
        <g>
          <g>
            <g>
              <path
                fillRule="evenodd"
                fill="#2C1329"
                d="M109.8,60.84c-1.62,1.63-4.96,4.38-9.64,5.23c-6.78,1.22-12.47-2.19-12.67-2.86
						c-0.05-0.14,0.57-1.63,0.54-2.74c-0.16-5.68,0.64-12.59,8.42-13.88c0,0,4.4-1.55,8.05,1.76c3.65,3.32,5.4,9.82,5.4,9.82
						S110.52,60.11,109.8,60.84z"
              />
              <path
                fillRule="evenodd"
                fill="#2C1329"
                d="M107.51,58.72c0,0,0.49-2.69,1.01-2.69c0.51-0.01,0.52,1.26,0.52,1.26
						s0.36-2.53,0.98-2.57c0.62-0.05,1.88,3.92-0.26,6.15"
              />
              <path
                fillRule="evenodd"
                fill="#2C1329"
                d="M92.56,60.99c-0.4-1.41-1.3-2.36-1.84-2.39c-0.54-0.02-0.26,1.9-0.26,1.9
						s-0.48-1.85-1.26-2.24c-0.78-0.39-0.35,2.48-0.35,2.48s-1.17-1.93-1.82-2.41c-0.65-0.48-1.41,0.73-0.63,2.94
						c0.61,1.72,1.47,2.35,1.47,2.35"
              />
            </g>
            <path
              fill="#2C1329"
              d="M92.37,74.28c-0.82-0.65-1.67-1.21-2.5-1.81c0,0.67,0.01,1.32,0.01,1.99l-4.23,0.05L87.68,78l-1.41,0.92
					l1.89,1.48c0,0-0.86,1.5-0.8,1.45c1.01,0.23,2.08,0.21,3.08-0.07l0.4,0.09l0.08,0.57l0.66-0.08l0.11,0.51
					c0,0,0.49-0.22,0.55-0.19c1.31,0.58,2.84,0.68,4.24,0.24c0.33-0.19,0.75-0.2,1.08-0.02c1.78,0.05,3.57-0.79,4.66-2.21l0.52,0.4
					l0.12-0.93l0.71-0.01c0,0-0.21-0.77-0.2-0.92c1.21-1.61,1.73-3.72,1.4-5.7c-0.09-0.5-0.4-0.96-0.83-1.22
					c-0.44-0.26-0.99-0.32-1.45-0.23c-0.95,0.88-1.69,1.4-2.87,1.92l-0.7-0.41l0.13,0.68l-0.93-0.26c0,0,0.19,0.83,0.13,0.87
					c-0.5,0.12-1.32-0.11-1.89,0.02l-0.36-0.5l-0.35,0.41l-0.39-0.37c0,0-0.07,0.42-0.17,0.39C94.13,74.99,93.1,74.85,92.37,74.28z"
            />
          </g>
          <g>
            <path
              fillRule="evenodd"
              fill="#2C1329"
              d="M95.74,47.57c0.43,0.23,0.84,0.45,1.25,0.66c3.71,1.84,6.42,2.27,7.77,2.36
					c0.62,0.04,0.95,0.01,0.95,0.01c0.35-1.29,0.08-6.07,0.08-6.07l-0.84,0.85c-0.22-3.91-1.42-9.15-1.42-9.15
					c-5.18,1.77-6.39,8.24-6.39,8.24c-0.43-1.03-0.43-2.86-0.43-2.86C95.54,43.64,95.74,47.57,95.74,47.57z"
            />
            <path
              fillRule="evenodd"
              fill="#2C1329"
              d="M82.85,49.21c0,0,1.51,3.11,2.79,5.05c1.67,2.54,3.29,3.23,3.88,2.72
					c1.4-1.21,3.85-3.88,4.53-8.29c0.04-0.28,0.08-0.56,0.11-0.86c0,0-2.74-2.8-3.61-4.92c0,0-0.64,0.44-0.42,1.74
					c0,0-4.86-3.06-6.33-6.67c0,0-2.14,6.6,1.23,11.6C85.04,49.59,83.86,48.83,82.85,49.21z"
            />
          </g>
        </g>
        <g>
          <path
            fillRule="evenodd"
            fill="#2C1329"
            d="M104.77,62.84c0,0-5.9,3.5-6.33,3.64c-0.42,0.14,2.43,7.86,6.13,7.5
				c3.72-0.38,7.04-3.83,6.13-4.73C109.79,68.34,104.77,62.84,104.77,62.84z"
          />
          <path
            fillRule="evenodd"
            fill="#2C1329"
            d="M108.11,67.47c0,0,0.3-1.93,1.37-1.57c1.07,0.36,4.03,3.32,3.44,3.68
				c-0.59,0.36-2.42-0.68-2.45-0.72l0,0c0,0,0.8,1.02-0.05,1.27C109.57,70.38,107.7,67.85,108.11,67.47z"
          />
          <path
            fillRule="evenodd"
            fill="#2C1329"
            d="M105.18,70.51c0,0,0.29-1.92,1.37-1.57c1.07,0.36,4.04,3.3,3.45,3.66
				c-0.58,0.36-2.42-0.67-2.45-0.71c0-0.01,0-0.01,0-0.01s0.8,1.01-0.04,1.27C106.65,73.41,104.78,70.89,105.18,70.51z"
          />
        </g>
      </EnlargeG>
    </g>
  </Success>
);

const Success = styled(Svg)``;

export const CheckMarkSvg = () => (
  <CheckMark viewBox="0 0 122.88 109.76">
    <path d="M0,52.88l22.68-0.3c8.76,5.05,16.6,11.59,23.35,19.86C63.49,43.49,83.55,19.77,105.6,0h17.28 C92.05,34.25,66.89,70.92,46.77,109.76C36.01,86.69,20.96,67.27,0,52.88L0,52.88z" />
  </CheckMark>
);

const CheckMark = styled(Svg)`
  fill: #00bd01;
  width: 40px;
`;

export const ParticlesSvg = () => (
  <Particles viewBox="0 0 40 15">
    <g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M23.14,9.31c0.06-0.06,0.17-0.06,0.23,0c0.06,0.06,0.06,0.17,0,0.23c-0.06,0.06-0.17,0.06-0.23,0
			C23.07,9.48,23.07,9.37,23.14,9.31z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M23.19,9.36c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C23.16,9.45,23.16,9.4,23.19,9.36z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M23.13,5.24c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C23.09,5.33,23.09,5.27,23.13,5.24z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M32.86,5.28c0.04-0.04,0.11-0.04,0.15,0c0.04,0.04,0.04,0.11,0,0.15c-0.04,0.04-0.11,0.04-0.15,0
			C32.82,5.39,32.82,5.32,32.86,5.28z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M32.9,5.31c0.02-0.02,0.06-0.02,0.08,0c0.02,0.02,0.02,0.06,0,0.08c-0.02,0.02-0.06,0.02-0.08,0
			C32.87,5.37,32.87,5.34,32.9,5.31z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M32.85,2.64c0.02-0.02,0.06-0.02,0.08,0c0.02,0.02,0.02,0.06,0,0.08c-0.02,0.02-0.06,0.02-0.08,0
			C32.83,2.7,32.83,2.66,32.85,2.64z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M35.38,12.83c0.06-0.06,0.17-0.06,0.23,0c0.06,0.06,0.06,0.17,0,0.23c-0.06,0.06-0.17,0.06-0.23,0
			C35.31,12.99,35.31,12.89,35.38,12.83z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M35.43,12.88c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C35.39,12.97,35.39,12.92,35.43,12.88z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M35.36,8.76c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C35.33,8.85,35.33,8.79,35.36,8.76z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M38.06,9.85c-0.08,0.03-0.18-0.01-0.21-0.09c-0.03-0.08,0.01-0.18,0.09-0.21
			c0.08-0.03,0.18,0.01,0.21,0.09C38.18,9.72,38.14,9.82,38.06,9.85z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M38.03,9.78c-0.05,0.02-0.1,0-0.12-0.05c-0.02-0.05,0-0.1,0.05-0.12c0.05-0.02,0.1,0,0.12,0.05
			C38.09,9.71,38.07,9.76,38.03,9.78z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M36.51,13.61c-0.05,0.02-0.1,0-0.12-0.05c-0.02-0.05,0-0.1,0.05-0.12c0.05-0.02,0.1,0,0.12,0.05
			C36.57,13.54,36.55,13.6,36.51,13.61z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M31.83,3.72c0.06-0.06,0.17-0.06,0.23,0c0.06,0.06,0.06,0.17,0,0.23c-0.06,0.06-0.17,0.06-0.23,0
			C31.77,3.89,31.77,3.79,31.83,3.72z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M31.88,3.78c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C31.85,3.87,31.85,3.81,31.88,3.78z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M38.54,9.97c-0.06,0.05-0.15,0.04-0.21-0.02c-0.05-0.06-0.04-0.15,0.02-0.21
			c0.06-0.05,0.15-0.04,0.21,0.02C38.61,9.82,38.6,9.92,38.54,9.97z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M38.5,9.92c-0.03,0.03-0.08,0.02-0.11-0.01c-0.03-0.03-0.02-0.08,0.01-0.11c0.03-0.03,0.08-0.02,0.11,0.01
			C38.53,9.84,38.53,9.89,38.5,9.92z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M38.24,13.6c-0.03,0.03-0.08,0.02-0.11-0.01c-0.03-0.03-0.02-0.08,0.01-0.11
			c0.03-0.03,0.08-0.02,0.11,0.01C38.28,13.52,38.28,13.57,38.24,13.6z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M32.25,8.25c-0.04,0.03-0.1,0.03-0.13-0.01c-0.03-0.04-0.03-0.1,0.01-0.13c0.04-0.03,0.1-0.03,0.13,0.01
			C32.29,8.15,32.29,8.21,32.25,8.25z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M32.22,8.21c-0.02,0.02-0.05,0.02-0.07-0.01c-0.02-0.02-0.02-0.05,0.01-0.07
			c0.02-0.02,0.05-0.02,0.07,0.01C32.25,8.16,32.24,8.19,32.22,8.21z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M32.06,10.6c-0.02,0.02-0.05,0.02-0.07-0.01c-0.02-0.02-0.02-0.05,0.01-0.07
			c0.02-0.02,0.05-0.02,0.07,0.01C32.08,10.55,32.08,10.58,32.06,10.6z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M28.49,5.79c-0.06,0.05-0.15,0.04-0.21-0.02c-0.05-0.06-0.04-0.15,0.02-0.21
			c0.06-0.05,0.15-0.04,0.21,0.02C28.56,5.64,28.55,5.73,28.49,5.79z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M28.44,5.74c-0.03,0.03-0.08,0.02-0.11-0.01c-0.03-0.03-0.02-0.08,0.01-0.11
			c0.03-0.03,0.08-0.02,0.11,0.01C28.48,5.66,28.48,5.71,28.44,5.74z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M28.19,9.42c-0.03,0.03-0.08,0.02-0.11-0.01c-0.03-0.03-0.02-0.08,0.01-0.11
			c0.03-0.03,0.08-0.02,0.11,0.01C28.23,9.34,28.23,9.39,28.19,9.42z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M25.88,8.24c0.08-0.02,0.16,0.02,0.18,0.1c0.02,0.08-0.02,0.16-0.1,0.18c-0.08,0.02-0.16-0.02-0.18-0.1
			C25.76,8.35,25.8,8.26,25.88,8.24z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M25.9,8.3c0.04-0.01,0.09,0.01,0.1,0.05c0.01,0.04-0.01,0.09-0.05,0.1c-0.04,0.01-0.09-0.01-0.1-0.05
			C25.83,8.36,25.85,8.32,25.9,8.3z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M27.54,5c0.04-0.01,0.09,0.01,0.1,0.05c0.01,0.04-0.01,0.09-0.05,0.1c-0.04,0.01-0.09-0.01-0.1-0.05
			C27.47,5.06,27.5,5.01,27.54,5z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M33.05,9.71c-0.06,0.05-0.15,0.04-0.21-0.02c-0.05-0.06-0.04-0.15,0.02-0.21
			c0.06-0.05,0.15-0.04,0.21,0.02C33.12,9.57,33.11,9.66,33.05,9.71z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M33.01,9.66c-0.03,0.03-0.08,0.02-0.11-0.01c-0.03-0.03-0.02-0.08,0.01-0.11
			c0.03-0.03,0.08-0.02,0.11,0.01C33.05,9.58,33.04,9.63,33.01,9.66z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M35.39,14.57c-0.05-0.06-0.04-0.15,0.02-0.21c0.06-0.05,0.15-0.04,0.21,0.02
			c0.05,0.06,0.04,0.15-0.02,0.21C35.53,14.64,35.44,14.63,35.39,14.57z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M35.44,14.52c-0.03-0.03-0.02-0.08,0.01-0.11c0.03-0.03,0.08-0.02,0.11,0.01
			c0.03,0.03,0.02,0.08-0.01,0.11C35.52,14.56,35.47,14.56,35.44,14.52z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M31.76,14.26c-0.03-0.03-0.02-0.08,0.01-0.11c0.03-0.03,0.08-0.02,0.11,0.01
			c0.03,0.03,0.02,0.08-0.01,0.11C31.84,14.3,31.79,14.3,31.76,14.26z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M26.57,10.23c0.06-0.06,0.17-0.06,0.23,0c0.06,0.06,0.06,0.17,0,0.23s-0.17,0.06-0.23,0
			C26.51,10.4,26.51,10.29,26.57,10.23z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M26.63,10.28c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C26.59,10.37,26.59,10.32,26.63,10.28z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M26.56,6.16c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C26.53,6.25,26.53,6.19,26.56,6.16z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M29.25,7.25c-0.08,0.03-0.18-0.01-0.21-0.09C29,7.08,29.04,6.98,29.13,6.95c0.08-0.03,0.18,0.01,0.21,0.09
			C29.38,7.12,29.34,7.22,29.25,7.25z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M29.22,7.18c-0.05,0.02-0.1,0-0.12-0.05c-0.02-0.05,0-0.1,0.05-0.12c0.05-0.02,0.1,0,0.12,0.05
			C29.29,7.11,29.27,7.16,29.22,7.18z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M27.7,11.01c-0.05,0.02-0.1,0-0.12-0.05c-0.02-0.05,0-0.1,0.05-0.12c0.05-0.02,0.1,0,0.12,0.05
			C27.77,10.94,27.75,11,27.7,11.01z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M26.59,11.97c-0.05-0.06-0.04-0.15,0.02-0.21c0.06-0.05,0.15-0.04,0.21,0.02
			c0.05,0.06,0.04,0.15-0.02,0.21C26.73,12.04,26.64,12.03,26.59,11.97z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M26.64,11.93c-0.03-0.03-0.02-0.08,0.01-0.11c0.03-0.03,0.08-0.02,0.11,0.01
			c0.03,0.03,0.02,0.08-0.01,0.11S26.67,11.96,26.64,11.93z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M22.96,11.67c-0.03-0.03-0.02-0.08,0.01-0.11c0.03-0.03,0.08-0.02,0.11,0.01
			c0.03,0.03,0.02,0.08-0.01,0.11C23.04,11.7,22.99,11.7,22.96,11.67z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M14.71,12.22c0.09,0.02,0.14,0.11,0.12,0.2c-0.02,0.09-0.11,0.14-0.2,0.12c-0.09-0.02-0.14-0.11-0.12-0.2
			C14.53,12.25,14.62,12.2,14.71,12.22z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M14.69,12.29c0.05,0.01,0.08,0.06,0.06,0.11c-0.01,0.05-0.06,0.08-0.11,0.06
			c-0.05-0.01-0.08-0.06-0.06-0.11C14.6,12.31,14.64,12.28,14.69,12.29z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M18.19,10.11c0.05,0.01,0.08,0.06,0.06,0.11c-0.01,0.05-0.06,0.08-0.11,0.06
			c-0.05-0.01-0.08-0.06-0.06-0.11C18.1,10.12,18.14,10.1,18.19,10.11z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M18.64,12.98c-0.07-0.05-0.09-0.16-0.03-0.23c0.05-0.07,0.16-0.09,0.23-0.03
			c0.07,0.05,0.09,0.16,0.03,0.23C18.82,13.02,18.72,13.03,18.64,12.98z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M18.69,12.92c-0.04-0.03-0.05-0.08-0.02-0.12c0.03-0.04,0.08-0.05,0.12-0.02
			c0.04,0.03,0.05,0.08,0.02,0.12S18.73,12.95,18.69,12.92z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M14.62,13.59c-0.04-0.03-0.05-0.08-0.02-0.12c0.03-0.04,0.08-0.05,0.12-0.02
			c0.04,0.03,0.05,0.08,0.02,0.12C14.72,13.61,14.66,13.62,14.62,13.59z"
        />
      </g>
      <g>
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M5.19,12.13c-0.07-0.05-0.09-0.16-0.03-0.23c0.05-0.07,0.16-0.09,0.23-0.03c0.07,0.05,0.09,0.16,0.03,0.23
			C5.36,12.17,5.26,12.18,5.19,12.13z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M5.23,12.07c-0.04-0.03-0.05-0.08-0.02-0.12c0.03-0.04,0.08-0.05,0.12-0.02c0.04,0.03,0.05,0.08,0.02,0.12
			C5.33,12.09,5.27,12.1,5.23,12.07z"
        />
        <Particle
          time={getRandomInt}
          fill="#FFFFFF"
          d="M1.17,12.74c-0.04-0.03-0.05-0.08-0.02-0.12c0.03-0.04,0.08-0.05,0.12-0.02c0.04,0.03,0.05,0.08,0.02,0.12
			S1.2,12.77,1.17,12.74z"
        />
      </g>
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M13.23,13.13c0.03-0.08,0.11-0.12,0.19-0.09s0.12,0.11,0.09,0.19s-0.11,0.12-0.19,0.09
			C13.24,13.29,13.2,13.21,13.23,13.13z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M13.29,13.15c0.01-0.04,0.06-0.06,0.1-0.05c0.04,0.01,0.06,0.06,0.05,0.1c-0.01,0.04-0.06,0.06-0.1,0.05
			C13.3,13.24,13.28,13.19,13.29,13.15z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M11.61,9.87c0.01-0.04,0.06-0.06,0.1-0.05c0.04,0.01,0.06,0.06,0.05,0.1c-0.01,0.04-0.06,0.06-0.1,0.05
			C11.62,9.95,11.6,9.91,11.61,9.87z"
      />

      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M32.36,6.43c-0.03-0.04-0.03-0.1,0.01-0.13c0.04-0.03,0.1-0.03,0.13,0.01c0.03,0.04,0.03,0.1-0.01,0.13
			C32.45,6.47,32.39,6.47,32.36,6.43z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M32.39,6.4c-0.02-0.02-0.02-0.05,0.01-0.07c0.02-0.02,0.05-0.02,0.07,0.01c0.02,0.02,0.02,0.05-0.01,0.07
			C32.44,6.42,32.41,6.42,32.39,6.4z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M30.01,6.23c-0.02-0.02-0.02-0.05,0.01-0.07c0.02-0.02,0.05-0.02,0.07,0.01c0.02,0.02,0.02,0.05-0.01,0.07
			C30.06,6.25,30.03,6.25,30.01,6.23z"
      />

      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M33.21,9.77C33.14,9.82,33.05,9.81,33,9.75c-0.05-0.06-0.04-0.15,0.02-0.21c0.06-0.05,0.15-0.04,0.21,0.02
			C33.28,9.63,33.27,9.72,33.21,9.77z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M33.16,9.72c-0.03,0.03-0.08,0.02-0.11-0.01c-0.03-0.03-0.02-0.08,0.01-0.11
			c0.03-0.03,0.08-0.02,0.11,0.01C33.2,9.64,33.2,9.69,33.16,9.72z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M32.91,13.4c-0.03,0.03-0.08,0.02-0.11-0.01c-0.03-0.03-0.02-0.08,0.01-0.11s0.08-0.02,0.11,0.01
			C32.95,13.32,32.94,13.37,32.91,13.4z"
      />

      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M30.59,12.22c0.08-0.02,0.16,0.02,0.18,0.1c0.02,0.08-0.02,0.16-0.1,0.18c-0.08,0.02-0.16-0.02-0.18-0.1
			C30.47,12.33,30.52,12.25,30.59,12.22z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M30.61,12.29c0.04-0.01,0.09,0.01,0.1,0.05c0.01,0.04-0.01,0.09-0.05,0.1c-0.04,0.01-0.09-0.01-0.1-0.05
			C30.55,12.35,30.57,12.3,30.61,12.29z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M32.26,8.98c0.04-0.01,0.09,0.01,0.1,0.05c0.01,0.04-0.01,0.09-0.05,0.1c-0.04,0.01-0.09-0.01-0.1-0.05
			C32.19,9.04,32.21,9,32.26,8.98z"
      />

      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M31.29,14.21c0.06-0.06,0.17-0.06,0.23,0c0.06,0.06,0.06,0.17,0,0.23c-0.06,0.06-0.17,0.06-0.23,0
			C31.23,14.38,31.23,14.28,31.29,14.21z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M31.34,14.27c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C31.31,14.36,31.31,14.3,31.34,14.27z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M31.28,10.14c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C31.24,10.23,31.24,10.18,31.28,10.14z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M37.08,10.41c-0.03-0.04-0.03-0.1,0.01-0.13c0.04-0.03,0.1-0.03,0.13,0.01c0.03,0.04,0.03,0.1-0.01,0.13
			C37.17,10.46,37.11,10.45,37.08,10.41z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M37.11,10.38c-0.02-0.02-0.02-0.05,0.01-0.07c0.02-0.02,0.05-0.02,0.07,0.01
			c0.02,0.02,0.02,0.05-0.01,0.07C37.16,10.41,37.13,10.4,37.11,10.38z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M34.73,10.21c-0.02-0.02-0.02-0.05,0.01-0.07c0.02-0.02,0.05-0.02,0.07,0.01
			c0.02,0.02,0.02,0.05-0.01,0.07C34.78,10.24,34.74,10.24,34.73,10.21z"
      />

      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M35.06,3c-0.05-0.06-0.04-0.15,0.02-0.21c0.06-0.05,0.15-0.04,0.21,0.02c0.05,0.06,0.04,0.15-0.02,0.21
			C35.2,3.07,35.11,3.06,35.06,3z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M35.11,2.95c-0.03-0.03-0.02-0.08,0.01-0.11c0.03-0.03,0.08-0.02,0.11,0.01c0.03,0.03,0.02,0.08-0.01,0.11
			C35.19,2.99,35.14,2.99,35.11,2.95z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M31.32,2.79c-0.08-0.1-0.07-0.24,0.03-0.32c0.1-0.08,0.24-0.07,0.32,0.03c0.08,0.1,0.07,0.24-0.03,0.32
			C31.54,2.9,31.4,2.88,31.32,2.79z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M32.61,0.38c0.02,0.08-0.02,0.16-0.1,0.18c-0.08,0.02-0.16-0.02-0.18-0.1c-0.02-0.08,0.02-0.16,0.1-0.18
			C32.51,0.26,32.59,0.3,32.61,0.38z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M32.55,0.4c0.01,0.04-0.01,0.09-0.05,0.1c-0.04,0.01-0.09-0.01-0.1-0.05c-0.01-0.04,0.01-0.09,0.05-0.1
			C32.49,0.34,32.53,0.36,32.55,0.4z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M35.85,2.05c0.01,0.04-0.01,0.09-0.05,0.1c-0.04,0.01-0.09-0.01-0.1-0.05c-0.01-0.04,0.01-0.09,0.05-0.1
			C35.79,1.98,35.83,2.01,35.85,2.05z"
      />

      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M30.89,7.22c-0.05-0.06-0.04-0.15,0.02-0.21c0.06-0.05,0.15-0.04,0.21,0.02c0.05,0.06,0.04,0.15-0.02,0.21
			C31.04,7.29,30.95,7.29,30.89,7.22z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M30.94,7.18c-0.03-0.03-0.02-0.08,0.01-0.11s0.08-0.02,0.11,0.01c0.03,0.03,0.02,0.08-0.01,0.11
			C31.02,7.22,30.97,7.21,30.94,7.18z"
      />

      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M14.28,11.41c0.06-0.06,0.17-0.06,0.23,0c0.06,0.06,0.06,0.17,0,0.23c-0.06,0.06-0.17,0.06-0.23,0
			C14.21,11.57,14.21,11.47,14.28,11.41z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M14.33,11.46c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C14.3,11.55,14.3,11.49,14.33,11.46z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M14.27,7.33c0.03-0.03,0.09-0.03,0.13,0c0.03,0.03,0.03,0.09,0,0.13c-0.03,0.03-0.09,0.03-0.13,0
			C14.23,7.43,14.23,7.37,14.27,7.33z"
      />

      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M19.63,7.88c-0.06,0.05-0.15,0.04-0.21-0.02c-0.05-0.06-0.04-0.15,0.02-0.21
			c0.06-0.05,0.15-0.04,0.21,0.02C19.7,7.74,19.69,7.83,19.63,7.88z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M19.58,7.83c-0.03,0.03-0.08,0.02-0.11-0.01c-0.03-0.03-0.02-0.08,0.01-0.11
			c0.03-0.03,0.08-0.02,0.11,0.01C19.62,7.75,19.62,7.8,19.58,7.83z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M19.33,11.51c-0.03,0.03-0.08,0.02-0.11-0.01c-0.03-0.03-0.02-0.08,0.01-0.11
			c0.03-0.03,0.08-0.02,0.11,0.01S19.36,11.48,19.33,11.51z"
      />

      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M17.01,10.34c0.08-0.02,0.16,0.02,0.18,0.1c0.02,0.08-0.02,0.16-0.1,0.18c-0.08,0.02-0.16-0.02-0.18-0.1
			C16.89,10.44,16.94,10.36,17.01,10.34z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M17.03,10.4c0.04-0.01,0.09,0.01,0.1,0.05c0.01,0.04-0.01,0.09-0.05,0.1c-0.04,0.01-0.09-0.01-0.1-0.05
			C16.97,10.46,16.99,10.41,17.03,10.4z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M18.68,7.1c0.04-0.01,0.09,0.01,0.1,0.05c0.01,0.04-0.01,0.09-0.05,0.1c-0.04,0.01-0.09-0.01-0.1-0.05
			C18.61,7.15,18.64,7.11,18.68,7.1z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M23.5,8.52c-0.03-0.04-0.03-0.1,0.01-0.13s0.1-0.03,0.13,0.01c0.03,0.04,0.03,0.1-0.01,0.13
			C23.59,8.57,23.53,8.56,23.5,8.52z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M23.53,8.49c-0.02-0.02-0.02-0.05,0.01-0.07c0.02-0.02,0.05-0.02,0.07,0.01c0.02,0.02,0.02,0.05-0.01,0.07
			C23.58,8.52,23.55,8.52,23.53,8.49z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M21.15,8.33c-0.02-0.02-0.02-0.05,0.01-0.07c0.02-0.02,0.05-0.02,0.07,0.01c0.02,0.02,0.02,0.05-0.01,0.07
			C21.2,8.35,21.17,8.35,21.15,8.33z"
      />

      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M22.03,9.32c-0.05-0.06-0.04-0.15,0.02-0.21c0.06-0.05,0.15-0.04,0.21,0.02c0.05,0.06,0.04,0.15-0.02,0.21
			C22.18,9.39,22.08,9.38,22.03,9.32z"
      />
      <Particle
        time={getRandomInt}
        fill="#FFFFFF"
        d="M22.08,9.28c-0.03-0.03-0.02-0.08,0.01-0.11s0.08-0.02,0.11,0.01s0.02,0.08-0.01,0.11
			S22.11,9.31,22.08,9.28z"
      />
    </g>
  </Particles>
);

function getRandomInt() {
  return Math.floor(Math.random() * 15 + 4);
}

const animParticle = keyframes`
  0% { transform: translate(0, 10px); opacity: 1; }
  100% { transform: translate(0, 3px); opacity: 0; }
`;

const Particle = styled.path`
  opacity: 0;
  animation: ${animParticle} ${props => props.time}s ease-in-out infinite;
`;

const Particles = styled(Svg)`
  width: 1200px;
`;

export const ButtonsSvg = ({ isActive }) => (
  <Buttons viewBox="0 0 80 30">
    <g id="ButtonDown">
      <rect
        id="Base_00000156589246577600153290000011146589571841526672_"
        x="3.41"
        y="26.56"
        fillRule="evenodd"
        fill="#321731"
        stroke="#000000"
        strokeWidth="0.8498"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        width="74.17"
        height="1.6"
      />
      <polygon
        id="Top_00000166672262089155051340000009409489976361132450_"
        fillRule="evenodd"
        fill="#41273E"
        stroke="#000000"
        strokeWidth="0.8498"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
    6.25,4.38 74.32,4.38 77.57,26.54 3.41,26.54 		"
      />
    </g>
    <ButtonUpG isActive={isActive} id="ButtonUp">
      <rect
        id="Base"
        x="3.41"
        y="25.06"
        display="inline"
        fillRule="evenodd"
        fill="#321731"
        stroke="#000000"
        strokeWidth="0.8498"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        width="74.17"
        height="3.1"
      />
      <polygon
        id="Top"
        display="inline"
        fillRule="evenodd"
        fill="#41273E"
        stroke="#000000"
        strokeWidth="0.8498"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
    6.25,2.84 74.32,2.84 77.57,25 3.41,25 		"
      />
    </ButtonUpG>
  </Buttons>
);

const Buttons = styled(Svg)`
  width: 100%;
`;

export const ChamberFullSvg = ({ isActive, step, handleClick }) => (
  <ChamberFull viewBox="0 0 180 220">
    <g>
      <g>
        <polygon fill="#231428" points="16.02,199.56 12.44,199.78 10.94,202.15 10.33,206.45 14.96,203.74 		" />
      </g>
      <path
        fill="#231428"
        d="M12.96,202.8l-2.79,0.71l-0.76,1.94c0,0-0.05,3.12-0.85,4.39c0,0,3.72-2.03,4.13-2.79
		C13.1,206.29,12.96,202.8,12.96,202.8z"
      />
      <polygon fill="#231428" points="165.08,196.14 170.06,197.09 171.15,199.97 171.76,204.26 167.13,201.55 	" />
      <g>
        <ellipse
          fillRule="evenodd"
          fill="#603852"
          stroke="#000000"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          cx="50.34"
          cy="165.52"
          rx="17.18"
          ry="17.17"
        />
        <ellipse fillRule="evenodd" fill="#261621" cx="57.82" cy="165.97" rx="17.18" ry="17.17" />

        <path
          fillRule="evenodd"
          fill="none"
          stroke="#000000"
          strokeWidth="0.72"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
			M34.18,161.74c0,0,8.69-1.44,14.51,1.59"
        />

        <path
          fillRule="evenodd"
          fill="none"
          stroke="#000000"
          strokeWidth="0.72"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
			M35.76,156.3c0,0,8.69-1.44,14.51,1.59"
        />
      </g>

      <path
        fillRule="evenodd"
        fill="#41273E"
        stroke="#000000"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M36.74,165.49l-5-0.01c-4.73,0.29-11.63,1.13-15.14,3.51c0,0-2.89,39.68-1.54,40.23c1.02,0.43,31.67,0.43,32.7,0
		c1.34-0.55,1.28-40.23,1.28-40.23C45.55,166.61,41.48,165.77,36.74,165.49z"
      />
      <polyline id="Shadow3" fillRule="evenodd" fill="#1A1019" points="37.02,166.11 37.93,180.84 44.5,179.93 43.82,166.56 	" />
      <g>
        <ellipse
          fillRule="evenodd"
          fill="#603852"
          stroke="#000000"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          cx="140.91"
          cy="154.37"
          rx="14.72"
          ry="21.01"
        />
        <ellipse fillRule="evenodd" fill="#261621" cx="134.79" cy="158.22" rx="14.72" ry="21.01" />

        <path
          fillRule="evenodd"
          fill="none"
          stroke="#000000"
          strokeWidth="0.72"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
			M138.45,149.27c0,0,5.74-2.95,16.55-2.04"
        />

        <path
          fillRule="evenodd"
          fill="none"
          stroke="#000000"
          strokeWidth="0.72"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
			M136.18,143.6c0,0,5.74-2.95,16.55-2.04"
        />

        <path
          fillRule="evenodd"
          fill="none"
          stroke="#000000"
          strokeWidth="0.72"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
			M133.01,139.52c0,0,5.74-2.95,16.55-2.04"
        />
      </g>

      <path
        fillRule="evenodd"
        fill="#41273E"
        stroke="#000000"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M151.64,152.33h-5c-4.73,0.29-11.64,1.12-15.14,3.5c0,0-0.06,52.47,1.28,53.02c1.03,0.42,31.68,0.42,32.7,0
		c1.35-0.56-1.54-53.02-1.54-53.02C160.43,153.46,156.37,152.62,151.64,152.33z"
      />
      <path fillRule="evenodd" fill="#341F32" d="M150.56,202.33c0,0,8.08,4.23,11.71,4.78l0.71,2.06l-13.47-0.94" />
      <path
        id="Shadow1"
        fillRule="evenodd"
        d="M149.86,209.48c0,0,2.23-8.1,2.01-12.73c-0.22-4.63-1.42-15.39-1.42-15.39l-1.44,15.05
		l0.84,0.17c0,0-1.05,10.35-2.34,11.61"
      />
      <polyline fillRule="evenodd" fill="#181018" points="147.97,152.67 146.15,181.69 138.22,179.87 141.17,152.9 	" />

      <path
        fillRule="evenodd"
        fill="#603852"
        stroke="#000000"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M47.96,29.87c0-13.05,19.93-23.63,44.51-23.63s44.51,10.58,44.51,23.63C136.98,31.13,47.96,30.8,47.96,29.87z"
      />
      <path
        fillRule="evenodd"
        fill="#341F32"
        d="M20.93,207.07c0,0,10.5-1.18,13.14-6.45l2.6,7.2l-8.33,1.36l-1.63,0.55L20.93,207.07
		L20.93,207.07z"
      />
      <path fillRule="evenodd" fill="#4D2D42" d="M48.36,28.64c0,0,1.7-20.34,44.1-21.82c0,0-33.99,4.84-37.28,19.88" />
      <path
        id="Shadow2"
        fillRule="evenodd"
        d="M35.17,209.83c0,0-2.23-8.1-2.01-12.73c0.22-4.63,1.42-15.39,1.42-15.39l1.44,15.05
		l-0.84,0.17c0,0,1.05,10.35,2.34,11.61"
      />

      <path
        fillRule="evenodd"
        fill="#41273E"
        stroke="#000000"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M43.57,31.08c0,0-2.19,13.52,2.01,15.53c3.77,1.8,39.67,1.69,46.97,1.65c7.29,0.04,43.19,0.15,46.97-1.65
		c4.2-2.01,2.01-15.53,2.01-15.53c-15.25-7.61-37.84-9.02-46.96-9.27v-0.05c0,0-0.73-0.01-2.03,0.01c-1.29-0.02-2.01-0.01-2.01-0.01
		v0.05C81.4,22.06,58.82,23.47,43.57,31.08z"
      />
      <path
        fillRule="evenodd"
        fill="#211420"
        d="M44.39,45.51c0,0-1.56-5.11-0.29-14.01c0,0,4.5-2.58,12.23-4.46
		c0,0-3.72,3.65,0.34,10.79c0,0-8.23,1.88-11.17,3.9"
      />
      <g id="Tank">
        <g>
          <TankTestPath
            isActive={step >= 0}
            d="M46.52,46.04l-2.96,130.21c-0.14,6.27,1.34,7.9,7.23,7.9h83.55
				c5.88,0,7.36-1.62,7.22-7.9l-2.95-130.21c-0.06-2.32-0.35-3.92-1.08-4.99v-0.02c0-2.98-20.17-5.38-45.06-5.38
				s-45.06,2.41-45.06,5.38c0,0.09,0.02,0.16,0.05,0.24C46.82,42.34,46.57,43.87,46.52,46.04z"
          />
        </g>
      </g>

      <g id="Tank">
        <g>
          <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="92.5585" y1="184.1414" x2="92.5585" y2="35.6494">
            <stop offset="10%" stopColor="#C0198C" />
            <stop offset="95%" stopColor="#00BDF2" />
            <stop offset="100%" stopColor="#00BDF2" />
          </linearGradient>
          <TankTestPath2
            step={step}
            isActive={step >= 2}
            fillRule="evenodd"
            fill="url(#SVGID_1_)"
            d="M46.52,46.04l-2.96,130.21c-0.14,6.27,1.34,7.9,7.23,7.9h83.55
				c5.88,0,7.36-1.62,7.22-7.9l-2.95-130.21c-0.06-2.32-0.35-3.92-1.08-4.99v-0.02c0-2.98-20.17-5.38-45.06-5.38
				s-45.06,2.41-45.06,5.38c0,0.09,0.02,0.16,0.05,0.24C46.82,42.34,46.57,43.87,46.52,46.04z"
          />
        </g>
      </g>

      <g id="Tank">
        <g>
          <linearGradient id="SVGID_2_" gradientUnits="userSpaceOnUse" x1="92.5585" y1="184.1414" x2="92.5585" y2="35.6494">
            <stop offset="40%" stopColor="#C0198C" />
            <stop offset="100%" stopColor="#00BDF2" />
            <stop offset="100%" stopColor="#00BDF2" />
          </linearGradient>
          <TankTestPath2
            step={step}
            isActive={step >= 3}
            fillRule="evenodd"
            fill="url(#SVGID_2_)"
            d="M46.52,46.04l-2.96,130.21c-0.14,6.27,1.34,7.9,7.23,7.9h83.55
				c5.88,0,7.36-1.62,7.22-7.9l-2.95-130.21c-0.06-2.32-0.35-3.92-1.08-4.99v-0.02c0-2.98-20.17-5.38-45.06-5.38
				s-45.06,2.41-45.06,5.38c0,0.09,0.02,0.16,0.05,0.24C46.82,42.34,46.57,43.87,46.52,46.04z"
          />
        </g>
      </g>

      <g id="Tank">
        <g>
          <linearGradient id="SVGID_3_" gradientUnits="userSpaceOnUse" x1="92.5585" y1="184.1414" x2="92.5585" y2="35.6494">
            <stop offset="60%" stopColor="#C0198C" />
            <stop offset="100%" stopColor="#00BDF2" />
            <stop offset="100%" stopColor="#00BDF2" />
          </linearGradient>
          <TankTestPath2
            step={step}
            isActive={step >= 4}
            fillRule="evenodd"
            fill="url(#SVGID_3_)"
            d="M46.52,46.04l-2.96,130.21c-0.14,6.27,1.34,7.9,7.23,7.9h83.55
				c5.88,0,7.36-1.62,7.22-7.9l-2.95-130.21c-0.06-2.32-0.35-3.92-1.08-4.99v-0.02c0-2.98-20.17-5.38-45.06-5.38
				s-45.06,2.41-45.06,5.38c0,0.09,0.02,0.16,0.05,0.24C46.82,42.34,46.57,43.87,46.52,46.04z"
          />
        </g>
      </g>
      <g id="Tank">
        <g>
          <linearGradient id="SVGID_4_" gradientUnits="userSpaceOnUse" x1="92.5585" y1="184.1414" x2="92.5585" y2="35.6494">
            <stop offset="100%" stopColor="#C0198C" />
            <stop offset="100%" stopColor="#00BDF2" />
            <stop offset="100%" stopColor="#00BDF2" />
          </linearGradient>
          <TankTestPath2
            step={step}
            isActive={step >= 5}
            fillRule="evenodd"
            fill="url(#SVGID_4_)"
            d="M46.52,46.04l-2.96,130.21c-0.14,6.27,1.34,7.9,7.23,7.9h83.55
				c5.88,0,7.36-1.62,7.22-7.9l-2.95-130.21c-0.06-2.32-0.35-3.92-1.08-4.99v-0.02c0-2.98-20.17-5.38-45.06-5.38
				s-45.06,2.41-45.06,5.38c0,0.09,0.02,0.16,0.05,0.24C46.82,42.34,46.57,43.87,46.52,46.04z"
          />
        </g>
      </g>

      <path
        id="ShadowTank"
        fillRule="evenodd"
        fill="rgba(0, 0, 0, 0.5)"
        d="M133.23,180.1c0,0,3.67-2.42,4.99-4.72c1.32-2.3,3.4-12.28,3.4-12.28
		s0.34,17.34-0.23,17.23"
      />
      <path
        id="ShadowTank_00000168096697722998312400000012651961053874700416_"
        fillRule="evenodd"
        fill="rgba(0, 0, 0, 0.5)"
        d="M46.4,51.28
		c0,0,0.02-9.83,1.15-10.68c1.13-0.84,7.19-2.36,7.19-2.36S47.08,42.67,46.4,51.28z"
      />
      <g id="CLIPPER">
        <path
          fillRule="evenodd"
          fill="#41273E"
          stroke="#000000"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
			M100.59,174.91v-0.15H84.31v0.14c-15.43,0.29-37.91,1.13-49.36,3.5c0,0-0.18,26.85,4.2,27.4c3.35,0.42,37.37-0.43,53.29-0.87
			c15.92,0.43,49.94,1.28,53.29,0.87c4.39-0.55,4.2-27.4,4.2-27.4C138.49,176.03,116.01,175.19,100.59,174.91z"
        />

        <path
          fillRule="evenodd"
          fill="#321731"
          stroke="#000000"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
			M100.61,177.72v-0.15H84.34v0.14c-15.43,0.29-37.91,1.13-49.36,3.5c0,0-0.18,26.85,4.2,27.4c3.35,0.42,37.37-0.43,53.29-0.87
			c15.92,0.43,49.94,1.28,53.29,0.87c4.39-0.55,4.2-27.4,4.2-27.4C138.51,178.85,116.03,178.01,100.61,177.72z"
        />
        <path
          fillRule="evenodd"
          fill="#281227"
          d="M51.02,188.98c0,0-3.13,2.61-7.96,3.79S36,193.83,36,193.83l0.03,3.17
			c0,0,12.72-3.21,14.67-2.21"
        />
        <path
          fillRule="evenodd"
          fill="#281227"
          d="M134.87,189.65c0,0,4.85,3.06,8.23,3.98c3.38,0.93,5.82,0.45,5.82,0.45l-0.11,2.31
			c0,0-12.03-1.83-13.27-1.16"
        />
      </g>
      <g id="BubblesTop">
        <circle fillRule="evenodd" fill="rgba(255,255,255, 0.6)" cx="64.74" cy="52.36" r="9.68" />
        <circle fillRule="evenodd" fill="rgba(255,255,255, 0.6)" cx="79.03" cy="64.06" r="2.6" />
      </g>

      <path
        fillRule="evenodd"
        fill="#41273E"
        stroke="#000000"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M100.79,193.43v-0.15H84.52v0.15c-15.43,0.29-37.91,1.12-49.35,3.5c0,0-0.19,12.24,4.2,12.79c3.34,0.42,37.36-0.44,53.29-0.87
		c15.91,0.43,49.93,1.28,53.28,0.87c4.39-0.55,4.21-12.79,4.21-12.79C138.7,194.55,116.22,193.72,100.79,193.43z"
      />

      <polygon
        fillRule="evenodd"
        fill="#321731"
        stroke="#000000"
        strokeWidth="0.48"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        points="
		16.2,180.77 22.11,180.33 21.71,196.19 15.4,196.98 	"
      />

      <path
        fillRule="evenodd"
        fill="#321731"
        stroke="#000000"
        strokeWidth="0.48"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        d="
		M135.23,195.55c-0.49-0.24-14.47-0.96-14.47-0.96l-0.22,7.5l13.6,0.45l1.07-6.83L135.23,195.55z"
      />
      <g>
        <path
          fillRule="evenodd"
          d="M47.55,40.01c0,0-1.13,4.72-1.01,6.57c0,0-2.47-0.63-3.02-2.28C42.97,42.67,47.55,40.01,47.55,40.01z
			"
        />
        <path
          fillRule="evenodd"
          d="M137.54,40.01c0,0,1.13,4.72,1.01,6.57c0,0,2.47-0.63,3.02-2.28
			C142.12,42.67,137.54,40.01,137.54,40.01z"
        />
      </g>
      <g>
        <path
          fillRule="evenodd"
          fill="none"
          stroke="#010101"
          strokeWidth="0.72"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
			M44.6,42.51c0,0,5.74-7.93,51.68-7.25"
        />

        <path
          fillRule="evenodd"
          fill="none"
          stroke="#010101"
          strokeWidth="0.72"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          d="
			M141.62,42.51c0,0-5.74-7.93-51.68-7.25"
        />
      </g>
      <g>
        <polygon fillRule="evenodd" fill="#FFFFFF" points="131.42,75.37 135.76,76.51 136.63,145.87 131.56,144.74 		" />
        <polygon fillRule="evenodd" fill="#FFFFFF" points="131.56,71.75 135.62,73.11 135.62,75.37 131.56,74.01 		" />
      </g>
      <g id="BubblesBottom">
        <circle fillRule="evenodd" fill="rgba(255,255,255, 0.6)" cx="127.96" cy="156.66" r="2.55" />
        <g>
          <path
            fillRule="evenodd"
            fill="rgba(255,255,255, 0.6)"
            d="M114.59,154.1c0.14-0.94,1.02-1.58,1.99-1.39c0.94,0.16,1.6,1.09,1.45,2.03
				c-0.14,0.94-1.04,1.58-1.99,1.41C115.1,155.97,114.44,155.07,114.59,154.1z"
          />
          <path
            fillRule="evenodd"
            fill="rgba(255,255,255, 0.6)"
            d="M108.34,145.4c0.33-2.03,2.23-3.38,4.26-3.01s3.44,2.31,3.11,4.34
				c-0.31,2.05-2.21,3.4-4.24,3.03C109.43,149.39,108.03,147.43,108.34,145.4z"
          />
        </g>
        <circle fillRule="evenodd" fill="#B6E2ED" cx="126.68" cy="171.8" r="1.28" />
      </g>
      <path d="M172.75,207.62c0,0-5.34-1.84-5.48-1.94c-0.14-0.09-2.64-2.5-2.64-2.5l-4.16,1.26l1.84,5.02l8.33,1.42" />
      <polyline points="166.78,196.82 169.16,198.17 171.49,201.29 167.4,199.21 166.33,197.7 	" />
      <g>
        <polygon fill="#231428" points="177.29,209.07 164.63,204.5 160.47,201.78 160.47,204.44 164.38,209.42 177.29,212.43 		" />
        <polyline
          fill="#392B3C"
          points="177.85,202.8 169.79,200.16 164.71,195.69 160.47,198.64 160.47,201.78 165.11,207.33 
			177.29,212.43 		"
        />
      </g>
      <path
        d="M163.25,210.23c0,0-10.23-2.89-10.48-3.08c-0.26-0.19-3.58-1.37-3.58-1.37h-6.56l-6.39,1.49h-7.6c0,0-15.79,1.35-17.32,1.3
		l-0.13,1.66l26.84,1.55l24.21-0.57"
      />
      <path
        fill="#392B3C"
        d="M168.88,210.88c0,0-11.33-2.89-11.62-3.08c-0.28-0.19-3.97-1.37-3.97-1.37h-7.27l-7.08,1.49h-8.43
		c0,0-17.5,1.35-19.2,1.3l-0.14,1.66l29.74,1.55l26.84-0.57"
      />
      <polyline
        fill="#231428"
        points="28.29,209.84 24.75,208.08 21.3,207.24 16.25,205.21 13.77,200.98 13.55,199.68 12.44,201.32 
		15.83,205.56 21.07,207.62 24.75,209.84 28.06,210.33 	"
      />
      <polyline points="34.07,210.33 27.14,207.24 20.37,205.89 16.57,203.49 13.77,200.98 14.99,206.67 21.71,210.33 30.29,210.79 	" />
      <path d="M33.16,209.2l6.39-2.94c0,0,8.39,1.13,8.67,0.97c0.27-0.16-0.73,3.09-0.73,3.09" />
      <g>
        <polyline points="60.93,211.54 60.36,206.43 56.11,204.5 52.8,201.55 49.78,202.8 49.4,206.43 46.47,209.83 46.04,211.78 		" />
        <polyline points="75.38,211.02 66.78,207.24 58.75,208.53 46.57,208.53 39.77,207.62 30.23,209.17 24.75,211.78 		" />
      </g>
      <g>
        <polyline
          fill="#392B3C"
          points="59.04,211.54 58.47,206.43 54.22,204.5 50.91,201.55 47.89,202.8 47.51,206.43 44.59,209.83 
			44.15,211.78 		"
        />
        <polyline
          fill="#392B3C"
          points="76.71,212.43 64.89,207.24 56.86,208.53 44.68,208.53 37.88,207.62 28.34,209.17 22.86,211.78 		
			"
        />
        <polygon fill="#231428" points="64.89,207.24 67.4,207.95 69.59,208.24 69.59,209.3 		" />
      </g>
      <polygon
        fill="#231428"
        points="59.82,208.06 59.48,206.43 54.91,204.25 51.59,201.32 50.91,201.55 54.31,204.69 58.56,207.24 
		58.67,208.24 	"
      />
      <polygon fill="#231428" points="46.28,207.86 37.88,207.62 44.68,208.53 45.7,208.53 	" />
      <polyline fill="#231428" points="58.66,208.06 59.04,210.72 59.33,208.14 	" />
      <polyline fill="#231428" points="45.7,208.53 44.47,210.33 45.41,208.08 	" />
      <polygon fill="#231428" points="44.39,210.33 43.5,210.95 37.12,211.25 43.45,211.25 	" />
      <polyline
        fill="#392B3C"
        points="27.11,209.9 20.93,207.07 15.83,205.56 12.64,200.98 10.77,204.24 9.4,208.12 7.33,209.45 
		2.93,211.32 12.41,211.56 27.87,211.32 	"
      />
      <polyline points="14.3,202.24 13.26,200.86 13.91,200.62 	" />
      <polyline fillRule="evenodd" points="75.38,210.55 68.4,208.78 70.67,214.95 110.18,214.95 114.01,208.78 106.54,209.64 	" />
      <g>
        <polygon
          fillRule="evenodd"
          fill="#603852"
          stroke="#000000"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
			72.69,165.98 107.61,165.98 112.01,198.67 68.85,198.67 		"
        />

        <polygon
          fillRule="evenodd"
          fill="#603852"
          stroke="#000000"
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit="10"
          points="
			52.93,165.85 132.6,165.85 137,198.54 49.09,198.54 		"
        />
        <path
          fillRule="evenodd"
          fill="#4D2D42"
          d="M50.17,194.5c11.83,1.98,25.89,1.69,41.75,1.75c16.75,0.07,31.55,0.32,43.86-1.75
			l0.83,4.17c-29.06,0-58.13,0-87.19,0"
        />
        <g>
          <linearGradient
            id="SVGID_00000030483229722290848920000002635138669684101043_"
            gradientUnits="userSpaceOnUse"
            x1="93.0143"
            y1="214.0644"
            x2="93.0143"
            y2="198.5371"
          >
            <stop offset="0" stopColor="#321731" />
            <stop offset="1" stopColor="#41273E" />
          </linearGradient>
          <polygon
            fillRule="evenodd"
            fill="url(#SVGID_00000030483229722290848920000002635138669684101043_)"
            points="49.09,198.54 
				136.94,198.54 135.11,214.06 50.91,214.06 			"
          />

          <polygon
            fill="none"
            stroke="#000000"
            strokeWidth="1.2"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            points="
				49.09,198.54 136.94,198.54 135.11,214.06 50.91,214.06 			"
          />
        </g>
        <path
          fillRule="evenodd"
          fill="#2B162A"
          d="M50.78,207.9c0,0,30.32,1.55,40.92,1.58s43.48-1.21,43.48-1.21l-0.54,5.26
			l-83.19-0.11"
        />
      </g>

      <polyline points="111.55,214.19 110.68,213.42 110.23,211.35 106.29,213.82 99.33,214.95 110.31,215.55 114.13,215.31 	" />
      <polyline
        fill="#392B3C"
        points="113.44,213.66 111.36,213.66 110.23,211.35 108.29,214.19 103.47,215.46 110.98,215.79 
		114.81,215.55 	"
      />
      <polyline
        points="104.56,215.79 97.8,211.35 95.78,208.54 92.66,209.3 90.25,207.9 85.58,210.83 82.6,211.87 80.81,212.43 
			77.36,214.95 		"
      />
      <g>
        <path
          fill="#392B3C"
          d="M70.18,214.61l7.18,0.35l5.57-2.52l3.68-0.85l3.84-3.05l2.2,1.68l3.31-0.39l1.61,2.27
				c0,0,2.5,1.32,2.79,1.46s4.53,2.6,4.53,2.6v1.51l-34.71-0.9"
        />
        <path
          fill="#231428"
          d="M77.36,214.95l5.57-1.39l6.28-1.7l1.7-0.99l2.27,0.91l1.37-0.44c0,0,2.31,1.56,2.46,1.61
				s6.14,2.21,6.14,2.21l-5.74-3.29l-1.68-2.36l-3.07,0.7l-2.2-1.68l-3.84,3.05l-4.07,1.02L77.36,214.95z"
        />
      </g>
    </g>
    <path fillRule="evenodd" d="M163.97,156.22l1.72,38.54c0,0-2.31-35.5-7.96-41.6" />
    <FoxGroup isActive={isActive}>
      <path
        fillRule="evenodd"
        fill="#2C1329"
        d="M112.55,101.16c-3,3.02-9.21,8.14-17.91,9.71c-12.59,2.27-23.17-4.07-23.54-5.31
						c-0.09-0.26,1.06-3.02,1-5.08C71.8,89.93,73.3,77.09,87.74,74.7c0,0,8.17-2.87,14.94,3.28c6.78,6.16,10.03,18.25,10.03,18.25
						S113.9,99.8,112.55,101.16z"
      />
      <path
        fillRule="evenodd"
        fill="#2C1329"
        d="M108.3,97.23c0,0,0.91-4.99,1.87-5c0.95-0.01,0.96,2.34,0.96,2.34
						s0.67-4.69,1.81-4.77c1.14-0.08,3.49,7.27-0.48,11.43"
      />
      <path
        fillRule="evenodd"
        fill="#2C1329"
        d="M80.53,101.43c-0.74-2.62-2.42-4.38-3.42-4.43c-1.01-0.04-0.48,3.53-0.48,3.53
						s-0.9-3.44-2.34-4.16c-1.45-0.72-0.64,4.6-0.64,4.6s-2.17-3.58-3.37-4.47c-1.2-0.89-2.63,1.36-1.17,5.46
						c1.13,3.2,2.74,4.37,2.74,4.37"
      />
      <path
        fill="#2C1329"
        d="M80.19,126.12c-1.53-1.21-3.1-2.24-4.64-3.35c0,1.24,0.02,2.46,0.02,3.69l-7.85,0.09l3.75,6.48
					l-2.62,1.71l3.51,2.75c0,0-1.6,2.79-1.48,2.7c1.88,0.43,3.86,0.4,5.72-0.12l0.74,0.17l0.14,1.07l1.22-0.15l0.21,0.95
					c0,0,0.92-0.42,1.02-0.36c2.44,1.09,5.27,1.26,7.88,0.44c0.6-0.36,1.39-0.37,2.01-0.04c3.31,0.09,6.63-1.47,8.65-4.1l0.96,0.74
					l0.22-1.73l1.31-0.01c0,0-0.39-1.44-0.38-1.7c2.25-2.98,3.21-6.9,2.61-10.59c-0.16-0.93-0.74-1.78-1.55-2.26
					c-0.81-0.49-1.84-0.6-2.69-0.43c-1.76,1.64-3.14,2.61-5.33,3.57l-1.3-0.76l0.24,1.27l-1.73-0.49c0,0,0.35,1.55,0.24,1.62
					c-0.94,0.22-2.45-0.21-3.5,0.03l-0.66-0.93l-0.65,0.76l-0.73-0.68c0,0-0.12,0.78-0.31,0.72
					C83.45,127.43,81.53,127.18,80.19,126.12z"
      />

      <path
        fillRule="evenodd"
        fill="#2C1329"
        d="M86.44,76.51c0.79,0.43,1.57,0.84,2.32,1.22c6.89,3.41,11.93,4.21,14.42,4.38
					c1.15,0.08,1.76,0.02,1.76,0.02c0.65-2.39,0.15-11.28,0.15-11.28l-1.56,1.58c-0.41-7.25-2.64-17-2.64-17
					c-9.61,3.3-11.86,15.31-11.86,15.31c-0.8-1.92-0.79-5.32-0.79-5.32C86.07,69.21,86.44,76.51,86.44,76.51z"
      />
      <path
        fillRule="evenodd"
        fill="#2C1329"
        d="M62.51,79.56c0,0,2.8,5.77,5.18,9.39c3.1,4.72,6.1,6.01,7.21,5.05
					c2.61-2.24,7.15-7.21,8.42-15.4c0.08-0.52,0.14-1.05,0.2-1.6c0,0-5.09-5.19-6.7-9.13c0,0-1.18,0.82-0.77,3.24
					c0,0-9.02-5.69-11.75-12.38c0,0-3.98,12.26,2.29,21.54C66.57,80.27,64.38,78.85,62.51,79.56z"
      />
      <path
        fillRule="evenodd"
        fill="#2C1329"
        d="M103.21,104.87c0,0-10.96,6.51-11.75,6.76c-0.78,0.26,4.51,14.6,11.38,13.92
				c6.91-0.7,13.08-7.11,11.39-8.79S103.21,104.87,103.21,104.87z"
      />
      <path
        fillRule="evenodd"
        fill="#2C1329"
        d="M109.41,113.47c0,0,0.56-3.59,2.55-2.92c1.98,0.67,7.49,6.17,6.39,6.83
				c-1.1,0.67-4.49-1.26-4.55-1.34l0,0c0,0,1.48,1.89-0.08,2.35C112.12,118.88,108.66,114.17,109.41,113.47z"
      />
      <path
        fillRule="evenodd"
        fill="#2C1329"
        d="M103.98,119.12c0,0,0.55-3.57,2.54-2.91c1.99,0.67,7.51,6.13,6.41,6.79
				c-1.09,0.67-4.49-1.24-4.55-1.32c0-0.01,0-0.01,0-0.01s1.48,1.88-0.08,2.35C106.71,124.5,103.23,119.82,103.98,119.12z"
      />
    </FoxGroup>
  </ChamberFull>
);

const ButtonUpG = styled.g`
  ${props => props.isActive && 'display: none;'}
  cursor: pointer;
  &:active {
    display: none;
  }
`;

const ButtonDownG = styled.g`
  cursor: pointer;
`;

const animTankBrightness = keyframes`
  0% { filter: brightness(1.1); } 
  50% { filter: brightness(1.2); } 
  100% { filter: brightness(1.1); } 
`;

const TankTestPath = styled.path`
  ${props => (props.isActive ? 'fill: #00BDF2; ' : 'fill: transparent; filter: brightness(1);')}
  transition: 2s all ease-in;
  /* animation: ${props => props.isActive && animTankBrightness} 2s 3s ease-in-out infinite; */
`;

const animTankPath2 = keyframes`

0% { opacity: 1; filter: brightness(1); } 
  50% { opacity: 0.6; filter: brightness(1); } 
  100% { opacity: 1; filter: brightness(1); } 
`;

const animTankPath2start = keyframes`
  0% { opacity: 0; filter: brightness(1); } 
  100% { opacity: 1; filter: brightness(1); } 
`;

const TankTestPath2 = styled.path`
  opacity: 0;
  filter: brightness(1);
  ${props => props.isActive && 'opacity: 1;'}
  transition: 1s all ease-in;
  animation: ${props => props.isActive && animTankPath2} 2s 0.5s ease-in-out infinite,
    ${props => props.isActive && animTankPath2start} 0.5s ease-in-out;
  ${props => props.step === 5 && 'filter: brightness(1.2);'}
  transition: 5s filter;
`;

const animFoxGroup = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(8px); }
  100% { transform: translateY(0px); }

`;

const FoxGroup = styled.g`
  animation: ${animFoxGroup} 8s infinite;
  ${props => (props.isActive ? 'opacity: 0;' : 'opacity: 1;')}
  transition: 1.5s 2s opacity ease-out;
`;

const ChamberFull = styled(Svg)`
  z-index: 5;
  width: 600px;

  @media (max-width: 480px) {
    width: 400px;
  }
`;

const Chamber = styled(Svg)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 95px;
  width: 59.25%;
  transition: all 1s;

  /* stop:first-child {
    stop-color: red;
  } */
`;

const animPillarFloat = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-9px); }
  100% { transform: translateY(0); }
`;

const animPillarFloatTopToBottom = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-15px); }
  100% { transform: translateY(0); }
`;

export const Pillar5TopSvg = () => (
  <Pillar5Top viewBox="0 0 40 50">
    <path
      fill="var(--mint-pillar-secondary-color-shadow)"
      d="M6.84,0.9L2.94,7.37l3.61,14.67l-1.25,4.59l5.56,14.04c3.31-1.53,6.62-3.05,9.92-4.58
		C16.14,24.36,11.49,12.63,6.84,0.9z"
    />
    <path fill="var(--mint-glow-primary)" d="M34.88,45.08c-1.01,1.24-2.01,2.47-3.02,3.71L37.28,49C36.48,47.69,35.68,46.39,34.88,45.08z" />
    <path
      fill="var(--mint-glow-primary)"
      d="M16.64,34.89l-5.77,5.77l17.1,0.7c1.58-1.59,3.16-3.18,4.74-4.77C27.35,36.02,21.99,35.45,16.64,34.89z"
    />
    <polygon
      fill="var(--mint-pillar-secondary-color-light)"
      points="37.28,49 29.77,22.66 28.66,22.8 27.48,17.44 28.73,15.92 25.67,6.05 6.84,0.9 16.64,34.89 
		31.44,37.18 27.97,41.35 29.64,41.48 31.86,48.79 34.92,46.5 	"
    />
  </Pillar5Top>
);

const Pillar5Top = styled(Svg)`
  position: absolute;
  bottom: 550px;
  right: 0;
  width: 200px;
  filter: contrast(0.9);
  animation: ${animPillarFloatTopToBottom} 11s 0.22s ease-in-out infinite;
`;

export const Pillar5BottomSvg = () => (
  <Pillar5Bottom viewBox="0 0 30 50">
    <path
      fill="var(--mint-pillar-secondary-color-shadow)"
      d="M13.03,24.75c-0.57-2.76-1.15-5.52-1.72-8.28c1.19-0.32,2.37-0.65,3.56-0.97
		c-0.88-0.73-1.76-1.46-2.64-2.19c-1.33-3.98-2.66-7.95-4-11.93L2.87,5.3l1.31,7.36L3.7,13.17L9.18,50h7.66
		c-0.83-7.76-1.66-15.52-2.48-23.28C13.92,26.06,13.47,25.41,13.03,24.75z"
    />
    <polygon
      fill="var(--mint-pillar-secondary-color-light)"
      points="26.11,44.36 19.38,27.81 17.45,26.08 17.13,22.95 13.73,21.12 13.08,18.34 15.56,15.44 12.92,4.27 
		8.23,1.38 10.32,14.74 14.23,15.35 9.92,15.44 11.19,25.36 12.49,26.93 16.85,50 26.89,50 	"
    />
    <path
      fill="var(--mint-glow-primary)"
      d="M17.89,19.94c-0.63,1.34-1.27,2.68-1.9,4.01l2.55-0.36l0.59-0.52l0.72-2.38
		C19.19,20.44,18.54,20.19,17.89,19.94z"
    />
    <path
      fill="var(--mint-glow-primary)"
      d="M16.89,16.81c0.01-0.56,0.03-1.13,0.04-1.69l-2.35-0.72l-2.48,3.13c1.1,0.54,2.19,1.07,3.29,1.61
		C15.89,18.36,16.39,17.58,16.89,16.81z"
    />
    <path
      fill="var(--mint-glow-secondary)"
      d="M17.56,19.08c-0.72-0.41-1.44-0.82-2.16-1.23l-3.3-0.33l1.04,3.98l2.84,2.45l2.61-2.55
		C18.25,20.64,17.9,19.86,17.56,19.08z"
    />
    <polygon
      fill="var(--mint-pillar-secondary-color-light)"
      points="15.4,17.85 16.74,19.91 18.6,21.41 19.84,20.69 18.99,16.06 16.93,15.11 15.76,16.38 	"
    />
  </Pillar5Bottom>
);

const Pillar5Bottom = styled(Svg)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 400px;
  filter: contrast(0.9);
`;

export const Pillar4TopSvg = () => (
  <Pillar4Top viewBox="0 0 25 50">
    <g>
      <path
        fill="var(--mint-pillar-primary-color-shadow)"
        d="M3.41,1.3L1.92,6.98l3.42,26.59l2.48,2.04H8.6c-0.42-0.8-0.84-1.61-1.27-2.41
		c0.06-0.72,0.11-1.44,0.17-2.17c0.23-0.14,0.47-0.27,0.7-0.41l0.73,0.19l2.04,8.88c1.47-0.47,2.94-0.94,4.4-1.41
		C11.39,25.96,7.4,13.63,3.41,1.3z"
      />
      <path
        fill="var(--mint-glow-secondary)"
        d="M13.68,37.44l-2.7,2.26l3.38,1.36l3.18-0.68l1.82,0.88c0.16-1.1,0.32-2.2,0.48-3.3
		C17.78,37.78,15.73,37.61,13.68,37.44z"
      />
      <path
        fill="var(--mint-pillar-primary-color-shadow)"
        d="M19.36,37.49c0,1.26,0,2.52,0,3.78l2.61,7.93l0.63-0.02l0.18-0.64C21.64,44.85,20.5,41.17,19.36,37.49z"
      />
      <polygon fill="var(--mint-pillar-primary-color-light)" points="19.36,39.09 22.78,48.53 16.87,24.47 6.06,1.25 3.41,1.3 13.68,37.44 	" />
      <polygon fill="var(--mint-pillar-primary-color-light)" points="6.72,33.63 8.6,35.62 8.2,30.63 6.78,30.27 	" />
    </g>
    {/* <g>
      <path
        fill="var(--mint-pillar-ribbon-secondary-shadow)"
        d="M9.05,23.85c-0.46,1.13-0.93,2.25-1.39,3.38c-0.16-2.98,0.08-4.08,0.36-4.49c0.01-0.01,0.03-0.05,0.07-0.1
		c0.11-0.16,0.31-0.47,0.54-0.87c0.12-0.21,0.25-0.46,0.39-0.75c0.11,0.4,0.23,0.81,0.34,1.21C9.25,22.77,9.15,23.31,9.05,23.85z"
      />
      <path
        fill="var(--mint-pillar-ribbon-primary-shadow)"
        d="M10.93,12.16c0,0,0.91,1.79,1.22,2.72s0.49,5.74,0.49,6.31s-1.71,2.74-1.71,2.74
		c-0.34-0.78-0.68-1.57-1.02-2.35c-0.21,0.13-0.42,0.25-0.64,0.38c-0.09-0.32-0.18-0.64-0.27-0.96c0.09-0.21,0.22-0.5,0.36-0.85
		c0,0,0.51-1.28,0.85-2.54C10.84,15.27,10.92,12.56,10.93,12.16z"
      />
      <path
        fill="var(--mint-pillar-ribbon-yellow)"
        d="M10.94,12.15c0,0,0.91,1.79,1.22,2.72s0.49,5.74,0.49,6.31c0,0.57-1.71,2.74-1.71,2.74l-3.29,3.3
		c0,0-0.3-4,0.36-4.49c0.66-0.49,2.52-3.8,2.52-3.8L10.94,12.15z"
      />
      <path
        fill="var(--mint-pillar-ribbon-primary-shadow)"
        d="M10.86,11.52c0,0-1.08,7.28-0.94,7.75c0.14,0.46-0.32,4.42,0.54,5.68c0.86,1.26,1.85,2.27,1.85,2.27
		l0.33-2.07l0.4-0.95c0,0,0.09-3.31,0.09-3.91s-0.3-5.1-0.3-5.33C12.84,14.74,11.7,12.05,10.86,11.52z"
      />
      <path
        fill="var(--mint-pillar-ribbon-shadow-yellow)"
        d="M11.99,14.28c-0.23-0.01-0.31,0.88-1.28,3.2c-0.08,0.18-0.21,0.5-0.36,0.93c-0.13,0.39-0.22,0.72-0.26,0.92
		C10,19.66,9.94,19.97,9.9,20.25c-0.13,0.95-0.16,2.16,0.14,3.49c0.07,0.3,0.15,0.59,0.23,0.86c0.17-0.17,0.34-0.34,0.51-0.51
		c0.76,0.04,1.52,0.07,2.27,0.11C12.76,16.26,12.35,14.29,11.99,14.28z"
      />
      <Pillar4Ribbon2
        fill="var(--mint-pillar-ribbon-red)"
        d="M10.86,11.52c0,0-1.08,7.28-0.94,7.75c0.14,0.46,0.33,4.34,1.19,5.59s1.53,1.82,1.53,1.82l0.72-1.46h1.36
		c0,0-1.59-4.34-1.59-4.93s-0.3-5.1-0.3-5.33C12.84,14.74,11.7,12.05,10.86,11.52z"
      />
    </g> */}
  </Pillar4Top>
);

const animPillar4Ribbon2 = keyframes`
  0% { transform: translate(0, 0) skew(0deg, 0deg); }
  50% { transform: translate(-0.75px, -3px) skew(3deg, 15deg); }
  100% { transform: translate(0, 0) skew(0deg, 0deg); }
`;

const Pillar4Ribbon2 = styled.path`
  animation: ${animPillar4Ribbon2} 2s ease-in-out infinite;
`;

const Pillar4Top = styled(Svg)`
  position: absolute;
  bottom: 550px;
  right: 450px;
  width: 210px;
  filter: contrast(0.95);
  animation: ${animPillarFloat} 8s 0.2s ease-in-out infinite;
`;

export const Pillar4BottomSvg = () => (
  <Pillar4Bottom viewBox="0 0 40 50">
    <g>
      <polygon fill="none" points="10.13,27.23 10.69,29.23 11.55,32.21 12.1,32.29 10.64,26.66 	" />
      <path
        fill="var(--mint-pillar-tertiary-color-shadow)"
        d="M12.51,23.19c-0.59-0.07-1.19-0.14-1.78-0.21c0.33,0.89,0.67,1.79,1,2.68l0.56-0.54l0.79,1.98l0.49,0.17
		l1.18,4.66l0.27,0.01c-0.51-2.45-1.03-4.9-1.54-7.35C13.16,24.13,12.84,23.66,12.51,23.19z"
      />
      <path
        fill="var(--mint-pillar-tertiary-color-shadow)"
        d="M10.64,26.66c-0.15-0.8-0.29-1.6-0.44-2.41c-0.47-1.16-0.94-2.32-1.4-3.49c-1.4,1.29-2.8,2.58-4.19,3.88
		c0.25,1.4,0.5,2.81,0.75,4.21c0.38,0.27,0.75,0.54,1.12,0.81c1.4-0.14,2.81-0.29,4.21-0.43l-0.56-1.99L10.64,26.66z"
      />
      <path
        fill="var(--mint-pillar-primary-color-shadow)"
        d="M10.78,24.24C8.39,17.59,6,10.95,3.61,4.31l-3.1,2.64l1.2,7.46l-0.37,0.23l4.01,27.01l-3.79,8.77h15.28
		c-0.09-0.76-0.18-1.52-0.28-2.28C14.65,40.17,12.71,32.2,10.78,24.24z M11.55,32.21l-0.86-2.98l-4.1-0.34l-0.92-0.52l-0.46-3.61
		l3.47-3.58l1.38,3.24l0.58,2.25l1.46,5.64L11.55,32.21z"
      />
      <polygon
        fill="var(--mint-pillar-primary-color-light)"
        points="36.72,49.14 19.53,39.89 17.89,34.73 16.86,33.93 13.16,32.35 11.74,25.67 11.21,23.17 
		12.47,23.72 13.25,24.61 15.02,31.95 15.45,31.89 13.3,23 10.72,14.6 6.62,9.93 3.61,4.31 10.52,24.46 16.3,48.21 16.86,50.42 
		38.78,50.42 	"
      />
    </g>
    <path
      fill="var(--mint-pillar-ribbon-red)"
      d="M2.18,20.25c0,0,5.56-2.86,5.72-2.92s3.97,1.84,4.28,2.01s0.81,2.64,0.81,2.64l-3.54-0.65L2.9,25.11
	C2.9,25.11,1.92,20.69,2.18,20.25z"
    />
    <path
      fill="var(--mint-pillar-ribbon-yellow)"
      d="M12.49,19.97c0,0-3.67-2.1-4.19-1.99c-0.51,0.11-6.12,3.19-6.12,3.19s0.64,4.13,0.7,4.15
	c0.06,0.02,6.63-3.78,6.69-3.78c0.06,0,3.42,0.44,3.42,0.44"
    />
    <path fill="var(--mint-pillar-ribbon-secondary-shadow)" d="M6.52,9.99c0,0,1.23,4.38,1.47,4.55H8.6l0.54,0.4l0.42-0.09l-0.88-1.86" />
    <path
      fill="var(--mint-pillar-ribbon-secondary-shadow)"
      d="M2.06,9.33v3.97c0,0,0.52-0.51,0.53-0.53C2.6,12.75,3.61,13,3.61,13S3.98,8.47,3.2,6.79"
    />
    <Pillar4Ribbon1
      fill="var(--mint-pillar-ribbon-red)"
      d="M1.4,6.07c0,0,0.21,2.32,0.23,3.17c0.02,0.85-0.97,3.4-0.97,3.4s0.81-0.21,0.97-0.16s0.7,0.76,0.7,0.76
	s1.03-1.48,1.14-3.56c0.1-1.82-0.53-4.13-0.68-4.65C2.77,5,2.89,4.92,2.92,4.89c0.01-0.01-0.13-0.02-0.27,0.06
	C2.22,5.21,1.38,5.86,1.4,6.07z"
    />
    <Pillar4Ribbon3
      fill="var(--mint-pillar-ribbon-yellow)"
      d="M6.34,9.41c0,0,1.24,4.36,2.22,5.32h0.69l0.34,0.48h0.41c0,0-1.47-2.79-1.73-3.39
	C8.02,11.22,6.34,9.41,6.34,9.41z"
    />
  </Pillar4Bottom>
);

const animPillar4Ribbon1 = keyframes`
  0% { transform: translate(0, 0) skew(0deg, 0deg); }
  50% { transform: translate(-0.25px, -0.5px) skew(3deg, 15deg); }
  100% { transform: translate(0, 0) skew(0deg, 0deg); }
`;

const Pillar4Ribbon1 = styled.path`
  animation: ${animPillar4Ribbon1} 3s ease-in-out infinite;
`;

const animPillar4Ribbon3 = keyframes`
  0% { transform: translate(0, 0) skew(0deg, 0deg); }
  40% { transform: translate(-0.95px, -0.5px) skew(5deg, 3deg); }
  100% { transform: translate(0, 0) skew(0deg, 0deg); }
`;

const Pillar4Ribbon3 = styled.path`
  animation: ${animPillar4Ribbon3} 5s ease-in-out infinite;
`;

const Pillar4Bottom = styled(Svg)`
  position: absolute;
  bottom: -70px;
  right: 0px;
  width: 600px;
  filter: contrast(0.95);
`;

export const Pillar3TopSvg = () => (
  <Pillar3Top viewBox="0 0 25 50">
    <g>
      <path
        fill="var(--mint-glow-primary)"
        d="M15.21,35.62c-4.06,1.06-8.11,2.11-12.17,3.17c-0.64,1.34-1.28,2.67-1.93,4.01l2.05,3.57L12.41,49
		l11.72-4.57C21.16,41.49,18.18,38.56,15.21,35.62z"
      />
      <path
        fill="var(--mint-glow-primary)"
        d="M22.46,32.03l1.01-0.8c-0.21-0.31-0.42-0.61-0.63-0.92c-0.48-2.08-0.95-4.16-1.43-6.24
		c-0.11-1.01-0.21-2.02-0.32-3.03c0.04-1.24,0.08-2.48,0.13-3.72l-2.07-1.17l0.56,7.57L22.46,32.03z"
      />
      <path
        fill="var(--mint-pillar-primary-color-light)"
        d="M13.28,36.33c3.62,2.7,7.23,5.4,10.85,8.1L22.6,32.45l-0.14-0.43l-2.77-8.3l-0.56-7.57L18.56,8.3
		l-1.18-2.46l-2.51-1.68l-0.15-1.77l-1.28-1.72C13.38,12.56,13.33,24.44,13.28,36.33z"
      />
      <polygon
        fill="var(--mint-pillar-primary-color-shadow)"
        points="20.29,24.09 22.43,30.89 23.46,31.22 23.35,22.54 21.21,17.32 20.1,21.06 	"
      />
      <polygon
        fill="var(--mint-pillar-primary-color-shadow)"
        points="14.82,37.43 13.44,0.67 10.13,1.85 10.04,8.65 7.87,9.44 5.4,7.96 2.05,10.18 2.05,19.54 
		1.21,20.72 1.11,42.8 2.79,43.44 	"
      />
    </g>
    <path
      fill="var(--mint-pillar-ribbon-secondary-shadow)"
      d="M14.78,31.78c-0.01,0.89-0.03,1.79-0.04,2.68c-0.06-0.05-0.13-0.1-0.19-0.15
	c-4.46-1.02-8.92-2.04-13.38-3.06c0-0.96,0-1.92,0-2.88C5.7,29.5,10.24,30.64,14.78,31.78z"
    />
    <path
      fill="var(--mint-pillar-ribbon-primary-shadow)"
      d="M23.28,38.24l0.37,2.34c-2.98-2.05-5.96-4.1-8.94-6.15c-0.05-1.05-0.1-2.09-0.15-3.14"
    />
    <path
      fill="var(--mint-pillar-ribbon-yellow)"
      d="M0.94,27.12c0,0-0.58,2.88-0.31,3.19c0.27,0.31,13.93,3.5,13.93,3.5l9.35,6.75l-0.48-1.56l-9.45-7.79"
    />
    <path
      fill="var(--mint-pillar-ribbon-red"
      d="M1.02,22.05c-0.24,0-0.67,7.42-0.47,7.66s13.76,3.08,14.06,3.19s9.1,6.8,9.1,6.8s-0.59-4.15-0.76-4.59
	s-8.55-8.84-8.55-8.84S1.27,22.05,1.02,22.05z"
    />
  </Pillar3Top>
);

const animPillar3Top = keyframes`
  0% { transform: translateX(-50%) translateY(0); }
  50% { transform: translateX(-50%) translateY(-8px); }
  100% { transform: translateX(-50%) translateY(0); }
`;

const Pillar3Top = styled(Svg)`
  position: absolute;
  bottom: clamp(610px, 27vw, 680px);
  left: 53.25%;
  transform: translateX(-50%);
  width: clamp(360px, 18vw, 400px);
  animation: ${animPillar3Top} 8s ease-in-out infinite;

  @media (max-width: 768px) {
    bottom: 320px;
    left: 57%;
    width: 160px;
    transform: rotate(5deg) translateX(-50%);
  }
`;

export const Pillar3BottomSvg = () => (
  <Pillar3Bottom viewBox="0 0 45 50">
    <g>
      <polygon fill="none" points="28.06,35.21 27.33,31.13 26.83,31.27 26.62,36.62 	" />
      <polygon fill="none" points="22.57,32.34 21.05,33.45 21.61,42.67 22.57,44.52 25.36,37.97 24.66,31.87 	" />
      <polygon
        fill="var(--mint-pillar-primary-color-light)"
        points="22.27,23.9 21.96,11.4 22.4,10.67 23.12,28.88 25.15,30.21 26.62,36.62 26.83,31.27 27.12,30.31 
		29.3,31.81 26.13,7.07 20.55,4.51 20.62,25.1 	"
      />
      <path
        fill="var(--mint-pillar-primary-color-shadow)"
        d="M30.09,33.13l-1.03,1.2l-0.23-1.85l0.47-0.68l-2.41-2.2l-0.06,1.67l0.5-0.14l0.73,4.08l-1.44,1.41
		l-0.7-7.04l-2.17-1.26L22.66,9.59l-1.29,1.2l0.29,12.68l-0.59,0.79L20.55,4.51L10.1,6.48L9.13,7.24L8.9,11.73l0.32,0.85L8.54,27.87
		l-1.38,0.41l-1.58,4.02l1.94,2.29l-1.17,1.79l-0.88,5.19l-3.76,4.67v3.73h31.73l-3.2-12.15L30.09,33.13z M22.57,44.52l-0.97-1.85
		l-0.56-9.22l1.53-1.12l2.08-0.47l0.7,6.1L22.57,44.52z"
      />
      <polygon fill="var(--mint-pillar-primary-color-light)" points="32.14,33.42 30.09,33.13 30.23,37.82 31.9,49.98 44,49.98 	" />
    </g>
    {/* <path
      fill="var(--mint-pillar-ribbon-secondary-shadow)"
      d="M15.7,19.99c0.72,0.2,1.45,0.39,2.17,0.59l0.54,3.02l0.49-4.89c-0.8-0.43-1.6-0.85-2.4-1.28
	C16.23,18.28,15.96,19.14,15.7,19.99z"
    /> */}
    {/* <g>
      <path
        fill="var(--mint-pillar-ribbon-secondary-shadow)"
        d="M10.41,11.07l-1.43-0.4c0.05,0.94,0.1,1.87,0.15,2.81c0.05,1.17,0.32,2.03,0.55,2.59
		c1.01,2.47,3.39,4.34,3.39,4.34l0,0l0.19,2.35l2.07,1.76l0.49-0.93l0.8,0.75l-0.14-1.15l-1.5-2.23c-0.2-1.49-0.41-2.97-0.61-4.46"
      />
      <path
        fill="var(--mint-pillar-ribbon-yellow)"
        d="M8.87,7.39c0,0-0.27,4.27-0.19,4.52c0.07,0.25,0.98,3.36,1.33,4.02c0.35,0.66,3.59,4.41,3.59,4.41
		l-0.35,2.42l2.6,1.76c0,0,0.05-0.75,0.12-0.77c0.07-0.02,1.15,0.12,1.15,0.12s-0.61-1.17-0.7-1.24c-0.09-0.07-1.08-0.96-1.08-1.13
		c0-0.16,0.52-1.85,0.61-2.11c0.09-0.26-2.02-3.14-2.02-3.14l-4.09-5.75L8.87,7.39z"
      />
      <path
        fill="var(--mint-pillar-ribbon-shadow-yellow)"
        d="M9.5,10.34l-0.84,1.14c0.06,0.69,0.54,5.29,4.24,7.5c1.01,0.6,2.03,0.88,2.87,1.01
		c0.25-0.82,0.51-1.64,0.76-2.46c-0.95-0.36-1.9-0.73-2.85-1.09L9.5,10.34z"
      />
      <path
        fill="var(--mint-pillar-ribbon-red)"
        d="M8.87,7.39c-0.14,0.09-0.36,3.85-0.21,4.1c0.15,0.25,4.11,7,4.36,7.28c0.25,0.28,5.38,1.37,5.38,1.37v3.45
		c0,0,0.46-0.79,0.97-1.72c0.05-0.09,0.42,0.9,0.47,0.81c0.59-1.09,0.86-3.36,0.86-3.7c0-0.4-5.26-2.15-5.26-2.15
		s-1.51-5.56-1.66-5.88c-0.13-0.28-3.72-3.03-4.64-3.56C9.03,7.32,8.89,7.38,8.87,7.39z"
      />
    </g> */}
  </Pillar3Bottom>
);

const Pillar3Bottom = styled(Svg)`
  position: absolute;
  bottom: -200px;
  left: 59%;
  transform: translateX(-50%);
  width: clamp(900px, 40vw, 1000px);

  @media (max-width: 768px) {
    display: none;
  }
`;

export const RibbonForegroundPillars3to4Svg = () => (
  <RibbonForegroundPillars3to4 viewBox="0 0 50 13">
    <polyline fill="var(--mint-pillar-ribbon-yellow)" points="1.23,12 24.04,9.43 49.06,10.34 45.12,7.16 20.7,6.86 2.86,9.43 " />
    <path
      fill="var(--mint-pillar-ribbon-red)"
      d="M1.77,4.07l21.78-3.09l24.71,3.98l0.8,5.38L23.98,8.42L0.87,12.11C0.87,12.11,1.33,4.14,1.77,4.07z"
    />
  </RibbonForegroundPillars3to4>
);

const animForegroundPillars3to4 = keyframes`
  0% { transform: translateX(-50%) skew(0, 0); }
  20% { transform: translateX(-50%) skew(-0.5deg, -0.3deg); }
  40% { transform: translateX(-50%) skew(0, 0); }
  70% { transform: translateX(-50%) skew(-0.6deg, -0.2deg); }
  80% { transform: translateX(-50%) skew(0, 0); }
  100% { transform: translateX(-50%) skew(0, 0); }

`;

const RibbonForegroundPillars3to4 = styled(Svg)`
  position: absolute;
  bottom: 300px;

  left: 57%;
  transform: translateX(-50%);
  width: 520px;
  animation: ${animForegroundPillars3to4} 7s ease-in-out infinite;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const RibbonForegroundPillar2to3Svg = () => (
  <RibbonForegroundPillar2to3 viewBox="0 0 50 18">
    <g>
      <path fill="var(--mint-pillar-ribbon-yellow)" d="M1.92,1.8L1.04,6.22c0,0-0.15,1.21-0.1,1.23c0.05,0.02,5.75,2.77,5.97,2.8" />
      <polyline
        fill="var(--mint-pillar-ribbon-yellow)"
        points="6.91,10.25 40.77,15.01 43.47,16.24 49.33,16.76 48.04,11.24 30.14,6.79 3.92,6.03 2.23,4.01 	"
      />
    </g>
    <path
      fill="var(--mint-pillar-ribbon-red)"
      d="M2.2,0.87c0,0,4.84,3,5.56,3.23c0.72,0.24,32.35,2.6,32.43,2.64c0.08,0.05,2.81,1.29,2.81,1.29l5.47,1.88
	c0,0,0.12,0.1,0.14,0.27c0.19,1.21,0.87,6.12,0.87,6.33c0,0.24-0.08,0.42-0.08,0.42l-6.04-1.36l-3.18-1.37L6.91,10.25L0.6,6.25
	L2.2,0.87z"
    />
  </RibbonForegroundPillar2to3>
);

const RibbonForegroundPillar2to3 = styled(Svg)`
  position: absolute;
  bottom: 400px;
  left: 45.75%;
  transform: translateX(-50%);
  width: 765px;
  animation: ${animForegroundPillars3to4} 4s ease-in-out infinite;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const RibbonBackgroundPillar2to3Svg = () => (
  <RibbonBackgroundPillar2to3 viewBox="0 0 50 40">
    <path
      fill="var(--mint-pillar-ribbon-red)"
      d="M46.05,2.6c0,0-41.31,14.11-42.67,13.17S2.13,35.01,2.13,35.01s45.82-16.1,46.91-17.73S46.05,2.6,46.05,2.6z
	"
    />
    <path
      fill="var(--mint-pillar-ribbon-yellow)"
      d="M45.93,3.97c0,0-42.39,15.58-43.75,14.64c-1.36-0.94-1.25,19.23-1.25,19.23s45.82-16.1,46.91-17.73
	S45.93,3.97,45.93,3.97z"
    />
  </RibbonBackgroundPillar2to3>
);

const RibbonBackgroundPillar2to3 = styled(Svg)`
  position: absolute;
  bottom: 600px;
  left: 40%;
  transform: translateX(-50%);
  width: 300px;
  transform-origin: top right;
  animation: ${animPillar3Top} 8s ease-in-out infinite;

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Pillar2Svg = () => (
  <Pillar2 viewBox="0 0 20 50">
    <g>
      <path
        fill="var(--mint-pillar-primary-color-shadow)"
        d="M15.62,1.25c-0.76,0.32-1.53,0.64-2.29,0.96l-2.54,13.21c1.24,0.11,2.49,0.23,3.73,0.34
		C14.88,10.93,15.25,6.09,15.62,1.25z"
      />
      <path
        fill="var(--mint-pillar-primary-color-shadow)"
        d="M13.96,33.11c-1.12-0.22-2.23-0.44-3.35-0.67c0.49-1.86,0.97-3.71,1.46-5.57c0.38-0.57,0.76-1.14,1.14-1.71
		c0.22-3.1,0.44-6.21,0.66-9.31l-2.91,0.3l-1.69,8.81L7.8,25.91l-5.28,24.1h2.88c1.27-5.05,2.55-10.11,3.82-15.16
		C10.79,34.27,12.38,33.69,13.96,33.11z"
      />
      <polygon
        fill="var(--mint-pillar-primary-color-light)"
        points="16.1,23.49 15.71,22.91 17.74,3.62 15.62,1.25 13.6,15.09 14.04,15.71 16.01,16.44 13.87,15.86 
		12.63,25.05 10.75,26.03 9.53,33.28 13.77,33.13 8.38,33.77 5.39,50.01 16.35,50.01 15.24,39.01 14.53,37.96 15.13,32.51 
		15.48,32.53 16.82,30.63 17.42,28.26 15.97,25.73 	"
      />
      <polygon fill="var(--mint-glow-primary)" points="16.01,16.44 14.04,15.71 13.6,15.09 10.78,15.43 10.95,16.16 13.87,15.86 	" />
    </g>
    <path
      fill="var(--mint-pillar-ribbon-secondary-shadow)"
      d="M15.96,25.25c0,0-0.93,2.83-0.86,3.01l0.34,0.23l0.15,0.42l0.26,0.11l0.2-1.33"
    />
    <path
      fill="var(--mint-pillar-ribbon-secondary-shadow)"
      d="M8.69,27.89v2.61c0,0,0.34-0.33,0.35-0.35c0.01-0.01,0.67,0.14,0.67,0.14s0.24-2.97-0.27-4.07"
    />
    <Pillar2RedRibbonPath
      fill="var(--mint-pillar-ribbon-red)"
      d="M8.26,25.75c0,0,0.14,1.52,0.15,2.08s-0.64,2.23-0.64,2.23s0.53-0.14,0.64-0.11c0.1,0.03,0.46,0.5,0.46,0.5
	s0.67-0.97,0.75-2.33c0.06-1.19-0.34-2.71-0.45-3.05c0-0.01,0.08-0.07,0.1-0.09c0,0-0.09-0.01-0.18,0.04
	C8.79,25.19,8.25,25.62,8.26,25.75z"
    />
    <Pillar2YellowRibbonPath
      fill="var(--mint-pillar-ribbon-yellow)"
      d="M16.03,24.85c0,0-0.92,2.82-0.73,3.71l0.37,0.25l0.01,0.39l0.22,0.15c0,0,0.22-2.05,0.3-2.48
	C16.28,26.44,16.03,24.85,16.03,24.85z"
    />
  </Pillar2>
);

const animPillar2RedRibbon = keyframes`
  0% { transform: translate(0, 0) skew(0deg, 0deg); }
  15% { transform: translate(-1.80px, 1.65px) skew(8deg, 15deg); }
  30% { transform: translate(-0.9px, 0.5px) skew(4deg, 5deg); }
  65% { transform: translate(-1.80px, 1.65px) skew(8deg, 15deg); }
  80% { transform: translate(-0.9px, 0.5px) skew(4deg, 5deg); }
  100% { transform: translate(0, 0) skew(0deg, 0deg); }
`;

const animPillar2YellowRibbon = keyframes`
  0% { transform: translate(0, 0) skew(0deg, 0deg); }
  45% { transform: translate(-0.9px, -0.3px) skew(2deg, 4deg); }
  100% { transform: translate(0, 0) skew(0deg, 0deg); }
`;

const Pillar2RedRibbonPath = styled.path`
  transform-origin: 75% 25%;
  animation: ${animPillar2RedRibbon} 7s ease-in-out infinite;
`;

const Pillar2YellowRibbonPath = styled.path`
  transform-origin: 50% 0%;
  animation: ${animPillar2YellowRibbon} 5s ease-in-out infinite;
`;

const Pillar2 = styled(Svg)`
  position: absolute;
  bottom: -350px;
  left: 175px;
  width: 600px;
  filter: contrast(0.95);

  @media (max-width: 800px) {
    display: none;
  }
`;

export const Pillar1TopSvg = () => (
  <Pillar1Top viewBox="0 0 40 40">
    <g>
      <path
        fill="var(--mint-glow-primary)"
        d="M1.46,28.18l16.01,7.04l17.8-1.9c-3.53-4.02-7.06-8.04-10.59-12.06C16.94,23.57,9.2,25.88,1.46,28.18z"
      />
      <path
        fill="var(--mint-pillar-secondary-color-shadow)"
        d="M29.25,3.25l-18.9,5.06L1.46,28.18c7.42-0.73,14.84-1.47,22.27-2.2C25.57,18.4,27.41,10.83,29.25,3.25z"
      />
      <polygon fill="var(--mint-pillar-secondary-color-light)" points="29.23,3.25 23.56,26.01 35.26,33.32 37.76,19.84 	" />
    </g>
  </Pillar1Top>
);

const Pillar1Top = styled(Svg)`
  position: absolute;
  bottom: 480px;
  left: 85px;
  width: 260px;
  filter: contrast(0.9);
  animation: ${animPillarFloat} 8s 0.2s ease-in-out infinite;
`;

export const Pillar1BottomSvg = () => (
  <Pillar1Bottom viewBox="0 0 40 40">
    <g>
      <path
        fill="var(--mint-pillar-secondary-color-light)"
        d="M10.58,34.88c-1.99-0.61-3.97-1.21-5.96-1.82l-2.17,0.32c0.76,0.65,1.52,1.3,2.28,1.96
		C6.68,35.18,8.63,35.03,10.58,34.88z"
      />
      <path
        fill="var(--mint-pillar-secondary-color-light)"
        d="M10.9,29.06c1.08-0.26,2.15-0.53,3.23-0.79c-0.73-0.69-1.46-1.38-2.19-2.06c-0.42-0.87-0.83-1.74-1.25-2.61
		l-1.86,0.5c0.06,1.07,0.12,2.13,0.19,3.2C9.65,27.88,10.27,28.47,10.9,29.06z"
      />
      <path
        fill="var(--mint-pillar-secondary-color-shadow)"
        d="M31.79,8.85c-0.8-1.35-1.6-2.69-2.4-4.04L18.84,5.99L10.69,23.6l0.41,3.35l2.31,1.27l-2.54,0.36l-1.09-1.58
		l-0.95-2.9l-4.21,8.96l4.3,1.77l-4.07-0.41l-2.4-1.04l-1.27,0.68v6.02H20.7c3.06-6.34,6.11-12.67,9.17-19.01
		C30.5,17,31.15,12.92,31.79,8.85z"
      />
      <polygon
        fill="var(--mint-pillar-secondary-color-light)"
        points="37.63,32.15 36,29.3 34.01,27.08 36.36,12.64 32.87,10.97 33.6,7.57 29.39,4.81 25.68,19.75 
		26.44,20.29 20.7,40.08 39.17,40.08 	"
      />
    </g>
  </Pillar1Bottom>
);

const Pillar1Bottom = styled(Svg)`
  position: absolute;
  bottom: 0;
  left: -200px;
  width: 600px;
  filter: contrast(0.9);
`;

export const GroundSvg = () => (
  <Ground viewBox="0 0 100 17">
    <g>
      <path
        fill="var(--mint-ground-cliff)"
        d="M68.14,5.12l0.04,0.9c-1.46-0.08-2.92-0.16-4.39-0.23c-3.03-0.15-6.05-0.29-9.04-0.41
		c-0.36,0.29-0.71,0.58-1.07,0.87c-0.66,0.78-1.32,1.55-1.97,2.33c14.4,0.54,28.81,1.08,43.21,1.62c-5.03,0.33-10.06,0.67-15.08,1
		c-8.17,0.22-16.33,0.44-24.5,0.67c-6.33-0.25-12.67-0.5-19-0.75c-1.09-0.73-2.18-1.47-3.26-2.2V8.33
		C28.03,9.09,23,9.86,17.96,10.62c-5.72,0.4-11.44,0.81-17.17,1.21C0.5,11.15,0.21,10.48-0.08,9.8l-0.04,7.37h100.21
		c0-3.79,0-7.59,0-11.38C93.64,5.91,86.94,5.88,80,5.67C75.96,5.54,72,5.35,68.14,5.12z"
      />
      <polygon fill="var(--mint-ground-surface)" points="19.89,12.9 33.07,8.33 -0.08,9.8 -0.1,14.99 	" />
      <polygon
        fill="var(--mint-ground-surface)"
        points="56.3,16.69 91.28,13.79 100.06,10.73 100.06,8.77 53.68,6.25 41.83,5.61 33.07,8.92 
		23.15,12.67 	"
      />
      <polygon fill="var(--mint-ground-surface)" points="68.18,6.02 63.33,5.05 55.27,4.93 53.79,6.19 70.21,6.43 	" />
      <polygon fill="var(--mint-ground-surface)" points="80.62,4.47 68.14,5.12 79.1,6.55 100.08,7.45 100.08,4.37 	" />
    </g>
    <CrackPolygon
      points="68.16,5.61 68.18,6.02 70.21,6.43 70.58,7.15 82.29,7.82 71.31,6.89 70.44,6.19 
	"
    />
    <CrackPolyline
      points="22.3,8.84 17.44,10.03 15.55,9.94 10.59,11.15 15.47,10.2 17.55,10.26 
	23.81,8.74 "
    />
    <CrackPolygon
      points="18.75,9.86 19.39,9.66 19.01,10.24 19.79,10.34 19.47,10.97 19.84,11.71 
	19.12,11 19.3,10.55 18.43,10.3 "
    />
    <CrackPolygon points="33.07,8.33 33.07,8.92 23,12.69 " />
    <CrackPolygon points="53.68,6.25 72.57,7.28 65.04,6.62 53.79,6.19 " />
  </Ground>
);

const animGroundCrack = keyframes`
  0% { filter: brightness(1); }
  50% { filter: brightness(0.75); }
  100% { filter: brightness(1); }


`;

const CrackPolyline = styled.polyline`
  fill: var(--mint-glow-primary);
  animation: ${animGroundCrack} 3s ease-in-out infinite;
`;

const CrackPolygon = styled.polygon`
  fill: var(--mint-glow-primary);
  animation: ${animGroundCrack} 3s ease-in-out infinite;
`;

const Ground = styled(Svg)`
  /* position: absolute; */
  /* bottom: -2px; */
  /* left: 50%;
  transform: translateX(-50%); */
  z-index: 20;
  width: 100%;
`;
