import styled, { keyframes } from "styled-components";

const ProgressBar = ({ width, colorScheme = 'dark' }) => {
  const colorPrimary = colorScheme === 'dark' ? '#000' : '#d55454';
  const colorSecondary = colorScheme === 'dark' ? '#555' : '#7b1d14';

  return <TimerBar width={width}>
    <TimerBarBackground background={colorPrimary} />
    <Time>2 HR 5 MIN</Time>
    <TimerWrapper>
      <Bar background={colorSecondary}>
        <Progress />
      </Bar>
    </TimerWrapper>
  </TimerBar>;
}

const animTimerBar = keyframes`
  0% { transform: translateY(10px) scale(0.99); opacity: 0; }
  100% { transform: translateY(0) scale(1); opacity: 1; }
`

const TimerWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`

const Bar = styled.div`
  position: absolute;
  ${props => `background: ${props.background};`}
  /* background: #545454; */
  /* background: #7b1d14; */
  width: 248px;
  height: 24px;
  transform: perspective(100px) rotate3d(-6, 4, -2, -3deg) skew(-1deg, -1deg) scale(1);
`
const Progress = styled.div`
  position: absolute;
  background: #ffffff;
  width: 90px;
  top: -2px;
  height: 27px;
  transform: perspective(100px) rotate3d(-6, 4, -2, -3deg) skew(-1deg, -1deg) scale(1);

`

const Time = styled.div`
  margin-left: 40px;
  width: 130px;
  z-index: 2;
  font-family: var(--primary-text-font);

`
const TimerBarBackground = styled.div`
  position: absolute;
  ${props => `background: ${props.background};`}
  left: -5px;
  /* background: rgb(0,0,0); */
  /* background: #d55454; */
  width: 434px;
  height: 49px;
  transform: perspective(100px) rotate3d(-3, 4, -2, -1deg) skew(-2deg, -1deg) scale(1);
`

const TimerBar = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${props => `width: ${props.width}px;`}
  color: #fff;
  /* opacity: 0; */
  height: 49px;
  /* animation: ${animTimerBar} 1.6s 0.1s cubic-bezier(0, 0.6, 0, 1) forwards; */
`

export default ProgressBar;