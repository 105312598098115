import styled from "styled-components"
import ProgressBar from "Project/Game/UI/components/ProgressBar";
import { questData } from "Project/Game/data/gameData";

const TimedQuest = () => {
  return null;
}

const QuestPreview = ({ selectedQuestId }) => {
  let selectedQuest = questData.find(quest => quest.id === selectedQuestId);
  console.log('selected quest is', selectedQuest);
  // return <Wrapper isVisible={selectedQuestId > -1}>
  return <Wrapper isVisible={true}>
    <Background />
    <Header>
      {/* <HeaderBg /> */}
      <HeaderTxt>{selectedQuest?.title}</HeaderTxt>
    </Header>
    <BorderWrapper>
      <Text>{selectedQuest?.description}</Text>
      <ProgressBar width={440} />
    </BorderWrapper>

  </Wrapper>;
}

const BorderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  row-gap: 20px;
  padding: 50px;

`

const Text = styled.div`
  position: relative;
  font-size: 1.2rem;
  line-height: 1.3;
  width: 50%;
  color: #000;
  font-family: var(--primary-text-font);
`

const HeaderTxt = styled.div`
  position: relative;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 25px 50px;
  font-size: 1.8rem;
  justify-content: center;
  font-family: var(--primary-text-font);
  height: 100%;

`

const HeaderBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #D64C4C;
  width: 100%;
  height: 100%;
`

const Header = styled.div`
  position: absolute;
  top: -50px;

  left: 50%;
  transform: translateX(-50%);
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #FFF4D9;
  width: 100%;
  /* border: 4px solid #000; */
  height: 100%;
transform: perspective(1000px) rotate3d(-2, -40, -9, -1.5deg);
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 700px;
  height: 600px;
  ${props => props.isVisible ? '' : 'display: none;'}
`

export default QuestPreview