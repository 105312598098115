import { useState, Provider } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Reset } from 'styled-reset';
import { themes } from './theme-context';
// import { ThemeProvider } from 'styled-components';

import { LanguageProvider } from 'containers/Language';

import Router from 'App/RouterAnimated';
import Navigation from 'Project/Navigation';
import Footer from 'Project/Footer';
import Styles from 'App/Styles';

const App = () => {
  // const [themeState, setThemeState] = useState({ ...themes });

  // const toggleTheme = () => {
  //   setThemeState(state => {
  //     let oppositeTheme = state.isDay ? themes.night : themes.day;
  //     return { ...oppositeTheme, ...themes.shared };
  //   });
  // };

  return (
    <>
      <Reset />
      <Styles />
      <BrowserRouter>
        {/* <ThemeProvider theme={themeState}> */}
        <LanguageProvider>
          {/* <Navigation /> */}
          <Router />
          {/* <Footer /> */}
        </LanguageProvider>

        {/* </ThemeProvider> */}
      </BrowserRouter>
    </>
  );
};

export default App;
