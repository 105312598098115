import styled from "styled-components";
import { useDroppable } from '@dnd-kit/core';
import { TYPE } from "Project/Game/const"
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { itemReducerAtom } from "Project/Game/data/atoms";

const Droppable = ({ id, item, supports = TYPE.All, children }) => {
  const [equipment, dispatchEquipment] = useAtom(itemReducerAtom);

  // const item = useItem(id, supports);
  const [isDroppable, setIsDroppable] = useState(false);
  const { isOver, over, active, setNodeRef } = useDroppable({
    id,
    data: {
      id,
      supports,
      item
    }
  })

  useEffect(() => {
    if (supports !== TYPE.All && supports !== active?.data?.current.item.properties.type) {
      if (isDroppable)
        setIsDroppable(false);
    } else {
      if (!isDroppable)
        setIsDroppable(true);
    }
  }, [isOver, item])


  // if (id === undefined) return <Wrapper />;
  return <Wrapper backgroundImg={item?.img?.icon} rarityColor={item?.properties?.rarity} isOver={isOver} isDroppable={isDroppable} ref={setNodeRef}>
    {children}
  </Wrapper>
}

const Wrapper = styled.div`
  display: flex;
  user-select: none;
  position: relative;
  justify-content: center;
  align-items: center;
  color: green;
  background: blue;
  width: 100%;
  height: 100%;  
  /* border: 3px solid #DECFAD; */
  border: 4px solid rgba(255,255,255,0.2);
  cursor: pointer;

  /* &:hover {
    scale: 1.15;
  } */

  background-color: rgba(0, 0, 0, 0.2);
  /* ${props => props.color && `background-color: ${props.color};`} */
  ${props => props.rarityColor && `background-color: ${props.rarityColor};`}
  ${props => props.isOver && 'border: 4px solid white;'}
  ${props => props.isOver && !props.isDroppable && `border: 4px solid red;`}
  ${props => props.isOver && props.isDroppable && `border: 4px solid white;`}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.2;
    filter: grayscale(1);
    /* background-color: ${props => props.isOver && '#aaa'}; */
    background-image: url('${props => props.backgroundImg}');
    background-size: 80px 80px;
    background-repeat: no-repeat;
    background-position: center center;
    width: 100%;
    height: 100%;  
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    opacity: 0.8;
    ${props => props.isOver && !props.isDroppable && `background: red; z-index: 50;`}
    left: 0;
    width: 100%;
    height: 100%;  
  }

`

export default Droppable;