import styled, { keyframes } from 'styled-components';

const Spinner = ({ width, height }) => {
  return (
    <Wrapper>
      <Spin width={width} height={height} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
        <Circle fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></Circle>
      </Spin>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const rotator = keyframes`
	 0% {
		 transform: rotate(0deg);
	}
	 100% {
		 transform: rotate(270deg);
	}
`;

const dash = keyframes`
	 0% {
		 stroke-dashoffset: 187;
	}
	 50% {
		 stroke-dashoffset: 46.75;
		 transform: rotate(135deg);
	}
	 100% {
		 stroke-dashoffset: 187;
		 transform: rotate(450deg);
	}
`;

const colors = keyframes`
	0% {
		 stroke: #354584;
	}
  20% {
		 stroke: #4c9196;
	}
	 60% {
		 stroke: #77ab62;
	}
	 80% {
		 stroke: #a53b75;
	}
	 100% {
		 stroke: #5e347c;
	}
`;

const Circle = styled.circle`
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke-width: 6px;
  animation: ${dash} 1.4s ease-in-out infinite, ${colors} 5.6s ease-in-out infinite;
`;

const Spin = styled.svg`
  animation: ${rotator} 1.4s linear infinite;
`;

export default Spinner;
