import { atom, useAtom } from "jotai";
import { atomWithReducer, useAtomValue } from "jotai/utils";
import { MAP_AREA, TYPE, OVERWORLD, CLASS, CURRENCY, MAP_SECTION, INTERFACE } from "../const";
import { v4 as uuidv4 } from 'uuid';
import { ZOOM } from "../const";
import { overworldMarkers } from "./gameData";
import { GAMESTATE } from "../const";

// new start
export const gameStateAtom = atom(GAMESTATE.StartScreen)
// new end





// export const userState = atom({
//   name: "",
//   camera: { focusId: '', object: { position: { x: 0, y: 0, z: 0 }, target: { x: 0, y: 0, z: 0 } } },
//   currency: [
//     { type: CURRENCY.CBDC, amount: 0 }, { type: CURRENCY.Firo, amount: 0 }
//   ],
//   class: CLASS.Hacker,
//   level: 1,
//   equipment: [],
//   inventory: [],
//   quests: [{}]
// });

let initialOverworldPosition = overworldMarkers[0];

// export const camAtom = atom({
//   activeMarker: initialOverworldPosition,
//   section: MAP_SECTION.OuterRim,
//   position: { x: 6776, y: 2786, z: 5759 },
//   target: { x: 1335, y: 5515, z: -395 },
//   // target: { x: 1335, y: 15, z: -395 },
//   zoom: ZOOM.Overworld,
//   inTransition: false,
//   previousCameraState: []
// })

export const camAtom = atom({
  "activeMarker": {
    "id": 6,
    "audioId": "undergroundBase",
    "areaType": 0,
    "zoom": 2,
    "name": "Underground Base",
    "description": "Resistance Headquarters",
    "data": {
      "markerPos": [
        2852,
        95,
        734
      ],
      "position": {
        "x": 3152,
        "y": 52,
        "z": 934
      },
      "target": {
        "x": 1335,
        "y": 15,
        "z": 195
      },
      "boxTransforms": [
        130,
        -1,
        1,
        -1
      ]
    }
  },
  "section": 0,
  "position": {
    "x": 3152,
    "y": 52,
    "z": 934
  },
  "target": {
    "x": 1335,
    "y": 15,
    "z": 195
  },
  "zoom": 2,
  "inTransition": false,
  "previousCameraState": []
})

export const interfaceAtom = atom({
  player: { mini: false, main: false },
  inventory: false,
  quests: { mini: false, main: false, selected: -1 },
  interactable: false,
  dialogue: false,
})

// export const interfaceAtom = atom({
//   player: { mini: true, main: false },
//   inventory: false,
//   quests: { mini: true, main: false, selected: -1 },
//   interactable: false,
//   dialogue: false,
// })

export const questsAtom = atom([])
export const loadedAudioAtom = atom({ layer1: { audio: new Audio(), src: '' }, layer2: { audio: new Audio(), src: '' } })

export const playerClassAtom = atom(null)
export const notificationAtom = atom(null)
export const camInTransitionAtom = atom(false)
export const selectedQuestAtom = atom(null)
export const areasAtom = atom([])
export const debugOptionsAtom = atom({ postProcessing: true, mouseCamera: false, resetCamera: false, camera: { fov: 50 } });
export const npcAtom = atom({ name: 'NPC', text: ['Hello, I am an NPC'], img: undefined });
export const exampleAtom = atom(false);
export const showMenuHud = atom(false);
export const zoomedAtom = atom({ inProgress: false, complete: false });

export const playerEnergyAtom = atom({ available: 2, total: 3, timeUntilNext: 0 });

const initializeItems = {
  [TYPE.Background]: { equipped: undefined, equippable: [] },
  [TYPE.Pattern]: { equipped: undefined, equippable: [] },
  [TYPE.Weapon]: { equipped: undefined, equippable: [] },
  [TYPE.Tail]: { equipped: undefined, equippable: [] },
  [TYPE.Clothing]: { equipped: undefined, equippable: [] },
  [TYPE.Shoulder]: { equipped: undefined, equippable: [] },
  [TYPE.Ears]: { equipped: undefined, equippable: [] },
  [TYPE.Head]: { equipped: undefined, equippable: [] },
  [TYPE.Eyes]: { equipped: undefined, equippable: [] },
  [TYPE.Eyebrows]: { equipped: undefined, equippable: [] },
  [TYPE.Mouth]: { equipped: undefined, equippable: [] },
  [TYPE.Facial]: { equipped: undefined, equippable: [] },
  [TYPE.Hat]: { equipped: undefined, equippable: [] },
}

const callItemReducer = (state, action) => {
  let newState, item, slotId, slotNumber, active, over, doesOverSupportActive, supportType
  switch (action.type) {
    case "INITIALIZE":
      newState = { ...state };
      item = action.payload.item
      const itemType = action.payload.type
      let equippable = action.payload.equippable
      if (action.payload.equippable.length > 0)
        equippable = [item, ...equippable]
      else
        equippable = [item]
      newState[itemType].equipped = item;
      newState[itemType].equippable = [...equippable];
      return newState;
    case "EQUIP":
      newState = { ...state };
      item = action.payload.item
      newState[item.properties.type].equipped = item;
      return newState;
    case "UNEQUIP":
      newState = { ...state };
      item = action.payload.item
      newState[item.properties.type].equipped = undefined
      return newState;
    case "ADD":
      newState = { ...state };
      item = action.payload.item
      newState[item.properties.type].equippable.push(item)
      return newState;
    case "REMOVE":
      newState = { ...state };
      return newState;
    default:
      return state;
  }
}

const inventoryREducer = (state, action) => {
  let newState, item, slotId, slotNumber, active, over, doesOverSupportActive, supportType
  switch (action.type) {
    case "INITIALIZE":
      newState = { ...state };
      item = action.payload.item
    case "ADD":
      newState = { ...state };
      item = action.payload.item
      newState[item.properties.type].equippable.push(item)
      return newState;
    case "REMOVE":
      newState = { ...state };
      return newState;
    default:
      return state;
  }
}

const initializeInventory = [null, null, null, null, null]

export const itemReducerAtom = atomWithReducer(initializeItems, callItemReducer);
export const inventoryReducerAtom = atomWithReducer(initializeInventory, callItemReducer);