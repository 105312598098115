
import { useState } from "react";
import styled from "styled-components";
import MenuChoices from "./MenuChoices";
import ConnectWallet from "./ConnectWallet";
import { GAMESTATE } from "Project/Game/const";
import { useAtom } from "jotai";
import { gameStateAtom } from "Project/Game/data/atoms";

const StartScreen = () => {
  const [gameState, setGameState] = useAtom(gameStateAtom);
  const [isConnected, setIsConnected] = useState(false);

  const handleMenuChoice = (choice) => {
    setGameState(choice);
  }

  return (
    <Wrapper>
      <Container>
        {
          isConnected ?
            <MenuChoices handleMenuChoice={(choice => handleMenuChoice(choice))} />
            :
            <ConnectWallet setIsConnected={setIsConnected} />
        }
      </Container>
    </Wrapper>
  )
}



const Container = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  z-index: 5;
`

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export default StartScreen;