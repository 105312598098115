import { useContext } from 'react';
import styled, { keyframes } from 'styled-components';
import { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { LanguageContext } from 'containers/Language';
import { TwitterSvg, DiscordSvg, OpenSeaSvg } from 'components/SVG/logos';
import MainButton from './MainButton';
import SocialButton from './SocialButton';
import TitleButton from './TitleButton';

import { FiroLogoFullSvg } from 'components/SVG/logos';
import logo from 'App/assets/images/navigation/logo.png';

const Navigation = ({ changeTheme }) => {
  const { dictionary } = useContext(LanguageContext);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const handleBurger = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const overlay = useRef();

  return (
    <Wrapper role="navigation" onClick={isMobileMenuOpen ? handleBurger : null}>
      <FiroCapper>
        <FiroTitleLink href="https://www.firo.org" target="_new">
          <FiroLogoFullSvg />
        </FiroTitleLink>
      </FiroCapper>
      <Body>
        <Logo>
          <TitleButton to={`preview/#`}>
            <Logo2 src={logo} alt="" />
            {/* <TitleIcon width="40" height="45" src={pawIconWhite} alt="InuBright Logo" />
          <TitleText width="729" height="214" src={titleTextImg} alt="InuBright Title Text" /> */}
          </TitleButton>
        </Logo>
        {/* <MobileMenuToggle onClick={() => handleBurger()}>
        <Hambruger isMobileMenuOpen={isMobileMenuOpen} onClick={() => handleBurger()}>
          <BurgerStrip>
            <div />
            <div />
            <div />
          </BurgerStrip>
        </Hambruger>
      </MobileMenuToggle> */}
        <Nav ref={overlay} isMobileMenuOpen={isMobileMenuOpen}>
          <MainButton to={`preview/#world`}>{dictionary.navigation.world}</MainButton>
          <MainButton to={`preview/#features`}>{dictionary.navigation.features}</MainButton>
          <MainButton to={`preview/#mint`}>{dictionary.navigation.mint}</MainButton>
          <MainButton to={`preview/#faq`}>{dictionary.navigation.faq}</MainButton>
          <MainButton to={`collection`}>{dictionary.navigation.collection}</MainButton>

          {/* <MainButton to={`preview/collection`}>Gallery</MainButton> */}
          <Socials>
            <SocialButton icon={<OpenSeaSvg />} text="OpenSea" link="https://twitter.com/firofoxes" />
            <SocialButton icon={<TwitterSvg />} text="Twitter" link="https://twitter.com/firofoxes" />
            <SocialButton icon={<DiscordSvg />} text="Discord" link="https://twitter.com/firofoxes" />
          </Socials>
          {/* <MintWrapper>
            <MintBtn to={'/collection'}>
              <MintTxt>Collection</MintTxt>
            </MintBtn>
          </MintWrapper> */}
        </Nav>
      </Body>
    </Wrapper>
  );
};

const FiroTitleLink = styled.a`
  opacity: 0.8;
  padding: 5px;

  &:hover {
    opacity: 1;
  }

  &:active {
    transform: translateY(1px);
  }
`;

const ToggleColor = styled.div`
  margin-left: auto;
  margin-right: 10px;
  cursor: pointer;
`;

const Logo2 = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: auto;
`;

const BurgerStrip = styled.div`
  div {
    height: 3px;
    border-radius: 2px;
    background: black;
    margin: 10px;
    transition: all 0.55s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 40px;

    /* margin: 10px auto; */
    transition: all 0.65s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
`;

const Hambruger = styled.div`
  display: none;
  width: 55px;
  height: 55px;
  border-radius: 10px;
  background: white;
  /* box-shadow: rgb(136 165 191 / 48%) 6px 2px 16px 0px, rgb(255 255 255 / 80%) -6px -2px 16px 0px; */
  cursor: pointer;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 1em;
  z-index: 5;
  transition: all 0.3s ease;

  @media (max-width: 1024px) {
    display: flex;
  }

  ${props =>
    props.isMobileMenuOpen
      ? `
      div {
      div:first-child {
        background: black; 
        transform: translateY(13px) rotate(0deg);
      }
      div:nth-child(2) {
        opacity: 0;
      }
      div:last-child {
        background: black;
        transform: translateY(-13px) rotate(90deg);
      }

      transform: scale(0.7) rotate(45deg);
      transition: all 0.25s ease 0.05s;
    }
  `
      : ``}
`;

const Socials = styled.div`
  @media (min-width: 1025px) {
    margin-left: 5px;
    display: flex;
    column-gap: 2px;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`;

const MintTxt = styled.span`
  font-weight: 500;
  letter-spacing: 0.25px;
  @media (max-width: 1024px) {
    font-size: 1.25rem;
  }
`;

const Logo = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  padding: 0 8px 0 5px;

  @media (max-width: 1024px) {
    display: none;
    width: 70px;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
  }
`;

const menuSlideIn = keyframes`
  0% { transform: translateY(5px); opacity: 0; }
  100% { transform: translateY(0); opacity: 1; }
`;

const fadeIn = keyframes`
  0% {opacity: 0;}
  100% {opacity: 1;}
`;

const MobileMenuToggle = styled.div`
  display: none;
  fill: #fff;

  @media (max-width: 1024px) {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 13px;
    width: 48px;
    padding: 34px;
    height: 48px;
    justify-content: center;
    z-index: 5;
  }
`;

const Route = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;

  @media (max-width: 1024px) {
    width: 100%;
    cursor: pointer;

    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &:first-child {
      border-top: 0;
    }
    height: clamp(60px, 8vh, 90px);
  }
`;

const MintWrapper = styled(Route)`
  /* margin-left: auto; */
  opacity: 0;
  margin-left: auto;
  position: relative;
  right: 15px;
  animation: ${menuSlideIn} 0.12s 0.25s linear forwards;

  &:active {
    transform: translateY(1px);
  }

  @media (min-width: 1025px) {
    animation: ${fadeIn} 0.12s 0.15s linear forwards;
  }
`;

const MintBtn = styled(Link)`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #000;
  column-gap: 7px;
  transition: all 0.1s;

  @media (min-width: 1025px) {
    font-size: 1.25rem;
    border-radius: 8px;
    /* background-color: rgba(255, 255, 255, 0.15); */
    background-color: lightgrey;

    //#ffe2ab

    /* background-color: #fff4e5; */
    padding: 18px 20px;
    font-size: 1.05rem;

    &:hover {
      /* background-color: rgba(255, 255, 255, 0.25); */
      background-color: #fff;
    }
  }

  @media (max-width: 1024px) {
    height: 60px;
    width: 100%;
    height: 100%;
    padding: 0 5px;
    justify-content: space-between;
    flex-direction: row-reverse;

    span {
      opacity: 0.8;
    }
  }
`;

const Nav = styled.menu`
  padding: 0;
  display: flex;
  font-size: 1.3em;
  width: 100%;
  column-gap: 0.5vw;
  flex-wrap: wrap;
  transition: all 1s;
  align-items: center;

  @media (max-width: 1024px) {
    ${props =>
    props.isMobileMenuOpen ? 'display: flex; height:100vh; width: 90vw; background-color: var(--nav-background);' : 'display: none; '}
    position: absolute;
    top: 0;
    left: 0;
    justify-content: flex-start;
    padding-right: 5vw;
    padding-left: 5vw;
    font-size: 1.2em;
    flex-direction: column;
    font-size: 1.25rem;
    margin: 0;
    padding-top: 70px;
  }
`;

const animFadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1;  }
`;

const animSlideIn = keyframes`
  0% {transform: translateY(-15px); }
  100% {  transform: translateY(0); }
`;

const FiroCapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(10px);
  height: 80px;
  width: 150px;
  background-color: #171717ed;
  padding: 0 0 0 10px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;

  @media (max-width: 1024px) {
    backdrop-filter: blur(0);
    display: none;
  }
`;

const Body = styled.div`
  display: flex;
  height: 100%;
  backdrop-filter: blur(0);
  width: 100%;

  @media (min-width: 1025px) {
    padding-left: 10px;
    backdrop-filter: blur(10px);
    background-color: #171717cf;
    border-top-right-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;

const Wrapper = styled.nav`
  position: fixed;
  top: 18px;
  display: flex;
  height: 80px;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
  max-width: 1450px;
  padding-left: 20px;
  padding-right: 13px;
  margin-left: 5%;
  margin-right: 5%;
  /* column-gap: 0.2vw; */
  width: 90%;
  z-index: 100;

  @media (max-width: 1920px) {
    width: 90vw;
  }

  @media (max-width: 1024px) {
    top: 0;
    padding-left: 3%;
    padding-right: 3%;
    backdrop-filter: blur(0);
    height: 70px;
    width: 94%;
    align-items: inherit;
    flex-direction: column;
  }

  @media (max-width: 480px) {
    padding-left: 5%;
    padding-right: 5%;
    width: 90%;
  }

  @media (min-width: 1025px) {
    animation: ${animFadeIn} 0.45s 0.7s ease-out forwards, ${animSlideIn} 0.7s 0.7s cubic-bezier(0.25, 1, 0.5, 1) forwards;
    opacity: 0;
  }
`;

export default Navigation;
