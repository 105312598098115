import { useRef, useState } from 'react';
import styled from 'styled-components';
import Trait from './Trait';
import { ChevronSvg } from 'components/SVG/ui';

const Category = ({ category, state, selected, dispatch, categoryTraitsJson }) => {
  const [isOpen, setIsOpen] = useState(false);
  const descriptionRef = useRef();
  const handleOpen = e => {
    e.stopPropagation();
    setIsOpen(!isOpen);
    descriptionRef.current.style.height = isOpen ? `0px` : `${descriptionRef.current.scrollHeight + 5}px`;
  };

  const handleTrait = ({ type, trait }) => dispatch({ type, category, trait })

  return (
    <Wrapper>
      <Container>
        <Header aria-controls={`${category}FilterMenuTray`} aria-expanded={isOpen} id={`${category}FilterMenuButton`} onClick={handleOpen}>{category} <Count visible={selected.length > 0}>{selected.length}</Count>
          <OpenState isOpen={isOpen}>{isOpen ? <ChevronSvg /> : <ChevronSvg />}</OpenState></Header>
        <List
          role="group"
          id={`${category}FilterMenuTray`}
          aria-labelledby={`${category}FilterMenuButton`}
          aria-hidden={!isOpen}
          isOpen={isOpen}
          ref={descriptionRef}
        >
          {Object.values(state).map(object => {
            return <Trait key={object.name} trait={object.name} handleTrait={handleTrait} count={object.count} isSelected={selected.find(name => name === object.name)} />
          })}
        </List>
      </Container>
    </Wrapper >
  );
};

const OpenState = styled.div.attrs(props => ({ transform: props.isOpen ? 'scale(-1)' : 'scale(1)' }))`
  position: absolute;
  top: 13px;
  right: 5%;
  z-index: 10;
  transform: ${props => props.transform};
`

const Count = styled.div.attrs(props => ({ visibility: props.visible ? 'visible' : 'hidden' }))`
  background: #c33b47;
  visibility: ${props => props.visibility};
  padding: 3px 5px;
  border-radius: 3px;
  font-size: 0.8rem;
  color: #fff;
`
const List = styled.fieldset.attrs(props => ({ visibility: props.isOpen ? 'visible' : 'hidden' }))`
  display: flex;
  flex-direction: column;  visibility: ${props => props.visibility};
  transition: height 0.35s cubic-bezier(0.25, 1, 0.2, 1);
  height: 0;
  overflow-y: hidden;

`

const Header = styled.div`
  display: flex;
  column-gap: 15px;
  align-items: center;
  position: sticky;
  z-index: 5;
  top: 54px;
  font-weight: 500;
  padding: 15px 0;
  background: #0b090a;
  text-transform: uppercase; 
  cursor: pointer;

    @media (max-width: 1024px) {
    background: #0b090a;
  }
`

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 10px;
`;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export default Category;
