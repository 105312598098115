import { Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import styled from 'styled-components';
import Howto from 'Project/Howto';
import PullPayment from 'Project/PullPayment';

import Landing from 'Project/Landing';
import Collection from 'Project/Collection';
import { WagmiConfig, createClient, configureChains } from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'
import { bscTestnet } from 'wagmi/chains'
import { publicProvider } from '@wagmi/core/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { BinanceWalletConnector } from 'connectors/binanceWallet/binanceWallet.ts'

import Game from 'Project/Game';

const AnimatedRouter = () => {
  const { chains, provider, webSocketProvider } = configureChains(
    [bscTestnet],
    [publicProvider()],
    // [
    //   jsonRpcProvider({
    //     rpc: (chain) => ({
    //       http: "https://rpc.ankr.com/bsc_testnet_chapel",
    //     }),
    //   }),
    // ],
  )

  const client = createClient({
    autoConnect: true,
    connectors: [
      new MetaMaskConnector({ chains }),
      // new InjectedConnector({
      //   chains,
      //   options: {
      //     name: 'Trust Wallet',
      //     getProvider: () =>
      //       typeof window !== 'undefined' ? window.trustwallet : undefined,
      //   },
      // }),
      // new BinanceWalletConnector({
      //   chains
      // }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
        },
      }),
    ],
    provider,
    webSocketProvider,
  })

  const location = useLocation();
  return (
    <WagmiConfig client={client}>
      <AnimatePresence mode="wait">
        <Routes key={location.pathname} location={location}>
          <Route exact path="/collection" element={<Collection />} />
          <Route exact path="/pull/:id" element={<PullPayment />} />
          <Route exact path="/game" element={<Game />} />
          <Route exact path="/preview" element={<Landing />} />
          <Route exact path="/firofox" element={<Landing />} />
          <Route exact path="/firofox" element={<Landing />} />
          <Route exact path="/howto" element={<Howto />} />
          <Route exact path="/" element={<Temp></Temp>} />
        </Routes>
      </AnimatePresence>
    </WagmiConfig>

  );
};

const Temp = styled.div`
  min-height: 90vh;
`;

export default AnimatedRouter;
