import styled from 'styled-components';
import Svg from 'components/SVG';

export const TopRibbonSvg = () => <TopRibbon viewBox="0 0 1269 754">
  <YellowRibbon d="M38.6,213.35c0,0,86.14-17.18,172.8-48.23c102.64-36.77,187.43-95.28,187.43-95.28s12.35-1.01,38.07-1.91
  c25.72-0.9,43.43-0.98,45.82-0.15c0,0-155.94,116.18-239.95,148.33S36.6,279.8,36.6,279.8s0.24-6.88,0.24-32.8
  S38.6,213.35,38.6,213.35z"/>
  <RedRibbon d="M36.58,206.87c0,0,85.33-16.53,171.19-46.4C309.45,125.09,393.44,68.8,393.44,68.8s12.92-0.4,38.4-1.27
  s45.84-1.5,46.31-0.47c0,0-156.08,111.54-239.3,142.47S36.6,275.38,36.6,275.38s-1.9-5.65-1.9-30.58S36.58,206.87,36.58,206.87z"
  />
  <g>
    <path fill="#141923" d="M52.95,255.15L8.02,360.53c0,0,16.59-8.88,17.06-9.33c0.47-0.45,18.95,14.98,18.95,14.98
    s39.78-55.6,53.61-173.37l-5.19-0.37"/>
    <YellowRibbon d="M58.82,202.83c0,0-16.38,50.52-23.61,68.47c-7.23,17.95-50.68,62.53-50.68,62.53s18.92,2.95,21.76,5.3
    c2.84,2.35,7.88,22.12,7.88,22.12s34.79-21.85,55.63-64.4c18.24-37.24,25.89-91.3,27.27-103.67c0.05-0.43,3.4-1.07,4.33-1.45
    c0.19-0.08-4.11,0.26-7.8,0.77C82.19,194.08,60.29,198.33,58.82,202.83z"/>
  </g>
  <g>
    <path fill="#141923" d="M83.32,77.18c15.42-3.21,73.2-2.24,74.56-1.57S99.36,144.16,34.53,237.93
    c-0.14,0.21-0.32-9.11,0.41-17.59c0.85-9.79,2.54-19.52,2.25-27.08C36.41,172.17,79.04,78.06,83.32,77.18z"/>
    <YellowRibbon d="M83.32,77.18c15.42-3.21,73.2-2.24,74.56-1.57S98.87,132.81,34.53,237.93
    c-0.13,0.22-0.32-9.11,0.41-17.59c0.85-9.79,2.54-19.52,2.25-27.08C36.41,172.17,79.04,78.06,83.32,77.18z"/>
    <RedRibbon d="M34.99,143.69c0,0,43.3-65.62,47.57-66.51c15.42-3.21,55.72-2.7,67.58-2.22
    c1.52,0.06-77.74,81.48-115.74,153.99c-0.25,0.48-1.44-21.43-1.5-35.55C32.82,173.09,34.55,144.36,34.99,143.69z"/>
  </g>
</TopRibbon>

export const BottomRibbonSvg = () => <BottomRibbon viewBox="0 0 1269 754">
  <path fill="#141923" d="M1143.17,704.13l-24.95-0.43l-0.53,0.56c0,0,12.61,2.53,26.21,3.33" />
  <path fill="#141923" d="M1174.5,700.4l14.01,4.73c0,0,35.59-47.53,39.39-54.33c3.8-6.8,5.6-11,5.6-11v-16.82" />
  <YellowRibbon d="M1233.5,554.25c0,0-116.75,148.94-115.81,150.02c0.94,1.08,68.66,2.08,70.82,0.87s44.51-67.68,44.99-70.53
			C1233.98,631.75,1233.5,554.25,1233.5,554.25z"/>
  <RedRibbon d="M1233.5,554.25c0,0-116.75,148.94-115.81,150.02c0.94,1.08,68.66,2.08,70.82,0.87s44.04-79.52,45.39-83.03
			C1235.25,618.6,1233.5,554.25,1233.5,554.25z"/>
  <path fill="#141923" d="M1085.1,707.2c0,0,20.32-0.22,22.08-0.87c1.77-0.64,127.23-106.13,127.23-106.13l-0.45-26.2" />
  <YellowRibbon d="M1012.63,703.8l220.75-202.67l0.87,89.96c0,0-125.5,113.34-126.89,114.35
			C1105.98,706.46,1012.63,703.8,1012.63,703.8z"/>
  <RedRibbon d="M1012.63,703.8l219.45-202.2c0,0-0.13-1.31,0.02-2.82c0.04-0.4,1.36,1.08,1.38,1.72s2.37,81.75,0.78,83.2
			c-1.58,1.45-132.3,121.34-135.8,123.1C1094.96,708.57,1012.63,703.8,1012.63,703.8z"/>
</BottomRibbon>


const YellowRibbon = styled.path`
  fill: var(--mint-pillar-ribbon-yellow);
`

const RedRibbon = styled.path`
fill: var(--mint-pillar-ribbon-red);
/* 
--mint-pillar-primary-color-light: #7d3865;
    --mint-pillar-primary-color-shadow: #4e244c;

    --mint-pillar-secondary-color-light: #653c58;
    --mint-pillar-secondary-color-shadow: #301934;

    --mint-pillar-tertiary-color-shadow: #40233d;

    --mint-pillar-ribbon-red: #a8123d;
    --mint-pillar-ribbon-yellow: #ddc677;
    --mint-pillar-ribbon-primary-shadow: #4e2d42;
    --mint-pillar-ribbon-secondary-shadow: #32222b;
    --mint-pillar-ribbon-shadow-yellow: #81724e; */
`

const BottomRibbon = styled(Svg)`
width: 1200px;
  position: absolute;
  bottom: -62px;
  right: -81px;
  z-index: 5;

  @media (max-width: 1250px) {
    display: none;
  }
`;

const TopRibbon = styled(Svg)`
  width: 1200px;
  position: absolute;
  z-index: 5;
  top: -78px;
  left: -14px;
  transform: translateZ(2px);

  @media (max-width: 1250px) {
    top: -84px;
    left: -29px;
  }

  @media (max-width: 768px) {
    width: 900px;
    top: -68px;
    left: -24px;
  }
`;