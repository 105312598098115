import styled from "styled-components";
import React, { useState, useMemo, Suspense, useRef, useEffect } from 'react'
import { KeyboardControls, BakeShadows, ContactShadows, Html, Preload, useProgress, Bvh, SoftShadows, AdaptiveDpr, AdaptiveEvents } from "@react-three/drei";
import DebugHeadless from "./Debug/DebugHeadless";
import UI from "Project/Game/UI";
import Overworld from "./Scenes/Overworld";
import { atom, useAtom } from "jotai";
import { atomWithReducer, useAtomValue } from "jotai/utils";
import { zoomedAtom, questsAtom, areasAtom } from "./data/atoms";
import comTowerExample from 'App/assets/images/temp/com-tower.png'
import StartScreen from "./Scenes/StartScreen";
import { Canvas } from "@react-three/fiber";
import { GAMESTATE } from "./const";
import { gameStateAtom } from "./data/atoms";
import Loader from "./Scenes/Overworld/Loader";

import Camera from "./Scenes/Overworld/Controls/Camera";
import { questData as questDatabase, areaMarkers, userQuests } from "./data/gameData";

// debugOptions, setDebugOptions, setNpc, isZoomed, setIsZoomed, showExample1, setShowExample1

const Game = () => {
  const [gameState, setGameState] = useAtom(gameStateAtom);
  const { active, progress, errors, item, loaded, total } = useProgress()
  const [quests, setQuests] = useAtom(questsAtom);
  const [areas, setAreas] = useAtom(areasAtom);

  useEffect(() => {
    console.log('loaded', loaded)
  }, [loaded])


  useEffect(() => {
    let newQuestData = [];
    let newAreasData = [...areaMarkers];

    const initQuests = () => {
      userQuests.forEach(quest => {
        newQuestData.push({ id: quest.id, startTime: Date.now(), endTime: null, rewardsClaimed: true });
      })
      console.log('initializing quests')
      setQuests([...newQuestData]);
    }

    const initAreas = () => {
      newAreasData.forEach(area => {
        newQuestData.forEach(newQuest => {
          let quest = questDatabase.find(questData => questData.id === newQuest.id);
          if (area.type === quest.area) {
            if (quest.startTime !== null && quest.finishTime !== null) {
              area.activeQuests++;
            }
          }
        })
      })

      setAreas([...newAreasData]);
    };

    initQuests();
    initAreas();
  }, [])

  // useEffect(() => {
  //   console.log('areas', areas)
  // }, [areas,])

  // useEffect(() => {
  //   console.log('quests', quests)
  // }, [, quests])

  return <Wrapper>
    <Container>
      <WrapCanvas>
        {gameState === GAMESTATE.StartScreen &&
          <Suspense fallback={<Loader />}>
            <Canvas>
              <StartScreen />
            </Canvas>
          </Suspense>
        }
        {gameState === GAMESTATE.MainGame && <Suspense fallback={<Loader />}><Overworld /></Suspense>}
      </WrapCanvas>
      <DebugHeadless />
      {/* {showExample1 === true && <Dialogue />} */}
      {loaded && <UI />}
    </Container>
  </Wrapper>
}

const WrapCanvas = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
`

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
`

export default Game;
