import styled from 'styled-components';

const Selection = ({ subheader, name, foreground, description }) => {
  return (
    <Wrapper>
      <Graphics name="compendium-content">
        {/* <BackgroundImg src={foxElitesBg} alt="" /> */}
        <ForegroundImg src={foreground} alt="" />
      </Graphics>
      <Explainer>
        {/* <Title>{name}</Title> */}
        <TextWrapper>
          <Subheader>
            <SubBorder />
            <Title>{name}</Title>
            <SubTextDesktop>Profile</SubTextDesktop>
            {/* <SubTextMobile>{name}</SubTextMobile> */}
          </Subheader>
          <Description>{description}</Description>
        </TextWrapper>
      </Explainer>
    </Wrapper>
  );
};

const SubTextDesktop = styled.div`
  position: relative;
  /* top: 30px; */
  font-family: var(--title-font);
  font-size: clamp(1.5rem, 3vw, 2.75rem);
  transform: translateZ(2px);
  /* line-height: 4rem; */
  /* text-shadow: 8px 8px 0 #000; */

  @media (max-width: 1200px) {
    display: none;
  }
`;

const SubTextMobile = styled.div`
  position: relative;
  font-family: var(--title-font);
  font-size: clamp(1.75rem, 3vw, 2.75rem);
  transform: translateZ(2px);
  padding-bottom: 5px;
  font-size: 4rem;
  text-shadow: 8px 8px 0 #000, 0 0 20px #000;

  @media (min-width: 1200px) {
    display: none;
  }
`;

const SubBorder = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  border: 3px solid var(--border);
  transform: rotate3d(25, 15, -35, 3deg);
  /* box-shadow: 4px 4px 0px var(--border); */

  @media (max-width: 1200px) {
    background: 0;
    box-shadow: unset;
    border: 0;
  }
`;

const Description = styled.div`
  background: #000;
  font-family: var(--primary-text-font);
  font-size: clamp(1rem, 2vw, 1.25rem);
  padding: 24px 18px 22px 28px;
  background: #000;
  border: 3px solid var(--border);
  box-shadow: 6px 8px 0px var(--border);
  line-height: clamp(1.5rem, 2.75vw, 2.1rem);
  transform: perspective(100px) rotate3d(12, -15, 0, 2deg);

  /* @media (max-width: 800px) {
    width: 60%;
    left: 50%;
  } */
`;

const TextWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: clamp(200px, 38vw, 500px);

  @media (max-width: 800px) {
    width: 80%;
    margin-left: 5%;
    margin-right: 5%;
  }
`;

const Subheader = styled.div`
  position: relative;
  left: 15px;
  bottom: -18px;
  padding: 22px 28px 8px 28px;
  perspective: 100;
  z-index: 25;
  width: fit-content;

  @media (max-width: 1200px) {
    padding: 0;
    left: 30px;
    top: -5px;
  }
`;

const Title = styled.div`
  position: absolute;
  left: -30px;
  /* box-shadow: 6px 8px 0px var(--border); */
  bottom: 120px;
  background: #000;
  border: 3px solid var(--border);
  font-size: clamp(3rem, 4.5vw, 5rem);
  text-shadow: 9px 9px 0 #000;
  font-family: var(--title-font);
  /* font-size: clamp(2rem, 3vw, 4.75rem); */
  line-height: clamp(2.55rem, 3.25vw, 4.5rem);
  padding: 20px 60px;
  /* width: fit-content; */
  max-width: 10ch;
  min-width: 4.5ch;
  transform: rotate3d(15, 125, 152, 3deg) rotate(-3deg) skewX(-2deg);

  @media (max-width: 1200px) {
    position: relative;
    left: -12px;
    background: transparent;
    border: 0;
    padding: 15px 30px;
    /* font-size: 4rem; */
    z-index: 55;
    bottom: -45px;
    /* display: none; */
  }
`;

const Explainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  perspective: 100;
  width: 70%;
  bottom: -90px;
  height: 100%;
  gap: 30px;

  @media (max-width: 800px) {
    height: auto;
    /* top: 65px; */
    justify-content: flex-start;
  }
`;

const ForegroundImg = styled.img`
  width: 100%;
`;

const BackgroundImg = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`;

const Graphics = styled.div`
  position: absolute;
  left: 300px;
  bottom: -220px;
  width: clamp(800px, 70vw, 1300px);

  @media (max-width: 1200px) {
    left: 22%;
    bottom: -75px;
  }

  @media (max-width: 800px) {
    position: relative;
    width: 620px;
    left: 50%;
    transform: translateX(-50%);
    top: auto;
    right: auto;
    bottom: -140px;
  }
`;

const Container = styled.div``;

const Wrapper = styled.div`
  position: relative;
  min-width: 120vw;

  @media (max-width: 800px) {
    min-width: auto;
    display: flex;
    left: 50%;
    transform: translateX(-50%);
    align-items: center;
    flex-direction: column;
    /* justify-content: flex-end; */
  }
`;

export default Selection;
