import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

const MainButton = ({ to, children }) => {
  return (
    <Wrapper>
      <Container to={to}><div>{children}</div></Container>
    </Wrapper>
  );
};

const animBounceText = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-1px); }
  100% { transform: translateY(0); }

`

const Container = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  padding: 11px 15px;
  width: 100%;
  cursor: pointer;
  min-height: 25px;
  min-width: 20px;
  border: 3px solid transparent;
  font-weight: 500;
  justify-content: flex-start;
  column-gap: 8px;
  color: #fff;
  border-radius: 8px;
  outline: none;
  /* transition: all 0.08s; */
  /* transition: 0.3s background, 0.2s color; */

  @media (min-width: 1025px) {
    &:hover {
      /* div {
        animation: ${animBounceText} 0.5s cubic-bezier(0.1, 1, 0.5, 0.7) forwards;
      } */
      /* background-color: rgba(255, 255, 255, 0.06); */
      background: #fff;
      color: #000;
    }

    &:active {
      transform: translateY(0.75px);
    }
  }

  @media (max-width: 1024px) {
    width: 100%;
    height: 100%;
    padding: 15px 1vw;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 400;

  @media (max-width: 1024px) {
    width: 100%;
    height: clamp(60px, 8vh, 90px);
    cursor: pointer;

    border-top: 1px solid rgba(0, 0, 0, 0.1);

    &:first-child {
      border-top: 0;
    }
  }
`;

export default MainButton;
