import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDraggable } from '@dnd-kit/core';


const Draggable = ({ item, slotId, mouseDown, setMouseDown }) => {
  const timer = useRef();
  const [hovered, setHovered] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const holdTimer = useRef();


  // useEffect(() => {
  // }, [])

  // useEffect(() => {
  //   if (hovered) {
  //     timer.current = setTimeout(() => { setShowTooltip(true) }, 100);
  //   } else {
  //     setShowTooltip(false);
  //     setHovered(false);
  //     clearTimeout(timer.current);
  //   }
  //   return () => clearTimeout(timer.current);
  // }, [hovered, item])


  const { attributes, listeners, setNodeRef, isDragging, transform } = useDraggable({
    id: slotId,
    data: {
      slotId,
      item
    }
  });

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  const handleMouseOver = () => {
    setHovered(true);
    if (mouseDown === false) { setShowTooltip(true) }
  }

  const handleMouseDown = () => {
    setMouseDown(true);
    clearTimeout(timer.current);
    setShowTooltip(false)
  }

  const handleMouseUp = () => {
    setMouseDown(false);
    clearTimeout(timer.current);
    if (hovered) setShowTooltip(true);
  }

  const handleMouseOut = () => {
    setHovered(false)
    setShowTooltip(false);
  }

  return (
    <Wrapper isDragging={isDragging} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {/* {props.children} */}
      {/* <TooltipWrapper isVisible={showTooltip}>
        <Name qualityColor={qualityColor.current}>
          {item.name}
        </Name>
        <Description>
          {item.description}
        </Description>
      </TooltipWrapper> */}
      <Img isDragging={isDragging} src={item?.img.icon} backgroundColor={item?.properties.quality} />
    </Wrapper>
  );
}

const Name = styled.div`
  font-weight: bold;
  ${props => props.qualityColor && `color: ${props.qualityColor};`}
`
const Description = styled.div``

const TooltipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1.1rem;
  row-gap: 2px;
  position: absolute;
  top: 50px;
  width: 100px;
  left: 80px;
  background: rgba(0,0,0,1);
  color: #fff;
  padding: 20px;
  z-index: 500;
  /* z-index: 500; */
  ${props => props.isVisible ? 'display: flex;' : 'display: none;'}
`

const Img = styled.img`
  width: 80px;
  height: 80px;
  transform: ${props => props.isDragging && 'scale(0.8)'};
  /* background: ${props => props.isDragging && props.backgroundColor}; */

`

const Wrapper = styled.div`
  position: relative;
  touch-action: none;
  display: flex;
  justify-content: center;
  z-index: ${props => props.isDragging ? '1500' : '50'};
  align-items: center;
  width: 100px;
  height: 90px;
  /* background: rgba(11, 25, 55, 0.3); */
`

export default Draggable;