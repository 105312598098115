import styled from 'styled-components';

const Explainer = ({ tag, title, description, link }) => {
  return (
    <Wrapper>
      <Container>
        {/* <Tag>{tag}</Tag> */}
        <WrapText>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </WrapText>
        <LearnButton>Learn More →</LearnButton>
      </Container>
    </Wrapper>
  );
};

const WrapText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  width: 90%;
`;

const LearnButton = styled.button`
  border: 2px solid #434343;
  border-radius: 8px;
  font-size: 1.1rem;
  padding: 16px;
  color: #c2c2c2;
  font-weight: 500;
  width: fit-content;

  &:hover {
    background: #fff;
    border: 2px solid #fff;
    color: #000;
  }
`;

const Description = styled.div`
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-weight: 500;
  color: #e1e1e1;
`;

const Title = styled.div`
  font-size: 1.4rem;
  line-height: 1.75rem;
  font-weight: 500;
`;

const Tag = styled.div`
  font-size: 0.9rem;
  font-weight: 500;
  color: #c2c2c2;
  background: #434343;
  width: fit-content;
  padding: 6px 8px;
  border-radius: 4px;

  @media (max-width: 1024px) {
    display: none;
  }
`;

const Container = styled.div`
  display: flex;
  row-gap: 18px;
  padding: 35px;
  flex-direction: column;
  /* border: 3px solid #1f1f1f; */
  /* border-radius: 18px; */

  @media (max-width: 1024px) {
    row-gap: 18px;
    border: 0;
    padding: 0;
  }
`;

const Wrapper = styled.li`
  display: flex;
  width: 325px;

  justify-content: center;
  align-items: center;

  @media (max-width: 1024px) {
    width: 80%;
    border-bottom: 3px solid #251e26;
    padding-bottom: 40px;

    &:last-child {
      border-bottom: 0;
      padding: 0;
    }
  }
`;

export default Explainer;
