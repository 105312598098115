import { useEffect, useState, useRef } from "react";
import styled, { keyframes } from "styled-components";
import Category from "./Category";
import traits from 'Project/Collection/traits.json';
import { SORT } from "const/sort";
import axios from "axios";
import { SERVER_URL } from "const/general";
import useDebounce from 'hooks/useDebounce'
import useBodyScrollLock from 'hooks/useBodyScrollLock';


const Filters = ({ state, setFilterWait, dispatch }) => {
  const modalRef = useRef();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
  const [isScrollLocked, setIsScrollLocked] = useBodyScrollLock(modalRef, false);

  // const sort = sort => {
  //   dispatch({ type: 'sort', sort })
  // };

  // const debouncedSort = useDebounce(sortType => sort(sortType), 500);

  // const handleSortDebounced = sortType => {
  //   debouncedSort(sortType);
  // };

  const handleSortState = (method) => {
    // setFilterWait(true);
    dispatch({ type: 'sort', method: method })
  }


  return <>
    <FilterWrapper onClick={() => { setIsMobileMenuOpen(true); setIsScrollLocked(true); }}>
      {/* <Count>{state.stack.length}</Count> */}
      <FilterBody>Filters</FilterBody>
    </FilterWrapper>
    <Wrapper isOpen={isMobileMenuOpen}>
      <MobileCloseBtn aria-hidden={!isMobileMenuOpen} >
        <Button onClick={() => { setIsMobileMenuOpen(false); setIsScrollLocked(false); }}>
          <Svg viewBox="0 0 1000 1000">
            <g>
              <path d="M617.2,495.8l349.1,350.9c31.7,31.8,31.7,83.5,0,115.3c-31.7,31.9-83.1,31.9-114.8,0L502.4,611.2L149.8,965.6c-32,32.2-83.8,32.2-115.8,0c-32-32.1-32-84.3,0-116.4l352.6-354.5L48.2,154.6c-31.7-31.9-31.7-83.5,0-115.4c31.7-31.9,83.1-31.9,114.7,0l338.4,340.2l343.3-345c32-32.1,83.8-32.1,115.8,0c32,32.2,32,84.3,0,116.4L617.2,495.8z" />
            </g>
          </Svg>
        </Button>
      </MobileCloseBtn>
      <MobileBgOverlay aria-hidden={true} onClick={() => { setIsMobileMenuOpen(false); setIsScrollLocked(false); }} />
      <Container ref={modalRef}>
        <Sorting>
          <Header>SORT</Header>
          <Options>
            <Option selected={state.sortMethod === SORT.firstMint} onClick={() => handleSortState(SORT.firstMint)}>First Mint</Option>
            <Option selected={state.sortMethod === SORT.lastMint} onClick={() => handleSortState(SORT.lastMint)}>Last Mint</Option>
            <Option selected={state.sortMethod === SORT.mostRare} onClick={() => handleSortState(SORT.mostRare)}>Least Rare</Option>
            <Option selected={state.sortMethod === SORT.leastRare} onClick={() => handleSortState(SORT.leastRare)}>Most Rare</Option>
            {/* <Option>Firocious</Option> */}
          </Options>
        </Sorting>
        <CategoryWrapper>
          <Header>FILTER</Header>
          <Categories>
            {Object.keys(traits).map(category => <Category setFilterWait={setFilterWait} key={category} category={category} state={state.state[category]} selected={state.selected[category]} dispatch={dispatch} />)}
          </Categories>
        </CategoryWrapper>
      </Container>
    </Wrapper>
  </>
}


const Count = styled.div`
  padding: 6px 10px;
  margin: 2px 0;
  background-color: #df6c77;
  border-radius: 8px;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterBody = styled.div`
  padding: 11px 10px 11px 5px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;
`;

const FilterWrapper = styled.div`
  display: flex;
  cursor: default;
  background-color: #c33b47;
  /* border-radius: 8px; */
  border: 4px solid #c33b47;
  visibility: hidden;
  font-weight: 500;
  position: absolute;
  bottom: 0;
  right: 0;

 &:hover {
  filter: brightness(1.1);
 }

  &:active {
    filter: brightness(0.9);
  }

  @media (max-width: 1024px) {
    visibility: visible;
    font-family: var(--primary-text-font);
    transform: perspective(100px) rotate3d(25, 65, -5, -5deg) translateZ(-3px);
    cursor: pointer;
    box-shadow: 0 0 0 0.22rem #df6c77, 0 5px 15px 0.42rem rgba(25, 25, 30, 0.2);

    position: fixed;
    bottom: 5vh;
    right: 8vw;
    /* background-color: #0b0a1a; */
    z-index: 10;
  }
`;

const Button = styled.div`
  padding: 25px;
`;

const Svg = styled.svg`
  width: 20px;
  height: 20px;
  fill: #fff;
`;

const MobileCloseBtn = styled.div`
  cursor: pointer;
  position: fixed;
  top: 0;
  height: 70px;
  right: 15px;
  z-index: 2000;

  @media (min-width: 1024px) {
    display: none;
  }
`;

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const MobileBgOverlay = styled.div`
  display: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  background-color: rgba(20,25,36,0.8);

  @media (max-width: 1024px) {
    display: block;
    position: fixed;
    z-index: 20;
    animation: ${fadeIn} 0.45s cubic-bezier(0.25, 1, 0.5, 1) forwards;
  }
`;

const Categories = styled.div`
  display: flex;
  flex-direction: column;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
`


const Option = styled.div.attrs(props => ({ color: props.selected ? '#fff' : 'grey' }))`
  color: ${props => props.color};
  padding: 15px 0;
  font-size: 1.05rem;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #fff;
  }

  &:active {
    color: #aaa;
  }
`


const SubCategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const Sorting = styled(SubCategoryWrapper)`
    padding-top: 35px;

`

const Header = styled.div`
  position: sticky;
  top: 0;
  background: #0b090a;
  z-index: 10;
  padding: 16px 0;
  font-size: 1.5rem;
  font-weight: 500;

  @media (max-width: 1024px) {
    background: #0b090a;
  }
`

const CategoryWrapper = styled(SubCategoryWrapper)``

const slideLeft = keyframes`
  0% { transform: translateX(80%); }
  100% { transform: translateX(0); }
`;

const Container = styled.div`
  top: 50px;
  /* width: 300px; */
  width: 240px;
  top: 15vh;
  color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  position: absolute;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-right: 10px;
  padding-bottom: 60px;
  scrollbar-width: none;
  -ms-overflow-style: none;
  height: 80.5vh;
  position: fixed;
  /* width: clamp(240px, 18vw, 300px); */



  &::-webkit-scrollbar {
    width: 10px;
    background-color: var(--background-color);
  }

  scrollbar-gutter: stable;

  &:hover {
    ::-webkit-scrollbar-track {
      background-color: #15141a;
    }

    ::-webkit-scrollbar {
      width: 8px;
      background-color: #15141a;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: #34323c;
    }
  }

  @media (max-width: 1024px) {
    padding-top: 0;
    padding: 0 25px 40px 25px;
    ${props => (props.isOpen ? 'display: inline-block; position: absolute; overflow-x: hidden; overflow-y: scroll;' : '')}
    z-index: 1000;
    top: 0;
    right: 0;
    height: 100%;
    overflow: scroll;
    width: 70%;
    animation: ${slideLeft} 0.25s cubic-bezier(0.25, 1, 0.5, 1) forwards;
    /* min-height: 100%; */
    background-color: #0b090a;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

const Wrapper = styled.div`
  width: 340px;

  @media (max-width: 1024px) {
    position: absolute;
    ${props => (props.isOpen ? 'display: flex;' : 'display: none;')}
  }
`

export default Filters;