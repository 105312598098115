import styled from "styled-components";

const Howto = () => {
  return <Wrapper><Container><WrapText>
    <p>
      <strong>To mint with wrapped Firo</strong>, you will need both testnet Firo (TFIRO) for the NFT cost and testnet Binance Coin (tBNB) for the transaction cost.
    </p>

    <p>
      <strong> To mint with BNB</strong>, you will need testnet Binance Coin (BNB, or sometimes TBNB) for both the NFT and transaction cost.
    </p>


    <p></p>
  </WrapText>
    <WrapBNBLink>
      <div>If 'Smart Chain Testnet' network is not in your wallet, you can either follow <a target="_outside" href="https://academy.binance.com/en/articles/connecting-metamask-to-binance-smart-chain">instructions from Binance (Link)</a>, or add the network below.</div>
      <div></div><p>
        <strong>NETWORK NAME:</strong> Smart Chain - Testnet
      </p>
      <p>
        <strong>New RPC URL:</strong> https://data-seed-prebsc-1-s1.binance.org:8545/
      </p>
      <p>
        <strong>ChainID:</strong> 97
      </p>
      <p>
        <strong>Symbol:</strong> BNB
      </p>
      <p>
        <strong>Block Explorer URL:</strong> https://testnet.bscscan.com
      </p>
    </WrapBNBLink>
  </Container>
  </Wrapper >

}
const WrapBNBLink = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 5px;

  a {
    color: lime;
    cursor: pointer;
  }
  
  strong {
    font-weight: 500;
  }
`


const WrapText = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  
  strong {
    font-weight: 500;
  }
`

const Container = styled.div`
  margin-top: 160px;
  line-height: 1.8rem;
  max-width: 650px;
  font-size: 1.2rem;
  color: #fff;
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

`

export default Howto;