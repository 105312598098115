import styled, { keyframes } from "styled-components"
import { useAtom } from "jotai"
import { questsAtom, interfaceAtom } from "Project/Game/data/atoms"
import { questData } from "Project/Game/data/gameData"
import { useState, useEffect } from "react"
import dayjs from "dayjs"
import { Howl } from "howler"

const Entry = ({ name, id, time, objectives, onClick }) => {
  const [timer, setTimer] = useState({ count: 0, total: 0, percentage: 0 });

  const calculateTimeRemaining = () => {
  }

  useEffect(() => {
    let timerrr;
    if (time.endTime && time.endTime > time.startTime) {
      // let timeRemain = time.endTime - time.startTime;
      let total = (time.endTime - time.startTime) / 1000;
      timerrr = setTimeout(() => {
        console.log(timer.count)
        console.log(total)
        // if (timer.count == total - 2)
        //   finishQuest.play();
        if (timer.count <= total) {
          setTimer(prev => {
            // console.log({ count: prev.count + 1, total, percentage: (prev.count / total) * 100, actual: dayjs((prev.total - prev.count) + 1 * 1000) })
            return { count: prev.count + 1, total, percentage: (prev.count / total) * 100, actual: dayjs((prev.total - prev.count) + 1 * 1000) }
          })
        }

      }, 1000)
    }
    return () => clearInterval(timerrr)
  }, [time, timer, setTimer])

  return <WrapQuest onClick={onClick}>

    <Quest delay={1.2}>
      {id === 0 && <QuestBg1 />}
      {id === 1 && <QuestBg2 />}
      {id === 2 && <QuestBg2 />}
      <QuestText>{name}</QuestText>
      <Items>
        {objectives.length > 0 && objectives.map((objective, idx) => <Item>{objectives.length > idx + 1 ? '✔' : `-`} {objective}</Item>)}
        {/* <Item>- Confront thieves</Item>
        <Item isActive={true}>- Return schematics</Item> */}
      </Items>
      {timer.count > 0 &&
        <ProgressBar>
          <TimeTxt>{parseInt(timer.total - timer.count)}</TimeTxt>
          <Filler percent={timer.percentage} />
        </ProgressBar>
      }
    </Quest>
  </WrapQuest>
}


const TimeTxt = styled.div`
  position: absolute;
  top: 50%;
  color: #fff;
  left: 50%;
  transform: translate(-50%, -50%);
`

const Filler = styled.div`
background: #ad4c43;
height: 120%;
width: ${props => props.percent}%;
`

const ProgressBar = styled.div`
  position: relative;
  width: 70%;
  height: 20px;
  display: flex;
  align-items: center;
  background: #87332b;

`

const QuestsMini = () => {
  const [interfaceState, setInterfaceState] = useAtom(interfaceAtom);
  const [quests, setQuests] = useAtom(questsAtom);
  const [displayedQuests, setDisplayedQuests] = useState([]);


  useEffect(() => {
    let ad = questData.filter(questDatabaseEntry => {
      let questData = quests.find(quest => quest.id === questDatabaseEntry.id && quest.rewardsClaimed === false && questDatabaseEntry.visible)
      return questData;
    });

    ad = ad.map(quest => {
      let questData = quests.find(questData => questData.id === quest.id);
      return { ...quest, startTime: questData.startTime, endTime: questData.endTime }
    })

    // check chain quests
    // ad.forEach(quest => { })

    console.log('ad', ad)
    setDisplayedQuests(ad)
  }, [quests])


  const handleButton = (questId) => {
    setInterfaceState(prev => {
      return { ...prev, player: { ...prev.player, main: false, mini: true }, quests: { ...prev.quests, main: false, mini: false, selected: questId } }
    })
  }

  // useEffect(() => {
  //   console.log(interfaceState)
  // }, [interfaceState])


  return <Wrapper isVisible={displayedQuests.length > 0}>
    {/* <Background /> */}
    <Quests>
      <Header>Quests</Header>
      {/* <AvailableQuests delay={0.2}>3 Available World Quests</AvailableQuests> */}
      <WrapEntries>
        {displayedQuests.map((quest, idx) => (
          <WrapSingle>
            <Breadcrumb />
            <Entry key={quest.id} id={idx} onClick={() => handleButton(quest.id)} time={{ startTime: quest.startTime, endTime: quest.endTime }} objectives={quest.objectives} name={quest.title} />
          </WrapSingle>
        ))}
      </WrapEntries>

      {/* <WrapQuest>
        <Quest delay={1.25}>
          <QuestBg2 />
          <QuestText>Check Cryo Chamber</QuestText>
        </Quest>
      </WrapQuest> */}
    </Quests>
  </Wrapper>
}

const Breadcrumb = styled.div`
  width: 18px;
  position: relative;
  top: 25px;
  border-left: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid rgba(255, 255, 255, 0.2);
`

const WrapSingle = styled.div`
  display: flex;
  position: relative;
  left: 8px;

  &:first-child:before {
      content: '';
      border-left: 4px solid rgba(255, 255, 255, 0.2);
      position: absolute;
      bottom: -22px;
      height: 30px;
      width: 3px;
      left: 0;
  }

  &:last-child {
    height: 3px;
  }
`

const WrapEntries = styled.ul`
  display: flex;
  position: relative;
  flex-direction: column;
`

const Item = styled.div`
  color: ${props => props.isActive ? '#fff' : '#aaa'};
  color: #ccc;
`

const Items = styled.div`
  align-self: flex-start;
  padding: 10px 35px 10px 35px;
  position: relative;
  display: flex;
  left: 10px;
  flex-direction: column;
  row-gap: 8px;

  div:last-child {
    color: #fff;
  }
`

const Header = styled.div`
  display: flex;
  padding: 20px 0;
  font-size: 1.6rem;
  font-family: var(--primary-text-font);
  color: #fff;
  left: -5px;
  top: -60px;
  /* left: 50%;
  transform: translateX(-50%); */
`

const Background = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 130%;
  height: 200%;
  background: rgba(0, 0, 0, 0.6);
`

const WrapQuest = styled.li`
  &:hover {
    h1 {
    }
  }
`

const animIn = keyframes`
  0% { transform: translateY(10px) scale(0.99); opacity: 0; }
  100% { transform: translateY(0) scale(1); opacity: 1; }
`

const AvailableQuests = styled.div`
  font-family: var(--primary-text-font);
  display: flex;
  justify-content: center;
  padding: 15px;
  color: #aaa;
  user-select: none;
  opacity: 0;
  animation: ${animIn} 0.5s ${props => props.delay}s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;
`

const Quests = styled.div`
  display: flex;
  transform-origin: top;
  flex-direction: column;
`

const QuestText = styled.h1`
  position: relative;
  line-height: 1.5rem;
  padding: 15px 25px 5px 25px;
  font-size: 1.5rem;
  color: #ccc;
`

const QuestBg2 = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 110%;
  transform: perspective(100px) rotate3d(4, -3, -5, -3deg) skew(-0deg, -1deg) scale(1);
`
const QuestBg1 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  /* background: #000; */
  width: 100%;
  height: 110%;
  transform: perspective(100px) rotate3d(-3, 4, -2, -1deg) skew(-2deg, -1deg) scale(1);
`

const Quest = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  font-family: var(--primary-text-font);
  opacity: 0;
  cursor: pointer;
  animation: ${animIn} 0.5s cubic-bezier(0.075, 0.82, 0.165, 1) forwards;


  /* h1:hover {
    color: red;
  } */
`

const Wrapper = styled.div`
  position: relative;
  ${props => props.isVisible ? 'display: block;' : 'display: none;'}
`

export default QuestsMini